import React, { useEffect, useState } from 'react';
import FileIcon from '../../../shared/assets/svg/file.svg';
import CustomerIcon from '../../../shared/assets/svg/customer.svg';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import {
  getAllUsers,
  getInvoiceById,
  getInvoiceByIdStatic
} from '../../services/api/implementation/impl';
import { transformData } from '../../utils/transformData';
import { useQuery } from '@tanstack/react-query';
import { getAllCompanies } from '../../../builder/services/api/implementation/impl';

export const EditInvoice = ({ onClose, onSubmit, id }) => {
  const { register, handleSubmit, setValue } = useForm();
  const [data, setData] = useState(null);

  // Fetching the invoice data by ID
  const { data: invoiceData, isLoading } = useQuery({
    queryKey: ['invoice', id],
    queryFn: () => getInvoiceById(id),
    onSuccess: (data) => {
      setData(data);
    }
  });

  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: getAllUsers
  });

  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: getAllCompanies
  });

  // useEffect to set form values when invoice data is fetched
  useEffect(() => {
    if (invoiceData) {
      setValue('amount', invoiceData.amount);
      setValue('invoice_number', invoiceData.invoice_number);
      setValue('date_issued', invoiceData.issue_date?.split('T')[0]); // Handling date format
      setValue('due_date', invoiceData.due_date?.split('T')[0]); // Handling date format
      setValue('send_sms', invoiceData.send_sms);
      setValue('send_email', invoiceData.send_email);
      setValue('notes', invoiceData.notes);
      // setValue('builder_id', invoiceData.builder?.name);
      // setValue('company_name', invoiceData.company?.company_name);
      // setValue('project_name', invoiceData.project?.name);
    }
  }, [invoiceData, setValue]);

  const handleFormSubmit = (formData) => {
    const { builder_id, company_name, project_name, ...payload } = formData;
    onSubmit(payload);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-[100%]">
      <div className="font-helvetica">
        <div className="flex items-center">
          <img src={FileIcon} alt="File Icon" />
          <h3 className="ml-2 font-bold text-lg">General Information</h3>
        </div>

        <div className="flex justify-between w-full gap-4 pt-4">
          <div className="text-md flex flex-col gap-2 w-full">
            <span className="text-gray-600">Amount</span>
            <input
              type="text"
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('amount')}
            />
          </div>

          <div className="text-md flex flex-col gap-2 w-full">
            <span className="text-gray-600">Invoice number</span>
            <input
              type="text"
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('invoice_number')}
            />
          </div>
        </div>

        <div className="flex justify-between w-full gap-4 pt-4">
          <div className="flex flex-shrink-0 w-[48%] flex-col gap-2">
            <FormInput
              width={100}
              label={'Date issued'}
              register_key={'date_issued'}
              register={register}
              type={'date'}
            />
            <div className="flex gap-1 items-center mt-2">
              <input {...register('send_sms')} className="w-[1rem] h-[1rem]" type="checkbox" />
              <label>Send SMS</label>
            </div>
          </div>

          <div className="flex flex-shrink-0 w-[48%] flex-col gap-2">
            <FormInput
              width={100}
              label={'Due date'}
              register_key={'due_date'}
              register={register}
              type={'date'}
              isRequired={true}
            />
            <div className="flex gap-1 items-center mt-2">
              <input {...register('send_email')} className="w-[1rem] h-[1rem]" type="checkbox" />
              <label>Send Email</label>
            </div>
          </div>
        </div>

        <div className="mt-6 w-full">
          <textarea
            className="border w-full p-4 rounded-md"
            {...register('notes')}
            placeholder="Add notes"
          />
        </div>

        <div className="flex w-full justify-between mt-10">
          <div className="flex items-center">
            <img src={CustomerIcon} alt="Customer Icon" />
            <h3 className="ml-2 font-bold text-lg">Customer Information</h3>
          </div>
        </div>

        <div className="flex justify-between w-full gap-4 pt-4">
          <FormInput
            width={30}
            register={register}
            isRequired
            register_key={'builder_id'}
            label={'Builders'}
            type={'text'}
            valuePre={invoiceData?.builder?.name}
          />

          <div className="flex flex-shrink-0 w-[30%] flex-col gap-2">
            <FormInput
              width={100}
              register={register}
              isRequired
              register_key={'company_name'}
              label={'Company'}
              type={'text'}
              valuePre={invoiceData?.company?.company_name}
            />
          </div>
          <div className="flex flex-shrink-0 w-[30%] flex-col gap-2">
            <FormInput
              width={100}
              label={'Project name'}
              isRequired
              register_key={'project_name'}
              register={register}
              type={'text'}
              valuePre={invoiceData?.project?.name}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(handleFormSubmit)} type="submit">
          Save
        </Button>
      </div>
    </div>
  );
};
