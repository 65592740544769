import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';

// Dummy data for statuses

// Validation schema
const validationSchema = yup.object().shape({
  status: yup.string().required('Status is required')
});

export const UpdateStatus = ({ onClose, onSubmit, statuses, type, isSubmitting }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmitForm = (data) => {
    console.log(data);

    onSubmit(data);
    onClose();
    reset();
  };

  console.log({ statuses });

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Update Status</span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          {type === 'unitPricing' ? (
            <FormInput
              width={100}
              label={'Status'}
              register={register}
              register_key={'status'}
              type={'dropdown'}
              defaultValuePre={'Select Status'}
              options={[
                ...(Array.isArray(statuses)
                  ? statuses.map((item) => ({
                      value: item?.trim(),
                      label: item?.replace(/_/g, ' ')?.trim() // Replace underscores with spaces for display
                    }))
                  : [])
              ]}
              errors={errors.status}
            />
          ) : (
            <FormInput
              width={100}
              label={'Status'}
              register={register}
              register_key={'status'}
              type={'dropdown'}
              defaultValuePre={'Select Status'}
              options={[
                ...(Array.isArray(statuses)
                  ? statuses.map((item) => ({
                      value: item?.name?.trim(),
                      label: item?.name?.replace(/_/g, ' ')?.trim() // Replace underscores with spaces for display
                    }))
                  : [])
              ]}
              errors={errors.status_id}
            />
          )}
        </div>
        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
};
