// File: OrderActivityList.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getOrderActivityByOrderId } from '../services/api/implementation/impl'; // <-- Your new API call
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import OrderActivityCard from './OrderActivityCard'; // <-- Similar to your DealCard but for orders

const OrderActivityList = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [orderId, setOrderId] = useState(null);

  const { id } = useParams();

  // Fetch order activities by order ID
  const {
    data: orderActivities,
    refetch: refetchOrderActivities,
    isLoading
  } = useQuery({
    queryKey: ['orderActivities', id],
    queryFn: () => getOrderActivityByOrderId(id),
    enabled: !!id // Ensures the query runs only when `id` is available
  });

  console.log('orderActivities:', orderActivities)

  // Once we get the data, filter or set it according to your data structure
  useEffect(() => {
    if (orderActivities) {
      // If your endpoint returns an array of activities for the given order:
      setFilteredData(orderActivities);

      // If needed, set the order ID from the param or from the returned data
      setOrderId(parseInt(id));
    }
  }, [orderActivities, id]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      {/* Header */}
      <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
        <Header data={{ name: 'Order Activities' }} backArrow={true} />
      </div>

      <div>
        {/* Main Container */}
        <div className="flex flex-col bg-white shadow-lg rounded-lg mt-[7rem] w-[96%] mx-auto p-6">
          <div className="w-full flex flex-col justify-between items-center gap-8 mt-10">
            {filteredData?.map((activity, index) => (
              <OrderActivityCard
                key={index}
                special={index === 0}  // Just an example prop; adjust as needed
                data={activity}
                orderId={orderId}
                onSuccess={() => refetchOrderActivities()}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OrderActivityList;
