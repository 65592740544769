import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import FormInput from '../../../shared/components/ui/FormInput';
import { useQuery } from '@tanstack/react-query';
import {
  getAllUsers,
  getPaymentByID,
  getPaymentsByIdStatic,
  getAllInvoices
} from '../../services/api/implementation/impl';
import { getAllCompanies, getAllProjects } from '../../../builder/services/api/implementation/impl';
import { transformData } from '../../utils/transformData';

export const EditPayment = ({ onClose, onSubmit, id, isDisabled, staticPayments }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  /* 
    Data fetching
  */
  const { data: users, isLoading: usersLoading } = useQuery({
    queryKey: ['users'],
    queryFn: () => getAllUsers()
  });

  const { data: companies, isLoading: companiesLoading } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getAllCompanies()
  });

  const { data: invoices, isLoading: invoicesLoading } = useQuery({
    queryKey: ['invoices'],
    queryFn: () => getAllInvoices()
  });

  const { data: projects, isLoading: projectsLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: () => getAllProjects()
  });

  const { data: payment, isLoading: paymentLoading } = useQuery({
    queryKey: ['payment', id],
    queryFn: () => getPaymentByID(id),
    enabled: !!id // Only fetch payment if id is provided
  });

  useEffect(() => {
    if (id) {
      const paymentData = getPaymentsByIdStatic(id, staticPayments);
      setData(paymentData);
    }
  }, [id, staticPayments]);

  /*
    Hook form
  */
  const { register, handleSubmit, setValue } = useForm();
  console.log('payment: ', payment);

  useEffect(() => {
    if (
      !usersLoading &&
      !companiesLoading &&
      !invoicesLoading &&
      !projectsLoading &&
      !paymentLoading
    ) {
      if (payment && payment[0]) {
        setValue('builder_id', payment[0]?.invoice?.builder?.id);
        setValue('company_id', payment[0]?.invoice?.company?.id);
        setValue('project_id', payment[0]?.invoice?.project?.id);
        setValue('invoice_id', payment[0].invoice_id);
        setValue('amount_paid', payment[0].amount);
      }
      setLoading(false);
    }
  }, [
    payment,
    usersLoading,
    companiesLoading,
    invoicesLoading,
    projectsLoading,
    paymentLoading,
    setValue
  ]);

  if (loading) {
    return <div>Loading...</div>;
  }

  /* 
    Helper method calls
  */
  const transformedData =
    users?.data?.map((item) => ({
      value: item.id,
      label: item.primery_user?.name?.trim()
    })) || [];

  const transformedDataCompany =
    companies?.map((item) => ({
      value: item.id,
      label: item.company_name?.trim()
    })) || [];

  const transformedDataInvoices =
    invoices?.map((item) => ({
      value: item.id,
      label: item.invoice_number?.trim()
    })) || [];

  const transformedDataProjects =
    projects?.map((item) => ({
      value: item.id,
      label: item.name?.trim()
    })) || [];

  /* 
    JSX
  */
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Builder Name'}
            register={register}
            register_key={'builder_id'}
            type={'dropdown'}
            defaultValuePre={'Select builder'}
            options={[{ value: '', label: 'Select builder' }, ...transformedData]}
          />

          <FormInput
            width={48}
            label={'Company Name'}
            register={register}
            register_key={'company_id'}
            type={'dropdown'}
            defaultValuePre={'Select company'}
            options={[{ value: '', label: 'Select company' }, ...transformedDataCompany]}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Project name'}
            register_key={'project_id'}
            register={register}
            type={'dropdown'}
            defaultValuePre={'Select project'}
            options={[{ value: '', label: 'Select project' }, ...transformedDataProjects]}
          />

          <FormInput
            width={48}
            label={'Invoice'}
            register_key={'invoice_id'}
            register={register}
            type={'dropdown'}
            defaultValuePre={'Select invoice'}
            options={[{ value: '', label: 'Select invoice' }, ...transformedDataInvoices]}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Amount paid'}
            register_key={'amount_paid'}
            register={register}
            type={'text'}
            defaultValuePre={data?.amount_paid || ''}
          />
        </div>
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} type="submit">
          Save
        </Button>
      </div>
    </>
  );
};
