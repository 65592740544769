import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import toast from 'react-hot-toast';
import cancel from '../../../shared/assets/svg/cancel_icon.svg';

const AddPaymentStage = ({ handleOnPaymentStage, onClose, isSubmitting }) => {
  const [totalPercentage, setTotalPercentage] = useState(0);

  const defaultPaymentStages = [
    { name: 'Advance', percentage: 10 },
    ...Array(9)
      .fill()
      .map((_, index) => ({
        name: `Payment ${index + 1}`,
        percentage: ''
      }))
  ];

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      payment: defaultPaymentStages
    }
  });

  const {
    fields: paymentStageFields,
    append: appendPaymentStage,
    remove: removePaymentStage
  } = useFieldArray({
    control,
    name: 'payment'
  });

  const watchPaymentFields = watch('payment');

  useEffect(() => {
    const total = watchPaymentFields.reduce(
      (acc, curr) => acc + (parseFloat(curr.percentage) || 0),
      0
    );
    setTotalPercentage(total);
  }, [watchPaymentFields]);

  const handlePercentageChange = (index, event) => {
    let value = parseFloat(event.target.value);

    if (value > 100) {
      value = 100;
    } else if (value < 0 || isNaN(value)) {
      value = 0;
    }

    setValue(`payment.${index}.percentage`, value);
    const updatedPayment = [...watchPaymentFields];
    updatedPayment[index].percentage = value;
    const total = updatedPayment.reduce((acc, curr) => acc + (parseFloat(curr.percentage) || 0), 0);
    setTotalPercentage(total);
  };

  const handleAddNewStages = () => {
    if (totalPercentage >= 100) {
      toast.error("You've reached 100% of Payment");
    } else {
      appendPaymentStage({ name: `Payment ${paymentStageFields.length + 1}`, percentage: '' });
    }
  };

  const handleOnSubmit = (data) => {
    if (totalPercentage !== 100) {
      toast.error('Total percentage must be exactly 100% before saving');
      return;
    }
    handleOnPaymentStage(data);
  };

  return (
    <div className="mt-10">
      <div className="max-h-[30rem] overflow-y-auto hide-scrollbar">
        <table className="w-full table-auto border rounded-lg">
          <thead className="bg-gray-200 !text-gray-600 rounded-t-xl">
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Percentage</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paymentStageFields?.map((field, index) => (
              <tr key={field.id}>
                <td className="px-4 py-2">
                  <FormInput
                    register={register}
                    register_key={`payment.${index}.name`}
                    type="text"
                    placeholder={`Payment ${index + 1}`}
                  />
                </td>
                <td className="px-4 py-2">
                  <input
                    {...register(`payment.${index}.percentage`)}
                    type="number"
                    placeholder="Percentage"
                    min="0"
                    max="100"
                    step="0.01"
                    onChange={(e) => handlePercentageChange(index, e)}
                    value={watchPaymentFields[index].percentage}
                    className="border rounded p-2 w-full"
                  />
                </td>
                <td className="px-4">
                  <div className="ml-6" onClick={() => removePaymentStage(index)}>
                    <img className="cursor-pointer" src={cancel} alt="cancel" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <p>Total Percentage: {totalPercentage.toFixed(2)}%</p>
        {totalPercentage !== 100 && (
          <p className="text-red-500">Total percentage must be exactly 100% before saving</p>
        )}
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          displayType="secondary"
          onClick={handleAddNewStages}
          disabled={totalPercentage >= 100}>
          Add New Stage
        </Button>
        <Button onClick={handleSubmit(handleOnSubmit)} disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddPaymentStage;
