import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { useParams } from 'react-router-dom';
import {
  getAllAccommodationsByProject,
  getBuildingsByProjectId
} from '../../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

// Validation schema
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Block Name is required')
    .max(50, 'Block Name cannot exceed 50 characters'),
  area: yup
    .number()
    .typeError('Block Area must be a number')
    .required('Block Area is required')
    .positive('Block Area must be a positive number'),

  // Conditionally require building_permission_no if type !== 'Independent'
  building_permission_no: yup.string().when('type', {
    is: (type) => type !== 'Independent',
    then: (schema) => schema.required('Building Permission No is required'),
    otherwise: (schema) => schema.notRequired().nullable()
  }),

  type: yup.string().required('Type is required'),
  number_of_levels: yup
    .number()
    .typeError('Number of Levels must be a number')
    .positive('Number of Levels must be a positive number')
    .min(1, 'Number of Levels must be at least 1')
    .required('Number of Levels is required')
});

const AddNewBlock = ({ onClose, onSubmit, onProceed, isSubmitting }) => {
  /* Hooks */
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  /* Query params */
  const { id: projectId } = useParams();

  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit || rawMeasurementUnit === 'null' || rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;

  // API calls
  const { data: buildingByProjectId } = useQuery({
    queryKey: ['buildingByProjectId', projectId],
    queryFn: () => getBuildingsByProjectId(projectId),
    enabled: !!projectId
  });

  const { data: accommodations } = useQuery({
    queryKey: ['accommodations', projectId],
    queryFn: () => getAllAccommodationsByProject(projectId)
  });

  // Create dropdown options for building permission numbers
  const buildingPermissionOptions =
    buildingByProjectId?.map((building) => ({
      value: building.id,
      label: `${building.permissionNo}`
    })) || [];

  const selectedBlockType = watch('type', '');
  const [blockTypeError, setBlockTypeError] = useState(null);

  // Set number_of_levels to 1 when Independent is selected
  useEffect(() => {
    if (selectedBlockType === 'Independent') {
      setValue('number_of_levels', 1);
    }
  }, [selectedBlockType, setValue]);

  // Check accommodations for selected type
  useEffect(() => {
    if (
      selectedBlockType &&
      (selectedBlockType === 'Independent' || selectedBlockType === 'Group') &&
      accommodations
    ) {
      const hasAccommodationOfType = accommodations.some(
        (acc) => acc.accomodationType === selectedBlockType.toUpperCase()
      );

      if (!hasAccommodationOfType) {
        setBlockTypeError(`No accommodations found for ${selectedBlockType} type`);
      } else {
        setBlockTypeError(null);
      }
    } else {
      setBlockTypeError(null);
    }
  }, [selectedBlockType, accommodations]);

  // Handle form submission
  const handleProceed = (data) => {
    const payload = {
      project_id: parseInt(projectId),
      name: data.name,
      area: parseInt(data.area),
      type: data.type,
      number_of_levels: parseInt(data.number_of_levels)
    };

    // If block type is not "Independent", then include building_id
    if (data.type !== 'Independent') {
      payload.building_id = parseInt(data.building_permission_no);
    }

    console.log(payload);
    onProceed(payload);
  };

  // Limit input to numeric values  //wriet a function to restrict number of units to only number
  const handleNumberOfUnitsChange = useCallback(
    async (val) => {
      const cleanedValue = val.replace(/[^0-9]/g, '').slice(0, 8);
      setValue('number_of_levels', cleanedValue);
    },
    [setValue]
  );

  //wriet a function to restrictblock area to only number
  const handleBlockAreaChange = useCallback(
    async (val) => {
      const cleanedValue = val.replace(/[^0-9]/g, '').slice(0, 8);
      setValue('area', cleanedValue);
    },
    [setValue]
  );

  return (
    <>
      <div>
        <div className="flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Block Details</span>
        </div>
        <form onSubmit={handleSubmit(handleProceed)} className="flex flex-col gap-4 pt-6">
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label={'Project ID'}
              register={register}
              isRequired={true}
              register_key={'project_id'}
              valuePre={projectId}
              type={'hidden'}
            />
            <FormInput
              width={48}
              label={'Block Name *'}
              register={register}
              register_key={'name'}
              type={'text'}
              errors={errors.name}
            />
            <FormInput
              width={48}
              label={`Block Area (${measurementType ? measurementType : 'sq/ft'} *)`}
              register={register}
              register_key={'area'}
              type={'text'}
              onType={handleBlockAreaChange}
              errors={errors.area}
            />
          </div>

          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label={'Type *'}
              register={register}
              register_key={'type'}
              type={'dropdown'}
              options={[
                { value: '', label: 'Select Type', disabled: true, selected: true },
                { value: 'Independent', label: 'Independent' },
                { value: 'Group', label: 'Group' }
              ]}
              errors={errors.type}
            />
            <FormInput
              width={48}
              label={`Building Permission No ${selectedBlockType === 'Independent'? '(Optional)' : '*'}`}
              register={register}
              register_key={'building_permission_no'}
              type={'dropdown'}
              options={[
                {
                  value: '',
                  label: 'Select Building Permission No',
                  disabled: true,
                  selected: true
                },
                ...buildingPermissionOptions
              ]}
              errors={errors.building_permission_no}
            />
          </div>

          <div className="flex justify-between items-center w-full">
            {selectedBlockType === 'Independent' ? (
              <FormInput
                width={48}
                label={'Number of Levels *'}
                register={register}
                register_key={'number_of_levels'}
                type={'text'}
                valuePre={1}
                disabled
                errors={errors.number_of_levels}
              />
            ) : (
              <FormInput
                width={48}
                label={'Number of Levels *'}
                register={register}
                register_key={'number_of_levels'}
                type={'text'}
                onType={handleNumberOfUnitsChange}
                errors={errors.number_of_levels}
              />
            )}
          </div>

          {blockTypeError && <div className="text-red-500 mt-2">{blockTypeError}</div>}

          <div className="w-full flex justify-end items-center space-x-4 mt-8">
            <Button type="reset" onClick={onClose} displayType="secondary">
              Cancel
            </Button>
            <Button type="submit" disabled={blockTypeError || isSubmitting}>
              Proceed
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddNewBlock;
