import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../ui/FormInput';
import Button from '../ui/Button';

const ForgotPasswordForm = ({
  step,
  onSendCode,
  onResendCode,
  onResetPassword,
  isSendingCode,
  isResettingPassword
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      email: '',
      code: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  // For demonstration: you could display a success message after Step 2 or something similar
  const [successMsg, setSuccessMsg] = useState('');

  // Step 1 submission: just gather email
  const handleEmailSubmission = ({ email }) => {
    if (!email) return;
    // calls parent's "onSendCode"
    onSendCode(email);
  };

  // Step 2 submission: gather code, newPassword, confirmPassword
  const handleResetSubmission = ({ code, newPassword, confirmPassword }) => {
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    // calls parent's "onResetPassword"
    onResetPassword({ code, newPassword });
    reset();
  };

  return (
    <div className="flex flex-col gap-6 w-full">
      {/* STEP 1: Enter Email */}
      {step === 1 && (
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleEmailSubmission)}>
          <FormInput
            key={'sendEmail'}
            register={register}
            register_key={'email'}
            label={'Email'}
            type={'email'}
            isRequired={true}
          />
          {errors.email && <p className="text-red-500">Email is required</p>}

          <div className="w-full flex flex-col items-center gap-4">
            <Button type="submit" displayType="full-w-primary" width={100} disabled={isSendingCode}>
              {isSendingCode ? 'Sending...' : 'Send Code'}
            </Button>
          </div>
        </form>
      )}

      {/* STEP 2: Enter Code, New Password, Confirm Password */}
      {step === 2 && (
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleResetSubmission)}>
          {/* add two more hidden fields for email and password  */}
          <input type="hidden" {...register('hiddenEmail')} />
          <input type="hidden" {...register('hiddenPassword')} />
          <FormInput
            key={'VerificationCode'}
            register={register}
            register_key={'code'}
            label={'Verification Code'}
            type={'text'}
            isRequired={true}
            autoComplete={'one-time-code'}
          />
          {errors.code && <p className="text-red-500">Code is required</p>}

          <FormInput
            key={'newPassword'}
            register={register}
            register_key={'newPassword'}
            label={'New Password'}
            type={'password'}
            isRequired={true}
            autoComplete={'newPassword'}
          />
          {errors.newPassword && <p className="text-red-500">New Password is required</p>}

          <FormInput
            key={'confirmPassword'}
            register={register}
            register_key={'confirmPassword'}
            label={'Confirm Password'}
            type={'password'}
            isRequired={true}
            autoComplete={'confirmPassword'}
          />
          {errors.confirmPassword && <p className="text-red-500">Please confirm your password</p>}

          <div className="flex flex-col items-center gap-4">
            {/* Resend Code */}
            <button
              type="button"
              onClick={onResendCode}
              className="text-blue-700 underline text-sm">
              Resend Code
            </button>

            <Button
              type="submit"
              displayType="full-w-primary"
              width={100}
              disabled={isResettingPassword}>
              {isResettingPassword ? 'Resetting...' : 'Reset Password'}
            </Button>
          </div>
        </form>
      )}

      {/* Optionally show a success message or anything else after resetting */}
      {successMsg && <p className="text-green-600">{successMsg}</p>}
    </div>
  );
};

export default ForgotPasswordForm;
