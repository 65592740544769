import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Slider package
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getAllProjects, createPost, getUserById } from '../../services/api/implementation/impl';
import FormInput from '../../../shared/components/ui/FormInput';
import company_image from '../../../shared/assets/png/company_image.png';
import Button from '../../../shared/components/ui/Button';
import LoginLogo from '../../../shared/assets/svg/login_logo.svg';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Import slider CSS
import { AiFillFile } from 'react-icons/ai'; // Icon for non-image files

export const CreatePost = ({ onSuccess }) => {
  const userId = Cookies.get('userId');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm();

  const [uploadImg, setUploadImg] = useState(false);
  const [userName, setUserName] = useState('');
  const MultipleFiles = useSelector((state) => state.file.multifiles);

  const { data: allProjects, refetch: refetchProjects } = useQuery({
    queryKey: ['getallproject'],
    queryFn: () => getAllProjects()
  });

  // Fetch user details
  const { data: userData, refetch: refetchUser } = useQuery({
    queryKey: ['getUserById', userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId
  });

  useEffect(() => {
    refetchUser();
    setUserName(userData?.name);
  }, [userId, userData]);

  const { mutate: handleOnSubmitCreatePost, isLoading: isLoadingCreatePost } = useMutation({
    mutationFn: (newPost) => createPost(newPost),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (res) => {
      toast.success('Post created successfully');
      setIsSubmitting(false);
      onSuccess();
    },
    onError: (err) => {
      console.error('Error creating new post:', err);
      toast.error('Error creating post');
      setIsSubmitting(false);
    }
  });

  const onSubmit = async (formData) => {
    const payload = {
      project_id: formData.project,
      title: formData.caption,
      post: formData.description,
      is_public: formData.visibility === 'Public',
      attachments: MultipleFiles?.postimg || [] // Attach uploaded images or files
    };

    console.log('Payload', payload);

    handleOnSubmitCreatePost(payload);
  };
  // Slider settings
  const sliderSettings =
    MultipleFiles?.postimg && MultipleFiles.postimg.length > 1
      ? {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      : {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false // Hide arrows if only one item
        };

  // Function to check if the file is an image
  const isImage = (file) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-10">
          <div className="w-[48%] h-[30rem] flex flex-col gap-3">
            <div className="w-full h-[25rem]">
              {/* Display slider with uploaded images or file icons */}
              {MultipleFiles?.postimg && MultipleFiles?.postimg.length > 0 ? (
                <Slider {...sliderSettings}>
                  {MultipleFiles.postimg?.map((file, index) => (
                    <div key={index}>
                      {isImage(file) ? (
                        <img className="w-full h-[25rem]" src={file.url} alt={file.name} />
                      ) : (
                        <div className="flex flex-col items-center justify-center h-[25rem]">
                          <AiFillFile size={80} />
                          <p>{file.name}</p>
                          <a
                            href={file.url}
                            download
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}>
                            <Button type="button">Download</Button>
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </Slider>
              ) : (
                <img className="w-full h-[25rem]" src={company_image} alt="Default" />
              )}
            </div>
            <FormInput
              register={register}
              register_key={'postimg'}
              label={'Upload Image/File'}
              type={'file-secondary'}
            />
          </div>
          <div className="w-[50%] flex flex-col gap-2">
            <div className="flex items-center gap-5 text-xl font-semibold">
              <img
                className="inline-block h-8 w-8 rounded-full ring-3 ring-white"
                src={LoginLogo}
                alt="Logo_image"
              />
              <p>{userName || ''}</p>
            </div>
            <FormInput
              register={register}
              register_key={`caption`}
              type="text"
              placeholder={`Title...`}
              width={80}
            />
            <textarea
              className="border w-[28.2rem] p-2 h-[7rem]"
              placeholder="Add description ..."
              {...register('description')}
            />
            <FormInput
              width={80}
              register={register}
              isRequired={true}
              label={'Project'}
              register_key={'project'}
              type={'dropdown'}
              defaultValuePre={'Select Project'}
              options={allProjects?.map((item) => ({
                label: item.name,
                value: item.id
              }))}
            />
            <FormInput
              width={80}
              register={register}
              isRequired={true}
              register_key={'visibility'}
              type={'dropdown'}
              label={'Visibility'}
              defaultValuePre={'Select Visibility'}
              options={['Private', 'Public']?.map((item) => ({
                label: item,
                value: item
              }))}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-5 items-center">
          <Button displayType={'secondary'}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            Post
          </Button>
        </div>
      </form>
    </>
  );
};
