export function splitData(str, splitKey, joinKey) {
  const spaceSepStr = str.split(splitKey).join(joinKey);
  return spaceSepStr.charAt(0).toUpperCase() + spaceSepStr.slice(1);
}

export function capitaliseData(str) {
  // console.log(str)
  const data = str?.charAt(0)?.toUpperCase() + str?.slice(1);
  // console.log("Transofrmed data: ", data);
  return data;
}
