import React, { useEffect, useState } from 'react';

const TabComponents = ({
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick
}) => {
  const [selectedButton, setSelectedButton] = useState('left'); // Initialize with 'left'

  // useEffect(() => {
  //   onLeftButtonClick(); // Trigger the left button click when the component mounts
  // }, [onLeftButtonClick]);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
    if (button === 'left' && onLeftButtonClick) {
      onLeftButtonClick();
    } else if (button === 'right' && onRightButtonClick) {
      onRightButtonClick();
    }
  };

  return (
    <div className="button-group flex gap-5">
      <p
        className={`p-1 cursor-pointer button ${selectedButton === 'left' ? 'active border-b-2 border-customBlue' : ''}`}
        onClick={() => handleButtonClick('left')}
      >
        {leftButtonText}
      </p>
      <p
        className={`p-1 cursor-pointer button ${selectedButton === 'right' ? 'active border-b-2 border-customBlue' : ''}`}
        onClick={() => handleButtonClick('right')}
      >
        {rightButtonText}
      </p>
    </div>
  );
};

export default TabComponents;
