import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import Button from '../../shared/components/ui/Button';
import FormInput from '../../shared/components/ui/FormInput';
import {
  addLateFeeConfig,
  getlatefeebyId,
  updateLateFeeConfig
} from '../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// Validation schema
const validationSchema = yup.object().shape({
  gracePeriod: yup.number().required('Grace Period is required'),
  lateFee: yup
    .number()
    .required('Late fee % is required')
    .max(100, 'Late Fee % should not be more than 100%')
});

export const LateFee = () => {
  const { id: projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [latefeeId, setlatefeeId] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  // Fetch existing late fee data (if any)
  const {
    data: getlatefeeData,
    refetch: refetchLateFeeData
  } = useQuery({
    queryKey: ['getlatefeeID', projectId],
    queryFn: () => getlatefeebyId(parseInt(projectId)),
    enabled: !!projectId
  });

  // Mutation: Add Late Fee
  const { mutate: addFeeConfigHandler } = useMutation({
    mutationFn: (payload) => addLateFeeConfig(payload),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: (res) => {
      setIsSubmitting(false);
      toast.success('Late fee config created successfully');
      setlatefeeId(res.id);
      setIsEdit(true);

      // Optionally reset form with newly created data
      reset({
        lateFee: res.percentage,
        gracePeriod: res.days
      });
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error creating late fee config');
      console.error('Error creating new late fee config', err);
    }
  });

  // Mutation: Update Late Fee
  const { mutate: updatelatefee } = useMutation({
    mutationFn: (payload) => updateLateFeeConfig(latefeeId, payload),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: (res) => {
      setIsSubmitting(false);
      toast.success('Late fee config updated successfully');
      reset({
        lateFee: res.percentage,
        gracePeriod: res.days
      });
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error updating late fee config');
      console.error('Error updating late fee config', err);
    }
  });

  // Form submit handler
  const onSubmitForm = (data) => {
    const payload = {
      project_id: parseInt(projectId),
      days: data.gracePeriod,
      percentage: data.lateFee
    };

    if (isEdit) {
      // Update late fee if record exists
      updatelatefee(payload);
    } else {
      // Otherwise, add new late fee record
      addFeeConfigHandler(payload);
    }
  };

  useEffect(() => {
    // Refetch data when projectId changes
    refetchLateFeeData();
  }, [projectId, refetchLateFeeData]);

  useEffect(() => {
    // If getlatefeeData has an object, switch to edit mode and prefill form
    if (getlatefeeData && Array.isArray(getlatefeeData) && getlatefeeData.length > 0) {
      const [lateFeeRecord] = getlatefeeData;
      setIsEdit(true);
      setlatefeeId(lateFeeRecord.id);

      reset({
        lateFee: lateFeeRecord.percentage || '',
        gracePeriod: lateFeeRecord.days || ''
      });
    } else {
      // No data means add mode
      setIsEdit(false);
      setlatefeeId('');
      reset({ 
        lateFee: '',
        gracePeriod: ''
      });
    }
  }, [getlatefeeData, reset]);

  const [maxMonthlyFee, setMaxMonthlyFee] = useState('');

  const handleMonthlyLateFeeChange = (e) => {
    let inputVal = e;

    // 1) Remove anything that's not a digit:
    inputVal = inputVal.replace(/\D/g, '');

    // 2) Convert to a number and cap at 100:
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Monthly late fee % cannot exceed 100');
    }

    // 3) Set our local state and also setValue in RHF so the form sees this updated value.
    setMaxMonthlyFee(inputVal);
    setValue('lateFee', inputVal);
  }

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4 mt-[15rem] p-5">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Late Fee Configuration
        </span>
      </div>

      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 p-5 pt-0">
        <div className="flex justify-between items-center w-full">
          <div className="w-[48rem] relative">
            <FormInput
              width={90}
              label="Monthly Late Fee %"
              isRequired
              register_key="lateFee"
              register={register}
              type="text"
              onType={handleMonthlyLateFeeChange}
              errors={errors.lateFee}
              defaultValuePre={maxMonthlyFee}
            />
            <span className="p-1 px-2 absolute right-[12%] top-[50%] bg-gray-100 rounded-md text-sm text-gray-600">
              %
            </span>
          </div>

          <div className="w-[48rem] relative">
            <FormInput
              width={90}
              label="Invoice Grace Period"
              isRequired
              register_key="gracePeriod"
              register={register}
              type="number"
              errors={errors.gracePeriod}
            />
            <span className="p-1 px-2 absolute right-[12%] top-[50%] bg-gray-100 rounded-md text-sm text-gray-600">
              Days
            </span>
          </div>
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="submit" disabled={isSubmitting}>
            {isEdit ? 'Update' : 'Add'}
          </Button>
        </div>
      </form>
    </>
  );
};
