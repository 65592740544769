import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Multiselect from 'multiselect-react-dropdown';
import { toast } from 'react-hot-toast'; // <-- Make sure you've installed react-toastify
import Button from '../../../shared/components/ui/Button';
import { getAllBlocks, getPlcbyId } from '../../services/api/implementation/impl';

export const EditPlcList = ({ id, handleEditplcSubmit, onClose, isSubmitting }) => {
  const { id: projectId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      block_ids: [],
    },
  });

  const { data: blockPlcId, refetch: refetchblockPlcId } = useQuery({
    queryKey: ['blocks', id],
    queryFn: () => getPlcbyId(parseInt(id)),
    enabled: !!id,
  });

  const { data: allBlocks, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks'],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId,
  });

  console.log({ blockPlcId });
  console.log({ allBlocks });

  const blockOptions =
    allBlocks
      ?.filter((item) => item?.levels?.length > 0)
      ?.map((item) => ({ id: item?.id, name: item.name })) || [];

  useEffect(() => {
    refetchblockPlcId();
    refetchBlocks();
  }, [id, projectId, refetchblockPlcId, refetchBlocks]);

  useEffect(() => {
    if (blockPlcId) {
      // Set default values once data is available
      setValue('name', blockPlcId.name || '');
      setValue('description', blockPlcId.description || '');
      setValue(
        'block_ids',
        blockPlcId?.plc_block_mapping?.map((item) => ({
          id: item.block.id,
          name: item.block.name,
        })) || []
      );
    }
  }, [blockPlcId, setValue]);

  // Handle valid form submission
  const onValid = (data) => {
    const transformedData = {
      ...data,
      block_ids: data?.block_ids?.map((block) => block.id),
    };

    console.log(transformedData);
    handleEditplcSubmit(transformedData);
  };

  // Handle invalid form submission (display toast errors)
  const onInvalid = (formErrors) => {
    if (formErrors.name) {
      toast.error(formErrors.name.message);
    }
    if (formErrors.block_ids) {
      toast.error(formErrors.block_ids.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onValid, onInvalid)}>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Accommodation Details
        </span>
      </div>

      <div className="grid grid-cols-2 gap-3 mt-6">
        {/* PLC Type Name (Required) */}
        <div>
          <label className="block mb-2">PLC Type Name</label>
          <input
            {...register('name', {
              required: 'PLC Type Name is required',
            })}
            className="w-full p-2 border rounded"
          />
          {/* Inline error (optional) */}
          {errors.name && (
            <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>

        {/* PLC Description (Optional) */}
        <div>
          <label className="block mb-2">PLC Description</label>
          <input
            {...register('description')}
            className="w-full p-2 border rounded"
          />
        </div>

        {/* Associated Block (Required) */}
        <div>
          <label className="block mb-2">Associated Block</label>
          <Controller
            name="block_ids"
            control={control}
            rules={{ required: 'At least one block is required' }}
            render={({ field }) => (
              <Multiselect
                options={blockOptions}
                displayValue="name"
                onSelect={field.onChange}
                onRemove={field.onChange}
                selectedValues={field.value}
                style={{
                  chips: { background: '#2A565E' },
                  searchBox: {
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px',
                  },
                }}
              />
            )}
          />
          {/* Inline error (optional) */}
          {errors.block_ids && (
            <p className="text-red-500 text-sm mt-1">{errors.block_ids.message}</p>
          )}
        </div>
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
};
