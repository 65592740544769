import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { set, useForm } from 'react-hook-form';
import EditTable from '../../../shared/components/layout/EditTable';
import { useQuery } from '@tanstack/react-query';
import { getBlockByID } from '../../services/api/implementation/impl';

export const EditLevel = ({
  onClose,
  handleEditLevel,
  data,
  currentLevelIndex,
  setCurrentLevelIndex,
  noOflevel,
  allAccommodations,
  blockType,
  blockSaved,
  prevLevelData
}) => {
  const [blockName, setBlockName] = useState('');
  useEffect(() => {
    if (blockSaved) {
      reset();
    }
  }, [blockSaved]);

  const getNumber = (name) => {
    const number = name.match(/\d+/g);
    return number;
  };
  function getFilteredFloorByType(data, floorType) {
    // Traverse the array in reverse order
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].floor_type === floorType) {
        // Create a new object excluding keys that start with "Unit" or specific keys
        const filteredObject = Object.fromEntries(
          Object.entries(data[i]).filter(
            ([key]) =>
              !key.startsWith('Unit') &&
              key !== 'name' &&
              key !== 'floor_type' &&
              key !== 'no_of_units'
          )
        );
        return filteredObject;
      }
    }
    // Return undefined if no match is found
    return undefined;
  }

  const getNumberFromName = useCallback(getNumber(data[currentLevelIndex]?.name), [
    data,
    currentLevelIndex
  ]);

  const { data: blockDyna, refetch: refetchBlock } = useQuery({
    queryKey: ['block'],
    queryFn: () => getBlockByID(data[0].blockId),
    enabled: !!data[0].blockId
  });

  useEffect(() => {
    if (blockDyna) {
      setBlockName(blockDyna.name);
      refetchBlock(); // Ensure refetching happens once blockDyna is set
    }
  }, [blockDyna]);

  const { register, handleSubmit, reset, watch, setValue } = useForm();
  const [blocksArray, setBlocksArray] = useState([]);

  useEffect(() => {
    const buildBlocksArray = () => {
      if (
        data.length === 0 ||
        currentLevelIndex < 0 ||
        currentLevelIndex >= data.length ||
        !blockName
      ) {
        setBlocksArray([]); // Reset the blocks array if data is not valid
        reset(); // Reset the form fields
        console.log('Invalid data');
        return;
      }

      const currentData = data[currentLevelIndex];
      const newBlocksArray = [];

      const getDropdownString = (blockType, unitType, accommodations) => {
        let filteredAccommodations;

        if (blockType) {
          filteredAccommodations = accommodations.filter(
            (accommodation) =>
              accommodation.accomodationType.toLowerCase() === blockType.toLowerCase() &&
              accommodation.unitType.toLowerCase() === unitType.toLowerCase()
          );
        } else {
          filteredAccommodations = accommodations.filter(
            (accommodation) => accommodation.unitType.toLowerCase() === unitType.toLowerCase()
          );
        }

        return `dropdown-[${filteredAccommodations?.map((accommodation) => `{value: '${accommodation.id}', label: '${accommodation.name}'}`).join(', ')}]`;
      };

      let dropdownString;
      if (currentData.floorType === 'RESIDENTIAL') {
        dropdownString = getDropdownString(blockType, 'Residential', allAccommodations);
      } else if (currentData.floorType === 'COMMERCIAL') {
        dropdownString = getDropdownString(blockType, 'Commercial', allAccommodations);
      } else if (currentData.floorType === 'PARKING') {
        const parkingAccommodations = allAccommodations.filter(
          (accommodation) => accommodation.accomodationType === 'PARKING'
        );
        dropdownString = `dropdown-[${parkingAccommodations?.map((accommodation) => `{value: '${accommodation.id}', label: '${accommodation.name}'}`).join(', ')}]`;
      } else {
        dropdownString = "dropdown-[{value: '2W', label: '2W'}, {value: '4W', label: '4W'}]";
      }

      for (let j = 0; j < currentData.noOfUnits; j++) {
        const unitNumber = j + 1;
        const formattedUnitNumber = unitNumber < 10 ? `0${unitNumber}` : `${unitNumber}`;

        newBlocksArray.push({
          level_name: `${
            currentData.floorType === 'PARKING'
              ? `L-${getNumberFromName}, P${unitNumber}`
              : blockType === 'Independent'
                ? `${blockName}-${formattedUnitNumber}`
                : `0${getNumberFromName}-${formattedUnitNumber}`
          }`,
          accommodation_type: dropdownString
        });
      }

      console.log('newBlocksArray', newBlocksArray);
      setBlocksArray(newBlocksArray);
      setValue('name', currentData.name);
      setValue('floor_type', currentData.floorType);
      setValue('no_of_units', currentData.noOfUnits);
    };
    setTimeout(() => {
      buildBlocksArray();
    }, 0);
  }, [
    data,
    currentLevelIndex,
    allAccommodations,
    blockType,
    reset,
    blockName,
    getNumberFromName,
    prevLevelData
  ]);

  const handleSaveEditLevel = async (formData) => {
    await handleEditLevel(formData); // Call the parent handler for level save

    // Reset after the current level is submitted
    setBlocksArray([]); // Clear the blocks array
    reset(); // Reset form fields
    if (currentLevelIndex < noOflevel - 1) {
      setCurrentLevelIndex(currentLevelIndex + 1); // Move to next level
    } else {
      onClose(); // Close if it's the last level
    }
  };

  useEffect(() => {
    if (prevLevelData) {
      const filteredData = getFilteredFloorByType(
        prevLevelData,
        data[currentLevelIndex]?.floorType
      );
      console.log('filteredData', currentLevelIndex, filteredData);
      const currentLevelData = data[currentLevelIndex];
      if (filteredData) {
        setValue('name', currentLevelData.name);
        setValue('floor_type', currentLevelData.floorType);
        setValue('no_of_units', currentLevelData.noOfUnits);
        reset(filteredData);
      }
    }
  }, [prevLevelData, currentLevelIndex]);

  return (
    <div>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Level Details</span>
      </div>
      <div className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Level Name'}
            register={register}
            register_key={'name'}
            type={'text'}
            valuePre={data[currentLevelIndex]?.name || ''}
          />

          <FormInput
            width={48}
            label={'Floor Type'}
            register={register}
            register_key={'floor_type'}
            valuePre={data[currentLevelIndex]?.floorType || ''}
            type={'text'}
          />
        </div>
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'No of Units'}
            register={register}
            register_key={'no_of_units'}
            type={'text'}
            valuePre={data[currentLevelIndex]?.noOfUnits || ''}
          />
        </div>
      </div>

      <div className="mt-10 h-[15rem]">
        {data[currentLevelIndex]?.floorType === 'SERVICES' ? (
          <p>Cant assign unit</p>
        ) : (
          <>
            <EditTable
              headers={['Unit Name', 'Accommodation Name']}
              data={blocksArray}
              onRegister={register}
            />
          </>
        )}
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-16">
        <Button type="submit" onClick={handleSubmit(handleSaveEditLevel)}>
          Next
        </Button>
      </div>
    </div>
  );
};
