import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { updateApplication } from '../../services/api/implementation/impl';

// Validation schema
const validationSchema = yup.object().shape({
  a_deed_date: yup.date().required('Deed date is required'),
  a_no_of_apartments: yup
    .number()
    .required('Number of apartments is required')
    .positive('Number must be a positive value'),
  a_no_of_houses: yup
    .number()
    .required('Number of houses is required')
    .positive('Number must be a positive value'),
  a_no_of_commercial_properties: yup
    .number()
    .required('Number of commercial properties is required')
    .positive('Number must be a positive value'),
  a_registry_para: yup.string().required('Registry paragraph is required'),
  handover_date: yup.date().required('Handover date is required'),
  a_ownership_act: yup.string().required('Ownership act is required')
});

export const AgreementMissingForm = ({ onClose, onSubmit, selectedApplicationId, onSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });
  console.log(selectedApplicationId);

  const { mutate: updateApplicationHandler } = useMutation({
    mutationFn: (formData) => updateApplication(selectedApplicationId, formData),
    onSuccess: () => {
      toast.success('Agreement updated successfully');
      onSuccess();
      onClose();
    },
    onError: () => {
      toast.error('Error updating agreement');
    }
  });

  const transformPayload = (data) => {
    return {
      a_deed_date: data.a_deed_date || null,
      a_no_of_apartments: data.a_no_of_apartments || null,
      a_no_of_houses: data.a_no_of_houses || null,
      a_no_of_commercial_properties: data.a_no_of_commercial_properties || null,
      a_registry_para: data.a_registry_para || null,
      handover_date: data.handover_date || null,
      a_ownership_act: data.a_ownership_act || null
    };
  };

  const onSubmitForm = (data) => {
    const transformedData = transformPayload(data);
    updateApplicationHandler(transformedData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
      <div className="flex justify-between items-center w-full">
        <FormInput
          label={'Deed Date'}
          isRequired
          width={32}
          register_key={'a_deed_date'}
          register={register}
          type={'date'}
          errors={errors.a_deed_date}
        />
        <FormInput
          label={'Number of Apartments'}
          isRequired
          width={32}
          register_key={'a_no_of_apartments'}
          register={register}
          type={'number'}
          errors={errors.a_no_of_apartments}
        />
        <FormInput
          label={'Number of Houses'}
          isRequired
          width={32}
          register_key={'a_no_of_houses'}
          register={register}
          type={'number'}
          errors={errors.a_no_of_houses}
        />
      </div>
      <div className="flex justify-between items-center w-full">
        <FormInput
          label={'Number of Commercial Properties'}
          isRequired
          width={32}
          register_key={'a_no_of_commercial_properties'}
          register={register}
          type={'number'}
          errors={errors.a_no_of_commercial_properties}
        />
        <FormInput
          label={'Ownership Act'}
          isRequired
          width={32}
          register_key={'a_ownership_act'}
          register={register}
          type={'text'}
          errors={errors.a_ownership_act}
        />

        <FormInput
          label={'Handover Date'}
          isRequired
          width={32}
          register_key={'handover_date'}
          register={register}
          type={'date'}
          errors={errors.handover_date}
        />
      </div>
      <div className="flex justify-between items-center w-full">
        <FormInput
          label={'Registry Paragraph'}
          isRequired
          width={64}
          register_key={'a_registry_para'}
          register={register}
          type={'textarea'}
          errors={errors.a_registry_para}
        />
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="button" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
};
