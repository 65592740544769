// components/SalesInventoryReports.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse'; // Import papaparse for CSV generation
import { toast } from 'react-hot-toast'; // Import react-hot-toast for notifications

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Table from '../../shared/components/layout/Table';
import Search from '../../shared/components/ui/Search';
import Button from '../../shared/components/ui/Button';
import PaginationComponent from '../../shared/components/PaginationComponent';
import NoDataFound from '../components/layout/NoDataFound';

import filter from '../../../modules/shared/assets/svg/filter.svg';
import FileDownload from '../../../modules/shared/assets/svg/download.svg';

// <-- The APIs
import {
  getAllProjects,
  getInventoryReports, // <-- your API to fetch the inventory data
} from '../services/api/implementation/impl';

// <-- The same utility used in UsersList
import { transformData } from '../../super-admin/utils/transformData';

const SalesInventoryReports = () => {
  /*
   * -------------------------------------------------
   * Data Format for the Inventory Table
   * (No Sales Contribution column)
   * -------------------------------------------------
   */
  const inventoryDataFormat = [
    'id',
    'accommodation_name',
    'accommodation_type',
    'units_sold',
    'units_available',
    'units_sold_contribution',
  ];

  /*
   * -------------------------------------------------
   * State & Refs
   * -------------------------------------------------
   */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Company');
  const applyFilterRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState([]);

  // For server-side pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // or make this dynamic
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  // react-hook-form
  const { register } = useForm();

  // Downloading State
  const [isDownloading, setIsDownloading] = useState(false);
  const [dotCount, setDotCount] = useState(0);

  /*
   * -------------------------------------------------
   * Fetch Projects
   * -------------------------------------------------
   */
  const {
    data: projects = [],
    isFetching: isFetchingProjects,
  } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
    keepPreviousData: true,
  });

  /*
   * -------------------------------------------------
   * Fetch Inventory Reports (Server-Side Pagination)
   * -------------------------------------------------
   */
  const {
    data: inventoryResponse,
    isFetching: isFetchingInventory,
  } = useQuery({
    queryKey: ['inventoryReports', page, limit, selectedProjectId],
    queryFn: () => getInventoryReports(page, limit, selectedProjectId),
    refetchOnMount: 'always',
    keepPreviousData: true,
  });

  // inventoryResponse is assumed to have the form { data: [...], meta: { total, limit, page } }
  const inventoryData = inventoryResponse?.data || [];

  // total number of items returned by the server
  const totalItems = inventoryResponse?.meta?.total || 0;
  // compute total pages
  const totalPages = Math.ceil(totalItems / limit);

  /*
   * -------------------------------------------------
   * Transform the Inventory Data
   * -------------------------------------------------
   */
  const processedInventory = inventoryData.map((item) => {
    // Example fields. Adapt to your real API data.
    // Removing "salesContribution" entirely.
    // 'units_sold_contribution' can be turned into a percentage string if desired.
    const unitsSoldContribution = item?.units_sold_contribution ?? 0; // Ensure correct field name
    return {
      id: item?.id ?? 'no-id', // unique identifier
      accommodation_name: item?.name || 'N/A',
      accommodation_type: item?.accomodationType || 'N/A',
      units_sold: item?.unitsSold || 0,
      units_available: item?.unitsAvailable || 0,
      units_sold_contribution: `${unitsSoldContribution}%`,
    };
  });

  // Apply transformData
  const transformedInventoryData = processedInventory.map((row) =>
    transformData(row, inventoryDataFormat)
  );

  /*
   * -------------------------------------------------
   * Filter Widget Logic
   * -------------------------------------------------
   */
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    handleClearAll();
  };

  const handleFilterClick = (filterName) => {
    setSelectedFilter(filterName);
  };

  const handleCheckboxChange = (val) => {
    setCheckedItems((prev) =>
      prev.includes(val) ? prev.filter((item) => item !== val) : [...prev, val]
    );
  };

  const handleClearAll = () => {
    setCheckedItems([]);
    setSelectedFilter('Company');
  };

  // Close the filter widget if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (applyFilterRef.current && !applyFilterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        handleClearAll();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /*
   * -------------------------------------------------
   * Page Change
   * -------------------------------------------------
   */
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  /*
   * -------------------------------------------------
   * Project Selection
   * -------------------------------------------------
   */
  const handleProjectNameChange = (e) => {
    setSelectedProjectId(e.target.value);
    setPage(1); // Reset to first page when project changes
  };

  /*
   * -------------------------------------------------
   * Handle Download CSV using papaparse
   * -------------------------------------------------
   */
  const handleDownload = async () => {
    if (isDownloading) return; // Prevent multiple clicks

    setIsDownloading(true);
    try {
      // Fetch all data with page=1 and limit=1000, including selectedProjectId if applicable
      const response = await getInventoryReports(1, 1000, selectedProjectId);
      const allInventoryData = response?.data || [];

      if (allInventoryData.length === 0) {
        toast.error('No data available to download.');
        return;
      }

      // Define CSV headers
      const headers = [
        'Accommodation Name',
        'Accommodation Type',
        'No of Units Sold',
        'Units Available',
        'Units Sold Contribution',
      ];

      // Map data to CSV rows
      const rows = allInventoryData.map((item) => ({
        'Accommodation Name': item?.name || 'N/A',
        'Accommodation Type': item?.accomodationType || 'N/A',
        'No of Units Sold': item?.unitsSold !== undefined ? item.unitsSold : 0,
        'Units Available': item?.unitsAvailable !== undefined ? item.unitsAvailable : 0,
        'Units Sold Contribution': item?.units_sold_contribution !== undefined ? `${item.units_sold_contribution}%` : '0%',
      }));

      // Convert to CSV using papaparse
      const csv = Papa.unparse({
        fields: headers,
        data: rows,
      });

      // Create a Blob from the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const fileName = selectedProjectId
        ? `sales_inventory_reports_project_${selectedProjectId}.csv`
        : `sales_inventory_reports.csv`;
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success('Download initiated successfully!');
    } catch (downloadError) {
      console.error('Download failed:', downloadError);
      toast.error('Failed to download the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  /*
   * -------------------------------------------------
   * Dots Animation Effect
   * -------------------------------------------------
   */
  useEffect(() => {
    let interval;
    if (isDownloading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
      }, 500);
    } else {
      setDotCount(0);
    }

    return () => clearInterval(interval);
  }, [isDownloading]);

  const renderDownloadingText = () => {
    return `Downloading${'.'.repeat(dotCount)}`;
  };

  /*
   * -------------------------------------------------
   * Render
   * -------------------------------------------------
   */
  return (
    <div className="w-full flex flex-col z-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Search & Filter/Download Icons */}
      <div className="flex items-center justify-between px-6 py-4 bg-white  border-t-[2px] border-stone-100 shadow-sm">
        {/* Search Component (Optional) */}
        {/* <Search placeholder="Search here..." /> */}
        <div></div>
        <div className="flex items-center gap-3">
          {/* Filter Icon (Optional) */}
          {/* <img
            onClick={toggleFilter}
            className="cursor-pointer"
            height={35}
            width={35}
            src={filter}
            alt="Filter Icon"
          /> */}
          {/* Download Button */}
          <button
            className={`relative flex items-center justify-center ${
              isDownloading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleDownload}
            disabled={isDownloading}
            aria-label="Download CSV"
          >
            <img
              height={35}
              width={35}
              src={FileDownload}
              alt="File Download Icon"
            />
            {isDownloading && (
              <span className="absolute top-10 left-0 text-sm text-gray-700">
                {renderDownloadingText()}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Filter Widget */}
      {isFilterOpen && (
        <div
          ref={applyFilterRef}
          className="absolute right-[2rem] mt-[14.5rem] w-[33rem] bg-white border rounded-lg shadow-lg z-[1000]"
        >
          <div className="py-3 px-4 border-b flex justify-between items-center">
            <h4 className="font-semibold">Apply Filters</h4>
            <p
              onClick={handleClearAll}
              className="text-blue-500 underline hover:text-blue-700 transition duration-200 cursor-pointer"
            >
              Clear all
            </p>
          </div>
          <div className="flex justify-between items-start">
            {/* Left Pane (tabs) */}
            <div className="flex flex-col w-[30%]">
              <p
                className={`py-3 px-4 cursor-pointer border-b ${
                  selectedFilter === 'Company' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Company')}
              >
                Company
              </p>
              <p
                className={`py-3 px-4 cursor-pointer border-b ${
                  selectedFilter === 'Project Name' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Project Name')}
              >
                Project Name
              </p>
              <p
                className={`py-3 px-4 cursor-pointer ${
                  selectedFilter === 'Month' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Month')}
              >
                Month
              </p>
              <p
                className={`py-3 px-4 cursor-pointer border-t ${
                  selectedFilter === 'Block' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Block')}
              >
                Block
              </p>
            </div>
            {/* Right Pane (checkboxes) */}
            <div className="flex flex-col w-[70%] border-l">
              {selectedFilter === 'Company' && (
                <>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(1)}
                      onChange={() => handleCheckboxChange(1)}
                    />
                    Ved Builders
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(2)}
                      onChange={() => handleCheckboxChange(2)}
                    />
                    Nupur Constructions
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(9)}
                      onChange={() => handleCheckboxChange(9)}
                    />
                    LR Constructions
                  </div>
                </>
              )}
              {selectedFilter === 'Project Name' && (
                <>
                  {/* Dynamically generate project names based on fetched projects */}
                  {projects.map((project) => (
                    <div
                      key={project.id}
                      className="flex items-center gap-2 py-3 px-4 text-[#67676C]"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={checkedItems.includes(project.name)}
                        onChange={() => handleCheckboxChange(project.name)}
                      />
                      {project.name}
                    </div>
                  ))}
                </>
              )}
              {selectedFilter === 'Month' && (
                <div className="grid grid-cols-3 gap-1">
                  {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                  ].map((month) => (
                    <div
                      key={month}
                      className="flex items-center gap-2 py-2 px-2 text-[#67676C]"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={checkedItems.includes(month)}
                        onChange={() => handleCheckboxChange(month)}
                      />
                      {month}
                    </div>
                  ))}
                </div>
              )}
              {selectedFilter === 'Block' && (
                <>
                  {/* Dynamically generate block names based on fetched inventory data */}
                  {inventoryData.map((item) => (
                    <div
                      key={item.id}
                      className="flex items-center gap-2 py-3 px-4 text-[#67676C]"
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={checkedItems.includes(item.name)}
                        onChange={() => handleCheckboxChange(item.name)}
                      />
                      {item.name}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end gap-4 p-6 border-t">
            <Button displayType="secondary" onClick={toggleFilter}>
              Cancel
            </Button>
            <Button onClick={() => { /* Implement Apply Filter Logic */ }}>Apply</Button>
          </div>
        </div>
      )}

      {/* Table with the new columns (no Sales Contribution) */}
      {transformedInventoryData.length > 0 ? (
        <GridWrapper width={100}>
          <div className="h-[25rem] relative">
            {/* Project Selection */}
            <div className="flex justify-between items-center mb-5">
              {/* Uncomment and implement project selection if needed */}
              {/* <div className="flex flex-col gap-2">
                <label htmlFor="project">Select Project</label>
                {projects.length > 0 ? (
                  <select
                    className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
                    {...register('selected_project')}
                    value={selectedProjectId || ''}
                    onChange={handleProjectNameChange}
                  >
                    <option value="">Select a Project</option>
                    {projects?.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <p>Loading projects...</p>
                )}
              </div> */}
            </div>

            {/* Revenue Table */}
            <Table
              headers={[
                'Accommodation Name',
                'Accommodation Type',
                'No of Units Sold',
                'Units Available',
                'Units Sold Contribution',
              ]}
              data={transformedInventoryData}
              identifier={'id'}
              isViewEnable={false}
              isDeleteEnable={false}
              isEditEnable={false}
            />

            {/* Pagination */}
            <PaginationComponent
              page={page}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isFetching={isFetchingInventory}
            />
          </div>
        </GridWrapper>
      ) : (
        <div className="pt-[3rem]">
          <NoDataFound disabledBtn screenHeight={true} />
        </div>
      )}
    </div>
  );
};

export default SalesInventoryReports;
