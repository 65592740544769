import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/ui/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInput from '../../../shared/components/ui/FormInput';
import { useQuery } from '@tanstack/react-query';
import {
  getUserById,
  getAllCompanies,
  getAllRoles,
  getAllDesignations
} from '../../services/api/implementation/impl';
import Multiselect from 'multiselect-react-dropdown';
import toast from 'react-hot-toast';

// Validation schema
const validationSchema = yup.object().shape({
  desgignation_id: yup.number().required('Designation is required'),
  name: yup
    .string()
    .required('Full name is required')
    .max(50, 'Full name should be at most 50 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      'Phone number is not valid'
    )
    .test(
      'len',
      'Must be a valid phone number',
      (val) => !val || (val && val.replace(/[^\d]/g, '').length === 10)
    ),
  email: yup.string().required('Email is required').email('Invalid email format'),
  company_ids: yup.array().min(1, 'At least one company must be selected')
});

export const EditUser = ({ userId, onSubmit, onClose, isSubmitting }) => {
  const [salesId, setSalesId] = useState('');
  /* 
    Hooks
  */
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  console.log(userId);

  /* 
    Data fetching
   */
  const {
    data: user,
    isSuccess: userLoaded,
    refetch: refetchUser
  } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId
  });

  console.log({ user });

  useEffect(() => {
    refetchUser();
  }, [userId]);

  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: getAllCompanies
  });

  const { data: roles, isSuccess: rolesLoaded } = useQuery({
    queryKey: ['roles'],
    queryFn: getAllRoles
  });

  const { data: designations, isSuccess: designationLoaded } = useQuery({
    queryKey: ['designations'],
    queryFn: () => getAllDesignations()
  });

  useEffect(() => {
    console.log({ designationLoaded, user });

    if (userLoaded && designationLoaded) {
      setValue('desgignation_id', user.desgignation_id);
      setValue('name', user.name);
      setValue('phone_number', user.phone_number);
      setValue('email', user.email);
      setValue('max_discount_limit', user.max_discount_limit);

      // Set the company_ids with the user's current companies
      if (user.userCompanyMapping && Array.isArray(user.userCompanyMapping)) {
        const selectedCompanies = user?.userCompanyMapping?.map((item) => ({
          id: item.company.id,
          name: item.company.company_name?.trim()
        }));
        console.log({ selectedCompanies });
        setValue('company_ids', selectedCompanies);
      }
    }
  }, [userLoaded, designationLoaded, setValue, user]);

  const transformedDataCompany = companies?.map((item) => ({
    id: item.id,
    name: item.company_name?.trim()
  }));

  const transformedDataRole = designations?.map((item) => ({
    value: item.id,
    label: item.name?.trim()
  }));

  const onFormSubmit = (data) => {
    console.log({ data });
    // Process the data as needed
    const processedData = {
      ...data,
      company_ids: data?.company_ids?.map((company) => company.id),
      max_discount_limit: data.max_discount_limit
        ? parseInt(data.max_discount_limit, 10)
        : undefined // Convert only if present
    };
    console.log({ processedData });
    onSubmit(processedData);
  };
  

  const handleSelected = (value) => {
    setSalesId(value);
  };

  const [maxDiscount, setMaxDiscount] = useState('');

  const handleMaxDiscountChange = (e) => {
    let inputVal = e;

    // 1) Remove anything that's not a digit:
    inputVal = inputVal.replace(/\D/g, '');

    // 2) Convert to a number and cap at 100:
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Max discount cannot exceed 100');
    }

    // 3) Set our local state and also setValue in RHF so the form sees this updated value.
    setMaxDiscount(inputVal);
    setValue('max_discount_limit', inputVal);
  }
  /* 
    JSX
  */
  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">User details</span>
      </div>
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Designations'}
            register={register}
            isRequired
            register_key={'desgignation_id'}
            type={'dropdown'}
            defaultValuePre={'Select Role'}
            options={transformedDataRole}
            onDropdownChange={handleSelected}
            errors={errors.desgignation_id}
          />
          <FormInput
            width={48}
            label={'Full Name'}
            isRequired
            register_key={'name'}
            register={register}
            type={'text'}
            errors={errors.name}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Phone Number'}
            isRequired
            register={register}
            register_key={'phone_number'}
            type={'text'}
            errors={errors.phone_number}
          />
          <FormInput
            width={48}
            label={'Email address'}
            register={register}
            isRequired
            register_key={'email'}
            type={'text'}
            errors={errors.email}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="w-[48%]">
            <label className="text-gray-600">Assign Company</label>
            <Controller
              name="company_ids"
              control={control}
              rules={{ required: 'At least one company must be selected' }}
              render={({ field }) => (
                <Multiselect
                  options={transformedDataCompany}
                  selectedValues={field.value}
                  onSelect={(selectedList) => field.onChange(selectedList)}
                  onRemove={(selectedList) => field.onChange(selectedList)}
                  displayValue="name"
                  style={{
                    optionContainer: {
                      border: '1px solid',
                      backgroundColor: 'white'
                    },
                    option: {
                      color: 'black',
                      backgroundColor: 'white'
                    },
                    chips: {
                      background: '#2A565E',
                      color: 'white'
                    }
                  }}
                />
              )}
            />
            {errors.company_ids && (
              <span className="text-red-500">{errors.company_ids.message}</span>
            )}
          </div>
          {user.desgignation_id == 3 ? (
            <FormInput
              width={48}
              label={'Max Discount (%)'}
              register={register}
              isRequired
              register_key={'max_discount_limit'}
              defaultValuePre={maxDiscount}
              onType={handleMaxDiscountChange}
              type={'text'}
              errors={errors.max_discount_limit}
            />
          ) : null}
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
};
