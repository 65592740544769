import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { getAllCategoriesForServiceProvider } from '../../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

// Dummy data for roles and projects
const roles = [
  { id: 1, name: 'ARCHITECTS' },
  { id: 2, name: 'INTERIOR DESIGN' },
  { id: 3, name: 'LIAISONING SERVICES' },
  { id: 4, name: 'LABOUR CONTRACTORS' },
  { id: 5, name: 'MECHANICAL CONTRACTORS' },
  { id: 6, name: 'ELECTRICAL CONTRACTORS' },
  { id: 7, name: 'PLUMBING CONTRACTORS' },
  { id: 8, name: 'FIRE FIGHING CONTRACTORS' },
  { id: 9, name: 'SECURITY SERVICES' }
];

const projects = [
  { id: 1, name: 'Project A' },
  { id: 2, name: 'Project B' },
  { id: 3, name: 'Project C' }
];

// Validation schema
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Full name is required')
    .max(50, 'Full name should be at most 50 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]{10}$/, 'Invalid phone number'),
  email: yup.string().required('Email is required').email('Invalid email format'),
  category_id: yup.number().required('User role is required'),
  type_id: yup.string().required('User type is required')
});

export const AddServiceProvider = ({ onClose, onSubmit, isSubmitting }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  //use state for categories
  const [categoriesForDropdown, setCategoriesForDropdown] = useState([]);

  //write a api call to get all categpries using mutation
  const { data: allCategories, refetch: refetchCategories } = useQuery({
    queryKey: ['getAllCategoriesForServiceProvider'],
    queryFn: getAllCategoriesForServiceProvider
  });

  useEffect(() => {
    if (Array.isArray(allCategories)) {
      setCategoriesForDropdown(allCategories);
    }
  }, [allCategories]);

  console.log('allCategories', allCategories);

  const onSubmitForm = (data) => {
    const formattedData = {
      phone_number: data.phone_number,
      email: data.email,
      name: data.name,
      category_id: data.category_id,
      type: data.type_id,
      is_active: false
    };
    console.log(formattedData, 'formattedData');
    onSubmit(formattedData);
  };

  const handlePhoneNumber = useCallback(
    async (val) => {
      const newval = val.replace(/[^0-9]/g, '');
      setValue('phone_number', newval);
    },
    [setValue]
  );

  const handleName = useCallback(
    async (val) => {
      const newval = val.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue('name', newval);
    },
    [setValue]
  );

  const handleEmailValidation = useCallback(
    async (val) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(val)) {
        setValue('email', val); // If valid, set the email value
      } else {
        // Handle invalid email case, e.g., show an error
        console.log('Invalid email format');
      }
    },
    [setValue]
  );

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Service Provider Details
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={100}
            label={'Full Name'}
            isRequired={true}
            register_key={'name'}
            register={register}
            type={'text'}
            errors={errors.name}
            onType={handleName}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Phone Number'}
            register={register}
            isRequired={true}
            register_key={'phone_number'}
            type={'text'}
            maxLength={10}
            errors={errors.phone_number}
            onType={handlePhoneNumber}
          />

          <FormInput
            width={48}
            label={'Email address'}
            register={register}
            isRequired={true}
            register_key={'email'}
            type={'text'}
            errors={errors.email}
            onType={handleEmailValidation}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Service Provider Category'}
            register={register}
            isRequired={true}
            register_key={'category_id'}
            type={'dropdown'}
            options={[
              { value: '', label: 'Select Category', disabled: true, selected: true },
              ...categoriesForDropdown.map((item) => ({
                value: item.id,
                label: item.name.trim()
              }))
            ]}
            errors={errors.category_id}
          />
          <FormInput
            width={48}
            label={'Service Provider Type'}
            register={register}
            isRequired={true}
            register_key={'type_id'}
            type={'dropdown'}
            options={[
              { value: '', label: 'Select Type' },
              { value: 'individual', label: 'Individual' },
              { value: 'company', label: 'Company' }
            ]}
            errors={errors.type_id}
          />
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddServiceProvider;
