import React from 'react'
import Button from '../../../shared/components/ui/Button'
import { getBuilderReport, getReportLeads } from '../../services/api/implementation/impl';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

export const ReportBuilder = () => {
    // Download Report For the Builder
    const { mutate: handleDownloadReportBuilder } = useMutation({
        mutationFn: async () => {
            return await getBuilderReport();
        },
        onSuccess: (data, variables) => {
            const id = variables;

            console.log({ data }, 'data');
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/xml' })); // Change type to XML
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `builder_Reports${id}.xml`; // Change extension to XML
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            toast.success('Report Downloaded');
        },
        onError: () => {
            toast.error('Error in downloading Report');
        }
    });


    // Download Report For the Leads

    const { mutate: handleDownloadReportBuilderLeads } = useMutation({
        mutationFn: async () => {
            return await getReportLeads();
        },
        onSuccess: (data, variables) => {
            const id = variables;

            console.log({ data }, 'data');
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `leads${id}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);


            toast.success('Leads Report Downloaded');
        },
        onError: () => {
            toast.error('Error in downloading Report');
        }
    });


    return (
        <div className='flex justify-evenly'>
            <div className='flex flex-col items-center text-center gap-3'>
                {/* <h1 className='text-xl font-semibold'>Download Report For the Builder</h1> */}
                <Button onClick={handleDownloadReportBuilder}>Download Report</Button>
            </div>
            <div className='flex  flex-col items-center text-center gap-3'>
                {/* <h1 className='text-xl font-semibold'>Download Report For the Leads</h1> */}
                <Button onClick={handleDownloadReportBuilderLeads}>Download Leads Report</Button>
            </div>
        </div>
    )
}
