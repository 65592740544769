import React from 'react';
import uploadIcon from '../../../shared/assets/svg/upload.svg';
export const ImportAccommodation = () => {
  return (
    <div className="flex w-full gap-4 justify-center flex-col items-center text-center mt-16 p-5 font-helvetica border-dashed border-2 border-[#e9f5f6] ">
      <div>
        <img src={uploadIcon} alt="" />
      </div>
      <h4 className="font-helvetica font-medium">Select a file or drag and drop here</h4>
      <p className="text-importColor font-normal">JPG, PNG or PDF, file size no more than 10MB</p>
      <div className="">
        <input type="file" className="file-input" name="file" id="file" />
        <label className="border p-2 rounded-md border-[#346C75] text-[#346C75]" htmlFor="file">
          Choose File
        </label>
      </div>
    </div>
  );
};
