import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';

import {
  getPlcbyAssociatedBlockId,
  getUnitsById,
  getBlockByID
} from '../services/api/implementation/impl';

import Button from '../../shared/components/ui/Button';

export const EditPlcUnit = ({ id, handleEditPlcUnits, blockId, onClose, isSubmitting }) => {
  /**
   * ================== LOCAL STATE ==================
   */
  // We store each direction’s selected IDs as an array of strings:
  // e.g. ["plcs-4", "common-1001", "common-user-My Custom Corridor"]
  const [selectedValues, setSelectedValues] = useState({
    north: [],
    south: [],
    east: [],
    west: [],
    others: []
  });

  /**
   * ================== API QUERIES ==================
   */
  const { data: selectedBlockDetails, refetch: refetchBlockById } = useQuery({
    queryKey: ['blockById', parseInt(blockId)],
    queryFn: () => getBlockByID(parseInt(blockId)),
    enabled: !!blockId
  });

  const { data: getUnitId, refetch: refetchUnitById } = useQuery({
    queryKey: ['unitsById', parseInt(id)],
    queryFn: () => getUnitsById(parseInt(id)),
    enabled: !!id
  });

  const { data: plc_associated, refetch: refetchplc_blockid } = useQuery({
    queryKey: ['plc_associated', parseInt(blockId)],
    queryFn: () => getPlcbyAssociatedBlockId(parseInt(blockId)),
    enabled: !!blockId
  });

  useEffect(() => {
    if (blockId) refetchBlockById();
    if (id) refetchUnitById();
    if (blockId) refetchplc_blockid();
  }, [blockId, id, refetchBlockById, refetchUnitById, refetchplc_blockid]);

  /**
   * ================== BUILD OPTIONS ==================
   * 1) PLC items
   * 2) Static Common items
   * 3) Other units on the same level (treated as Common)
   */
  // PLC items -> "plcs-<id>" format
  const plcOptions = useMemo(() => {
    if (!plc_associated?.plc_block_mapping) return [];
    return plc_associated.plc_block_mapping.map((item) => ({
      value: `plcs-${item.plc.id}`,
      label: item.plc.name
    }));
  }, [plc_associated]);

  // Static Common items -> "common-<id>" e.g. "common-1005" => "Road"
  const StaticOption = useMemo(
    () => [
      { value: 'common-1000', label: 'Staircase' },
      { value: 'common-1001', label: 'Corridor' },
      { value: 'common-1003', label: 'Lift' },
      { value: 'common-1004', label: 'Adjacent Block' },
      { value: 'common-1005', label: 'Road' }
    ],
    []
  );

  // Other units on the same level -> also "common-<unitId>" IDs
  // (Make sure we exclude this unit itself from the list)
  const levelOption = useMemo(() => {
    if (!plc_associated?.levels || !getUnitId?.level_id) return [];
    // find the relevant level among all block's levels
    const targetLevel = plc_associated?.levels?.find(
      (lvl) => lvl.id === getUnitId.level_id
    );
    if (!targetLevel) return [];
    // from that level, build an array of other units
    return (targetLevel.units || [])
      .filter((u) => u.id !== getUnitId.id)
      .map((u) => ({
        value: `common-${u.id}`,
        label: u.unitName
      }));
  }, [plc_associated, getUnitId]);

  /**
   * ================== GROUPED OPTIONS (DISPLAYED) ==================
   * For directions except "others," we show either:
   * - only PLC group if block is "Independent"
   * - "Common" + "PLC" group otherwise.
   */
  const groupedOptions = useMemo(() => {
    const commonGroup = {
      label: 'Common',
      options: [...StaticOption, ...levelOption]
    };
    const plcGroup = {
      label: 'Plcs',
      options: plcOptions
    };

    // If block is "Independent", only display PLC group for new selections
    if (selectedBlockDetails?.type === 'Independent') {
      return [plcGroup];
    }
    return [commonGroup, plcGroup];
  }, [StaticOption, levelOption, plcOptions, selectedBlockDetails]);

  // For "others" direction, we only display the PLC group by default
  // (still allowing custom user creation).
  const othersOptions = useMemo(() => {
    const plcGroup = { label: 'Plcs', options: plcOptions };
    return [plcGroup];
  }, [plcOptions]);

  /**
   * ================== ALL OPTIONS FOR LOOKUP ==================
   * Even if we hide the Common group in "Independent" mode,
   * we STILL keep them in a separate array for label lookups (so "common-1005" => "Road").
   */
  const allOptionsForLookup = useMemo(() => {
    // Flatten the static + level + PLC
    const allCommon = [...StaticOption, ...levelOption];
    return [...allCommon, ...plcOptions];
  }, [StaticOption, levelOption, plcOptions]);

  /**
   * ================== PARSE INITIAL SELECTED VALUES ==================
   * Convert existing DB data to "plcs-<id>" or "common-<id>" / "common-user-<label>"
   */
  const getInitialIdsForDirection = (direction) => {
    // Entities => "common" IDs or user-labeled
    const entityItems =
      getUnitId?.entityMappings
        ?.filter((em) => em.direction === direction)
        ?.map((em) => {
          // We'll match em.entity.name against known static or level items:
          const foundStatic = StaticOption.find((s) => s.label === em.entity.name);
          if (foundStatic) return foundStatic.value; // e.g. "common-1005"

          const foundUnit = levelOption.find((u) => u.label === em.entity.name);
          if (foundUnit) return foundUnit.value; // e.g. "common-XY"

          // Else treat it as user-labeled
          return `common-user-${em.entity.name}`;
        }) || [];

    // PLC => "plcs-XX"
    const plcItems =
      getUnitId?.unitPlcs
        ?.filter((plc) =>
          direction === 'others' ? plc.direction === 'other' : plc.direction === direction
        )
        ?.map((plcItem) => `plcs-${plcItem.plc.id}`) || [];

    return [...entityItems, ...plcItems];
  };

  // On mount (and whenever getUnitId changes), populate `selectedValues`.
  useEffect(() => {
    if (!getUnitId) return;
    const newValues = {
      north: getInitialIdsForDirection('north'),
      south: getInitialIdsForDirection('south'),
      east: getInitialIdsForDirection('east'),
      west: getInitialIdsForDirection('west'),
      others: getInitialIdsForDirection('others') // or 'other' if that’s how your DB stores it
    };
    setSelectedValues(newValues);
  }, [getUnitId, StaticOption, levelOption]);

  /**
   * ================== CONVERT SELECTED IDs -> REACT-SELECT Value ==================
   */
  const getValueForDirection = useCallback(
    (direction) => {
      const ids = selectedValues[direction] || [];
      return ids.map((id) => {
        // If user-created => "common-user-<someString>"
        if (/^common-user-/.test(id)) {
          const customLabel = id.replace('common-user-', '');
          return { value: id, label: customLabel };
        }
        // Otherwise, try to find it in our "allOptionsForLookup"
        const found = allOptionsForLookup.find((opt) => opt.value === id);
        if (found) {
          // e.g. { value: "common-1005", label: "Road" }
          return found;
        }
        // fallback if not found
        return { value: id, label: id };
      });
    },
    [selectedValues, allOptionsForLookup]
  );

  /**
   * ================== HANDLE CHANGES IN SELECT ==================
   */
  const handleDirectionChange = (newValue, _actionMeta, direction) => {
    // newValue is an array of { value, label }
    const newIds = newValue.map((item) => item.value);
    setSelectedValues((prev) => ({
      ...prev,
      [direction]: newIds
    }));
  };

  const handleCreateOption = (inputValue, direction, currentSelected) => {
    // Example: create "common-user-foo"
    const newId = `common-user-${inputValue}`;
    const updatedIds = [...currentSelected.map((item) => item.value), newId];
    setSelectedValues((prev) => ({
      ...prev,
      [direction]: updatedIds
    }));
  };

  /**
   * ================== BUILD THE FINAL PAYLOAD ==================
   */
  const handleOnSubmit = () => {
    if (!getUnitId?.level) {
      toast.error('Unit data not found.');
      return;
    }

    // For directions north/south/east/west => collect PLC vs Common
    // For "others" => only PLC
    const directions = ['north', 'south', 'east', 'west', 'others'];

    // Prepare placeholders
    const plcsByDirection = { north: [], south: [], east: [], west: [] };
    const entityByDirection = { north: [], south: [], east: [], west: [] };
    let otherPlcs = []; // for "others"

    directions.forEach((direction) => {
      const ids = selectedValues[direction] || [];
      if (direction === 'others') {
        // "others" => store them in otherPlcs
        otherPlcs = ids
          .filter((id) => id.startsWith('plcs-'))
          .map((id) => parseInt(id.replace('plcs-', ''), 10));
      } else {
        // For each direction => split into PLC vs. common
        plcsByDirection[direction] = ids
          .filter((id) => id.startsWith('plcs-'))
          .map((id) => parseInt(id.replace('plcs-', ''), 10));

        // Common => static or user
        const commonItems = ids.filter((id) => id.startsWith('common-'));
        // convert them to their display names
        const entityNames = commonItems.map((commonId) => {
          // if user created => "common-user-"
          if (commonId.startsWith('common-user-')) {
            return commonId.replace('common-user-', '');
          }
          // else find from allOptionsForLookup to get the label
          const found = allOptionsForLookup.find((opt) => opt.value === commonId);
          return found ? found.label : 'Unknown';
        });
        entityByDirection[direction] = entityNames;
      }
    });

    // Build final
    const payload = [
      {
        level_id: getUnitId.level.id,
        units: [
          {
            unit_id: getUnitId.id,
            plcs: plcsByDirection,
            entity: entityByDirection,
            other: otherPlcs
          }
        ]
      }
    ];

    // Pass upward
    handleEditPlcUnits(payload);
  };

  /**
   * ================== RENDER ==================
   */
  return (
    <div>
      <div>
        <p className="font-semibold text-lg mt-3 mb-3">
          {getUnitId?.level?.name || 'Floor Name'}
        </p>
      </div>
      <div>
        <p>{getUnitId?.unitName || 'Unit Name'}</p>
        <div>
          <div className="grid grid-cols-2 gap-3">
            {['north', 'south', 'east', 'west', 'others'].map((direction) => {
              // Convert the stored string IDs -> {value, label} objects
              const valueForDirection = getValueForDirection(direction);

              // Choose which options to show in the dropdown
              // "others" => only the PLC group
              // everything else => groupedOptions
              const directionOptions =
                direction === 'others' ? othersOptions : groupedOptions;

              return (
                <div key={direction}>
                  <label className="capitalize">{direction}</label>
                  <CreatableSelect
                    isMulti
                    options={directionOptions}
                    value={valueForDirection}
                    onChange={(newValue, actionMeta) =>
                      handleDirectionChange(newValue, actionMeta, direction)
                    }
                    onCreateOption={(inputValue) =>
                      handleCreateOption(inputValue, direction, valueForDirection)
                    }
                    styles={{
                      groupHeading: (provided) => ({
                        ...provided,
                        fontWeight: 'bold'
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? '#fff' : '#000',
                        backgroundColor: state.isFocused ? '#eee' : '#fff'
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: '#2A565E'
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: '#fff'
                      })
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" onClick={handleOnSubmit} disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};
