import React, { useEffect, useState } from 'react';
import FormInput from '../../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../shared/components/ui/Button';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { updatePLCPricing } from '../../services/api/implementation/impl';

export const CreatePLCRateList = ({
  projectName,
  createSuccess,
  plcList,
  blockName,
  selectedBlockId,
  onClose
}) => {
  const { register, handleSubmit, reset } = useForm();
  const { id: projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accommodationListOptions, setAccommodationListOptions] = useState([]);

  // Preview States
  const [formPayload, setFormPayload] = useState({});

  console.log({ plcList });

  const onSubmit = (data) => {
    console.log('Form Data:', data);

    // Transform data to the required structure
    const plcRates = plcList?.map((plc, index) => {
      // Sanitize the input to extract only the numeric value
      const sanitizedPrice = parseFloat(data[plc.name + index].replace(/[^\d.]/g, ''));

      return {
        plc_id: plc.id,
        price: sanitizedPrice || 0 // Default to 0 if conversion fails
      };
    });

    const payload = {
      plc_rates: plcRates
    };

    console.log('Payload:', payload);

    // Mutate the data
    mutatePLCPricing(payload);
  };

  const { mutate: mutatePLCPricing } = useMutation({
    mutationFn: (updatedPLCPricing) => updatePLCPricing(updatedPLCPricing),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Successfully updated PLC pricing');
      console.log('PLC pricing updated successfully');
      reset();
      createSuccess();
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error updating PLC pricing');
      console.error('Error updating PLC pricing:', err);
    }
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="flex flex-col justify-between items-start gap-4">
            <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
              Sq/ft Based Rate Details
            </span>
          </div>
          {plcList &&
            plcList.length > 0 &&
            plcList?.map((plc, index) => (
              <div className="flex justify-between mt-4" key={index}>
                <p>{plc.name}</p>
                <FormInput
                  width={49}
                  register_key={plc.name + index}
                  register={register}
                  type={'text'}
                  defaultValuePre={plc.rate.replace('₹ ', '')}
                />
              </div>
            ))}
          <div className="w-full flex justify-end items-center space-x-4 mt-8">
            <Button type="reset" displayType="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Proceed
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
