import React from 'react';
import Table from '../../../shared/components/layout/Table';
import ratepreview from '../../static/data/RateUnitId.json';
import { transformData } from '../../../super-admin/utils/transformData';
import Button from '../../../shared/components/ui/Button';
import Cookies from 'js-cookie';

export const PreviewRateUnitID = ({ previewData, handlePreviewProceed, onEdit, isSubmitting }) => {
  const PreviewRateUnitFormat = [
    'project_name', //project name
    // "block_name", //block name
    'accommodation_name', //accommodation name
    // "unit_name", //unit name
    // "plc_name", //plc name
    // "build_up_area",
    'loading_factor',
    // "super_build_up_area",
    'price',
    // "unit_cost",
    'id'
  ];

  const PreviewRateData = previewData?.map((el) => transformData(el, PreviewRateUnitFormat));
  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit ||
    rawMeasurementUnit === 'null' ||
    rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;

  return (
    <div>
      <div className="flex flex-col justify-between items-start gap-4  ">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Preview Unit Rate Details
        </span>
      </div>
      <div className="mt-4 h-[24rem] overflow-scroll">
        <Table
          headers={['Project', 'Accommodation Name', 'Loading Factor', `Rate (${measurementType ? measurementType : 'sq/ft'})`]}
          data={PreviewRateData}
          bigHeaders={true}
          identifier={'id'}
        />
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-16">
        <Button type="reset" displayType="secondary" onClick={onEdit}>
          Edit
        </Button>
        {/* <Button type="reset" displayType="secondary">
                    Add More
                </Button> */}
        <Button type="submit" onClick={handlePreviewProceed} disabled={isSubmitting}>
          Procced
        </Button>
      </div>
    </div>
  );
};
