import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import SecondaryTabbedComponent from '../../shared/components/layout/SecondaryTabbedComponent';
import AccommodationDetails from './AccommodationDetails';
import Button from '../../shared/components/ui/Button';
import SaleOverview from './SaleOverview';
import DealsDetails from './DealsDetails';
import ScheduleDetails from './ScheduleDetails';
import ActivityList from './ActivityList';
import SaleAttachmentList from './SaleAttachmentList';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getLeadByID } from '../services/api/implementation/impl';

const SaleDetails = () => {
  const { id } = useParams();

  const {
    data: leadbyId,
    isLoading,
    error,
    refetch: refetchLeadbyId
  } = useQuery({
    queryKey: ['leadById', parseInt(id)],
    queryFn: async () => {
      const res = await getLeadByID(parseInt(id));
      return res;
    },
    enabled: !!id // Only run the query if id is truthy
  });

  /* 
    Meta data
  */
  const secondaryTabbedComponentMetaData = {
    tabs: ['Details', 'Schedule', 'Activity']
  };

  /* 
    Local state
  */
  const [secondaryTab, setSecondaryTab] = useState('Details');

  /* 
    Data fetching
  */
  const { data: sale } = useState(null);

  console.log({ leadbyId });
  /* 
    JSX
  */
  return (
    <>
      {/* Main content */}
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18%] z-10 w-[82%]">
          <Header data={{ name: `Sales > ${leadbyId?.user?.name}` }} backArrow={true} />
        </div>

        {/* Secondary Tabbed content */}
        <div className="w-[82%] fixed top-[4rem] z-10 bg-orange-500">
          <SecondaryTabbedComponent
            data={secondaryTabbedComponentMetaData}
            activePrimary={secondaryTab}
            onChangePrimaryTab={(tab) => setSecondaryTab(tab)}>
            {/* Buttons section for overview */}

            {secondaryTab === 'Details' && (
              <div className="flex justify-between items-center space-x-4">
                {/* <Button displayType="secondary">Edit</Button> */}
                {/* <Button onClick={handleToggleAddDetailsModal}>Add details</Button> */}
              </div>
            )}

            {/* Buttons section for Accommodation */}
            {secondaryTab === 'Deals' && (
              <div className="flex justify-between items-center space-x-4"></div>
            )}
          </SecondaryTabbedComponent>
        </div>

        {/* Overview content */}
        {secondaryTab === 'Details' && (
          <div className="w-full h-fit overflow-y-scroll">
            <SaleOverview />
          </div>
        )}

        {secondaryTab === 'Deals' && (
          <div className="w-full h-full">
            <DealsDetails />
          </div>
        )}

        {/* Attachments content */}
        {secondaryTab === 'Attachments' && (
          <div className=" w-full h-full ">
            <SaleAttachmentList />
          </div>
        )}

        {/* Schedule */}
        {secondaryTab === 'Schedule' && (
          <div className=" w-full h-full ">
            <ScheduleDetails />
          </div>
        )}

        {secondaryTab === 'Activity' && (
          <div className=" w-full h-full ">
            <ActivityList />
          </div>
        )}
      </Layout>
    </>
  );
};

export default SaleDetails;
