import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import TableData from '../../../shared/components/ui/TableData';

const schema = yup.object().shape({
  username: yup.string().required('Full Name is required')
});

const AddCustomRole = ({ onSubmit }) => {
  /*
    Hook form
  */
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  /*
    JSX
  */
  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Role details</span>

        <div className="w-full flex flex-col gap-2">
          <div className="text-md flex flex-col gap-2 w-full">
            <span className="text-gray-600">Full Name</span>
            <input
              type="text"
              className="w-full p-2 border-[1px] border-stone-300 rounded placeholder:text-sm"
              placeholder="Enter name"
              {...register('username')}
            />
            {errors.username && <span className="text-red-600">{errors.username.message}</span>}
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="w-full bg-gray-100 flex flex-col p-4">
          <div className="w-full flex justify-between items-center">
            <TableData data={'Permission'} width={30} />
            <TableData data={'Create Access'} width={30} />
            <TableData data={'View Access'} width={30} />
          </div>
        </div>

        <div className="w-full bg-white border-t-[1px] border-gray-300 flex flex-col p-4">
          <div className="w-full flex justify-between items-center">
            <TableData data={'Lands'} width={30} />
            <label className="checkbox-container flex items-center w-[28%] space-x-3">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-500" />
              <span className="ml-2">Enable</span>
            </label>
            <label className="checkbox-container flex items-center w-[28%] space-x-3">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-500" />
              <span className="ml-2">Enable</span>
            </label>
          </div>
        </div>

        <div className="w-full bg-white border-t-[1px] border-gray-300 flex flex-col p-4">
          <div className="w-full flex justify-between items-center">
            <TableData data={'Master rates'} width={30} />
            <label className="checkbox-container flex items-center w-[28%] space-x-3">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-500" />
              <span className="ml-2">Enable</span>
            </label>
            <label className="checkbox-container flex items-center w-[28%] space-x-3">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-500" />
              <span className="ml-2">Enable</span>
            </label>
          </div>
        </div>

        <div className="w-full bg-white border-t-[1px] border-b-[1px] border-gray-300 flex flex-col p-4">
          <div className="w-full flex justify-between items-center">
            <TableData data={'Sales'} width={30} />
            <label className="checkbox-container flex items-center w-[28%] space-x-3">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-500" />
              <span className="ml-2">Enable</span>
            </label>
            <label className="checkbox-container flex items-center w-[28%] space-x-3">
              <input type="checkbox" className="form-checkbox h-5 w-5 text-blue-500" />
              <span className="ml-2">Enable</span>
            </label>
          </div>
        </div>
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-6">
        <Button type="reset" displayType="secondary">
          Cancel
        </Button>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
};

export default AddCustomRole;
