import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fileUrls: {
    logo: []

    // Add other file types as needed
  },
  multifiles: {
    building_layout_plan: []
  }
};

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    uploadFile(state, action) {
      console.log(state, action);
      const { fileType, url } = action.payload;
      state.fileUrls[fileType] = url;
    },
    multipleUploadFiles(state, action) {
      const { fileType, urls } = action.payload;
      state.multifiles[fileType] = urls;
      // console.log(fileType, urls);
    }
  }
});

export const { uploadFile, multipleUploadFiles } = fileSlice.actions;

export default fileSlice.reducer;
