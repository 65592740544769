import React, { useCallback } from 'react';
import Button from '../ui/Button';
import FormInput from '../ui/FormInput';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const RegisterForm = ({ onSubmit }) => {
  /* 
    Hook form
  */
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  /* 
    JSX
  */
  return (
    <form className={`flex flex-col gap-6 w-full`}>
      <span className="text-stone-800 text-3xl font-semibold mb-24">Hey there,</span>

      <div className="flex flex-col justify-between items-start gap-4">
        <FormInput
          width={100}
          register={register}
          register_key={'email'}
          label={'Email'}
          type={'email'}
          isRequired={true}
        />

        <FormInput
          width={100}
          register={register}
          register_key={'password'}
          label={'Password'}
          type={'password'}
          isRequired={true}
        />
      </div>

      <div className="w-full flex flex-col justify-center items-center gap-4">
        <Button
          type="submit"
          displayType="full-w-primary"
          width={100}
          onClick={handleSubmit(onSubmit)}
        >
          Register
        </Button>

        <div className="flex justify-center items-center w-full space-x-5">
          <span className="bg-stone-400 w-[40%] h-[1px]">&nbsp;</span>
          <span className="text-stone-500">Or</span>
          <span className="bg-stone-400 w-[40%] h-[1px]">&nbsp;</span>
        </div>

        <Button
          type="button"
          displayType="full-w-secondary"
          width={100}
          onClick={() => navigate('/auth/login')}
        >
          Login
        </Button>
      </div>
    </form>
  );
};

export default RegisterForm;
