import React, { useCallback, useState } from 'react';
import Button from '../../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { getAllInvoices, getAllUsers } from '../../services/api/implementation/impl';
import { getAllCompanies, getAllProjects } from '../../../builder/services/api/implementation/impl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  builder_id: yup.number().required('Builder name is required'),
  company_id: yup.number().required('Company name is required'),
  project_id: yup.number().required('Project name is required'),
  invoice_id: yup.number().required('Invoice number is required'),
  amount: yup
    .number()
    .typeError('Amount paid must be a number')
    .positive('Amount paid must be positive')
    .required('Amount paid is required')
});

export const BillingHistoryForm = ({ onClose, onSubmit }) => {
  // State for selected values
  const [selectedBuilder, setSelectedBuilder] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedProject, setSelectedProject] = useState('');

  // Fetch data using React Query
  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: () => getAllUsers()
  });

  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getAllCompanies()
  });

  const { data: invoices } = useQuery({
    queryKey: ['invoices'],
    queryFn: () => getAllInvoices()
  });

  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: () => getAllProjects()
  });

  // Transform data for dropdowns
  const transformedBuilders = users?.data?.map((item) => ({
    value: item.id,
    label: item.primery_user?.name?.trim()
  }));

  const transformedCompanies = companies
    ?.filter((company) => company.builder.id === parseInt(selectedBuilder))
    ?.map((item) => ({
      value: item.id,
      label: item.company_name?.trim()
    }));

  const transformedProjects = projects
    ?.filter((project) => project.company_id === parseInt(selectedCompany))
    ?.map((item) => ({
      value: item.id,
      label: item.name?.trim()
    }));

  const transformedInvoices = invoices
    ?.filter((invoice) => invoice.project_id === parseInt(selectedProject))
    ?.map((item) => ({
      value: item.id,
      label: item.invoice_number?.trim()
    }));

  // Handle dropdown changes
  const handleBuilderChange = (e) => {
    const builderId = e.target.value;
    setSelectedBuilder(builderId);
    setSelectedCompany(''); // Reset company and project when builder changes
    setSelectedProject('');
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    setSelectedProject(''); // Reset project when company changes
  };

  const handleProjectChange = (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId);
  };

  // Hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleAmountPaid = useCallback(
    async (val) => {
      const newval = val.replace(/[^0-9]/g, '');
      setValue('amount', newval);
    },
    [setValue]
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col gap-2 w-[48%]">
            <label className="font-medium">Builder Name *</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('builder_id')}
              value={selectedBuilder}
              onChange={handleBuilderChange}
            >
              <option value="">Select builder</option>
              {transformedBuilders?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.builder_id && <span className="text-red-600">{errors.builder_id.message}</span>}
          </div>

          <div className="flex flex-col gap-2 w-[48%]">
            <label className="font-medium">Company Name *</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('company_id')}
              value={selectedCompany}
              onChange={handleCompanyChange}
              disabled={!selectedBuilder}
            >
              <option value="">Select company</option>
              {transformedCompanies?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.company_id && <span className="text-red-600">{errors.company_id.message}</span>}
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col gap-2 w-[48%]">
            <label className="font-medium">Project Name *</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('project_id')}
              value={selectedProject}
              onChange={handleProjectChange}
              disabled={!selectedCompany}
            >
              <option value="">Select project</option>
              {transformedProjects?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.project_id && <span className="text-red-600">{errors.project_id.message}</span>}
          </div>

          <div className="flex flex-col gap-2 w-[48%]">
            <label className="font-medium">Invoice *</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('invoice_id')}
              disabled={!selectedProject}
            >
              <option value="">Select invoice</option>
              {transformedInvoices?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.invoice_id && <span className="text-red-600">{errors.invoice_id.message}</span>}
          </div>
        </div>

        <div className="flex flex-col justify-start items-start gap-2 w-full">
          <label className="font-medium">Amount Paid *</label>
          <input
            className="w-full p-2 border-[1px] border-stone-300 rounded"
            type="text"
            {...register('amount')}
            onChange={(e) => handleAmountPaid(e.target.value)}
            maxLength={10}

          />
          {errors.amount && <span className="text-red-600">{errors.amount.message}</span>}
        </div>
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} type="submit">
          Add
        </Button>
      </div>
    </>
  );
};
