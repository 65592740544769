// File: OrderActivityCard.js

import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import Button from '../../shared/components/ui/Button';
import clocksvg from '../../shared/assets/svg/clocks.svg';
// Replace this path with wherever your download icon is located
import DownloadIcon from '../../shared/assets/svg/download-s.svg'

// Import the API calls
import {
  downloadInvoiceForOrder,
  downloadBillForOrder
} from '../services/api/implementation/impl';

const OrderActivityCard = ({ special, data, orderId, onSuccess }) => {
  // Format the date using US locale with day, month, year, hour, and minute.
  function changeDateToCustomFormat(originalDate) {
    const newDate = new Date(originalDate);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return newDate.toLocaleString('en-US', options);
  }

  // Download Invoice mutation
  const { mutate: handleDownloadInvoice } = useMutation({
    mutationFn: async (id) => {
      return await downloadInvoiceForOrder(id);
    },
    onSuccess: (fileData, id) => {
      if (!fileData || fileData.byteLength === 0) {
        toast.error('Error: File data is empty or invalid.');
        return;
      }
      const url = window.URL.createObjectURL(new Blob([fileData], { type: 'application/pdf' }));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `Order_${id}_Invoice.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toast.success('Invoice Downloaded');
      onSuccess && onSuccess();
    },
    onError: (error) => {
      console.error('Error downloading invoice:', error);
      toast.error('Error in downloading invoice');
    }
  });

  // Download Bill mutation
  const { mutate: handleDownloadBill } = useMutation({
    mutationFn: async (id) => {
      return await downloadBillForOrder(id);
    },
    onSuccess: (fileData, id) => {
      if (!fileData || fileData.byteLength === 0) {
        toast.error('Error: File data is empty or invalid.');
        return;
      }
      const url = window.URL.createObjectURL(new Blob([fileData], { type: 'application/pdf' }));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `Order_${id}_Bill.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      
      toast.success('Bill Downloaded');
      onSuccess && onSuccess();
    },
    onError: (error) => {
      console.error('Error downloading bill:', error);
      toast.error('Error in downloading bill');
    }
  });

  // The "activity" itself is your status string, e.g. "Order Confirm", "Payment Done", etc.
  const activityStatus = data?.activity;

  return (
    <div className="flex flex-col w-full flex-shrink-0 border-l border-dashed border-l-gray-400 left-0 gap-5 relative">
      {/* Title Section */}
      <div className="flex flex-col items-start gap-4 pl-6">
        <div className="flex flex-col gap-0">
          <div className="flex justify-between items-start space-x-4">
            <p className="text-lg font-semibold text-[#2A565E]">
              {data?.activity || 'No activity name'}
            </p>
          </div>
        </div>
      </div>

      {/* Body Section */}
      <div className="w-[96%] flex flex-col gap-2 p-4 rounded-md bg-[#E9F5F6] self-center">
        <div className="flex justify-between items-center">
          {/* Date */}
          <div className="flex items-center gap-2">
            <img src={clocksvg} alt="clock_icon" />
            <p className="text-gray-700">{changeDateToCustomFormat(data?.updated_at)}</p>
          </div>
          {/* Current Activity/Status Label */}
          <p className="bg-[#c4c3d4] text-white border p-1 rounded-xl">{activityStatus}</p>
        </div>

        {/* Example of referencing order info */}
        <span className="text-gray-500">{`Order #${data?.order_id}`}</span>

        {/* If activity is "Order Confirm", show button to download Invoice */}
        {activityStatus === 'Order Confirm' && (
                      <div className="mt-2">
          <Button
            type="button"
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            displayType="downloadButton"
            onClick={() => handleDownloadInvoice(orderId)}
          >
            Invoice 
            <img
              src={DownloadIcon}
              style={{ width: '17px', filter: 'invert(1)' }}
              alt="download_icon"
            />
          </Button>
        </div>
        )}

        {/* If activity is "Payment Done", show button to download Bill */}
        {activityStatus === 'Payment Done' && (
          <div className="mt-2">
          <Button
            type="button"
            customStyle={{
              padding: '0.5rem 1rem',
              fontSize: '13px',
              display: 'flex',
              gap: '5px',
              alignItems: 'center'
            }}
            displayType="downloadButton"
            onClick={() => handleDownloadBill(orderId)}
          >
            Bill 
            <img
              src={DownloadIcon}
              style={{ width: '17px', filter: 'invert(1)' }}
              alt="download_icon"
            />
          </Button>
        </div>
        )}
      </div>

      {/* Icon on the left (depending on "special" prop) */}
      <img
        src={`/icons/${special ? 'document' : 'organisation'}.svg`}
        alt="document_icon"
        className="absolute left-[-10px] top-0 bg-white"
      />
    </div>
  );
};

export default OrderActivityCard;
