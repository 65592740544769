import React, { useCallback, useState } from 'react';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Header from '../../shared/components/layout/Header';
import Layout from '../components/layout/AppLayout';
import { AddUser } from '../components/forms/AddUser';
import Modal from '../../shared/components/ui/Modal';
import Table from '../../shared/components/layout/Table';
import FormInput from '../../shared/components/ui/FormInput';
import Accordian from '../components/layout/Accordian';
import AddCustomRole from '../components/forms/AddCustomRole';

const RolePermission = () => {
  /* 
    Local state
  */
  const [isOpenAddRole, setIsOpenAddRole] = useState(false);
  const [openRole, setOpenRole] = useState('Project manager');

  /* 
    Event handlers
  */
  const handleToggleOpenRole = useCallback(
    (openRoleComing) => {
      openRole === openRoleComing ? setOpenRole(null) : setOpenRole(openRoleComing);
    },
    [openRole]
  );

  /* 
    JSX
  */
  return (
    <>
      {/* Add Custom role form */}
      {isOpenAddRole && (
        <Modal width={56} name="Add Custom Role" onClose={() => setIsOpenAddRole(false)}>
          <AddCustomRole />
        </Modal>
      )}

      {/* LAYOUT */}
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
          <Header data={{ name: 'Users > Roles & Permission' }} backArrow={true} />
        </div>

        {/* Main content */}
        <div className="flex  z-40 items-center justify-end px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
          {/* Pass searchQuery and handleSearchChange as props to Search component */}
          <div className="">
            <Button onClick={() => setIsOpenAddRole(true)}>Add Custom Role</Button>
          </div>
          <div className="flex gap-5 cursor-pointer">
            <div>
              <img className="" src={''} alt="" />
            </div>
          </div>
        </div>

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="mt-[10.4rem] flex flex-col gap-6">
            <Accordian
              name={'Project manager'}
              isOpen={openRole === 'Project manager'}
              onClose={handleToggleOpenRole}
            />
            <Accordian
              name={'Sales person'}
              isOpen={openRole === 'Sales person'}
              onClose={handleToggleOpenRole}
            />
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default RolePermission;
