import React, { useCallback, useState } from 'react';
import RegisterForm from '../components/forms/RegisterForm';
import toast from 'react-hot-toast';
import { registerUser } from '../services/api/implementation/impl';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useNavigate } from 'react-router-dom';

const Register = () => {
  /* 
    Hooks
  */
  const naviagte = useNavigate();

  /* 
    Event handlers
  */
  const { mutate: handleRegister, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: async (payload) => await registerUser(payload),
    onSuccess: async () => {
      toast.success('Verification code sent to your email');
      naviagte('/auth/verify-email');
    },
    onError: (err) => {
      console.error('Error registering user');
      toast.error('Error registering user');
    }
  });

  /* 
    JSX
  */
  return (
    <div className="text-stone-700 flex justify-between items-stretch h-screen w-screen overflow-hidden">
      <div className="h-screen w-[64%] bg-black">
        <img
          src="https://cdn.pixabay.com/photo/2022/10/03/23/41/house-7497001_1280.png"
          alt="login_vector_image"
          className="w-full h-full !bg-center !bg-cover !object-cover !object-center"
        />
      </div>

      <div className="h-screen w-[36%] bg-white flex flex-col items-center justify-start">
        <div className="w-[92%] rounded-lg p-8 py-14">
          <RegisterForm onSubmit={handleRegister} />
        </div>
      </div>
    </div>
  );
};

export default Register;
