import React, { useState } from 'react';
import Avatar from 'react-avatar';
import MsgSend from '../../shared/assets/svg/msgSendIcon.svg';
import DownloadIcon from '../../shared/assets/svg/download.svg';
import TextareaAutosize from 'react-textarea-autosize';
import Slider from 'react-slick';
// Import slick styles (required)
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function LiveFeedCard({ data, onSuccess }) {
  const [mainComment, setMainComment] = useState('');
  const [comments, setComments] = useState(data?.comments || []);
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyTexts, setReplyTexts] = useState({});
  const [visibleComments, setVisibleComments] = useState(2);
  const [visibleReplies, setVisibleReplies] = useState({});

  // Helper to check if file is an image
  const isImage = (fileNameOrUrl = '') => {
    const lowerName = fileNameOrUrl.toLowerCase();
    return (
      lowerName.endsWith('.jpg') ||
      lowerName.endsWith('.jpeg') ||
      lowerName.endsWith('.png') ||
      lowerName.endsWith('.gif') ||
      lowerName.endsWith('.webp')
    );
  };

  // Separate attachments
  const imageAttachments = data?.attachments?.filter((attach) =>
    isImage(attach.name),
  );
  const fileAttachments = data?.attachments?.filter((attach) =>
    !isImage(attach.name),
  );

  // Slider settings
  const sliderSettings = {
    dots: true,            // Show dots
    arrows: false,         // Hide arrows
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Handlers
  const handleMainCommentChange = (e) => setMainComment(e.target.value);

  const handleReplyChange = (e, commentId) => {
    setReplyTexts((prev) => ({
      ...prev,
      [commentId]: e.target.value,
    }));
  };

  const handleSendComment = (parentId = null) => {
    if (parentId === null) {
      if (mainComment.trim()) {
        const newComment = {
          id: Date.now(),
          name: 'Current User',
          project: 'Dream Project',
          content: mainComment,
          replies: [],
        };
        setComments((prev) => [...prev, newComment]);
        setMainComment('');
      }
    } else {
      const replyText = replyTexts[parentId]?.trim();
      if (replyText) {
        const newReply = {
          id: Date.now(),
          name: 'Current User',
          project: 'Dream Project',
          content: replyText,
        };
        setComments((prev) =>
          prev.map((comment) =>
            comment.id === parentId
              ? { ...comment, replies: [...comment.replies, newReply] }
              : comment,
          ),
        );
        setReplyTexts((prev) => ({ ...prev, [parentId]: '' }));
        setReplyingTo(null);
      }
    }
  };

  const handleReply = (commentId) => {
    setReplyingTo(commentId);
  };

  const loadMoreComments = () => {
    setVisibleComments((prev) => prev + 10);
  };

  const toggleReplies = (commentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  return (
    <div className="w-[400px] bg-white p-4 rounded-lg shadow-lg mx-auto">
      {/* Top Section */}
      <div className="topSection flex items-center gap-2">
        <Avatar name={data?.creater_name} size="40" round />
        <div className="info">
          <p className="text-sm">{data?.creater_name}</p>
          <p className="text-gray-400 text-xs">Dream Project</p>
        </div>
      </div>

      {/* Content Section */}
      <div className="content flex flex-col gap-4 mt-4">
        {/* Description */}
        <p className="desc text-sm">{data?.post}</p>

        {/* Image Slider or Single Image */}
        {imageAttachments && imageAttachments.length > 0 && (
          <div className="mt-4 relative">
            {imageAttachments.length === 1 ? (
              /* If there's only one image, just render it (no dots) */
              <img
                src={imageAttachments[0].url}
                alt={imageAttachments[0].name}
                className="w-full h-[368px] object-contain bg-black"
              />
            ) : (
              /* If more than one image, show Slider with dots */
              <Slider {...sliderSettings}>
                {imageAttachments.map((attach) => (
                  <div
                    key={attach.id}
                    className="flex justify-center items-center"
                  >
                    <img
                      src={attach.url}
                      alt={attach.name}
                      className="w-full h-auto object-contain bg-black"
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        )}

        {/* Files List Section */}
        {fileAttachments && fileAttachments.length > 0 && (
          <div className="mt-4">
            <p className="font-medium text-sm mb-2">Files</p>
            {fileAttachments.map((file) => (
              <div
                key={file.id}
                className="flex items-center justify-between bg-gray-50 p-2 rounded mb-2"
              >
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline text-sm"
                >
                  {file.name}
                </a>
                <a
                  href={file.url}
                  download={file.name}
                  className="text-white rounded text-xs"
                >
                  <img src={DownloadIcon} alt="" />
                </a>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Main Comment Input */}
      <div className="mt-4 flex items-center border rounded-lg px-4 py-2">
        <TextareaAutosize
          value={mainComment}
          onChange={handleMainCommentChange}
          className="flex-1 text-sm border-none outline-none resize-none overflow-hidden"
          placeholder="Add a comment..."
          minRows={1}
        />
        <button onClick={() => handleSendComment()} className="ml-2">
          <img src={MsgSend} alt="Send" className="h-[20px]" />
        </button>
      </div>

      {/* Comment Section */}
      <div className="comments mt-4">
        {comments.slice(0, visibleComments).map((comment) => (
          <div key={comment.id} className="comment mb-4">
            <div className="flex items-start gap-2">
              <Avatar name={comment.name} size="32" round />
              <div className="flex-1">
                <p className="text-sm font-medium">{comment.name}</p>
                <p className="text-xs text-gray-400">{comment.project}</p>
                <p className="text-sm mt-1">{comment.content}</p>
                <button
                  onClick={() => handleReply(comment.id)}
                  className="text-blue-500 text-xs mt-1"
                >
                  Reply
                </button>

                {/* Replies Section */}
                {comment.replies.length > 0 && (
                  <div className="replies mt-2 border-l-2 border-gray-200 pl-4">
                    {comment.replies.length > 2 && !visibleReplies[comment.id] ? (
                      <button
                        onClick={() => toggleReplies(comment.id)}
                        className="text-blue-500 text-xs mt-1"
                      >
                        See previous replies
                      </button>
                    ) : (
                      comment.replies.map((reply) => (
                        <div key={reply.id} className="reply mb-2">
                          <div className="flex items-start gap-2">
                            <Avatar name={reply.name} size="28" round />
                            <div>
                              <p className="text-sm font-medium">
                                {reply.name}
                              </p>
                              <p className="text-xs text-gray-400">
                                {reply.project}
                              </p>
                              <p className="text-sm mt-1">{reply.content}</p>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}

                {/* Reply Input */}
                {replyingTo === comment.id && (
                  <div className="mt-2 flex items-center gap-2">
                    <TextareaAutosize
                      value={replyTexts[comment.id] || ''}
                      onChange={(e) => handleReplyChange(e, comment.id)}
                      className="flex-1 text-sm border rounded-lg px-2 py-1 resize-none"
                      placeholder="Write a reply..."
                      minRows={1}
                    />
                    <button onClick={() => handleSendComment(comment.id)}>
                      <img src={MsgSend} alt="Send" className="h-[20px]" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleComments < comments.length && (
          <button
            onClick={loadMoreComments}
            className="text-blue-500 text-sm mt-4"
          >
            Load more comments
          </button>
        )}
      </div>
    </div>
  );
}

export default LiveFeedCard;
