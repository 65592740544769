import React, { useCallback, useEffect, useState } from 'react';
import Search from '../../shared/components/ui/Search';
import Table from '../../shared/components/layout/Table';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import { transformData } from '../../super-admin/utils/transformData';
import { useQuery } from '@tanstack/react-query';
import {
  getAllBlocks,
  getAllLead,
  getCloseDeals,
  getLeadsByStatus
} from '../services/api/implementation/impl';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';

const CustomerList = () => {
  const { id: projectId } = useParams();
  const { register } = useForm();
  const navigate = useNavigate();

  const userId = Cookies.get('userId');

  const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  const {
    data: deals,
    refetch: refetchDeals,
    isFetching
  } = useQuery({
    queryKey: ['deals', page],
    queryFn: () => getCloseDeals(page, limit),
    keepPreviousData: true
  });

  const totalPages = deals?.meta ? Math.ceil(deals?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const [newBlockId, setNewBlockId] = useState(null);
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const CustomerFormat = [
    'id',
    'name',
    'phone',
    'email',
    // "assigned_to",
    'project',
    'unit_sold',
    'cost',
    'actions'
  ];

  const handleClosedCustomerData = (item) => {
    let newitem = {
      id: item?.id,
      name: item?.lead?.user?.name,
      phone: item?.lead?.user?.phone_number,
      email: item?.lead?.user?.email,
      assigned_to: '___',
      project: item?.unit?.project?.name,
      unit_sold: `${item?.unit?.block?.name} , ${item?.unit?.unitName}`,
      cost: item?.unit?.cost === 0 ? '0' : item?.unit?.cost
    };
    return newitem;
  };

  console.log({ deals });

  // Filter the deals based on sales_id
  // const filteredDeals = deals?.filter(deal => deal.lead?.sales_id === parseInt(userId));
  // console.log({ filteredDeals });

  // Transform the filtered deals

  useEffect(() => {
    setFilteredData(CustomerData);
  }, [deals?.data]);
  const CustomerData = deals?.data?.map((item) =>
    transformData(handleClosedCustomerData(item), CustomerFormat)
  );

  console.log({ CustomerData });

  const handleViewClosedDealsByID = (id) => {
    navigate(`/builder/deals/${id}`);
  };

  const handleSelectBlockChange = (event) => {
    const selectedBlockId = event.target.value;
    setNewBlockId(selectedBlockId);
  };

  useEffect(() => {
    if (blocksDyna?.blocks) {
      const blocksWithLevels = blocksDyna.blocks.filter((block) => block?.levels?.length > 0);
      const sorted = blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        setNewBlockId(sorted[0].id);
      }
    }
  }, [blocksDyna]);

  const [searchQuery, setSearchQuery] = useState('');

  const [filteredData, setFilteredData] = useState(CustomerData);

  const handleOnClickSearch = () => {
    const filtered = CustomerData?.user?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchQuery('');
  };

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const lowerCaseQuery = incomingQuery.toLowerCase();

        const filteredLeads = CustomerData?.filter((lead) => {
          return (
            lead.name?.toLowerCase().includes(lowerCaseQuery) ||
            lead.phone?.toLowerCase().includes(lowerCaseQuery) ||
            lead.email?.toLowerCase().includes(lowerCaseQuery) ||
            lead.project?.toLowerCase().includes(lowerCaseQuery) ||
            lead.unit_sold?.toLowerCase().includes(lowerCaseQuery) ||
            lead.cost?.toString().toLowerCase().includes(lowerCaseQuery)
          );
        });
        setFilteredData(filteredLeads);
      } else {
        setFilteredData(CustomerData);
      }
    },
    [CustomerData]
  );

  return (
    <div className="w-full flex flex-col absolute z-10 bg-gray-100 pb-10 overflow-hidden">
      {CustomerData && CustomerData?.length > 0 ? (
        <>
          <div className="flex z-40 items-center justify-between px-6 py-4 bg-white fixed top-[9rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
            <Search
              onChange={handleSearchChange}
              query={searchQuery}
              onClick={handleOnClickSearch}
            />
            <div className="flex gap-5 cursor-pointer">
              <div>{/* <img className="" src={filter} alt="" /> */}</div>
              {/* <Button children={'Create'} /> */}
            </div>
          </div>

          {/* Grid */}

            <GridWrapper width={100}>
              <div className="mt-[15rem] h-[28rem] overflow-scroll hide-scrollbar relative">
                <Table
                  headers={[
                    'Name',
                    'Phone',
                    'Email',
                    // 'Assigned To',
                    'Project',
                    'Unit Sold',
                    'Cost',
                    'Action'
                  ]}
                  data={filteredData}
                  isViewEnable={true}
                  isEditEnable={false}
                  identifier={'id'}
                  onView={(id) => handleViewClosedDealsByID(id)}
                />
                <PaginationComponent
                  page={page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  isFetching={isFetching}
                />
              </div>
            </GridWrapper>
          
        </>
      ) : (
        <div className="pt-[3rem]">
          <NoDataFound disabledBtn screenHeight={true} />
        </div>
      )}
    </div>
  );
};

export default CustomerList;
