import React from 'react';
import Sidebar from '../../../shared/components/layout/Sidebar';

import logo from '../../../shared/assets/svg/group.svg';
import dashboard from '../../../shared/assets/svg/dashboard.svg';
import users from '../../../shared/assets/svg/users.svg';
import analytics from '../../../shared/assets/svg/analytics.svg';
import sales from '../../../shared/assets/svg/sales.svg';
import orgaisation from '../../../shared/assets/svg/organisation.svg';
import operations from '../../../shared/assets/svg/operations.svg';
import Reports from '../../../shared/assets/svg/reports.svg';
import Integration from '../../../shared/assets/svg/integration.svg';
import Logout from '../../../shared/assets/svg/logout.svg';
import { Toaster } from 'react-hot-toast';

const Layout = ({ children }) => {
  /* 
    Meta data
  */
  const adminLinks = [
    // { name: 'Live Feed', path: '/builder/feed', icon: dashboard },
    {
      name: 'Management',
      path: '/builder/management',
      icon: orgaisation,
      children: [
        { displayName: 'Company', path: 'company' },
        { displayName: 'Land', path: 'land' }
      ]
    },
    {
      name: 'Operations',
      path: '/builder/operations',
      icon: operations,
      children: [
        { displayName: 'Vendors', path: 'vendors' },
        { displayName: 'Service Providers', path: 'service-providers' },
        { displayName: 'Requirements', path: 'requirements' },
        { displayName: 'Orders', path: 'orders' },
        { displayName: 'Materials', path: 'materials' },
        // { displayName: 'Inventory', path: 'inventory' }
      ]
    },
    {
      name: 'Reports',
      path: '/builder/reports',
      icon: Reports,
      children: [
        { displayName: 'Sales Report', path: 'sales' },
        { displayName: 'Accounts Report', path: 'accounts' }
        // { displayName: 'Inventory Report', path: 'inventory' }
      ]
    },
    {
      name: 'Integration',
      path: '/builder/integration',
      icon: Integration
    },
    { name: 'Users', path: '/builder/users', icon: users },
    // { name: 'Analytics', path: '/builder/analytics', icon: analytics },
    { name: 'Sales', path: '/builder/sales', icon: sales },
    { name: 'Logout', path: 'logout', icon: Logout }
  ];

  /* 
    JSX
  */
  return (
    <div className="flex justify-between items-stretch relative min-h-screen">
      {/* Sidebar */}
      <Sidebar role="builder" links={adminLinks} logo={logo} />

      {/* Main content outlet */}
      <div className={`flex-1 bg-gray-100 min-h-screen absolute left-[18%] w-[82%]`}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
