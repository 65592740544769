import React from 'react';

function TableHead({ name, width, rightAlign }) {
  const style = { width: `${width}%` };

  return (
    <div
      className={`flex ${rightAlign ? 'justify-end' : 'justify-start'} px-3`} // If design break, remove margin
      style={style}
    >
      {name}
    </div>
  );
}

export default TableHead;
