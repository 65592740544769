import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';

const EditBlock = ({
  onClose,
  onSubmit,
  setIsEditingBlock,
  IsOpenAddNewBlock,
  handleEditBlock,
  onProceed,
  data,
  isSubmitting
}) => {
  /* 
    Hooks
  */
  const { register, handleSubmit } = useForm();

  /* 
    Event handlers
  */

  return (
    <>
      <div>
        <div className="flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Block details</span>
        </div>
        <div className="flex flex-col gap-4 pt-6">
          <div className="flex justify-between items-center w-full">
            <FormInput
              width={48}
              label={'Block Name'}
              register={register}
              register_key={'name'}
              type={'text'}
              defaultValuePre={data?.name}
              // options={users?.map((item) => ({
              //   value: item?.Phone?.trim(),
              //   label: item?.Phone?.trim(),
              // }))}
            />

            <FormInput
              width={48}
              label={'Block area'}
              register={register}
              register_key={'area'}
              type={'text'}
              defaultValuePre={data?.area}
            />
          </div>
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="reset" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit(onProceed)} type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditBlock;
