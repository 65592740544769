import React from 'react';

import Sidebar from '../../../shared/components/layout/Sidebar';

import logo from '../../../shared/assets/svg/group.svg';
import dashboard from '../../../shared/assets/svg/dashboard.svg';
import users from '../../../shared/assets/svg/users.svg';
import billing from '../../../shared/assets/svg/billing.svg';
import analytics from '../../../shared/assets/svg/analytics.svg';
import Logout from '../../../shared/assets/svg/logout.svg';


const Layout = ({ children }) => {
  /* 
    Meta data
  */
  const adminLinks = [
    { name: 'Dashboard', path: '/super-admin/home', icon: dashboard },
    {
      name: 'User Management',
      path: '/super-admin/user-management',
      icon: users
    },
    {
      name: 'Billings & Payments',
      path: '/super-admin/billings-payment',
      icon: billing
    },
    // { name: 'Analytics', path: '/super-admin/analytics', icon: analytics },
    { name: 'Logout', path: 'logout', icon: Logout }
  ];

  /* 
    JSX
  */
  return (
    <div className="flex justify-between items-stretch relative min-h-screen">
      {/* Sidebar */}
      <Sidebar role="admin" links={adminLinks} logo={logo} />;{/* Main content outlet */}
      <div className="flex-1 bg-gray-100 w-[82%] absolute left-[18%] min-h-screen overflow-y-scroll">
        {children}
      </div>
    </div>
  );
};

export default Layout;
