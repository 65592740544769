import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { getAllCategories } from '../../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';

const validationSchema = yup.object().shape({
  name: yup.string().optional().max(50, 'Full name should be at most 50 characters'),
  business_name: yup.string().optional().max(100, 'Business name should be at most 100 characters'),
  phone_number: yup
    .string()
    .optional()
    .matches(/^[0-9]{10}$/, 'Invalid phone number'),
  email: yup.string().optional().email('Invalid email format'),
  category_id: yup.string().optional(),
  companyIds: yup.array().of(yup.number().integer().positive()).optional(),
  role_id: yup.number().integer().positive().optional(),
  userId: yup.number().optional()
});

export const EditVendor = ({ vendor, onClose, onSubmit }) => {
  const [categories, setCategories] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: vendor?.name || '',
      business_name: vendor?.businessName || '',
      phone_number: vendor?.phone_number || '',
      email: vendor?.email || '',
      category_id: vendor?.category || ''
    }
  });

  const { data: allCategories, refetch: refetchCategories } = useQuery({
    queryKey: ['categories'],
    queryFn: getAllCategories
  });

  useEffect(() => {
    if (allCategories?.data && allCategories?.data.length > 0) {
      setCategories(allCategories?.data);
    }
  }, [allCategories?.data]);

  const onSubmitForm = (data) => {
    const formattedData = {
      name: data.name || undefined,
      businessName: data.business_name || undefined,
      email: data.email || undefined,
      phone_number: data.phone_number || undefined,
      category: data.category_id ? data.category_id.toUpperCase() : undefined
    };

    onSubmit(formattedData);
    reset();
  };

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Edit Vendor Details
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Contact Person Name'}
            register_key={'name'}
            register={register}
            type={'text'}
            errors={errors.name}
          />
          <FormInput
            width={48}
            label={'Business Name'}
            register_key={'business_name'}
            register={register}
            type={'text'}
            errors={errors.business_name}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Phone Number'}
            register={register}
            register_key={'phone_number'}
            type={'text'}
            errors={errors.phone_number}
          />

          <FormInput
            width={48}
            label={'Email Address'}
            register={register}
            register_key={'email'}
            type={'text'}
            errors={errors.email}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Vendor Category'}
            register={register}
            register_key={'category_id'}
            type={'dropdown'}
            defaultValuePre={'Select Category'}
            options={[
              { value: '', label: 'Select Category' },
              ...categories.map((category) => ({
                value: category.id,
                label: category.name.trim()
              }))
            ]}
            errors={errors.category_id}
          />
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit">Update</Button>
        </div>
      </form>
    </>
  );
};

export default EditVendor;
