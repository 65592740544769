import React, { useCallback, useState, useEffect } from 'react';
import { getAllCompanies, getStateAndCity } from '../../services/api/implementation/impl';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

const AddLand = ({ onClose, onSubmit, isSubmitting }) => {
  /* 
    Data fetching
  */
  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getAllCompanies()
  });

  /* 
    Local state
  */
  const [owners, setOwners] = useState([
    {
      ownerId: Date.now(),
      khasras: [
        {
          khasraId: Date.now(),
          khasra_number: '',
          mainArea: '',
          subArea: '',
          khasra_map: '',
          khasra_p1b2: '',
          land_use_map: ''
        }
      ]
    }
  ]);

  // Pincode Related states
  const [city, setCity] = useState('Select');
  const [state, setState] = useState('-');
  const [district, setDistrict] = useState('Select');
  const [tehsil, setTehsil] = useState('Select');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);
  const [districtDropdownOptions, setDistrictDropdownOptions] = useState([]);
  const [tehsilDropdownOptions, setTehsilDropdownOptions] = useState([]);

  // Total Area States
  const [totalMainArea, setTotalMainArea] = useState(0);
  const [totalSubArea, setTotalSubArea] = useState(0);
  const [totalMainAreaType, setTotalMainAreaType] = useState('sq/hectare'); // Default unit
  const totalSubAreaType = 'sq/gunta'; // Fixed unit

  const fileUrls = useSelector((state) => state.file.fileUrls);
  const MultipleFiles = useSelector((state) => state.file.multifiles);

  /* 
    Hook form
  */
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      measuring_unit: 'sq/hectare',
      total_mainArea: 0,
      total_subArea: 0
    }
  });

  /* 
    Event handlers
  */
  const handlePincodeChange = useCallback(
    async (pincode) => {
      const cleanedPincode = pincode.replace(/[^0-9]/g, '');
      setValue('pincode', cleanedPincode);
      if (cleanedPincode.length === 6) {
        try {
          const pincodeList = await getStateAndCity(cleanedPincode);
          const postOfficeList = pincodeList[0].PostOffice;
          if (postOfficeList.length > 0) {
            setState(postOfficeList[0].State);
            const uniqueCircles = [...new Set(postOfficeList.map((office) => office.Name))];
            const uniqueDistricts = [...new Set(postOfficeList.map((office) => office.District))];
            const uniqueTehsils = [...new Set(postOfficeList.map((office) => office.Block))];
            setCityDropdownOptions(
              uniqueCircles.map((circle) => ({ value: circle, label: circle }))
            );
            setDistrictDropdownOptions(
              uniqueDistricts.map((district) => ({ value: district, label: district }))
            );
            setTehsilDropdownOptions(
              uniqueTehsils.map((tehsil) => ({ value: tehsil, label: tehsil }))
            );

            setValue('state', postOfficeList[0].State);
            setValue('city', uniqueCircles[0]);
            setValue('district', uniqueDistricts[0]);
            setValue('tehsil', uniqueTehsils[0]);

            setState(postOfficeList[0].State);
            setCity(uniqueCircles[0]);
            setDistrict(uniqueDistricts[0]);
            setTehsil(uniqueTehsils[0]);
          } else {
            setValue('state', '-');
            setValue('city', 'Select');
            setValue('district', 'Select');
            setValue('tehsil', 'Select');

            setState('-');
            setCity('Select');
            setDistrict('Select');
            setTehsil('Select');
            setCityDropdownOptions([]);
          }
        } catch (error) {
          toast.error('The entered pincode is invalid. Please enter a valid pincode.');
          setValue('state', '-');
          setValue('city', 'Select');
          setValue('district', 'Select');
          setValue('tehsil', 'Select');

          setState('-');
          setCity('Select');
          setDistrict('Select');
          setTehsil('Select');
          setCityDropdownOptions([]);
        }
      }
    },
    [setValue]
  );

  const handleMainAreaChange = useCallback(
    (ownerId, khasraId, value) => {
      const cleanedValue = value.replace(/[^0-9.]/g, '').slice(0, 8);
      setOwners((prevOwners) =>
        prevOwners.map((owner) =>
          owner.ownerId === ownerId
            ? {
                ...owner,
                khasras: owner.khasras.map((khasra) =>
                  khasra.khasraId === khasraId
                    ? { ...khasra, mainArea: parseFloat(cleanedValue) || 0 }
                    : khasra
                )
              }
            : owner
        )
      );
      setValue(`mainArea-${khasraId}`, cleanedValue);
    },
    [setValue]
  );

  const handleSubAreaChange = useCallback(
    (ownerId, khasraId, value) => {
      const cleanedValue = value.replace(/[^0-9]/g, '').slice(0, 5);
      setOwners((prevOwners) =>
        prevOwners.map((owner) =>
          owner.ownerId === ownerId
            ? {
                ...owner,
                khasras: owner.khasras.map((khasra) =>
                  khasra.khasraId === khasraId
                    ? { ...khasra, subArea: parseInt(cleanedValue) || 0 }
                    : khasra
                )
              }
            : owner
        )
      );
      setValue(`subArea-${khasraId}`, cleanedValue);
    },
    [setValue]
  );

  // Update total main and sub areas whenever owners state changes
  useEffect(() => {
    const totalMain = owners.reduce(
      (sum, owner) =>
        sum + owner.khasras.reduce((kSum, khasra) => kSum + (parseFloat(khasra.mainArea) || 0), 0),
      0
    );
    const totalSub = owners.reduce(
      (sum, owner) =>
        sum + owner.khasras.reduce((kSum, khasra) => kSum + (parseInt(khasra.subArea) || 0), 0),
      0
    );
    setTotalMainArea(totalMain);
    setTotalSubArea(totalSub);
    setValue('total_mainArea', totalMain);
    setValue('total_subArea', totalSub);
  }, [owners, setValue]);

  const handleAddKhasraBlock = useCallback((ownerId) => {
    setOwners((prevOwners) =>
      prevOwners.map((owner) =>
        owner.ownerId === ownerId
          ? {
              ...owner,
              khasras: [
                ...owner.khasras,
                {
                  khasraId: Date.now(),
                  khasra_number: '',
                  mainArea: '',
                  subArea: '',
                  khasra_map: '',
                  khasra_p1b2: '',
                  land_use_map: ''
                }
              ]
            }
          : owner
      )
    );
  }, []);

  const handleRemoveKhasraBlock = useCallback((ownerId, khasraId) => {
    setOwners((prevOwners) =>
      prevOwners.map((owner) =>
        owner.ownerId === ownerId
          ? {
              ...owner,
              khasras: owner.khasras.filter((khasra) => khasra.khasraId !== khasraId)
            }
          : owner
      )
    );
  }, []);

  const handleAddOwnerBlock = useCallback(() => {
    setOwners((prevOwners) => [
      ...prevOwners,
      {
        ownerId: Date.now(),
        khasras: [
          {
            khasraId: Date.now(),
            khasra_number: '',
            mainArea: '',
            subArea: '',
            khasra_map: '',
            khasra_p1b2: '',
            land_use_map: ''
          }
        ]
      }
    ]);
  }, []);

  const handleRemoveOwnerBlock = useCallback((ownerId) => {
    setOwners((prevOwners) => prevOwners.filter((owner) => owner.ownerId !== ownerId));
  }, []);

  /* 
    Form submission handler
  */
  const onSubmitHandler = (formData) => {
    console.log('formData', formData);
    const transformedData = {
      owner: owners.map((owner) => ({
        name: formData[`name-${owner.ownerId}`],
        email: formData[`email-${owner.ownerId}`],
        phone_number: formData[`phone_number-${owner.ownerId}`],
        aadhar: formData[`aadhar-${owner.ownerId}`],
        pan_number: formData[`pan-${owner.ownerId}`],
        diversion_order_link: fileUrls[`diversion_order-${owner.ownerId}`] || [],
        // diversionOrderFiles: fileUrls[`diversion_order-${owner.ownerId}`] || [],
        khasra: owner.khasras.map((khasra) => ({
          number: formData[`khasra_number-${khasra.khasraId}`],
          mainArea: parseFloat(formData[`mainArea-${khasra.khasraId}`]),
          subArea: parseInt(formData[`subArea-${khasra.khasraId}`]),
          mainAreaType: formData.measuring_unit,
          subAreaType: totalSubAreaType,
          khasra_map_link: fileUrls[`khasra_map-${khasra.khasraId}`] || [],
          khasra_p1b2_link: fileUrls[`khasra_p1b2-${khasra.khasraId}`] || [],
          land_use_map: fileUrls[`land_use_map-${khasra.khasraId}`] || []
        }))
      })),
      name: formData.name,
      company_id: parseInt(formData.company_id),
      registry_doc_link: MultipleFiles?.registry_document || [],
      advocate_search_link: MultipleFiles?.advocate_document || [],
      measuringUnit: formData.measuring_unit,
      total_mainArea: parseFloat(formData.total_mainArea),
      total_subArea: parseInt(formData.total_subArea),
      total_mainAreaType: formData.measuring_unit,
      total_subAreaType: totalSubAreaType,
      pincode: parseInt(formData.pincode),
      state: formData.state,
      district: formData.district,
      tehsil: formData.tehsil,
      area: formData.area,
      google_map_link: formData.google_map_link,
      additional_documents_link: MultipleFiles?.land_map || []
    };

    // Call the onSubmit function passed as a prop
    onSubmit(transformedData);
  };

  const handleAadharChange = useCallback(
    async (aadhar, key) => {
      const cleanedAadhar = aadhar.replace(/[^0-9]/g, '').slice(0, 12); // Aadhar card is 12 digits long
      setValue(key, cleanedAadhar);
    },
    [setValue]
  );

  const handlePhoneNumber = useCallback(
    async (val, key) => {
      const newval = val.replace(/[^0-9]/g, '');
      setValue(key, newval);
    },
    [setValue]
  );

  const handleLandName = useCallback(
    async (val) => {
      const newval = val.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue('name', newval);
    },
    [setValue]
  );

  const handleOwnerName = useCallback(
    async (val, key) => {
      const newval = val.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue(key, newval);
    },
    [setValue]
  );

  const handlePanChange = useCallback(
    async (pan, key) => {
      const cleanedPan = pan.toUpperCase().replace(/[^A-Z0-9]/g, '');
      setValue(key, cleanedPan);
    },
    [setValue]
  );

  const handleLocalityChange = useCallback(
    async (val) => {
      const cleanedValue = val.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue('area', cleanedValue);
    },
    [setValue]
  );

  // Watch measuring_unit and store it in a state
  const watchedMeasuringUnit = useWatch({
    control,
    name: 'measuring_unit',
    defaultValue: 'sq/hectare' // Ensure this matches your default in state
  });

  useEffect(() => {
    setTotalMainAreaType(watchedMeasuringUnit);
  }, [watchedMeasuringUnit]);

  // Watch state
  const watchStateChange = useWatch({
    control,
    name: 'state'
  });

  const [watchState, setWatchState] = useState(null);

  useEffect(() => {
    setWatchState(watchStateChange?.toLowerCase());
  }, [watchStateChange]);

  //writw a function to return "sq/gunta" to "gunta" and "sq/hectares" to "hectares"
  const formatAreaType = (areaType) => {
    return areaType?.replace('sq/', '');
  };

  /* 
    JSX
  */
  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmitHandler)}>
      {/* Land Details Section */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Land Details</span>

        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    isRequired={true}
                    register_key={'name'}
                    label={'Land Name'}
                    type={'text'}
                    onType={handleLandName}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={'company_id'}
                    isRequired={true}
                    label={'Company name'}
                    type={'dropdown'}
                    options={[
                      { value: '', label: 'Select Company' },
                      ...(companies
                        ? companies.map((item) => ({
                            value: item?.id,
                            label: item?.company_name?.trim()
                          }))
                        : [])
                    ]}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={'registry_document'}
                    label={'Registry Document'}
                    type={'file-secondary'}
                  />
                </div>
              </div>

              <div className="w-full flex justify-start space-x-4 gap-3 items-top pb-6">
                <FormInput
                  width={32}
                  register={register}
                  register_key={'advocate_document'}
                  label={'Advocate Search'}
                  type={'file-secondary'}
                />
                {/* <FormInput
                  width={32}
                  label={'Khasra Area Measurement Type'}
                  register={register}
                  register_key={'measuring_unit'}
                  isRequired={true}
                  type={'dropdown'}
                  options={[
                    {
                      value: 'sq/hectare',
                      label: 'hectares',
                      selected: true
                    },
                    {
                      value: 'sq/acres',
                      label: 'acres'
                    }
                  ]}
                  errors={errors.measuring_unit}
                /> */}

                {/* Sub Area Measuring Unit (Fixed to Gunta) */}
                {/* <FormInput
                  width={32}
                  register={register}
                  register_key={'sub_area_measuring_unit'}
                  // label={`Sub area measuring unit`}
                  type={'text'}
                  valuePre={'guntha'}
                  CustomStyle={{ pointerEvents: 'none', backgroundColor: '#E7E5E4' }}
                /> */}

                <div className="flex flex-col w-[32%]">
                  
                  <label className="text-md text-gray-600 mb-2">
                    Khasra Area Measurement Type
                  </label>

                  <div className="flex items-center justify-between gap-4">
                    {/* Main area measuring unit */}
                    <select
                      className="border border-gray-300 rounded-md px-3 py-2 w-1/2"
                      {...register('measuring_unit', { required: true })}>
                      <option value="sq/hectare" selected>Hectares</option>
                      <option value="sq/acres">Acres</option>
                    </select>

                    {/* Sub area measuring unit */}
                    <input
                      className="border border-gray-300 rounded-md px-3 py-2 bg-[#e7e5e4] w-1/2"
                      type="text"
                      placeholder="Guntha"
                      // {...register('sub_area_measuring_unit', { required: true })}
                      value={'guntha'}
                      disabled={true}
                    />
                  </div>

                  {/* Error messages (optional)
                  {errors.measuring_unit && (
                    <p className="text-red-500 text-sm mt-1">{errors.measuring_unit.message}</p>
                  )}
                  {errors.sub_area_measuring_unit && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.sub_area_measuring_unit.message}
                    </p>
                  )} */}
                </div>
                <div className="flex w-[32%] gap-4 items-center">
                <FormInput
                  width={55}
                  register={register}
                  isRequired={true}
                  register_key={'total_main_area'}
                  label={`Total ${watchState === 'karnataka' ? 'Survey' : 'Khasra'} area (${formatAreaType(watchedMeasuringUnit)})`}
                  type={'text'}
                  valuePre={totalMainArea || 0}
                  CustomStyle={{ pointerEvents: 'none', backgroundColor: '#E7E5E4' }}
                />
                <FormInput
                  width={40}
                  register={register}
                  isRequired={true}
                  register_key={'total_sub_area'}
                  label={`Total (${formatAreaType(totalSubAreaType) === 'gunta' ? 'guntha' : 'guntha'})`}
                  type={'text'}
                  valuePre={totalSubArea || 0}
                  CustomStyle={{ pointerEvents: 'none', backgroundColor: '#E7E5E4' }}
                />
                </div>
              </div>


              <div className="w-full flex justify-between space-x-4 items-center">
                <FormInput
                  width={32}
                  register={register}
                  isRequired={true}
                  register_key={'pincode'}
                  label={'Pincode'}
                  type={'text'}
                  maxLength={6}
                  onType={handlePincodeChange}
                />

                <FormInput
                  width={32}
                  register={register}
                  isRequired={true}
                  register_key={'land_use_map'}
                  label={'Land use map'}
                  type={'hidden'}
                />

                <FormInput
                  width={32}
                  register={register}
                  register_key={'state'}
                  label={`State`}
                  errors={errors.state}
                  valuePre={state}
                  CustomStyle={{ pointerEvents: 'none' }}
                />

                <FormInput
                  width={32}
                  register={register}
                  register_key={'district'}
                  label={'District'}
                  type={'dropdown'}
                  defaultValuePre={district}
                  options={districtDropdownOptions}
                />
              </div>

              <div className="w-full flex justify-start space-x-4 items-center pb-6">
                <FormInput
                  width={32}
                  register={register}
                  register_key={'tehsil'}
                  label={`${watchState === 'karnataka' ? 'Taluk' : 'Tehsil'}`}
                  type={'dropdown'}
                  defaultValuePre={tehsil}
                  options={tehsilDropdownOptions}
                />

                <FormInput
                  width={32}
                  register={register}
                  register_key={'area'}
                  label={'Locality'}
                  type={'text'}
                  onType={handleLocalityChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Owner Details Section */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg w-full pb-2">Owner Details</span>

        {owners.map((owner, ownerIndex) => (
          <div
            className={`flex flex-col w-full flex-1 gap-4 ${ownerIndex > 0 ? 'pt-6' : 'pb-6'} border-[1px] rounded-md p-3`}
            key={owner.ownerId}>
            <div className="flex space-x-8 justify-between items-start w-full">
              <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
                <div className="w-full flex flex-col gap-4 flex-1">
                  <div className="flex justify-between space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      isRequired={true}
                      register_key={`name-${owner.ownerId}`}
                      label={'Owner Name'}
                      type={'text'}
                      onType={(val) => handleOwnerName(val, `name-${owner.ownerId}`)}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      register_key={`email-${owner.ownerId}`}
                      label={'Email'}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      isRequired={true}
                      register_key={`phone_number-${owner.ownerId}`}
                      label={'Mobile Number'}
                      type={'text'}
                      onType={(val) => handlePhoneNumber(val, `phone_number-${owner.ownerId}`)}
                    />
                  </div>
                </div>

                <div className="w-full flex justify-start space-x-6 items-center">
                  <FormInput
                    width={32}
                    register={register}
                    isRequired={true}
                    register_key={`aadhar-${owner.ownerId}`}
                    label={'Aadhar number'}
                    type={'text'}
                    onType={(val) => handleAadharChange(val, `aadhar-${owner.ownerId}`)}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    isRequired={true}
                    register_key={`pan-${owner.ownerId}`}
                    label={'PAN number'}
                    type={'text'}
                    onType={(val) => handlePanChange(val, `pan-${owner.ownerId}`)}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={`diversion_order-${owner.ownerId}`}
                    label={`${watchState === 'karnataka' ? 'Conversion Order' : 'Diversion Order'}`}
                    type={'file-btn'}
                  />
                </div>
              </div>
            </div>

            {/* Khasra Details */}
            {owner.khasras.map((khasra, khasraIndex) => (
              <div key={khasra.khasraId} className="flex flex-col gap-6 bg-gray-50 rounded-md p-3">
                <div className="w-full flex justify-between space-x-4 items-center pt-6">
                  <FormInput
                    width={20}
                    register={register}
                    register_key={`khasra_map-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Survey Map' : 'Khasra Map'}`}
                    type={'file-btn'}
                  />

                  <FormInput
                    width={20}
                    register={register}
                    register_key={`khasra_p1b2-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Podi / Hissas ' : 'Khasra P1B2'}`}
                    type={'file-btn'}
                    accept={'.pdf'}
                  />

                  <FormInput
                    width={20}
                    register={register}
                    register_key={`land_use_map-${khasra.khasraId}`}
                    label={'Land Use Certificate'}
                    type={'file-btn'}
                    accept={'.pdf'}
                  />

                  <FormInput
                    width={30}
                    register={register}
                    isRequired={true}
                    register_key={`khasra_number-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Survey Number' : 'Khasra Number'}`}
                    type={'text'}
                  />
                </div>

                {/* Khasra Area Inputs */}
                <div className="w-full flex justify-start space-x-4 items-center">
                  {/* Main Area Input */}
                  <FormInput
                    width={30}
                    register={register}
                    isRequired={true}
                    register_key={`mainArea-${khasra.khasraId}`}
                    label={`${watchState === 'karnataka' ? 'Survey Area' : 'Khasra Area'} (${formatAreaType(totalMainAreaType)})`}
                    type={'textOnType'}
                    onType={(e) =>
                      handleMainAreaChange(owner.ownerId, khasra.khasraId, e.target.value)
                    }
                  />

                  {/* Sub Area Input (Guntha) */}
                  <FormInput
                    width={30}
                    register={register}
                    isRequired={true}
                    register_key={`subArea-${khasra.khasraId}`}
                    label={`(${formatAreaType(totalSubAreaType) === 'gunta' ? 'guntha' : 'guntha'})`}
                    type={'textOnType'}
                    onType={(e) =>
                      handleSubAreaChange(owner.ownerId, khasra.khasraId, e.target.value)
                    }
                  />
                </div>



                <div className="w-full flex justify-start space-x-4 items-center">
                  {owner.khasras.length > 1 && (
                    <div className="w-full flex justify-end mt-4 self-end">
                      <span
                        className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer mt-4"
                        onClick={() => handleRemoveKhasraBlock(owner.ownerId, khasra.khasraId)}>
                        - Remove khasra details
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="w-full flex justify-end self-end">
              <span
                className="text-stone-700 underline underline-offset-2 transition-all duration-300 hover:scale-[102%] cursor-pointer mt-4"
                onClick={() => handleAddKhasraBlock(owner.ownerId)}>
                + Add more khasra details
              </span>
            </div>
            {owners.length > 1 && (
              <div className="w-full flex justify-end mt-4 self-end">
                <span
                  className="text-stone-700 border-customBlue border py-2 px-4 rounded-lg transition-all duration-300 hover:scale-[102%] cursor-pointer mt-4"
                  onClick={() => handleRemoveOwnerBlock(owner.ownerId)}>
                  - Remove owner details
                </span>
              </div>
            )}
          </div>
        ))}
        <div className="w-full flex justify-end mt-4 self-end">
          <span
            className="text-stone-700 border-customBlue border py-2 px-4 rounded-lg transition-all duration-300 hover:scale-[102%] cursor-pointer mt-4"
            onClick={handleAddOwnerBlock}>
            + Add more owner details
          </span>
        </div>
      </div>

      {/* Additional Details Section */}
      <div className="flex flex-col justify-between items-start gap-4 pt-6">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Additional Details
        </span>

        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-start space-x-4">
                  <FormInput
                    width={32}
                    register={register}
                    register_key={'google_map_link'}
                    errors={errors.google_map_link}
                    label={'Google Map Link'}
                    type={'textOnType'}
                  />

                  <FormInput
                    width={32}
                    register={register}
                    register_key={'land_map'}
                    label={'Additional Documents'}
                    type={'file-secondary'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default AddLand;
