import React from 'react';

const HeaderCard = ({ data }) => {
  let bgColor = 'gray';

  switch (data?.type) {
    case 'information':
      bgColor = '#D1B44D';
      break;
    case 'ongoing':
      bgColor = '#E97B37';
      break;
    case 'completed':
      bgColor = '#3F8D78';
      break;
    case 'delayed':
      bgColor = '#C2281B';
      break;
    default:
      bgColor = 'gray';
  }

  return (
    <div
      className={`p-6 rounded-lg flex flex-col gap-1 items-start justify-center bg-white w-[30%] relative`}
    >
      <span className="text-stone-600 w-full">{data?.key}</span>
      <span className="text-xl font-semibold text-stone-700">{data?.value}</span>
      <div
        className={`w-[6px] h-[56%] rounded-t-2xl rounded-br-[3rem] absolute left-0 top-[50%] translate-y-[-50%]`}
        style={{ backgroundColor: bgColor }}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default HeaderCard;
