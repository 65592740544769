import React from 'react';
import { useForm } from 'react-hook-form';

import FormInput from '../../../shared/components/ui/FormInput';
import Table from '../../../shared/components/layout/Table';

import blocksStatic from '../../static/data/block.json';
import Button from '../../../shared/components/ui/Button';

const PreviewBlockDetails = () => {
  /* 
    Hook form
  */
  const { register } = useForm();

  return (
    <div className="flex flex-col gap-6">
      <div className="w-full flex flex-col gap-2">
        <span>Block</span>

        <FormInput
          width={100}
          type={'dropdown'}
          defaultValuePre={'Select Block'}
          register={register}
        />
      </div>

      <div className="h-[28rem] overflow-y-scroll">
        <Table
          headers={['Stage', 'Estimated Date of Completion', 'Actual Date of Completion']}
          data={blocksStatic}
        />
      </div>

      <div className="flex space-x-4 justify-end items-center">
        <Button displayType={'secondary'}>Cancel</Button>
        <Button displayType={'secondary'}>Add New Stage</Button>
        <Button>Save</Button>
      </div>
    </div>
  );
};

export default PreviewBlockDetails;
