import React, { useEffect, useState, useMemo } from 'react';
import FormInput from '../../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import Button from '../../../shared/components/ui/Button';
import { useQuery } from '@tanstack/react-query';
import { getAllAccommodations, getBlockByID, getAllAccommodationsByProject } from '../../services/api/implementation/impl';

export const EditUnit = ({
  handleEditUnit,
  unitId,
  activeLevelType,
  data,
  onClose,
  isSubmitting
}) => {
  const [filteredAccommodations, setFilteredAccommodations] = useState([]);

  const {
    data: blockDyna,
    refetch: refetchBlockDyna,
    isLoading: isBlockDynaLoading
  } = useQuery({
    queryKey: ['block'],
    queryFn: () => getBlockByID(data[0]?.block_id),
    enabled: !!data[0]?.block_id
  });

  const { data: accommodations } = useQuery({
    queryKey: ['accommodations', data[0].project_id],
    queryFn: () => getAllAccommodationsByProject(data[0].project_id)
  });

  console.log({ data });
  console.log('accommodations', accommodations);

  console.log('blockDyna', blockDyna);

  useEffect(() => {
    if (data[0]?.block_id) {
      console.log('data', data);
      refetchBlockDyna(data[0]?.block_id);
    }
  }, [data[0]?.block_id, refetchBlockDyna]);

  // Filter the data based on unitId outside of useEffect
  const filteredData = useMemo(() => {
    return data?.filter((item) => parseInt(item?.id) === parseInt(unitId));
  }, [data, unitId]);
  console.log('filteredData', filteredData);

  const { handleSubmit, register, setValue } = useForm();

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      setValue('unit_name', filteredData[0]?.unitName || '');
      setValue('accomodation_id', filteredData[0]?.accommodation?.id || '');
      setValue('carpetArea', filteredData[0]?.accommodation.carpetArea || 0);
      setValue('buildUpArea', filteredData[0]?.accommodation.buildUpArea || 0);
      setValue('area', filteredData[0]?.area || '');
      setValue('loadingFactor', filteredData[0]?.accommodation?.loadingFactor || '');
      setValue('superBuildUpArea', filteredData[0]?.accommodation?.superBuildUpArea || '');
      setValue('electrified', filteredData[0]?.accommodation?.electrified ? true : false);
    }
  }, [filteredData, setValue]);

  console.log(accommodations, filteredData, blockDyna, isBlockDynaLoading);

  useEffect(() => {
    if (accommodations && filteredData?.length > 0 && blockDyna && !isBlockDynaLoading) {
      console.warn('accommodations', accommodations);
      let filtered = [];
      const blockType = blockDyna?.type;
      console.log('activeLevelType testing', activeLevelType);

      if (activeLevelType === 'PARKING') {
        // Show all accommodations with accomodationType as "PARKING"
        filtered = accommodations.filter(
          (accommodation) => accommodation.accomodationType.toLowerCase() === 'parking'
        );
      } else {
        const unitType = filteredData[0]?.accommodation?.unitType || activeLevelType;
        console.log('unitType testing', unitType);
        console.log('blockType testing', blockDyna);

        // Filter accommodations based on blockType and unitType
        filtered = accommodations.filter(
          (accommodation) =>
            accommodation.accomodationType.toLowerCase() === blockType?.toLowerCase() &&
            accommodation.unitType.toLowerCase() === unitType.toLowerCase()
        );

        console.warn('filtered', filtered);

        // Fallback to filtering only by unitType if no matches
        if (!blockType || filtered.length === 0) {
          filtered = accommodations.filter(
            (accommodation) => accommodation.unitType.toLowerCase() === unitType.toLowerCase()
          );
        }
      }

      setFilteredAccommodations(filtered);
    }
  }, [blockDyna, isBlockDynaLoading, accommodations, filteredData, activeLevelType]);

  console.log('filteredAccommodations', filteredAccommodations);

  const onSubmit = (formData) => {
    // Convert string values to numbers where necessary
    formData.carpetArea = parseFloat(formData.carpetArea);
    formData.buildUpArea = parseFloat(formData.buildUpArea);
    handleEditUnit(formData);
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-5">
        <FormInput
          width={100}
          register={register}
          register_key={'unit_name'}
          label={'Unit Name'}
          type={'text'}
          isRequired={true}
        />
        <FormInput
          width={100}
          register={register}
          register_key={'accomodation_id'}
          label={activeLevelType === 'PARKING' ? 'Parking Type' : 'Accommodation Name'}
          type={'dropdown'}
          options={filteredAccommodations?.map((acc) => ({ value: acc.id, label: acc.name }))}
        />
        {activeLevelType !== 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key={'carpetArea'}
            label={'Carpet Area'}
            type={'text'}
            disabled
            valuePre={filteredData[0]?.accommodation?.carpetArea || 0}
          />
        )}
        {activeLevelType !== 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key={'buildUpArea'}
            label={'Build up Area'}
            type={'text'}
            disabled
            valuePre={filteredData[0]?.accommodation?.buildUpArea || 0}
          />
        )}
        {activeLevelType === 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key={'area'}
            label={'Area'}
            type={'text'}
            disabled
            valuePre={filteredData[0]?.area || ''}
          />
        )}
        {activeLevelType === 'COMMERCIAL' && (
          <FormInput
            width={100}
            register={register}
            register_key={'loadingFactor'}
            label={'Loading Factor'}
            type={'text'}
            disabled
            valuePre={filteredData[0]?.accommodation?.loadingFactor || ''}
          />
        )}
        {activeLevelType === 'COMMERCIAL' && (
          <FormInput
            width={100}
            register={register}
            register_key={'superBuildUpArea'}
            label={'Super Build Up'}
            type={'text'}
            disabled
            valuePre={filteredData[0]?.accommodation?.superBuildUpArea || 0}
          />
        )}
        {activeLevelType === 'PARKING' && (
          <FormInput
            width={100}
            register={register}
            register_key={'electrified'}
            label={'Electrified'}
            type={'dropdown'}
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
          />
        )}
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};
