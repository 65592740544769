import React, { useEffect, useState } from 'react';
import DateFormat from '../../../super-admin/utils/DateFormat';

function EditTableData({
  data,
  width,
  identifier,
  onEdit,
  onDelete,
  onView,
  isDeleteEnable,
  isViewEnable,
  isEditEnable = true,
  onRegister,
  register_key,
  onType,
  editTableType,
  blockType
}) {
  const containerStyle = {
    width: `${Math.floor(width)}%`
  };

  // Using controlled state for the input field
  const [inputValue, setInputValue] = useState(data);

  useEffect(() => {
    if (editTableType === 'LevelEdit' && data !== inputValue) {
      setInputValue(data); // Set the input value to the data when editTableType is 'LevelEdit'
      console.warn('Data updated in useEffect:', data);
    }
  }, [data, editTableType]);

  if (data === '2024-04-25T00:00:00Z') {
    return <DateFormat dateString={data} />;
  }

  // Payment history status will be paid by default
  if (data === null || data === undefined) {
    return (
      <div
        className={`text-sm flex px-3 overflow-hidden text-stone-400 text-center justify-center items-center translate-x-[-25%]`}
        style={containerStyle}>
        {``}
      </div>
    );
  }

  if (
    [
      'unpaid',
      'Unpaid',
      'unapproved',
      'Unapproved',
      'inactive',
      'Inactive',
      'Low',
      false,
      'No',
      'no'
    ].includes(data)
  ) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="h-[100%] flex items-center pl-6 overflow-hidden text-sm">{data ? data : 'Inactive'}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#C2281B] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (['Medium'].includes(data)) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{data ? data : 'Inactive'}</span>

        <div
          className={`w-[5px] h-[100%] bg-orange-400 rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (
    ['paid', 'Paid', 'approved', 'Approved', 'active', 'Active', 'High', 'Yes', 'yes'].includes(
      data
    )
  ) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="h-[100%] flex items-center pl-6 overflow-hidden text-sm">
          {data ? data : 'Active'}
        </span>

        <div
          className={`w-[5px] h-[100%] bg-[#3F8D78] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (data === true) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{'Active'}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#3F8D78] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (['pending', 'Pending', 'invite-sent'].includes(data)) {
    return (
      <div className={`relative z-0`} style={containerStyle}>
        <span className="flex pl-6 overflow-hidden text-sm">{data}</span>

        <div
          className={`w-[5px] h-[100%] bg-[#E97B37] rounded-t-2xl rounded-r-[3rem] absolute left-[4px] top-[-2px]`}>
          &nbsp;
        </div>
      </div>
    );
  }

  if (data && data.includes('dropdown')) {
    try {
      // Extract and parse the JSON string
      const jsonString = data.split('dropdown-')[1];
      const correctedJsonString = jsonString.replace(/(\w+):/g, '"$1":').replace(/'/g, '"');
      const options = JSON.parse(correctedJsonString);

      return (
        <div className="flex px-3 overflow-hidden text-sm" style={containerStyle}>
          <select
            className="w-full p-2 border-[1px] border-stone-300 rounded"
            {...onRegister(register_key)}>
            {options?.map((item, index) => (
              <option value={item.value} key={index}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      );
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  // console.log(register_key, 'register_key');

  return (
    <>
      <div className={`flex px-3 overflow-hidden text-sm`} style={containerStyle}>
        {editTableType === 'LevelEdit' ? (
          <input
            type="text"
            value={inputValue} // Controlled input value
            className={`w-full p-2 border-[1px] border-stone-300 rounded`}
            {...onRegister(register_key)}
            onChange={(e) => {
              if (register_key.includes('Level Name')) {
                const enteredValue = e.target.value;

                if (blockType === "Independent") {
                  //set the entered value to the input value
                  console.log('enteredValue', enteredValue);
                  console.log('register_key new', register_key);
                  const index = register_key.split('-').pop();
                  // onType && onType(enteredValue, parseInt(index, 10));
                  setInputValue(enteredValue);
                  return;
                }
                console.log('blockType outside', blockType);

                // Ensure "Level" is always part of the value
                if (!enteredValue.startsWith('Level ') && blockType === "Group") {
                  console.log('blockType inside', blockType);
                  setInputValue(`Level `); 
                  return;
                }

                // Extract the numeric part (allow negative numbers)
                const numericPart = enteredValue.replace('Level ', '');

                // Allow only valid numbers (including negative numbers) after "Level "
                if (/^-?\d*$/.test(numericPart)) {
                  // Regex to allow positive/negative numbers
                  setInputValue(`Level ${numericPart}`);
                  const index = register_key.split('-').pop();
                  onType && onType(`Level ${numericPart}`, parseInt(index, 10)); // Pass value and index to onType
                } else if (blockType === "Group") {
                  setInputValue(`Level `); // Reset to "Level " if invalid input is detected
                }
              } else {
                const index = register_key.split('-').pop();
                onType && onType(e.target.value, parseInt(index, 10));
                setInputValue(e.target.value);
              }
            }}
            disabled={(() => {
              const index = register_key.split('-').pop();
              return (
                (register_key.includes('Level Name') && index !== '0')
              ); // Disable for non-first rows
            })()}
          />
        ) : (
          <input
            type="text"
            defaultValue={data} // Use controlled input state
            className={`w-full p-2 border-[1px] border-stone-300 rounded`}
            {...onRegister(register_key)}
            onChange={onType ? (e) => onType(e.target.value) : (e) => console.clear}
          />
        )}
      </div>
    </>
  );
}

export default EditTableData;
