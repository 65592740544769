import React from 'react';

const PrimaryTabbedComponent = ({ data, onChangePrimaryTab, activePrimary, width }) => {
  // const styles = { width: `${width}%` };
  const styles = width
    ? { width: `${width}%` }
    : {
        whiteSpace: 'nowrap',
        width: 'fit-content',
        padding: '0.5rem 1.65rem'
      };

  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div
        className={`w-full flex justify-start items-center px-8 py-6 border-b-[1px] border-t-[1px] border-b-gray-300 border-t-gray-300 text-gray-600`}>
        {data?.tabs?.map((item, index) => (
          <div
            className={`flex justify-center items-center py-2 px-4 ${item === activePrimary ? 'text-gray-100 bg-primary border-transparent' : 'border-gray-200'} ${index === 0 && 'rounded-l-md'} ${index === data?.tabs?.length - 1 && 'rounded-r-md'} border-[1px] cursor-pointer transition-all duration-300`}
            style={styles}
            onClick={() => onChangePrimaryTab(item)}
            key={index}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrimaryTabbedComponent;
