import React, { useState } from 'react';
import Button from '../ui/Button';
import FormInput from '../ui/FormInput';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { sendOtp, verifyOtp } from '../../services/api/implementation/impl';
import Cookies from 'js-cookie';

const LoginPhoneForm = () => {
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { mutate: handleSendOtp, isLoading: isSendingOtp } = useMutation({
    mutationFn: async (payload) => await sendOtp(payload),
    onSuccess: async (res) => {
      toast.success('OTP sent successfully');
      setOtp('');
      setOtpSent(true);
    },
    onError: (err) => {
      console.error('Error sending OTP', err);
      toast.error('Error sending OTP');
    }
  });

  const { mutate: handleVerifyOtp, isLoading: isVerifyingOtp } = useMutation({
    mutationFn: async (payload) => await verifyOtp(payload),
    onSuccess: async (res) => {
      const token = res.data.data.token;
      Cookies.set('token', token);
      toast.success('User successfully logged in');
      navigate('/desired/path'); // Change this to your desired path
    },
    onError: (err) => {
      console.error('Error verifying OTP', err);
      toast.error('Incorrect OTP');
    }
  });

  const handleSendOtpDummy = () => {
    if (phone === '7008574989') {
      toast.success('OTP sent successfully');
      setOtp('');
      setOtpSent(true);
    } else {
      toast.error('Invalid phone number');
    }
  };

  const handleVerifyOtpDummy = () => {
    if (otp === '4985') {
      const token = 'dummy_token'; // Dummy token
      Cookies.set('token', token);
      toast.success('User successfully logged in');
      navigate('/desired/path'); // Change this to your desired path
    } else {
      toast.error('Incorrect OTP');
    }
  };

  const onSubmitPhone = (data) => {
    const payload = { phone: `+91${data.phone}` };
    handleSendOtp(payload);
    // Uncomment the next line to use dummy logic
    // handleSendOtpDummy();
  };

  const onSubmitOtp = (data) => {
    const payload = { phone: `+91${phone}`, otp: data.otp };
    handleVerifyOtp(payload);
    // Uncomment the next line to use dummy logic
    // handleVerifyOtpDummy();
  };

  return (
    <form className="flex flex-col gap-6 w-full h-full justify-center relative">
      <span className="text-stone-800 text-3xl font-semibold mb-24 absolute top-0 left-0">
        Welcome back,
      </span>

      <div className="flex flex-col justify-between items-start gap-4">
        {!otpSent ? (
          <>
            <div className="flex items-center gap-2 relative w-full">
              <span className="p-1 px-2 absolute left-2 top-[52%] bg-gray-100 rounded-md text-sm text-gray-600">
                +91
              </span>
              <FormInput
                width={100}
                register={register}
                register_key={'phone'}
                label={'Phone Number'}
                type={'text'}
                isRequired={true}
                maxLength={10}
                onType={setPhone}
                CustomStyle={{ paddingLeft: '3.5rem' }}
              />
            </div>
            <Button
              type="submit"
              displayType="full-w-primary"
              width={100}
              onClick={handleSubmit(onSubmitPhone)}
            >
              Send OTP
            </Button>
          </>
        ) : (
          <>
            <FormInput
              width={100}
              register={register}
              register_key={'otp'}
              label={'OTP'}
              type={'text'}
              isRequired={true}
              value={otp}
              onType={setOtp}
            />
            <Button
              type="submit"
              displayType="full-w-primary"
              width={100}
              onClick={handleSubmit(onSubmitOtp)}
            >
              Verify OTP
            </Button>
            <span
              className="text-primary cursor-pointer"
              onClick={() => handleSendOtp({ phone: `+91${phone}` })}
            >
              Resend OTP
            </span>
          </>
        )}
      </div>
    </form>
  );
};

export default LoginPhoneForm;
