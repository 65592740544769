import React from 'react';

const EventsCard = ({ data }) => {
  // Function to format the date and time
  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return date.toLocaleString('en-US', {
      weekday: 'short', // e.g., Mon
      year: 'numeric',
      month: 'short', // e.g., Jan
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // 12-hour format
    });
  };

  // Function to calculate the meeting duration
  const calculateMeetingDuration = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);

    const diffInMs = end - start;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const remainingMinutes = diffInMinutes % 60;

    return `${diffInHours} hour(s) ${remainingMinutes} minute(s)`;
  };

  return (
    <div className="w-full p-4 rounded-md bg-gray-100 flex flex-col gap-2">
      <span className="text-gray-600 font-semibold text-lg">{data?.title}</span>

      <div className="flex justify-between items-center w-full">
        <div className="flex flex-col w-full">
          <div className="flex items-center space-x-1">
            <img src="/icons/clocks.svg" alt="clock_icon" className="h-[100%] w-[24px]" />
            <span className="text-gray-800">
              {formatDateTime(data?.start_time)} - {formatDateTime(data?.end_time)}
            </span>
          </div>

          {/* Display the duration of the meeting */}
          <div className="flex items-center space-x-1 mt-1">
            {/* <img src="/icons/duration.svg" alt="duration_icon" className="h-[100%] w-[24px]" /> */}
            <span className="text-gray-600">
              Duration: {calculateMeetingDuration(data?.start_time, data?.end_time)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsCard;
