import React, { useCallback, useEffect, useState } from 'react';
import FormInput from '../../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import Button from '../../../shared/components/ui/Button';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { multipleUploadFiles } from '../../features/files';
import { useDispatch, useSelector } from 'react-redux';

export const EditAccommodation = ({
  onClose,
  onEditIndependent,
  onEditParking,
  onEditGroup,
  id,
  accommodationData,
  isSubmitting
}) => {
  const dispatch = useDispatch();
  const projectId = useParams();

  // Get the existing accommodation from props by id
  const value = accommodationData?.filter((item) => item.id === parseInt(id));
  const accomodationType = value?.[0]?.accomodationType;

  // Retrieve measurement unit from cookie
  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit || rawMeasurementUnit === 'null' || rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;

  // set up react-hook-form with default values
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      // Common fields
      projectId: projectId.id,
      accomodationType: accomodationType,
      name: value?.[0]?.name || '',
      unitType: value?.[0]?.unitType || '',
      measurementUnit: value?.[0]?.measurementUnit || measurementType,

      // For Independent
      plotType: value?.[0]?.plotType || 'regular',
      width: value?.[0]?.width || '',
      length: value?.[0]?.length || '',
      eastLength: value?.[0]?.eastLength || '',
      westLength: value?.[0]?.westLength || '',
      northLength: value?.[0]?.northLength || '',
      southLength: value?.[0]?.southLength || '',
      plotArea: value?.[0]?.plotArea || '',
      buildUpArea: value?.[0]?.buildUpArea || '',
      carpetArea: value?.[0]?.carpetArea || '',

      // For Parking
      electrified:
        value?.[0]?.electrified === true ? 'Yes' : value?.[0]?.electrified === false ? 'No' : '',

      // For file uploads
      floorPlanLink: value?.[0]?.floorPlanLink || [],
      materialListLink: value?.[0]?.materialListLink || []
    }
  });

  // Watch the relevant fields
  const watchedPlotType = watch('plotType');
  const watchedWidth = watch('width');
  const watchedLength = watch('length');

  // For irregular
  const watchedEast = watch('eastLength');
  const watchedWest = watch('westLength');
  const watchedNorth = watch('northLength');
  const watchedSouth = watch('southLength');

  // Automatically calculate plot area if plotType = "regular"
  useEffect(() => {
    if (accomodationType === 'INDEPENDENT') {
      if (watchedPlotType === 'regular') {
        const widthNum = parseFloat(watchedWidth);
        const lengthNum = parseFloat(watchedLength);
        if (!isNaN(widthNum) && !isNaN(lengthNum)) {
          const calculatedPlotArea = (widthNum * lengthNum).toFixed(2);
          setValue('plotArea', calculatedPlotArea);
        } else {
          setValue('plotArea', '');
        }
      }
    }
  }, [accomodationType, watchedPlotType, watchedWidth, watchedLength, setValue]);

  // Reset or keep plot area on plotType toggle
  useEffect(() => {
    if (watchedPlotType === 'regular') {
      // Clear the irregular fields
      setValue('eastLength', '');
      setValue('westLength', '');
      setValue('northLength', '');
      setValue('southLength', '');
    } else {
      // Clear the regular fields
      setValue('width', '');
      setValue('length', '');
      // setValue('plotArea', ''); // If you want the user to re-enter plot area for irregular
    }
  }, [watchedPlotType, setValue]);

  // For building up area
  const handleBuildUpAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('buildUpArea', cleanedValue);
    },
    [setValue]
  );

  // Access your Redux store for multiple files
  const MultipleFiles = useSelector((state) => state.file.multifiles);

  // --- PREVENT INFINITE LOOP: only dispatch if the existing Redux data differs ---
  useEffect(() => {
    if (value && value[0]) {
      const current = value[0];

      // 1) Check floorPlanLink
      if (current.floorPlanLink && Array.isArray(current.floorPlanLink)) {
        // Transform current floorPlanLink data
        const currentFloorArray = current.floorPlanLink.map((url) => ({
          name: typeof url === 'string' ? url : url?.name,
          url: typeof url === 'string' ? url : url?.url
        }));

        // Compare to what's in Redux
        const storeFloorArray = MultipleFiles?.floorPlanLink || [];

        // Simple equality check: same length, and each item is identical
        const isSameFloorPlan =
          currentFloorArray.length === storeFloorArray.length &&
          currentFloorArray.every((item, idx) => {
            const storeItem = storeFloorArray[idx];
            return item.name === storeItem.name && item.url === storeItem.url;
          });

        // Only dispatch if different
        if (!isSameFloorPlan) {
          dispatch(
            multipleUploadFiles({
              fileType: 'floorPlanLink',
              urls: currentFloorArray
            })
          );
        }
      }

      // 2) Check materialListLink
      if (current.materialListLink && Array.isArray(current.materialListLink)) {
        // Transform current materialListLink data
        const currentMaterialArray = current.materialListLink.map((url) => ({
          name: typeof url === 'string' ? url : url?.name,
          url: typeof url === 'string' ? url : url?.url
        }));

        // Compare to what's in Redux
        const storeMaterialArray = MultipleFiles?.materialListLink || [];

        // Simple equality check: same length, and each item is identical
        const isSameMaterialList =
          currentMaterialArray.length === storeMaterialArray.length &&
          currentMaterialArray.every((item, idx) => {
            const storeItem = storeMaterialArray[idx];
            return item.name === storeItem.name && item.url === storeItem.url;
          });

        // Only dispatch if different
        if (!isSameMaterialList) {
          dispatch(
            multipleUploadFiles({
              fileType: 'materialListLink',
              urls: currentMaterialArray
            })
          );
        }
      }
    }
  }, [value, dispatch, MultipleFiles]);

  // Final submit
  const handleOnSubmitEditAccommodation = (formData) => {
    // Collect updated file arrays from Redux
    const floorPlanLink = MultipleFiles?.floorPlanLink || [];
    const materialListLink = MultipleFiles?.materialListLink || [];

    // We remove fields from the payload that are NOT relevant for the current type
    if (accomodationType === 'INDEPENDENT') {
      const { electrified, ...rest } = formData;
      const transformedData = {
        ...rest,
        floorPlanLink,
        materialListLink
      };
      onEditIndependent(transformedData);
    } else if (accomodationType === 'GROUP') {
      const { electrified, ...rest } = formData;
      const transformedData = {
        ...rest,
        floorPlanLink,
        materialListLink
      };
      onEditGroup(transformedData);
    } else if (accomodationType === 'PARKING') {
      const transformedData = {
        ...formData,
        floorPlanLink,
        materialListLink
      };
      onEditParking(transformedData);
    }
  };

  // For plot area (irregular) manual typing
  const handlePlotAreaChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 8);
      setValue('plotArea', cleanedValue);
    },
    [setValue]
  );

  return (
    <div>
      <div>
        <div className="flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
            Accommodation Details
          </span>
        </div>
        <div className="flex flex-col gap-6 pt-6">
          {/* Common fields row */}
          <div className="flex gap-10 justify-between items-center w-full">
            {/* projectId as hidden field */}
            <FormInput
              width={50}
              label="Project ID"
              register={register}
              isRequired={true}
              register_key="projectId"
              type="hidden"
            />

            {/* Accommodation Type (read-only) */}
            <FormInput
              width={48}
              label="Accommodation Type"
              register={register}
              isRequired={true}
              register_key="accomodationType"
              type="dropdown"
              valuePre={accomodationType}
              readOnly
            />

            {/* Accommodation Name */}
            <FormInput
              width={48}
              label="Accommodation Name"
              register={register}
              isRequired={true}
              register_key="name"
              defaultValuePre={value?.[0]?.name}
              type="text"
            />
          </div>

          {/* RENDER FIELDS DEPENDING ON ACCOMMODATION TYPE */}
          {accomodationType === 'INDEPENDENT' && (
            <div className="flex flex-col gap-6">
              {/* UNIT TYPE & PLOT TYPE */}
              <div className="flex justify-between items-center w-full">
                {/* Unit Type */}
                <FormInput
                  width={48}
                  label="Unit Type"
                  register_key="unitType"
                  register={register}
                  isRequired={true}
                  type="dropdown"
                  defaultValuePre={value?.[0]?.unitType || 'Residential'}
                  options={[
                    { value: '', label: 'Select Unit Type', disabled: true },
                    { value: 'Residential', label: 'Residential' },
                    { value: 'Commercial', label: 'Commercial' }
                  ]}
                />

                {/* Plot Type (regular / irregular) */}
                <FormInput
                  width={48}
                  label="Plot Type"
                  register_key="plotType"
                  register={register}
                  isRequired={true}
                  type="dropdown"
                  options={[
                    { value: 'regular', label: 'Regular' },
                    { value: 'irregular', label: 'Irregular' }
                  ]}
                />
              </div>

              {/* REGULAR vs IRREGULAR */}
              {watchedPlotType === 'regular' ? (
                <div className="flex justify-between items-center w-full">
                  <FormInput
                    key="width"
                    width={32}
                    label={`Width (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register_key="width"
                    register={register}
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.width}
                    onType={(val) => setValue('width', val)}
                    validation={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Width must be a number'
                      }
                    }}
                  />

                  <FormInput
                    key="length"
                    width={32}
                    label={`Depth (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register_key="length"
                    register={register}
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.length}
                    onType={(val) => setValue('length', val)}
                    validation={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Depth must be a number'
                      }
                    }}
                  />

                  <FormInput
                    key="measurementUnit"
                    width={32}
                    label="Measurement Unit"
                    register_key="measurementUnit"
                    register={register}
                    isRequired={true}
                    type="text"
                    valuePre={measurementType}
                    readOnly={true}
                  />
                </div>
              ) : (
                <div className="flex justify-between gap-3 flex-wrap items-center w-full">
                  <FormInput
                    key="eastLength"
                    width={18}
                    label={`East (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register_key="eastLength"
                    register={register}
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.eastLength}
                    validation={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'East must be a number'
                      }
                    }}
                  />
                  <FormInput
                    key="westLength"
                    width={18}
                    label={`West (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register_key="westLength"
                    register={register}
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.westLength}
                    validation={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'West must be a number'
                      }
                    }}
                  />
                  <FormInput
                    key="northLength"
                    width={18}
                    label={`North (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register_key="northLength"
                    register={register}
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.northLength}
                    validation={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'North must be a number'
                      }
                    }}
                  />
                  <FormInput
                    key="southLength"
                    width={18}
                    label={`South (${measurementType === 'sq/mt' ? 'mt' : 'ft'})`}
                    register_key="southLength"
                    register={register}
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.southLength}
                    validation={{
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'South must be a number'
                      }
                    }}
                  />
                  <FormInput
                    key="measurementUnitirregular"
                    width={16}
                    label="Measurement Unit"
                    register_key="measurementUnit"
                    register={register}
                    isRequired={true}
                    type="text"
                    valuePre={measurementType}
                    readOnly={true}
                  />
                </div>
              )}

              {/* PLOT AREA, BUILDUP AREA, CARPET AREA */}
              <div className="flex justify-between items-center w-full">
                {watchedPlotType === 'regular' ? (
                  <FormInput
                    width={32}
                    label={`Plot Area (${measurementType ? measurementType : 'sq/ft'})`}
                    register={register}
                    register_key="plotArea"
                    isRequired={true}
                    type="text"
                    valuePre={value?.[0]?.plotArea}
                    readOnly
                    validation={{
                      required: 'Plot Area is required',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Plot Area must be a number'
                      }
                    }}
                    errors={errors}
                  />
                ) : (
                  <FormInput
                    width={32}
                    label={`Plot Area (${measurementType ? measurementType : 'sq/ft'})`}
                    register={register}
                    register_key="plotArea"
                    isRequired={true}
                    type="text"
                    defaultValuePre={value?.[0]?.plotArea}
                    onType={handlePlotAreaChange}
                    placeholder="Enter plot area"
                    validation={{
                      required: 'Plot Area is required for Irregular plots',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Plot Area must be a number'
                      }
                    }}
                    errors={errors}
                  />
                )}

                <FormInput
                  width={32}
                  label={`Build Up Area (${measurementType})`}
                  register={register}
                  isRequired={true}
                  register_key="buildUpArea"
                  type="text"
                  defaultValuePre={value?.[0]?.buildUpArea}
                  onType={handleBuildUpAreaChange}
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Build Up Area must be a number'
                    }
                  }}
                />
                <FormInput
                  width={32}
                  label={`Carpet Area (${measurementType})`}
                  register={register}
                  isRequired={true}
                  register_key="carpetArea"
                  type="text"
                  defaultValuePre={value?.[0]?.carpetArea}
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Carpet Area must be a number'
                    }
                  }}
                />
              </div>

              {/* File Uploads */}
              <div className="flex justify-start space-x-4 items-center w-full">
                <FormInput
                  width={32}
                  register={register}
                  isRequired={false}
                  type="file-btn"
                  register_key="floorPlanLink"
                  label="Add Floor Plan"
                  prefilledFiles={MultipleFiles['floorPlanLink'] || []}
                  defaultValuePre={value?.[0]?.floorPlanLink}
                />
                       {/* <FormInput
                  width={24}
                  register={register}
                  register_key={'project_images'}
                  label={'Project Images'}
                  type={'file-secondary'}
                  prefilledFiles={MultiFiles['project_images'] || []}
                  accept={'.pdf, .png, .jpg, .jpeg, .doc '}
                /> */}
                {/* <FormInput
                  width={32}
                  register={register}
                  isRequired={false}
                  type="file-btn"
                  register_key="materialListLink"
                  label="Material List"
                  defaultValuePre={value?.[0]?.materialListLink}
                /> */}
              </div>

              {/* Submit Buttons */}
              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="reset" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit(handleOnSubmitEditAccommodation)} type="submit">
                  Save
                </Button>
              </div>
            </div>
          )}

          {/* GROUP ACCOMMODATION */}
          {accomodationType === 'GROUP' && (
            <div className="flex flex-col gap-6">
              <div className="flex justify-between items-center w-full">
                <FormInput
                  width={48}
                  label={`Build Up Area (${measurementType})`}
                  register={register}
                  isRequired={true}
                  register_key="buildUpArea"
                  defaultValuePre={value?.[0]?.buildUpArea}
                  type="text"
                  onType={handleBuildUpAreaChange}
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Build Up Area must be a number'
                    }
                  }}
                />
                <FormInput
                  width={48}
                  label={`Carpet Area (${measurementType})`}
                  register={register}
                  isRequired={true}
                  register_key="carpetArea"
                  defaultValuePre={value?.[0]?.carpetArea}
                  type="text"
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Carpet Area must be a number'
                    }
                  }}
                />
              </div>
              <div className="flex justify-between items-center w-full">
                <FormInput
                  width={48}
                  label="Unit Type"
                  register_key="unitType"
                  register={register}
                  isRequired={true}
                  type="dropdown"
                  defaultValuePre={value?.[0]?.unitType || 'Residential'}
                  options={[
                    { value: '', label: 'Select type', disabled: true },
                    { value: 'Residential', label: 'Residential' },
                    { value: 'Commercial', label: 'Commercial' }
                  ]}
                />
                 <FormInput
                  width={48}
                  register={register}
                  isRequired={false}
                  type="file-btn"
                  register_key="floorPlanLink"
                  label="Add Floor Plan"
                  defaultValuePre={value?.[0]?.floorPlanLink}
                />
              </div>
              <div className="flex justify-start space-x-4 items-center w-full">
               
                {/* <FormInput
                  width={32}
                  register={register}
                  isRequired={false}
                  type="file-btn"
                  register_key="materialListLink"
                  label="Material List"
                  defaultValuePre={value?.[0]?.materialListLink}
                /> */}
              </div>

              {/* Submit Buttons */}
              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="reset" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit(handleOnSubmitEditAccommodation)} type="submit">
                  Save
                </Button>
              </div>
            </div>
          )}

          {/* PARKING ACCOMMODATION */}
          {accomodationType === 'PARKING' && (
            <div className="flex flex-col gap-6">
              <div className="flex justify-between items-center w-full space-x-8">
                <FormInput
                  width={46}
                  label="Unit Type"
                  register={register}
                  isRequired={true}
                  register_key="unitType"
                  type="dropdown"
                  defaultValuePre={value?.[0]?.unitType}
                  options={[
                    { value: '', label: 'Select type', disabled: true },
                    { value: '2-wheeler', label: '2-wheeler' },
                    { value: '4-wheeler', label: '4-wheeler' }
                  ]}
                />
                <FormInput
                  width={46}
                  label={`Build Up Area (${measurementType})`}
                  register={register}
                  isRequired={true}
                  register_key="buildUpArea"
                  defaultValuePre={value?.[0]?.buildUpArea}
                  type="text"
                  onType={handleBuildUpAreaChange}
                  validation={{
                    pattern: {
                      value: /^[0-9]*\.?[0-9]+$/,
                      message: 'Build Up Area must be a number'
                    }
                  }}
                />
              </div>
              <div className="flex justify-start items-center">
                <div className="text-md flex flex-col justify-start gap-2 w-[46%]">
                  <span className="text-gray-600">Electrified</span>
                  <div className="w-fit flex justify-start space-x-6">
                    <div className="flex justify-between space-x-2">
                      <input
                        type="radio"
                        name="electrified"
                        className="border-stone-300 rounded"
                        value="Yes"
                        {...register('electrified')}
                        defaultChecked={value?.[0]?.electrified === true}
                      />
                      <label>Yes</label>
                    </div>

                    <div className="flex justify-between space-x-2">
                      <input
                        type="radio"
                        name="electrified"
                        className="border-stone-300 rounded"
                        value="No"
                        {...register('electrified')}
                        defaultChecked={value?.[0]?.electrified === false}
                      />
                      <label>No</label>
                    </div>
                  </div>
                  {errors.electrified && (
                    <p className="text-red-500 text-xs mt-1">{errors.electrified.message}</p>
                  )}
                </div>
              </div>

              {/* Submit Buttons */}
              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="reset" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={handleSubmit(handleOnSubmitEditAccommodation)}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
