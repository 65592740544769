import React from 'react';
import Button from '../../../shared/components/ui/Button';
import Table from '../../../shared/components/layout/Table';
import previewAcc from '../../static/data/RateAccommodationId.json';
import { transformData } from '../../../super-admin/utils/transformData';

export const PreviewRateAccommodationId = ({
  previewData,
  handlePreviewProceed,
  onEdit,
  isSubmitting
}) => {
  const PreviewRateAccFormat = [
    'project_name', //project name
    'accommodation_name', //accommodation name
    // "build_up_area",
    // "super_build_up_area",
    // 'loading_factor',

    'price',

    // "plc_min",
    // "plc_max",
    // "price_min",
    // "price_max",
    'id'
  ];
  const previewAccData = previewData?.map((el) => transformData(el, PreviewRateAccFormat));
  return (
    <div>
      <div className="flex flex-col justify-between items-start gap-4  ">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Preview Unit Rate Details
        </span>
      </div>
      <div className="mt-4 h-[24rem] overflow-scroll">
        <Table
          headers={['Project', 'Acc. Name', 'Rate']}
          data={previewAccData}
          bigHeaders
          identifier={'id'}
        />
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-24">
        <Button type="reset" displayType="secondary" onClick={onEdit}>
          Edit
        </Button>
        {/* <Button type="reset" displayType="secondary">
                    Add More
                </Button> */}
        <Button type="submit" onClick={handlePreviewProceed} disabled={isSubmitting}>
          Procced
        </Button>
      </div>
    </div>
  );
};
