import React from 'react';
import Button from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormInput from '../ui/FormInput';

const VerifyEmailForm = ({ email, onSubmit }) => {
  /* 
    Hook form
  */
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const navigate = useNavigate();

  return (
    <form className={`flex flex-col gap-6 w-full`}>
      <div className="flex flex-col justify-between items-start gap-4">
        <FormInput
          width={100}
          register={register}
          register_key={'email'}
          label={'Email'}
          type={'email'}
          valuePre={email}
          isRequired={true}
        />

        <FormInput
          width={100}
          register={register}
          register_key={'verificationCode'}
          label={'Verification code'}
          type={'text'}
          isRequired={true}
        />
      </div>

      <div className="w-full flex flex-col justify-center items-center gap-4">
        <Button
          type="submit"
          displayType="full-w-primary"
          width={100}
          onClick={handleSubmit(onSubmit)}
        >
          Verify Email
        </Button>
      </div>
    </form>
  );
};

export default VerifyEmailForm;
