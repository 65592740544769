import React, { useCallback, useEffect, useState } from 'react';

import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import PrimaryTabbedComponent from '../../shared/components/layout/PrimaryTabbedComponent';
import SalesTargets from './SalesTargets';
import SalesReceivables from './SalesReceivables';
import SalesRevenue from './SalesRevenue';
import SalesInventoryReports from './SalesInventoryReport';

const SalesReports = () => {
  /* 
    Local state 
  */
  const [primaryTab, setPrimaryTab] = useState('Targets');

  /* 
    Meta data
  */
  const tabbedComponentMetaData = {
    tabs: ['Targets', 'Receivables', 'Revenue Report', 'Inventory Report']
  };

  useEffect(() => {
    const savedPrimaryTab = localStorage.getItem('primaryTab');
    if (savedPrimaryTab) setPrimaryTab(savedPrimaryTab);
  }, []);

  useEffect(() => {
    const clearStorage = () => {
      localStorage.removeItem('primaryTab'); 
    };

    // Clear on unmount
    return () => {
      clearStorage();
    };
  }, []);

  /* 
    Event handlers
  */
  const handleChangePrimaryTab = useCallback((tabName) => {
    setPrimaryTab(tabName);
    localStorage.setItem('primaryTab', tabName); // Save to localStorage
  }, []);

  /* 
    JSX 
  */
  return (
    <>
      <Layout>
        {/* Header */}
        <div className="z-50 ">
          <Header data={{ name: 'Sales Reports' }} backArrow={true} />
        </div>
        {/* Primary Tabbed content */}
        <div className="w-full z-10 bg-white">
          <PrimaryTabbedComponent
            // width={10}
            data={tabbedComponentMetaData}
            activePrimary={primaryTab}
            onChangePrimaryTab={(tab) => handleChangePrimaryTab(tab)}
          />
        </div>
        {/* Targets Content */}
        {primaryTab === 'Targets' && (
          <div className="w-full h-full">
            <SalesTargets key={"salesTarget"}/>
          </div>
        )}
        {/* Receivables Content */}
        {primaryTab === 'Receivables' && (
          <div className="w-full h-full">
            <SalesReceivables key={"salesReceivables"} />
          </div>
        )}
        {/* Revenue Report Content */}
        {primaryTab === 'Revenue Report' && (
          <div className="w-full h-full">
            <SalesRevenue key={'salesRevenue'} />
          </div>
        )}
        {/* Inventory Report Content */}
        {primaryTab === 'Inventory Report' && (
          <div className="w-full h-full">
            <SalesInventoryReports key={'salesInventoryReports'} />
          </div>
        )}
      </Layout>
    </>
  );
};

export default SalesReports;
