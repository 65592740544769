import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { updateApplication } from '../../services/api/implementation/impl';

const createValidationSchema = (data) => {
  const schemaShape = {};

  if (data[0]?.colonizer_statement === null) {
    schemaShape.colonizer_statement = yup.string().required('Colonizer statement is required');
  }

  if (data[0]?.commencement_certificate_statement === null) {
    schemaShape.commencement_certificate_statement = yup
      .string()
      .required('Commencement certificate statement is required');
  }

  if (data[0]?.extra_charges.length === 0) {
    schemaShape.extra_charges = yup
      .array()
      .of(
        yup.object().shape({
          extra_charge_name: yup.string().required('Charge name is required'),
          price: yup
            .number()
            .required('Price is required')
            .positive('Price must be a positive number'),
          remarks: yup.string()
        })
      )
      .min(1, 'At least one extra charge is required');
  }

  return yup.object().shape(schemaShape);
};

export const AllotmentMissingForm = ({
  onClose,
  onSubmit,
  selectedApplicationId,
  onSuccess,
  data
}) => {
  const [extraCharges, setExtraCharges] = useState([
    { extra_charge_name: '', price: '', remarks: '' }
  ]);
  console.log({ selectedApplicationId, data });

  const validationSchema = createValidationSchema(data);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      colonizer_statement: '',
      commencement_certificate_statement: '',
      extra_charges: [{ extra_charge_name: '', price: '', remarks: '' }]
    }
  });

  const addExtraCharge = () => {
    setExtraCharges([...extraCharges, { extra_charge_name: '', price: '', remarks: '' }]);
  };

  const removeExtraCharge = (index) => {
    if (extraCharges.length > 1) {
      const newCharges = extraCharges.filter((_, i) => i !== index);
      setExtraCharges(newCharges);
    }
  };

  const { mutate: updateApplicationHandler } = useMutation({
    mutationFn: (formData) => updateApplication(selectedApplicationId, formData),
    onSuccess: () => {
      toast.success('Application updated successfully');
      onSuccess();
      onClose();
    },
    onError: () => {
      toast.error('Error updating application');
    }
  });

  const transformPayload = (formData) => {
    const transformedData = {};

    if (data[0]?.colonizer_statement === null) {
      transformedData.colonizer_statement = formData.colonizer_statement;
    }

    if (data[0]?.commencement_certificate_statement === null) {
      transformedData.commencement_certificate_statement =
        formData.commencement_certificate_statement;
    }

    if (data[0]?.extra_charges.length === 0) {
      transformedData.extra_charges = formData.extra_charges?.map((charge) => ({
        name: charge.extra_charge_name,
        charge: charge.price.toString(),
        remarks: charge.remarks
      }));
    }

    return transformedData;
  };

  const onSubmitForm = (formData) => {
    console.log('Form Data:', formData);
    const transformedData = transformPayload(formData);
    console.log('Transformed Data:', transformedData);
    updateApplicationHandler(transformedData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
      <div>
        <div className="flex justify-between items-center w-full">
          {data[0]?.colonizer_statement === null && (
            <FormInput
              label={'Colonizer Statement'}
              isRequired
              width={48}
              register_key={'colonizer_statement'}
              register={register}
              type={'textarea'}
              errors={errors.colonizer_statement}
            />
          )}
          {data[0]?.commencement_certificate_statement === null && (
            <FormInput
              label={'Commencement Certificate Statement'}
              isRequired
              width={48}
              register_key={'commencement_certificate_statement'}
              register={register}
              type={'textarea'}
              errors={errors.commencement_certificate_statement}
            />
          )}
        </div>
        {data[0]?.extra_charges?.length == 0 && (
          <div>
            <p className="text-gray-600 border-b-2 w-full py-3">Extra Charges</p>
            {extraCharges?.map((_, index) => (
              <div key={index} className="flex gap-2 mt-5 items-center">
                <FormInput
                  label={'Charge Name'}
                  isRequired
                  width={30}
                  register_key={`extra_charges[${index}].extra_charge_name`}
                  register={register}
                  type={'text'}
                  errors={errors?.extra_charges?.[index]?.extra_charge_name}
                />
                <FormInput
                  label={'Price'}
                  isRequired
                  width={30}
                  register_key={`extra_charges[${index}].price`}
                  register={register}
                  type={'number'}
                  errors={errors?.extra_charges?.[index]?.price}
                />
                <FormInput
                  label={'Remarks'}
                  isRequired
                  width={30}
                  register_key={`extra_charges[${index}].remarks`}
                  register={register}
                  type={'text'}
                  errors={errors?.extra_charges?.[index]?.remarks}
                />
                {extraCharges.length > 1 && (
                  <button type="button" className="pt-5" onClick={() => removeExtraCharge(index)}>
                    ×
                  </button>
                )}
              </div>
            ))}
            <div className="flex justify-end mt-5">
              <button type="button" onClick={addExtraCharge}>
                Add More
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="button" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
};
