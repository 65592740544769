import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '../../shared/components/ui/Button';
import AgreementForSale from '../../shared/assets/png/Agreement-for-Sale.png';
import Modal from '../../shared/components/ui/Modal';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAgreementLetter, getallotmentLetter } from '../services/api/implementation/impl';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';
import { AllotmentMissingForm } from '../components/forms/AllotmentMissingForm';
import { set } from 'react-hook-form';

import { getApplicationByDealId } from '../services/api/implementation/impl';
import { AgreementMissingForm } from '../components/forms/AgreementMissingForm';

const AgreementFormHandler = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const commentsRef = useRef(null);
  const [newComment, setNewComment] = useState('');
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false);

  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [noOfAppartments, setNoOfAppartments] = useState(null);

  const { id } = useParams();
  let location = useLocation();
  let pathname = location.pathname;
  let parts = pathname.split('/');
  // console.log(parts[2]);

  console.log(id);

  // api call for getApplicationByDealId
  const { data: applicationData, refetch: refetchApplicationData } = useQuery({
    queryKey: ['getApplicationByDealId', id],
    queryFn: () => getApplicationByDealId(id),
    enabled: !!id
  });

  console.log('applicationdata', applicationData);

  // console.log("applicationdata", applicationData[0]?.id);

  useEffect(() => {
    console.log({ applicationData })
    if (applicationData) {
      console.log(applicationData);
      setSelectedApplicationId(applicationData[0]?.id);
      setNoOfAppartments(applicationData[0]?.a_no_of_apartments);
    }
  }, [applicationData, id]);

  console.log({ applicationData });

  const addComment = () => {
    const newDiv = document.createElement('div');
    newDiv.className = 'border p-4 mb-2';
    newDiv.innerHTML = `
      <div class="comment-content">${newComment}</div>
      <button class="mt-2 px-4 py-2 bg-red-500 text-white rounded" onclick="this.parentElement.remove()">Delete Comment</button>
    `;
    commentsRef.current.appendChild(newDiv);
    setNewComment('');
  };

  const { mutate: handleGetAgreementForm } = useMutation({
    mutationFn: async (id) => {
      return await getAgreementLetter(id); // Fetch the PDF or binary content
    },
    onSuccess: (data) => {
      const blob = new Blob([data], { type: 'application/pdf' }); // Adjust the type if it's not a PDF
      const url = window.URL.createObjectURL(blob);

      if (!isPreview) {
        // Download as file
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Agreement_Form${id}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success('Agreement Form Downloaded');
      } else {
        // Show in modal for preview
        setHtmlContent(url + '#toolbar=0'); // Use this URL in the iframe
        setIsPreviewEnabled(true);
      }
    },
    onError: () => {
      toast.error('Error in downloading agreement form');
    }
  });

  const handlePreview = () => {
    setIsPreview(true);
    if (!noOfAppartments || noOfAppartments === '') {
      toast.error('Please fill all the missing fields');
      openMissingFieldModal();
      return;
    }
    handleGetAgreementForm(id); // Only pass id, handle preview via state
  };

  const handleDownload = () => {
    setIsPreview(false);
    if (!noOfAppartments || noOfAppartments === '') {
      toast.error('Please fill all the missing fields');
      openMissingFieldModal();
      return;
    }
    handleGetAgreementForm(id); // Call without setting isPreview to trigger download
  };

  const openMissingFieldModal = () => {
    console.log('Open Missing Field Modal');
    setIsMissingFieldModalOpen(true);
  };

  const missinModalClose = () => {
    setIsMissingFieldModalOpen(false);
    setIsPreview(false);
  };

  return (
    <Layout>
      {isPreviewEnabled && (
        <Modal
          width={40}
          name={'Agreement Form Preview'}
          onClose={() => setIsPreviewEnabled(false)}
        >
          <div className="h-[500px]">
            <iframe src={htmlContent} width="100%" height="100%" style={{ border: 'none' }} />{' '}
          </div>
        </Modal>
      )}

      {isMissingFieldModalOpen && (
        <Modal width={68} name={'Allotment Letter'} onClose={missinModalClose}>
          <AgreementMissingForm
            onSubmit={() => console.log('submitted')}
            onClose={missinModalClose}
            selectedApplicationId={selectedApplicationId}
            onSuccess={() => refetchApplicationData()}
          />
        </Modal>
      )}
      <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
        <Header data={{ name: `Company > ${parts[2]} Letter` }} backArrow={true} />
      </div>

      <div className="w-full flex flex-col justify-between items-center gap-4 mt-[4rem] px-5">
        <div className="flex justify-end items-end w-full mt-4 px-4 py-2 gap-5">
          {!noOfAppartments && (
            <Button
              className="border px-4 py-2 rounded-lg bg-[#2a575d] text-white"
              onClick={openMissingFieldModal}
            >
              Add Missing Fields
            </Button>
          )}

          <Button
            className="border px-4 py-2 rounded-lg bg-[#2a575d] text-white"
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>

        <div className="h-[400px] relative w-full border-2 flex items-center justify-center bg-cover bg-center">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${AgreementForSale})` }}
          ></div>
          <div className="absolute inset-0 backdrop-blur-sm bg-white/30"></div>
          <div className="relative">
            <Button
              className="border px-4 py-2 rounded-lg bg-[#2a575d] text-white"
              onClick={handlePreview}
            >
              Preview
            </Button>{' '}
          </div>
        </div>

        <div className="w-full p-8 bg-white">
          <h2 className="text-xl font-bold mb-4">Comments</h2>
          <div className="flex flex-col gap-4">
            <ReactQuill value={newComment} onChange={setNewComment} />
            <div>
              <Button onClick={addComment}>Add Comment</Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AgreementFormHandler;
