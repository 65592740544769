import Cookies from 'js-cookie';

const getToken = async () => {
  let authToken = Cookies.get('token');
  if (!authToken) {
    // Logic to fetch and set token if not available
    // This could involve making a request to get a new token
    authToken = Cookies.get('token'); // Implement this function based on your auth flow
    Cookies.set('token', authToken);
  }
  return authToken;
};

export const getHeaders = async () => {
  const token = await getToken();
  return {
    headers: {
      authorization: `${token}` // Include the cookie in the request headers
    }
  };
};
