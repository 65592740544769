import React, { useState, useEffect, useCallback } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import CreatableSelect from 'react-select/creatable';

import {
  createMaterial,
  getBrandsByCategory,
  addBrand,
  addCategory,
  getAllCategories,
  updateMaterial
} from '../../services/api/implementation/impl';

// ================== VALIDATION SCHEMAS ==================
const materialSchema = yup.object().shape({
  categoryId: yup
    .number()
    .required('Category is required')
    .typeError('Category is required'),
  materials: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('Material name is required')
        .max(50, 'Material name should be at most 50 characters'),
      units: yup
        .array()
        .of(
          yup
            .string()
            .required('Unit is required')
            .max(20, 'Unit should be at most 20 characters')
        )
        .min(1, 'At least one unit is required')
    })
  )
});

const categorySchema = yup.object().shape({
  categoryName: yup
    .string()
    .required('Category name is required')
    .max(50, 'Category name should be at most 50 characters')
});

const brandSchema = yup.object().shape({
  categoryId: yup
    .number()
    .required('Category is required')
    .typeError('Category is required'),
  brandNames: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('Brand name is required')
        .max(50, 'Brand name should be at most 50 characters')
    })
  )
});

export const AddMaterials = ({ onClose, onSubmit, onSuccess, id }) => {
  const [activeTab, setActiveTab] = useState('material');
  const [availableBrands, setAvailableBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isEditMode, setIsEditMode] = useState(!!id); // Detect edit mode based on `id`
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ============ QUERY CLIENT ============
  const queryClient = useQueryClient();

  // ============ SCHEMA RESOLVER BY TAB ============
  const schemaResolver = () => {
    switch (activeTab) {
      case 'material':
        return yupResolver(materialSchema);
      case 'category':
        return yupResolver(categorySchema);
      case 'brand':
        return yupResolver(brandSchema);
      default:
        return yupResolver(materialSchema);
    }
  };

  // ============ REACT-HOOK-FORM SETUP ============
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    setValue
  } = useForm({
    resolver: schemaResolver(),
    defaultValues: {
      categoryId: '',
      materials: [{ name: '', units: [], id: '' }],
      brandNames: [{ name: '' }]
    }
  });

  // Field Arrays
  const {
    fields: materialFields,
    append: appendMaterial,
    remove: removeMaterial
  } = useFieldArray({
    control,
    name: 'materials'
  });

  const {
    fields: brandFields,
    append: appendBrand,
    remove: removeBrand
  } = useFieldArray({
    control,
    name: 'brandNames'
  });

  // ============ WATCHERS ============
  const selectedCategory = watch('categoryId');

  // ============ QUERIES ============
  // 1) Fetch all categories (includes items & brands data)
  const {
    data: allCategories,
    refetch: refetchCategories,
    isLoading
  } = useQuery({
    queryKey: ['categories'],
    queryFn: getAllCategories
  });

  // 2) Fetch brands for the currently selected category
  const { data: brands, refetch: refetchBrands } = useQuery({
    queryKey: ['brands', selectedCategory],
    queryFn: () => getBrandsByCategory(selectedCategory),
    enabled: !!selectedCategory
  });

  // ============ USE EFFECTS ============

  /**
   * When categories are loaded, store them in local state.
   */
  useEffect(() => {
    if (!isLoading && allCategories?.data) {
      setCategories(allCategories.data);
    }
  }, [isLoading, allCategories]);

  /**
   * If in edit mode and categories are available, set the categoryId once.
   * We only do this after the categories have been fully fetched.
   */
  useEffect(() => {
    if (isEditMode && !isLoading && categories.length > 0 && id) {
      const numericId = parseInt(id);
      // Find the matching category
      const category = categories.find((cat) => parseInt(cat.id) === numericId);
      if (category) {
        // Set the category in the form
        setValue('categoryId', parseInt(category.id));
        // For debugging
        console.log('Editing: auto-select category =>', {
          catId: category.id,
          catName: category.name
        });
      }
    }
  }, [isEditMode, id, isLoading, categories, setValue]);

  /**
   * Whenever `selectedCategory` changes (auto-set or user-changed),
   * find that category from the full list, and update the form's "materials" + "brands."
   */
  useEffect(() => {
    if (selectedCategory && categories.length > 0) {
      const foundCat = categories.find(
        (cat) => parseInt(cat.id) === parseInt(selectedCategory)
      );
      if (foundCat) {
        // Prepopulate the form with items => each item has "name" and "units"
        setValue(
          'materials',
          foundCat.items
            ? foundCat.items.map((item) => ({
                name: item.name,
                units: item.units || [],
                id: item.id
              }))
            : []
        );
        // Also update available brands
        setAvailableBrands(foundCat.brands || []);
      } else {
        // If not found, reset
        setValue('materials', []);
        setAvailableBrands([]);
      }
    } else {
      // If categoryId is blank, clear
      setValue('materials', []);
      setAvailableBrands([]);
    }
  }, [selectedCategory, categories, setValue]);

  /**
   * Whenever brands data are fetched for the selected category, update local brand list
   */
  useEffect(() => {
    if (brands) {
      setAvailableBrands(brands);
    }
  }, [brands]);

  // ============ HANDLE TAB CHANGES ============
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    refetchCategories();
    reset({
      categoryId: '',
      materials: [{ name: '', units: [], id: '' }],
      brandNames: [{ name: '' }]
    });
  };

  // ============ MUTATIONS ============
  const { mutate: handleCreateMaterial } = useMutation({
    mutationFn: (data) => createMaterial(data),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Material added successfully!');
      onClose();
      reset();
      refetchCategories();
      onSuccess();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error creating material:', error);
      toast.error('Failed to submit form!');
    }
  });

  const { mutate: handleUpdateMaterial } = useMutation({
    mutationFn: (data) => updateMaterial(data),
    mutationKey: ['category', 'categories'],
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Material updated successfully!');
      onClose();
      onSuccess();
      queryClient.invalidateQueries('categories');
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error updating material:', error);
      toast.error('Failed to update material!');
    }
  });

  const { mutate: handleAddCategory } = useMutation({
    mutationFn: (data) => addCategory(data),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Category added successfully!');
      reset();
      refetchCategories();
      onSuccess();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error adding category:', error);
      toast.error('Failed to submit form!');
    }
  });

  const { mutate: handleAddBrand } = useMutation({
    mutationFn: (data) => addBrand(data),
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Brand added successfully!');
      reset();
      refetchCategories();
      onSuccess();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error adding brand:', error);
      toast.error('Failed to submit form!');
    }
  });

  // ============ FORM SUBMISSION HANDLER ============
  const onSubmitForm = (data) => {
    if (activeTab === 'material') {
      const materials = data.materials?.map((material) => ({
        ...material,
        category_id: data.categoryId // 'units' remains as array of strings
      }));

      const payload = {
        items: isEditMode
          ? materials.map((item) => ({
              ...item,
              id: item.id || undefined
            }))
          : materials
      };

      if (isEditMode) {
        handleUpdateMaterial(payload);
      } else {
        handleCreateMaterial(payload);
      }
    } else if (activeTab === 'category') {
      const categoryData = {
        name: data.categoryName,
        description: data.categoryName
      };
      handleAddCategory(categoryData);
    } else if (activeTab === 'brand') {
      const { categoryId, brandNames } = data;
      const brandsPayload = brandNames?.map((item) => ({
        name: item.name,
        category_id: categoryId
      }));
      handleAddBrand({ brands: brandsPayload });
    }
  };

  // ============ "ADD MORE" HANDLERS ============
  const handleAddMaterialField = () => {
    appendMaterial({ name: '', units: [] });
  };

  const handleAddBrandField = () => {
    appendBrand({ name: '' });
  };

  // ============ INPUT CHANGE HELPERS ============
  const handleCategoryName = useCallback(
    (val) => {
      const newval = val.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue('categoryName', newval);
    },
    [setValue]
  );

  const handleNameChange = useCallback(
    (key, value) => {
      const cleanedValue = value.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue(key, cleanedValue);
    },
    [setValue]
  );

  // Build the category dropdown options.
  // Note: No `selected: true` on the placeholder so it doesn't override a setValue().
  const categoryOptions = [
    {
      value: '',
      label: 'Select Category',
      disabled: true
    },
    ...(Array.isArray(categories)
      ? categories.map((item) => ({
          value: parseInt(item.id),
          label: item.name.trim()
        }))
      : [])
  ];

  // ============ RENDER ============
  return (
    <div>
      {/* ========= TAB HEADERS ========= */}
      <div className="flex space-x-4">
        <button
          type="button"
          className={`tab px-4 py-2 ${
            activeTab === 'material'
              ? 'border-b-2 border-primary text-primary'
              : 'text-gray-500'
          }`}
          onClick={() => handleTabChange('material')}
        >
          {isEditMode ? 'Edit Materials' : 'Add Materials'}
        </button>

        {!isEditMode && (
          <button
            type="button"
            className={`tab px-4 py-2 ${
              activeTab === 'category'
                ? 'border-b-2 border-primary text-primary'
                : 'text-gray-500'
            }`}
            onClick={() => handleTabChange('category')}
          >
            Add Category
          </button>
        )}

        <button
          type="button"
          className={`tab px-4 py-2 ${
            activeTab === 'brand'
              ? 'border-b-2 border-primary text-primary'
              : 'text-gray-500'
          }`}
          onClick={() => handleTabChange('brand')}
        >
          Add Brand
        </button>
      </div>

      {/* ========= MATERIAL TAB CONTENT ========= */}
      {activeTab === 'material' && (
        <div className="tab-content">
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            className="flex flex-col gap-4 mt-4"
          >
            {/* Category Dropdown */}
            <FormInput
              width={100}
              label="Category"
              register={register}
              register_key="categoryId"
              type="dropdown"
              options={categoryOptions}
              errors={errors.categoryId}
              disabled={isLoading} // Only disable if data is loading
            />

            {/* Show available brands if they exist for the chosen category */}
            {selectedCategory && availableBrands.length > 0 && (
              <div>
                <span className="text-sm text-gray-400">
                  Available Brands:{' '}
                  {availableBrands.map((brand) => brand.name).join(', ')}
                </span>
              </div>
            )}

            {/* Materials Loop */}
            {materialFields.map((field, index) => (
              <div key={field.id} className="flex flex-col gap-3 w-full mb-2">
                {/* Material Name + Remove Button */}
                <div className="flex items-center gap-3 justify-between w-full">
                  <FormInput
                    width={index === 0 ? 48 : 46}
                    register={register}
                    register_key={`materials[${index}].name`}
                    type="text"
                    placeholder={`Material Name ${index + 1}`}
                    errors={errors?.materials?.[index]?.name}
                    onType={(value) =>
                      handleNameChange(`materials[${index}].name`, value)
                    }
                  />

                  {/* Units (CreatableSelect) */}
                  <div className="w-1/2">
                    <Controller
                      control={control}
                      name={`materials[${index}].units`}
                      defaultValue={field.units || []}
                      render={({ field: { onChange, value } }) => {
                        const convertedValue =
                          value?.map((val) => ({
                            value: val,
                            label: val
                          })) || [];

                        return (
                          <CreatableSelect
                            isMulti
                            value={convertedValue}
                            onChange={(newValue) =>
                              onChange(newValue.map((item) => item.value))
                            }
                            onCreateOption={(inputValue) =>
                              onChange([...(value || []), inputValue])
                            }
                            placeholder="Units..."
                            styles={{
                              multiValue: (provided) => ({
                                ...provided,
                                backgroundColor: '#2A565E'
                              }),
                              multiValueLabel: (provided) => ({
                                ...provided,
                                color: '#fff'
                              })
                            }}
                          />
                        );
                      }}
                    />
                  </div>

                  {/* Remove Material Button */}
                  {index > 0 && (
                    <button
                      type="button"
                      className="p-2 text-red-600"
                      onClick={() => removeMaterial(index)}
                    >
                      &times;
                    </button>
                  )}
                </div>

                {/* Units Validation Error */}
                {errors?.materials?.[index]?.units && (
                  <p className="text-red-600 text-sm">
                    {errors.materials[index].units.message}
                  </p>
                )}
              </div>
            ))}

            {/* Add More Materials Button */}
            <div className="flex justify-end">
              <button
                type="button"
                className="text-primary"
                onClick={handleAddMaterialField}
              >
                Add more
              </button>
            </div>

            {/* Submit Button */}
            <div>
              <Button displayType="primary" type="submit" disabled={isSubmitting}>
                {isEditMode ? 'Update Material' : 'Add Material'}
              </Button>
            </div>
          </form>
        </div>
      )}

      {/* ========= CATEGORY TAB CONTENT ========= */}
      {activeTab === 'category' && (
        <div className="tab-content">
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            className="flex flex-col gap-4 mt-4"
          >
            <FormInput
              width={100}
              label="Category Name"
              register={register}
              register_key="categoryName"
              type="text"
              placeholder="Category Name"
              errors={errors.categoryName}
              onType={handleCategoryName}
            />
            <div>
              <Button type="submit" disabled={isSubmitting}>
                Add Category
              </Button>
            </div>
          </form>
        </div>
      )}

      {/* ========= BRAND TAB CONTENT ========= */}
      {activeTab === 'brand' && (
        <div className="tab-content">
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            className="flex flex-col gap-4 mt-4"
          >
            <FormInput
              width={100}
              label="Category"
              register={register}
              register_key="categoryId"
              type="dropdown"
              options={[
                {
                  value: '',
                  label: 'Select Category',
                  disabled: true
                },
                ...categories.map((item) => ({
                  value: item.id,
                  label: item.name.trim()
                }))
              ]}
              errors={errors.categoryId}
            />

            {/* Brand Names Loop */}
            {brandFields.map((field, index) => (
              <div key={field.id} className="flex items-center mb-2">
                <FormInput
                  width={index === 0 ? 100 : 95}
                  register={register}
                  register_key={`brandNames[${index}].name`}
                  type="text"
                  placeholder={`Brand Name ${index + 1}`}
                  errors={errors?.brandNames?.[index]?.name}
                />
                {index > 0 && (
                  <button
                    type="button"
                    className="ml-2 text-lg text-red-600"
                    onClick={() => removeBrand(index)}
                  >
                    &times;
                  </button>
                )}
              </div>
            ))}

            {/* Add More Brands Button */}
            <div className="flex justify-end">
              <button
                type="button"
                className="text-primary"
                onClick={handleAddBrandField}
              >
                Add more
              </button>
            </div>

            {/* Submit Button */}
            <div>
              <Button type="submit" disabled={isSubmitting}>
                Add Brand
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
