import React from 'react';
import Table from '../../../shared/components/layout/Table';
import GridWrapper from '../../../shared/components/layout/GridWrapper';
import { transformData } from '../../../super-admin/utils/transformData';

const itemFormatData = ['id', 'name', 'units'];

export const ItemList = ({ itemList }) => {
  const headers = ['Item Name', 'Unit'];
  const modifiedData = itemList?.map((item) => {
    return {
      id: item.id,
      name: item.name,
      //units will comes asa array with comma seperated values so we need to join them and make it comma seperated string
      units: item.units ? item.units?.join(', ') : ''
    };
  });
  const transformedData = modifiedData?.map((item) => transformData(item, itemFormatData));

  return (
    <GridWrapper width={100}>
      <div className="mt-[2rem] h-[10rem]">
        <Table headers={headers} data={transformedData} identifier={'id'} isEditEnable={false} />
      </div>
    </GridWrapper>
  );
};
