import React, { useEffect, useState } from 'react';
import Table from '../../shared/components/layout/Table';
import { transformData } from '../../super-admin/utils/transformData';
import Cookies from 'js-cookie';

const AccommodationDetails = ({ accommodations, onEdit }) => {
  const [selectedType, setSelectedType] = useState('INDEPENDENT');
  const [tableHeight, setTableHeight] = useState('auto');

  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementUnit =
    !rawMeasurementUnit ||
    rawMeasurementUnit === 'null' ||
    rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;
      
  const accommodationTypes = {
    INDEPENDENT: {
      title: 'Independent',
      headers: [
        'Accommodation',
        'Unit Type',
        `Plot Area (${measurementUnit ? measurementUnit : 'sq/ft'})`,
        `Build Up Area (${measurementUnit ? measurementUnit : 'sq/ft'})`,
        `Carpet Area (${measurementUnit ? measurementUnit : 'sq/ft'})`,
        'Actions'
      ],
      form: ['id', 'name', 'unitType', 'plotArea', 'buildUpArea', 'carpetArea', 'actions']
    },
    GROUP: {
      title: 'Group',
      headers: [
        'Accommodation',
        'Unit Type',
        `Build Up Area (${measurementUnit ? measurementUnit : 'sq/ft'})`,
        `Carpet Area (${measurementUnit ? measurementUnit : 'sq/ft'})`,
        'Actions'
      ],
      form: ['id', 'name', 'unitType', 'buildUpArea', 'carpetArea', 'actions']
    },
    PARKING: {
      title: 'Parking',
      headers: ['Accommodation', `Build Up Area (${measurementUnit ? measurementUnit : 'sq/ft'})`, 'Unit Type', 'Electrified', 'Actions'],
      form: ['id', 'name', 'buildUpArea', 'unitType', 'electrified', 'actions']
    }
  };

  const getData = (type) => {
    return accommodations
      ?.filter((item) => item.accomodationType === type)
      ?.map((item) => transformData(item, accommodationTypes[type].form));
  };

  const calculateHeight = (data) => {
    const rowHeight = 48;
    const headerHeight = 48;
    if (data?.length == 0) {
      return '200px';
    }
    const calculatedHeight = data?.length * rowHeight + headerHeight;
    return `${Math.min(calculatedHeight, 352)}px`;
  };

  useEffect(() => {
    const data = getData(selectedType);
    console.log(data);

    setTableHeight(calculateHeight(data));
  }, [selectedType, accommodations]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="w-full flex flex-col absolute top-[16rem] z-5 px-5 bg-gray-100 pb-10 overflow-hidden">
      <div className="mb-4">
        <h3 className="mb-2 ml-1">Select Accommodation</h3>
        <select
          value={selectedType}
          onChange={handleTypeChange}
          className="w-64 p-2 border border-gray-300 rounded">
          {Object.keys(accommodationTypes)?.map((type) => (
            <option key={type} value={type}>
              {accommodationTypes[type].title}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-20">
        <h3 className="m-3 text-md font-semibold">
          Accommodation Type - {accommodationTypes[selectedType].title}
        </h3>
        <div style={{ height: tableHeight }}>
          <Table
            headers={accommodationTypes[selectedType].headers}
            identifier={'id'}
            onEdit={onEdit}
            data={getData(selectedType)}
          />
        </div>
      </div>
    </div>
  );
};

export default AccommodationDetails;
