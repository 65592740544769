import React, { useState } from 'react'
import Button from '../../../shared/components/ui/Button'
import { useForm } from 'react-hook-form';
import { getAllUsers } from '../../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';

export const ReportAdmin = () => {
    const [reportType, setReportType] = useState('builder');
    const { register, handleSubmit } = useForm()
    const [selectedBuilder, setSelectedBuilder] = useState('');

    const { data: users } = useQuery({
        queryKey: ['users'],
        queryFn: () => getAllUsers()
    });

    const handleBuilderChange = (e) => {
        setSelectedBuilder(e.target.value);
    };

    const transformedBuilders = users?.map((item) => ({
        value: item?.id,
        label: item?.primery_user?.name?.trim()
    }));




    return (
        <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow">
            <div className="mb-8">
            </div>

            <div className="space-y-6">
                <div className="flex flex-col">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Builder Name
                    </label>
                    <select
                        className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 
                                 bg-white shadow-sm transition-colors duration-200
                                 hover:bg-[#2a575d] hover:bg-opacity-10 hover:border-[#2a575d]
                                 focus:border-[#2a575d] focus:outline-none focus:ring-1 focus:ring-[#2a575d]"
                        value={selectedBuilder}
                        onChange={handleBuilderChange}
                    >
                        <option value="">Select builder</option>
                        {transformedBuilders?.map((item) => (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex justify-center">
                    <button
                        className="px-4 py-2 bg-[#2a575d] text-white rounded-md 
                                 hover:bg-[#234448] focus:outline-none focus:ring-2 
                                 focus:ring-[#2a575d] focus:ring-offset-2 
                                 transition-colors duration-200"
                    >
                        Download {reportType === 'leads' ? '' : 'Builder'} Report
                    </button>
                </div>
            </div>
        </div>
    )
}