import React, { useCallback, useEffect, useState } from 'react';
import FormInput from '../../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../shared/components/ui/Button';
import { PreviewRateUnitID } from './PreviewRateUnitID';
import { PreviewRateAccommodationId } from './PreviewRateAccommodationId';
import {
  createPricing,
  getAllAccommodations,
  getAllAccommodationsByProject,
  getAllBlocks,
  getPlc,
  getPlcbyProjectId
} from '../../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

export const CreateRateList = ({ projectName, createSuccess, pricingType, onClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();
  const rawMeasurementUnit = Cookies.get('measurement_type');
  const measurementType =
    !rawMeasurementUnit || rawMeasurementUnit === 'null' || rawMeasurementUnit === 'undefined'
      ? 'sq/ft'
      : rawMeasurementUnit;
  const measurementTypeWithoutSlash = measurementType.replace('/', '');
  console.log({ measurementTypeWithoutSlash });

  const [accommodationType, setAccommodationType] = useState('');
  const [isOpenRateListById, setIsRateListOpenById] = useState(false);
  const [subCategory, setSubCategory] = useState('');
  const [onProccedbtn, setProccedbtn] = useState('');
  const { id: projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accommodationListOptions, setAccommodationListOptions] = useState([]);
  const [blocksListOptions, setBlocksListOptions] = useState([]);
  const [unitListOptions, setUnitListOptions] = useState([]);
  const [PLCListOptions, setPLCListOptions] = useState([]);
  const [selectedBlockId, setSelectedBlockId] = useState('');

  //Preview States
  const [formPayload, setFormPayload] = useState({});
  const [previewData, setPreviewData] = useState([]);

  // API Calls
  const { data: accommodations, refetch: refetchAccommodations } = useQuery({
    queryKey: ['accommodations', projectId],
    queryFn: getAllAccommodationsByProject(projectId),
    enabled: !!projectId
  });

  console.log({ accommodations });

  const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  const { data: PLCbyProjectIdList, refetch: refetchPLCProject } = useQuery({
    queryKey: ['PLCbyProjectIdList'],
    queryFn: () => getPlc(),
    enabled: !!projectId
  });

  useEffect(() => {
    if (accommodations) {
      const accommodationNameList = Object.values(accommodations).flat();
      if (accommodationNameList.length > 0) {
        setAccommodationListOptions([
          { value: '', label: 'Select type' },
          ...accommodationNameList.map((item) => ({
            value: item.id,
            label: item.name,
            type: item.accomodationType
          }))
        ]);
      }
    }
  }, [accommodations]);

  useEffect(() => {
    if (blocksDyna && blocksDyna.blocks) {
      setBlocksListOptions([
        { value: 'Select type', label: 'Select type' },
        ...blocksDyna.blocks.map((item) => ({ value: item.id, label: item.name }))
      ]);
    }
  }, [blocksDyna]);

  useEffect(() => {
    if (PLCbyProjectIdList) {
      const filterList = PLCbyProjectIdList.filter((item) => item.id === parseInt(selectedBlockId));
      const extractPLC = filterList.flatMap((item) => item.plcs);
      const PLCData = extractPLC?.map((plc) => ({ value: plc.id, label: plc.name }));
      setPLCListOptions([{ value: 'Select type', label: 'Select type' }, ...PLCData]);
    }
  }, [PLCbyProjectIdList, selectedBlockId]);

  useEffect(() => {
    if (blocksDyna && blocksDyna.blocks) {
      const filteredBlocks = blocksDyna.blocks.filter(
        (item) => item.id === parseInt(selectedBlockId)
      );
      const extractedUnits = filteredBlocks.flatMap((item) =>
        item.levels.flatMap((level) => level.units)
      );
      const unitsData = extractedUnits?.map((unit) => ({
        value: unit.id,
        label: unit.unitName
      }));
      setUnitListOptions([{ value: 'Select type', label: 'Select type' }, ...unitsData]);
    }
  }, [blocksDyna, selectedBlockId]);

  const handlePreviewAccmmodationIDonProccedbtn = () => {
    setProccedbtn('previewAccommodationById');
    setSubCategory('');
  };

  const handlePreviewUnitIDonProccedbtn = () => {
    setProccedbtn('previewUnitById');
    setSubCategory('');
  };

  const onSubmit = (data) => {
    setPreviewData([]);
    const payload = {
      mode: subCategory === 'sqft' || subCategory === 'sqmt' ? 'sqft' : 'accommodation'
    };

    if (subCategory === measurementTypeWithoutSlash) {
      payload.accommodation_id = parseInt(data.accommodationId);
      payload.loading_factor = parseFloat(data.loadingFactor);
      payload.project_id = parseInt(projectId);

      if (accommodationType === 'INDEPENDENT') {
        payload.plote_price = parseInt(data.plote_price);
        payload.construction_price = parseInt(data.construction_price);
      } else {
        payload.price = parseInt(data.rate_per_sqft);
      }
    } else if (subCategory === 'accommodation') {
      payload.accommodation_id = parseInt(data.accommodationId);
      payload.project_id = parseInt(projectId);

      if (accommodationType === 'INDEPENDENT') {
        payload.plote_price = parseInt(data.plote_price);
        payload.construction_price = parseInt(data.construction_price);
      } else {
        payload.price = parseInt(data.price);
      }
    }

    const filteredAcc = accommodationListOptions.filter(
      (item) => item.value === parseInt(data.accommodationId)
    );
    const filteredUnit = unitListOptions.filter((item) => item.value === parseInt(data.unitId));
    const filteredBlock = blocksListOptions.filter(
      (item) => item.value === parseInt(selectedBlockId)
    );
    const filteredPLC = PLCListOptions.filter((item) => item.value === parseInt(data.plc));

    const previewPayload = {
      ...payload,
      project_name: projectName || '',
      block_name: filteredBlock[0]?.label || '',
      accommodation_name: filteredAcc[0]?.label || '',
      unit_name: filteredUnit[0]?.label || '',
      plc_name: filteredPLC[0]?.label || ''
    };

    // If you still want to store payload in state
    setFormPayload(payload);

    console.log({ payload });
    setPreviewData((prevData) => [...prevData, previewPayload]);

    // Call the mutation with `payload` directly
    handleCreatePricing(payload);
  };

  const { mutate: handleCreatePricing } = useMutation({
    mutationFn: (payload) => createPricing(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: (data) => {
      setIsSubmitting(false);
      toast.success('Pricing created successfully');
      // Add any additional success handling logic here
      setProccedbtn('');
      setFormPayload({});
      setPreviewData([]);
      createSuccess();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new block', err);
      toast.error('Error creating new block', err);
    }
  });

  const handleSelectBlockChange = (e) => {
    setSelectedBlockId(e.target.value);
  };

  const handleCreatePricingClick = () => {
    handleCreatePricing(formPayload);
  };

  const accommodationIdValue = watch('accommodationId');

  useEffect(() => {
    if (!accommodationIdValue || accommodationIdValue === 'Select type') {
      setAccommodationType('');
      return;
    }
    // For demonstration, we’ll assume the `accommodationListOptions` array has `type` or some identifier
    const selected = accommodationListOptions.find(
      (item) => item.value === parseInt(accommodationIdValue)
    );
    if (selected && selected.type) {
      setAccommodationType(selected.type);
      console.log('Select type', selected.type);
    } else {
      setAccommodationType('');
    }
  }, [accommodationIdValue, accommodationListOptions]);

  console.log(pricingType, 'pricingType');

  const [loadingFactor, setLoadingFactor] = useState('');

  const handleLoadingFactorChange = (e) => {
    let inputVal = e;

    // 1) Remove anything that's not a digit:
    inputVal = inputVal.replace(/\D/g, '');

    // 2) Convert to a number and cap at 100:
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Loading factor cannot exceed 100');
    }

    // 3) Set our local state and also setValue in RHF so the form sees this updated value.
    setLoadingFactor(inputVal);
    setValue('loadingFactor', inputVal);
  };

  return (
    <div>
      {onProccedbtn === 'previewAccommodationById' && (
        <PreviewRateAccommodationId
          previewData={previewData}
          isSubmitting={isSubmitting}
          handlePreviewProceed={handleCreatePricingClick}
          onEdit={() => {
            setIsRateListOpenById(true);
            setProccedbtn('');
            setSubCategory('accommodation');
          }}
        />
      )}
      {onProccedbtn === 'previewUnitById' && (
        <PreviewRateUnitID
          previewData={previewData}
          isSubmitting={isSubmitting}
          handlePreviewProceed={handleCreatePricingClick}
          onEdit={() => {
            setIsRateListOpenById(true);
            setProccedbtn('');
            setSubCategory(measurementTypeWithoutSlash);
          }}
        />
      )}
      {isOpenRateListById ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {subCategory === measurementTypeWithoutSlash && (
            <>
              <div>
                <div className="flex flex-col justify-between items-start gap-4">
                  <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
                    {measurementType} Based Rate Details
                  </span>
                </div>
                <div className="flex justify-between mt-4">
                  <FormInput
                    width={49}
                    label={'Accommodation Name'}
                    register_key={'accommodationId'}
                    isRequired={true}
                    register={register}
                    type={'dropdown'}
                    options={accommodationListOptions}
                  />
                  <FormInput
                    width={49}
                    label={'Loading Factor (%)'}
                    register_key={'loadingFactor'}
                    register={register}
                    type={'text'}
                    onType={handleLoadingFactorChange}
                    defaultValuePre={loadingFactor}
                    errors={errors.loadingFactor}
                  />
                </div>
                <div className="flex w-full justify-between mt-4">
                  {accommodationType === 'INDEPENDENT' ? (
                    <div className="flex justify-between mt-4 w-full">
                      <FormInput
                        width={49}
                        label={'Plot Price'}
                        register_key={'plote_price'}
                        register={register}
                        isRequired={true}
                        type={'text'}
                      />
                      <FormInput
                        width={49}
                        label={'Construction Price'}
                        register_key={'construction_price'}
                        register={register}
                        type={'text'}
                      />
                    </div>
                  ) : (
                    <FormInput
                      width={49}
                      label={`Rate per ${measurementType}`}
                      register_key={'rate_per_sqft'}
                      isRequired={true}
                      register={register}
                      type={'text'}
                    />
                  )}
                </div>

                <div className="w-full flex justify-end items-center space-x-4 mt-8">
                  <Button type="reset" displayType="secondary">
                    Cancel
                  </Button>
                  <Button type="submit">Proceed</Button>
                </div>
              </div>
            </>
          )}

          {subCategory === 'accommodation' && (
            <>
              <div>
                <div className="flex flex-col justify-between items-start gap-4">
                  <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
                    Accommodation Based Rate Details
                  </span>
                </div>
                <div className="flex justify-between mt-4">
                  {/* <FormInput
                    width={49}
                    label={'Loading Factor'}
                    register_key={'loadingFactor'}
                    register={register}
                    type={'text'}
                  /> */}
                </div>
                <div className="flex justify-between mt-4">
                  <FormInput
                    width={49}
                    label={'Accommodation Name'}
                    register_key={'accommodationId'}
                    register={register}
                    isRequired={true}
                    type={'dropdown'}
                    options={accommodationListOptions}
                  />
                  {accommodationType !== 'INDEPENDENT' && (
                    <FormInput
                      width={49}
                      label={'Price'}
                      isRequired={true}
                      register_key={'price'}
                      register={register}
                      type={'text'}
                    />
                  )}
                </div>
                {accommodationType === 'INDEPENDENT' && (
                  <div className="flex justify-between mt-4">
                    <FormInput
                      width={49}
                      label={'Plot Price'}
                      register_key={'plote_price'}
                      isRequired={true}
                      register={register}
                      type={'text'}
                    />
                    <FormInput
                      width={49}
                      label={'Construction Price'}
                      register_key={'construction_price'}
                      register={register}
                      type={'text'}
                    />
                  </div>
                )}

                <div className="w-full flex justify-end items-center space-x-4 mt-8">
                  <Button type="reset" displayType="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Proceed</Button>
                </div>
              </div>
            </>
          )}
        </form>
      ) : (
        <div className="flex gap-10 justify-center h-[15rem] items-center">
          {pricingType !== 'sqft' && (
            <div
              className="p-5 shadow-md font-semibold flex flex-col items-center cursor-pointer"
              onClick={() => {
                setIsRateListOpenById(true);
                setSubCategory('accommodation');
              }}>
              <h3>Accommodation Based Rate List</h3>
              <p className="text-sm text-[#BCC1CA]">Accommodation Based Rate List</p>
            </div>
          )}
          {(pricingType === 'sqft' || pricingType === 'all') && (
            <div
              className="p-5 shadow-md cursor-pointer font-semibold flex flex-col items-center"
              onClick={() => {
                setIsRateListOpenById(true);
                setSubCategory(measurementTypeWithoutSlash);
              }}>
              <h3>{measurementTypeWithoutSlash} Based Rate List</h3>
              <p className="text-sm text-[#BCC1CA]">{measurementTypeWithoutSlash} Rate List</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
