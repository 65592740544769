import React from 'react';
import GooglePlayBadge from '../../shared/assets/svg/getItOnGooglePlay.svg'; 
import AppStoreBadge from '../../shared/assets/svg/getItOnAppStore.svg'; 
import PageNotAccessibleImage from '../../shared/assets/svg/pageNotAccessible.svg'; 

const PageNotAccessible = () => {
  return (
    <div className="flex flex-col items-center justify-center xs:min-h-screen h-full py-[50px] bg-gray-100">
      <div className="bg-white shadow-md p-6 rounded-lg text-center w-[90%] md:w-[40%]">
        <h1 className="text-xl font-semibold mb-4">Dear User</h1>
        <p className="text-gray-700 text-sm mb-6">
          The page you are trying to access is not accessible through Mobile Web. Please use the
          HousinGram App to access this page.
        </p>
        <img
          src={PageNotAccessibleImage}
          alt="Page Not Accessible"
          className="w-[100%] mx-auto mb-6 sm:w-[60%]"
        />
        <div className="flex justify-center gap-4 flex-wrap">
          <a
            href="https://play.google.com/store/apps/details?id=com.housingram&pcampaignid=web_share"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={GooglePlayBadge}
              alt="Google Play Badge"
              className="h-[50px] w-auto cursor-pointer"
            />
          </a>
          <a
            href="https://apps.apple.com/in/app/housingram/id6727006782"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={AppStoreBadge}
              alt="App Store Badge"
              className="h-[50px] w-auto cursor-pointer"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotAccessible;
