export function sanitizeData(obj) {
  const sanitizedObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (
      value !== null &&
      value !== undefined &&
      !(typeof value === 'string' && value.trim() === '') &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      sanitizedObj[key] = value;
    }
  });

  return sanitizedObj;
}
