import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import toast from 'react-hot-toast';
import { transformData } from '../../super-admin/utils/transformData';
import { useQuery } from '@tanstack/react-query';
import {
  getQuatationRequestItems,
  updateQuatationRequestItems
} from '../services/api/implementation/impl';
import { use } from 'react';

const QuotationDetails = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();
  const { id: quotationId } = useParams();
  const [isRejecting, setIsRejecting] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [checkIfAccepted, setCheckIfAccepted] = useState(false);

  const { data: quotationRequestItem, refetch: refetchquotationRequest } = useQuery({
    queryKey: ['quotationRequestItem', quotationId],
    queryFn: () => getQuatationRequestItems(parseInt(quotationId)),
    enabled: !!parseInt(quotationId, 10)
  });

  console.log({ quotationRequestItem });

  useEffect(() => {
    refetchquotationRequest();
  }, [quotationId]);

  /* 
    Dummy data
  */

  useEffect(() => {
    if (quotationRequestItem) {
      const checkIfAccepted = quotationRequestItem?.some(
        (item) => item.quotation.status === 'Accept' || item.quotation.status === 'Rejected'
      );
      setCheckIfAccepted(checkIfAccepted);
    }
  }, [quotationRequestItem]);

  const handleQuotationRequestItemFormat = (item) => {
    return {
      id: item?.id,
      item: item?.item?.name,
      brand: item?.quotation?.brand?.name,
      quantity: item?.vendorRequirmentMappingItems?.quantity,
      unit: item?.item?.uom,
      rate: item?.single_unit_price,
      cost: item?.amount
    };
  };

  const itemsDataFormat = ['id', 'item', 'brand', 'quantity', 'unit', 'rate', 'cost'];
  const quotation = quotationRequestItem?.map((item) =>
    transformData(handleQuotationRequestItemFormat(item), itemsDataFormat)
  );

  /* 
    Event handlers
  */

  const handleAccept = () => {
    setIsAccepting(true);
    updateQuatationRequestItems(parseInt(quotationId), {
      status: 'Accept'
    })
      .then((res) => {
        setIsAccepting(false);
        toast.success('Quotation accepted successfully');
        navigate(-1);
      })
      .catch((err) => {
        setIsAccepting(false);
        console.log('Error in update Quatation');
      });
  };

  const handleReject = () => {
    setIsRejecting(true);
    updateQuatationRequestItems(parseInt(quotationId), {
      status: 'Rejected'
    })
      .then((res) => {
        setIsRejecting(false);
        toast.error('Quotation rejected');
        navigate(-1);
      })
      .catch((err) => {
        setIsRejecting(false);
        console.log('Error in update Quatation');
      });
  };

  /* 
    JSX
  */
  return (
    <>
      <Layout>
        {/* Header */}
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: `Requirements Item List ` }} backArrow={true} />
        </div>

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="mt-[6rem] h-[20rem]">
            <Table
              headers={['Item', 'Brand', 'Quantity', 'Unit', 'Rate Incl of GST', 'Cost']}
              data={quotation}
              identifier={'id'}
              isViewEnable={false}
              isEditEnable={false}
            />
          </div>

          {/* <div className="flex justify-between p-4 border-t border-gray-200 mt-20 px-6">
            <p className="font-bold">Total</p>
            <p className="font-bold">{quotation.totalCost}</p>
          </div> */}
          {!checkIfAccepted && (
            <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-[60px]">
              <Button displayType={'primary-red'} onClick={handleReject} disabled={isRejecting}>
                Reject
              </Button>
              <Button displayType={'primary'} onClick={handleAccept} disabled={isAccepting}>
                Accept
              </Button>
            </div>
          )}
        </GridWrapper>
      </Layout>
    </>
  );
};

export default QuotationDetails;
