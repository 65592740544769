import React from 'react';

import company_image from '../../shared/assets/png/company_image.png';
import { useForm } from 'react-hook-form';
import FormInput from '../../shared/components/ui/FormInput';
import { getLeadByID } from '../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import Avatar from 'react-avatar';

const SaleOverview = () => {
  /* 
    Header data
  */

  const { id } = useParams();

  const {
    data: leadbyId,
    isLoading,
    error,
    refetch: refetchLeadbyId
  } = useQuery({
    queryKey: ['leadById', parseInt(id)],
    queryFn: async () => {
      const res = await getLeadByID(parseInt(id));
      return res;
    },
    enabled: !!id // Only run the query if id is truthy
  });

  /* 
    Hook form
  */
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  /* 
    JSX
  */
  return (
    <div className="overflow-y-auto h-screen p-8 bg-gray-100">
      <div className="mt-[10rem] mb-8 p-8 rounded-lg bg-white shadow-md flex items-center justify-between gap-6 space-x-10">
        {/* {true ? (
          <img
            src={company_image}
            alt="company_image"
            className="w-[18rem] h-[18rem] bg-center bg-cover object-cover object-center"
          />
        ) : (
          <div className="bg-gray-300 w-[12rem] h-[12rem] rounded-full flex justify-center items-center">
            <span className="text-[8rem] text-white bg-center bg-cover object-cover object-center">
              {'A'}
            </span>
          </div>
        )} */}

        {/* <img
          src={company_image}
          alt="company_image"
          className="w-[18rem] h-[18rem] bg-center bg-cover object-cover object-center"
        /> */}
        {/* writw avatar code  */}
        <Avatar
          name={leadbyId?.user?.name || 'Anonymous'}
          size="200"
          round={false}
          maxInitials={2}
          color="#49A4AB"
          textSizeRatio={1.5}
        />

        <div className="flex-1 h-full flex flex-col items-between gap-8">
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-4">
              <span className="font-semibold text-xl">{leadbyId?.user?.name}</span>
              <span className="text-gray-400 text-sm">{`Private Limited Company`}</span>
            </div>
          </div>

          <div className="w-full flex justify-between items-center gap-4 flex-wrap">
            <div className="flex flex-col gap-4 flex-shrink-0 min-w-[30%]">
              <span className="">{'Phone Number'}</span>
              <span className="ml-4 text-sm">{leadbyId?.user?.phone_number}</span>
            </div>

            <div className="flex flex-col gap-4 flex-shrink-0 min-w-[30%]">
              <span className="">{'Email'}</span>
              <span className="ml-4 text-sm">{leadbyId?.user?.email}</span>
            </div>
            <div className="flex flex-col gap-4 flex-shrink-0 min-w-[30%]">
              <span className="">{'Customer Type'}</span>
              <span className="ml-4 text-sm">{leadbyId?.user?.customer_type || '---'}</span>
            </div>
          </div>

          <div className="w-full flex justify-start items-center gap-10 flex-wrap">
            <div className="flex flex-col gap-4 flex-shrink-0 min-w-[30%]">
              <span className="">{'Budget'}</span>
              <span className="ml-4 text-sm">Max Budget - {leadbyId?.max_estimated_budget}</span>
              <span className="ml-4 text-sm">Min Budget - {leadbyId?.min_estimated_budget}</span>
            </div>

            <div className="flex flex-col gap-4 flex-shrink-0 min-w-[30%]">
              <span className="">{'Status'}</span>
              <span className="ml-4 text-sm">{leadbyId?.status}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="p-8 rounded-lg bg-white shadow-md flex flex-col items-center justify-between gap-6 ">
        <div className="w-full flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
            Interest Insights
          </span>

          <div className="flex flex-col w-full flex-1 gap-4">
            <div className="flex space-x-8 justify-between items-start w-full">
              <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
                <div className="w-full flex flex-col gap-4 flex-1">
                  <div className="flex justify-start space-x-4">
                    <FormInput
                      width={40}
                      register={register}
                      register_key={'project_interested'}
                      label={'Project Interested In'}
                      type={'text'}
                      valuePre={leadbyId?.user?.property_interested || 'NA'}
                    />

                    {/* <FormInput
                      width={40}
                      register={register}
                      register_key={'project_type'}
                      label={'Project Type'}
                      type={'text'}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Address</span>

          <div className="flex flex-col w-full flex-1 gap-4">
            <div className="flex space-x-8 justify-between items-start w-full">
              <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
                <div className="w-full flex flex-col gap-4 flex-1">
                  <div className="flex justify-start space-x-4">
                    <FormInput
                      width={24}
                      register={register}
                      register_key={'zipcode'}
                      label={'Zip/Pin Code'}
                      // type={'text'}
                      valuePre={leadbyId?.address?.zipCode || 'NA'}
                    />

                    <FormInput
                      width={24}
                      register={register}
                      register_key={'state'}
                      label={'State'}
                      type={'text'}
                      valuePre={leadbyId?.address?.state || 'NA'}
                    />

                    <FormInput
                      width={24}
                      register={register}
                      register_key={'city'}
                      label={'City'}
                      type={'text'}
                      valuePre={leadbyId?.address?.city || 'NA'}
                    />

                    <FormInput
                      width={24}
                      register={register}
                      register_key={'country'}
                      label={'Country'}
                      type={'text'}
                      valuePre={'India'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2"></span>

          <div className="flex flex-col w-full flex-1 gap-4">
            <div className="flex space-x-8 justify-between items-start w-full">
              <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
                <div className="w-full flex flex-col gap-4 flex-1">
                  <div className="flex justify-start space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={'sales_person'}
                      label={'Sales Person'}
                      type={'text'}
                      valuePre={leadbyId?.sales?.name || 'NA'}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      register_key={'budget'}
                      label={'Estimated Budget'}
                      type={'text'}
                      valuePre={leadbyId?.min_estimated_budget || 'NA'}
                    />

                    <span className="self-end">to</span>

                    <div className="self-end w-[31%]">
                      <FormInput
                        width={100}
                        register={register}
                        register_key={'budget_end'}
                        label={''}
                        type={'text'}
                        valuePre={leadbyId?.max_estimated_budget || 'NA'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full flex-1 gap-4">
            <div className="flex space-x-8 justify-between items-start w-full">
              <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
                <div className="w-full flex flex-col gap-4 flex-1">
                  <div className="flex justify-start space-x-4">
                    <FormInput
                      width={32}
                      register={register}
                      register_key={'source'}
                      label={'Source'}
                      type={'text'}
                      valuePre={leadbyId?.source || 'NA'}
                    />

                    <FormInput
                      width={32}
                      register={register}
                      register_key={'status'}
                      label={'Status'}
                      type={'text'}
                      valuePre={leadbyId?.status || 'NA'}
                    />

                    {/* <span className="self-end">to</span>

                    <div className='self-end w-[31%]'>
                      <FormInput
                        width={100}
                        register={register}
                        register_key={'project_type'}
                        label={''}
                        type={'text'}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleOverview;
