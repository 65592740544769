import React from 'react';

const SaleAttachmentList = () => {
  return (
    <div className="w-full flex flex-col mt-[9.6rem]">
      <div className="bg-white flex justify-between items-center px-10 py-6">
        <span className="text-gray-700 font-semibold">Shared Documents</span>
        {/* <Button>Assign to Project</Button> */}
      </div>

      <div className="flex justify-start space-x-8 px-10 py-6">
        {[1, 2, 3, 4, 5]?.map((item, index) => (
          <div className="flex flex-col cursor-pointer" key={index}>
            <img src="/graphics/file_pdf.png" alt="file_pdf_graphics" />
            <span className="text-gray-600">Registry.pdf</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SaleAttachmentList;
