import React from 'react';
import { TimeFormat } from '../../../super-admin/utils/DateFormat';

const ScheduleCard = ({ data }) => {
  console.log({ data });

  return (
    <div className="flex flex-col w-[50%] flex-shrink-0 gap-4 p-4 rounded-md bg-[#F4FAFA]">
      <div className="flex rounded-full justify-between items-center space-x-2 bg-red-600 w-fit p-2 px-3">
        <img src="/icons/bell.svg" alt="bell_icon" className="h-[24px] w-[24px]" />
        <span className="text-gray-100">
          Meeting Start In {TimeFormat(data?.start_time)} Onwards
        </span>
      </div>

      <span className="text-xl text-[#2A565E] font-semibold">{data?.title}</span>

      <div className="flex justify-start items-center space-x-2 w-full">
        <img src="/icons/clocks.svg" alt="clock_icon" className="h-[100%] w-[24px]" />
        <span className="text-gray-800">
          {TimeFormat(data?.start_time)} - {TimeFormat(data?.end_time)}
        </span>
      </div>

      <div className="flex justify-start items-center space-x-2 w-full">
        <img src="/icons/pin.svg" alt="meet_icon" className="h-[100%] w-[24px]" />
        <span className="text-gray-800">{data?.meeting_type}</span>
      </div>

      <div className="flex justify-between items-center w-full">
        <div className="w-fit flex rounded-full justify-between items-center space-x-2 bg-[#5EA9B1] p-2 px-3">
          <img src="/icons/user.svg" alt="bell_icon" className="h-[24px] w-[24px]" />
          <span className="text-gray-100">External</span>
        </div>

        {/* <div className="w-[6rem] h-[36px] relative">
          <img
            src="/graphics/avatar_grp.png"
            alt="avatar_grp_img"
            className="absolute top-0 left-[10%] h-[100%] w-[40%] bg-center bg-cover object-cover object-center"
          />
          <img
            src="/graphics/avatar_grp.png"
            alt="avatar_grp_img"
            className="absolute top-0 left-[36%] h-[100%] w-[40%]"
          />
          <img
            src="/graphics/avatar_grp.png"
            alt="avatar_grp_img"
            className="absolute top-0 right-0 h-[100%] w-[40%]"
          />
        </div> */}
      </div>
    </div>
  );
};

export default ScheduleCard;
