import React, { useState, useRef, useEffect } from 'react';
import LoginLogo from '../../assets/svg/login_logo.svg';
import NotificationIcon from '../../assets/svg/notificationIcon.svg';
import left_arrow from '../../assets/svg/left_arrow.svg';
import { useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import { getUserById } from '../../../builder/services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

const Header = ({ data, onBack, backArrow }) => {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const navigate = useNavigate();
  const widgetRef = useRef(null);
  const iconRef = useRef(null);
  const userId = Cookies.get('userId');

  //user api call to get user details
  const { data: user } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId
  });

  console.log({ user });

  // Sample notifications data relevant to construction work
  const notifications = [
    {
      id: 1,
      title: 'New Blueprint Uploaded',
      message: 'The architect has uploaded the new blueprint for Project X.',
      time: '2024-08-17 09:30 AM',
      avatar: 'https://via.placeholder.com/40'
    },
    {
      id: 2,
      title: 'Concrete Pouring Scheduled',
      message: 'Concrete pouring for the foundation is scheduled for tomorrow.',
      time: '2024-08-16 04:00 PM',
      avatar: 'https://via.placeholder.com/40'
    },
    {
      id: 3,
      title: 'Inspection Passed',
      message: 'The building inspection for Phase 1 has passed successfully.',
      time: '2024-08-15 11:15 AM',
      avatar: 'https://via.placeholder.com/40'
    },
    {
      id: 4,
      title: 'Electrical Wiring Update',
      message: 'John mentioned you in the electrical wiring update for Floor 2.',
      time: '2024-08-14 03:45 PM',
      avatar: 'https://via.placeholder.com/40'
    },
    {
      id: 5,
      title: 'Material Delivery Confirmed',
      message: 'Steel rods delivery has been confirmed and is on-site.',
      time: '2024-08-13 08:20 AM',
      avatar: 'https://via.placeholder.com/40'
    }
  ];

  // Toggle the notification widget
  const toggleWidget = () => {
    setIsWidgetOpen(!isWidgetOpen);
  };

  // Close the widget when clicking outside of it, but not when clicking the icon
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        widgetRef.current &&
        !widgetRef.current.contains(event.target) &&
        !iconRef.current.contains(event.target)
      ) {
        setIsWidgetOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex w-full justify-between items-center px-6 py-4 bg-white border-b-2 z-10">
      <div className="flex justify-between items-center space-x-1 text-stone-700 font-semibold text-lg">
        {backArrow && (
          <img
            className="inline-block h-8 w-8 rounded-full ring-3 ring-white cursor-pointer hover:scale-105 transition-all duration-300"
            src={left_arrow}
            alt="Logo_image"
            onClick={onBack ? onBack : () => navigate(-1)}
          />
        )}

        <span>{data?.name}</span>
      </div>

      <div className="flex gap-4">
        <div className="relative">
          <img
            ref={iconRef}
            className="inline-block h-8 w-8 rounded-full ring-3 ring-white cursor-pointer"
            src={NotificationIcon}
            alt="Notification_image"
            onClick={toggleWidget} // Toggle widget on icon click
          />

          {isWidgetOpen && (
            <div
              ref={widgetRef}
              className="absolute right-0 mt-2 w-80 bg-white border rounded-lg shadow-lg z-[1000]">
              <div className="p-4 border-b flex justify-between items-center">
                <h3 className="text-lg font-semibold">Notifications</h3>
                <button onClick={toggleWidget} className="text-gray-500">
                  &times;
                </button>
              </div>
              <ul className="h-[500px] overflow-auto">
                {notifications?.map((notification) => (
                  <li
                    key={notification.id}
                    className="flex items-center gap-4 p-4 border-b hover:bg-gray-50">
                    <img
                      src={notification.avatar}
                      alt="User Avatar"
                      className="h-10 w-10 rounded-full"
                    />
                    <div>
                      <p className="font-medium">{notification.title}</p>
                      <p className="text-sm text-gray-500">{notification.message}</p>
                      <p className="text-xs text-gray-400">{notification.time}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Avatar
          name={user?.name || 'Anonymous'}
          size="30"
          round={true}
          maxInitials={2}
          color="#49A4AB"
        />
      </div>
    </div>
  );
};

export default Header;
