import React from 'react';
import user from '../../shared/assets/svg/atozIcon.svg';
import { useForm } from 'react-hook-form';

const DealsDetails = () => {
  /* 
    Hook form
  */
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  /* 
    JSX
  */
  return (
    <div className="flex justify-between items-start mt-[9.2rem]">
      <div className="py-8 w-[40%] bg-white min-h-[80vh]">
        <div className="w-[88%] m-auto mb-6">
          <select
            className={`w-full p-2 border-[1px] border-stone-300 rounded`}
            {...register(`current_status`)}>
            {[{ label: 'Ongoing (3)', value: 'Ongoing (3)' }]?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {[1, 2, 3]?.map((item, index) => (
          <div
            className={`flex flex-col gap-4 border-[1px] p-5 pb-7 ${index % 2 && 'bg-gray-100'}`}
            key={index}>
            <div className="flex flex-col gap-2">
              <div className="w-full flex justify-between items-center">
                <span
                  className={`flex justify-center items-center p-2 px-3 rounded-full bg-[#E9F5F6] text-[#346C75]`}>
                  Suman Vihar
                </span>

                <div className="flex justify-between items-center space-x-1 mr-4">
                  <div className="w-[10px] h-[10px] rounded-full bg-orange-400">&nbsp;</div>
                  <span className="text-stone-800">Ongoing</span>
                </div>
              </div>

              <span className="text-stone-900 font-semibold text">Discussing Proposal</span>
            </div>

            <span>
              It is a long established fact that a reader will be distracted by the readable content
              of a page
            </span>
          </div>
        ))}
      </div>

      <div className="p-8 w-[56%] mx-auto my-8 bg-white min-h-[72vh] rounded-lg self-start flex flex-col gap-5">
        <div className="w-full flex justify-between items-center">
          <span className={`text-stone-800 text-lg font-semibold`}>Discussion Proposal</span>

          <div className="flex justify-between items-center space-x-1">
            <div className="w-[10px] h-[10px] rounded-full bg-orange-400">&nbsp;</div>
            <span className="text-stone-800">Pending</span>
          </div>
        </div>

        <div className="flex flex-col items-start w-fit">
          <div className="flex justify-between items-start space-x-3">
            <div className="bg-gray-300 w-[2.4rem] h-[2.4rem] rounded-full flex justify-center items-center">
              <span className="text-[1.6rem] text-white bg-center bg-cover !object-cover !object-center">
                {'A'}
              </span>
            </div>

            <span className="text-stone-500">Ainsley Ochoa</span>
          </div>

          <div className="flex justify-between items-center space-x-3 ml-12 mt-[-10px]">
            <span className="text-stone-500">To: me</span>
            <span className="text-stone-500">03:49</span>
          </div>
        </div>

        <span className="text-stone-700">Dear Alisa,</span>

        <span className="text-stone-700">
          Esse enim laboris anim duis eiusmod minim. Quis eiusmod consequat in id sunt velit ex
          ipsum consequat. Ea sunt eiusmod ipsum laboris
        </span>

        <span className="text-stone-700">
          Veniam nisi veniam sunt sint culpa est anim et ut quis duis aliqua exercitation.
        </span>

        <div className="flex justify-between items-stretch space-x-5 w-fit">
          <div className="bg-gray-400 w-[4rem] h-[4rem] rounded-lg flex justify-center items-center">
            <span className="text-[1.6rem] text-white bg-center bg-cover !object-cover !object-center">
              {'C'}
            </span>
          </div>

          <div className="flex flex-col justify-between py-[2px]">
            <span className="text-stone-600 font-semibold">Ms. Ainsley Ochoa</span>
            <span className="text-stone-600 font-semibold">Design Team Leader</span>
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <div className="w-[72%] text-stone-800 flex justify-between items-center">
            {[1, 2, 3]?.map((item, index) => (
              <div className="w-fit flex justify-between items-center space-x-3" key={index}>
                <img
                  src={user}
                  alt="user_image"
                  className="w-[1rem] bg-center bg-cover !object-cover !object-center"
                />
                <span className=" text-sm">Rakesh Yadav</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealsDetails;
