import React, { useState } from 'react';

import down_arrow from '../../../shared/assets/svg/drop_down.svg';
import edit_arrow from '../../../shared/assets/svg/edit_arrow.svg';
import TableData from '../../../shared/components/ui/TableData';

const Accordian = ({ data, isOpen, name, onClose }) => {
  /* 
    Local state
  */
  const [isChildrenOpen, setIsChildrenOpen] = useState(true);

  /* 
    Styles  
  */
  const accordianStyles = {
    'max-height': 0,
    overflow: 'hidden',
    transition: 'max-height 0.3s ease'
  };

  const accordianStylesOpen = {
    'max-height': '500px'
  };

  return (
    <div className="w-full flex flex-col gap-4">
      {/* Head */}
      <div className="w-full bg-white rounded-t-xl  flex justify-between items-center p-4 px-6">
        <span className="font-semibold">{name}</span>

        <div
          className={`w-[24px] h-[24px] ${isOpen && 'rotate-180'} cursor-pointer`}
          onClick={() => onClose(name)}
        >
          <img
            src={down_arrow}
            alt="Down icon"
            className="w-[24px] h-[24px] bg-contain !object-contain "
          />
        </div>
      </div>

      {/* Main contnent */}
      {isOpen && (
        <div className="w-full bg-white rounded-b-xl">
          {/* Role name */}
          <div className="w-full bg-[#E9F5F6] flex flex-col p-4 px-6">
            <div className="w-full flex justify-between items-center">
              <span className="font-semibold">Site Engineer</span>

              <div className="flex justify-between items-center space-x-4">
                <div className="flex space-x-2">
                  <img
                    src={edit_arrow}
                    alt="Down icon"
                    className="w-[20px] h-[20px] bg-contain !object-contain "
                  />

                  <span className="text-sm">Edit</span>
                </div>

                <div onClick={() => setIsChildrenOpen(!isChildrenOpen)}>
                  <img
                    src={down_arrow}
                    alt="Down arrow"
                    className={`${!isChildrenOpen && 'rotate-180'} w-[18px] h-[18px] cursor-pointer`}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Table header */}
          {isChildrenOpen && (
            <>
              <div className="w-full bg-gray-200 flex flex-col p-4">
                <div className="w-full flex justify-between items-center">
                  <TableData data={'Permissions'} width={30} />
                  <TableData data={'Create'} width={30} />
                  <TableData data={'View'} width={30} />
                </div>
              </div>

              <div className="w-full bg-white border-t-[1px] border-gray-300 flex flex-col p-4">
                <div className="w-full flex justify-between items-center">
                  <TableData data={'Company'} width={30} />
                  <TableData data={'✓'} width={30} />
                  <TableData data={'✓'} width={30} />
                </div>
              </div>

              <div className="w-full bg-white border-t-[1px] border-gray-300 flex flex-col p-4">
                <div className="w-full flex justify-between items-center">
                  <TableData data={'Project'} width={30} />
                  <TableData data={'✓'} width={30} />
                  <TableData data={'✓'} width={30} />
                </div>
              </div>

              <div className="w-full bg-white border-t-[1px] border-gray-300 flex flex-col p-4">
                <div className="w-full flex justify-between items-center">
                  <TableData data={'Land'} width={30} />
                  <TableData data={'✓'} width={30} />
                  <TableData data={'✓'} width={30} />
                </div>
              </div>

              <div className="w-full bg-white border-t-[1px] border-gray-300 flex flex-col p-4 rounded-b-xl">
                <div className="w-full flex justify-between items-center">
                  <TableData data={'Sales'} width={30} />
                  <TableData data={'✓'} width={30} />
                  <TableData data={'✓'} width={30} />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Accordian;
