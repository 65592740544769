import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import drop_down from '../../../shared/assets/svg/drop_down_white.svg';
import socket from '../../../../socket';
import toast from 'react-hot-toast';

const Sidebar = ({ role, links, logo }) => {
  /* 
    Hooks
  */
  const navigate = useNavigate();
  const location = useLocation();
  const DesignationName = Cookies.get('userDesignation'); // Getting the designation from cookies

  /* 
    Local state
  */
  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [expandedItem, setExpandedItem] = useState('');

  /* 
    Event handlers
  */
  const handleLogout = useCallback(() => {
    Cookies.remove('token'); // Assuming 'token' is the name of your cookie
    Cookies.remove('userRole');
    Cookies.remove('userRoleId');
    Cookies.remove('userId');
    navigate('/');
  }, [navigate]);

  const handleLinkClick = useCallback(
    (path) => {
      if (path === 'logout') {
        handleLogout();
      } else {
        setSelectedPath(path);
        navigate(path);
      }
    },
    [navigate, handleLogout]
  );

  /* 
    Effects
  */
  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  socket.on('Post', () => {
    toast.success('New Post Received');
  });
  socket.on('Comment', () => {
    toast.success('New Comment Received');
  });
  socket.on('Reply', () => {
    toast.success('You got a reply on your comment');
  });

  /* 
    Helper methods
  */
  const renderLinks = (links) => {
    const filteredLinks = links.filter(
      (link) => link.name !== 'Integration' || DesignationName === 'Admin'
    );

    return filteredLinks.map((link, index) => (
      <li
        key={index}
        className={`flex flex-col justify-center items-start mb-2 p-3 ${
          selectedPath.includes(link.path) ? 'rounded-lg p-3 bg-bgSidebar' : ''
        }`}
      >
        <div className="flex w-full justify-between items-center space-x-2">
          <div className="flex items-center space-x-1">
            <img src={link.icon} alt={link.name} className="h-5 w-5 mr-2 cursor-pointer" />

            <div
              className={`text-white hover:text-gray-300 cursor-pointer ${
                selectedPath.includes(link.path) ? 'active' : ''
              }`}
              onClick={() => handleLinkClick(link.path)}
            >
              {link.name}
            </div>
          </div>

          {link?.children && (
            <img
              src={drop_down}
              alt="Drop down icon"
              className={`cursor-pointer ${expandedItem === link && 'rotate-180'}`}
              onClick={() => setExpandedItem(expandedItem === link ? '' : link)}
            />
          )}
        </div>

        {link?.children && expandedItem === link && (
          <div className="flex flex-col mt-3 gap-3 ml-9">
            {link?.children?.map((item, index) => {
              const displayName = typeof item === 'object' ? item.displayName : item;
              const pathSegment = typeof item === 'object' ? item.path : item;
              return (
                <span
                  className={`cursor-pointer ${
                    selectedPath.includes(pathSegment.toLowerCase()) ? 'text-[#77BDC5]' : 'text-white'
                  }`}
                  key={index}
                  onClick={() => handleLinkClick(`${link?.path}/${pathSegment.toLowerCase()}`)}
                >
                  {displayName}
                </span>
              );
            })}
          </div>
        )}
      </li>
    ));
  };

  /* 
    JSX
  */
  return (
    <div className="sidebar fixed z-[20] top-0 left-0 bg-customBlue w-[18vw] min-h-screen border-r border-gray-300">
      <div className="flex items-center justify-start p-4">
        <img src={logo} alt="Logo" className="h-30 w-50 mt-2" />
      </div>
      <ul className="p-4">{renderLinks(links)}</ul>
    </div>
  );
};

export default Sidebar;
