import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { useQuery } from '@tanstack/react-query';
import { getAllVendor } from '../../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import DateFormat from '../../../super-admin/utils/DateFormat';

// Dummy data for brands and vendors
const brands = [
  { id: 1, name: 'Brand A' },
  { id: 2, name: 'Brand B' },
  { id: 3, name: 'Brand C' }
];

const vendors = [
  { id: 1, name: 'Vendor X' },
  { id: 2, name: 'Vendor Y' },
  { id: 3, name: 'Vendor Z' }
];

// Validation schema
const validationSchema = yup.object().shape({
  vendor_id: yup.number().required('Vendor is required')
});

const RequestQuotation = ({ onClose, onSubmit, getByIdrequiremnt, isSubmitting }) => {
  const { id: requirementId } = useParams();
  console.log(requirementId, getByIdrequiremnt);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm();

  const { data: allVendors, refetch: refetchVendors } = useQuery({
    queryKey: ['vendor'],
    queryFn: getAllVendor
  });

  console.log({ allVendors });

  const onSubmitForm = (data) => {
    console.log({ data });

    let payload = {
      requirement_id: requirementId,
      quotations: data?.quotations?.map((item) => ({ vendor_id: item.id }))
    };

    // console.log(payload);

    onSubmit(payload); // Call the passed onSubmit function with form data
    reset();
  };
  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Request Quotation
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between border p-2 bg-slate-200 rounded-lg">
          <h3>Delivery Date</h3>
          <h3>{DateFormat(getByIdrequiremnt.delivery_date)}</h3>
        </div>

        <div className="w-full flex flex-col gap-4">
          <label className="text-gray-600">Select Vendors</label>
          <Controller
            name="quotations"
            control={control}
            // rules={{ required: 'At least one company must be selected' }}
            render={({ field }) => (
              <Multiselect
                options={allVendors?.data
                  ?.filter((item) => item?.category?.name == getByIdrequiremnt?.category.name)
                  ?.map((item) => ({
                    id: item?.user?.id,
                    name: item?.user?.name.trim()
                  }))}
                selectedValues={field.value}
                onSelect={(selectedList) => field.onChange(selectedList)}
                onRemove={(selectedList) => field.onChange(selectedList)}
                displayValue="name"
                placeholder="Select Vendors"
                style={{
                  optionContainer: {
                    border: '1px solid',
                    backgroundColor: 'white'
                  },
                  option: {
                    color: 'black',
                    backgroundColor: 'white'
                  },
                  chips: {
                    background: '#2A565E',
                    color: 'white'
                  }
                }}
              />
            )}
          />
          {errors.quotations && <span className="text-red-500">{errors.quotations.message}</span>}
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Send
          </Button>
        </div>
      </form>
    </>
  );
};

export default RequestQuotation;
