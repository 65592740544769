import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store';
import PageNotAccessible from './modules/shared/pages/PageNotAccessible';

const RootComponent = () => {
  const [isAccessible, setIsAccessible] = useState(window.innerWidth >= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsAccessible(window.innerWidth >= 1000);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isAccessible ? (
    <Provider store={store}>
      <App />
    </Provider>
  ) : (
    <PageNotAccessible />
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);
