import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Table from '../../shared/components/layout/Table';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import filter from '../../../modules/shared/assets/svg/filter.svg';
import Header from '../../shared/components/layout/Header';
import Layout from '../components/layout/AppLayout';
import Search from '../../shared/components/ui/Search';
import LeadsData from '../static/data/leads.json';
import { transformData } from '../../super-admin/utils/transformData';
import { useNavigate } from 'react-router-dom';
import { getAllLead, getLeadByID, getLeadsByStatus } from '../services/api/implementation/impl';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';
import Modal from '../../shared/components/ui/Modal';
import AddSalesTarget from '../components/forms/AddSalesTarget';

export const SalesList = () => {
  const [LeadId, setLeadId] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [secondaryTab, setSecondaryTab] = useState('Overview');
  const [openAddTargetModal, setOpenAddTargetModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  /* 
      Hooks
    */
  const navigate = useNavigate();

  const userId = Cookies.get('userId');

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  // const {
  //   data: leads,
  //   refetch: refetchLeads,
  //   isFetching
  // } = useQuery({
  //   queryKey: ['lead', page],
  //   queryFn: () => getAllLead(page, limit),
  //   keepPreviousData: true
  // });
  const status = 'Active';
  const {
    data: leads,
    refetch: refetchLeads,
    isFetching
  } = useQuery({
    queryKey: ['lead', page, status],
    queryFn: () => getLeadsByStatus(status, page, limit),
    keepPreviousData: true
  });

  const totalPages = leads?.meta ? Math.ceil(leads?.meta.total / limit) : 1;

  console.log({ leads, userId });

  const Leads = leads?.data?.filter((item) => item.status !== 'Closed');

  console.log({ Leads });

  const handleChangeSecondaryTab = useCallback((tabName) => {
    setSecondaryTab(tabName);
  }, []);

  console.log('leads', leads);

  /* 
      Meta data
    */
  const leadsFormat = [
    'name',
    'phone_number',
    'email',
    'customer_type',
    // 'date_of_birth',
    // 'age',
    'address',
    'reference',
    'actions',
    'id'
  ];

  useEffect(() => {
    setFilteredData(Leads);
  }, [leads]);

  const leadsData = filteredData?.map((el) =>
    transformData(
      { ...el.user, address: el.address.street, reference: el.reference, id: el.id },
      leadsFormat
    )
  );

  const { mutate: handleViewSalesMutation, isLoading: isLoadingViewSales } = useMutation({
    mutationFn: () => getLeadByID(LeadId),
    onSuccess: async () => {
      navigate(`/builder/sales/${LeadId}`);
      await refetchLeads(); // UI data update
    },
    onError: (err) => {
      console.error('Error get lead by Id');
      toast.error('Error get lead by Id');
    }
  });
  const handleViewSales = (id) => {
    setLeadId(id);
    handleViewSalesMutation();
  };

  const handleToggleAddUser = () => {
    setOpenAddTargetModal(!openAddTargetModal);
  };

  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const lowerCaseQuery = incomingQuery.toLowerCase();

        const filteredLeads = Leads?.filter((lead) => {
          const user = lead.user;
          const address = lead.address;

          return (
            (user &&
              (user.name?.toLowerCase().includes(lowerCaseQuery) ||
                user.email?.toLowerCase().includes(lowerCaseQuery) ||
                user.phone_number?.toLowerCase().includes(lowerCaseQuery) ||
                user.customer_type?.toLowerCase().includes(lowerCaseQuery))) ||
            lead.reference?.toLowerCase().includes(lowerCaseQuery) ||
            (address && address.street?.toLowerCase().includes(lowerCaseQuery))
          );
        });

        setFilteredData(filteredLeads);
      } else {
        setFilteredData(Leads);
      }
    },
    [Leads]
  );

  const handleOnClickSearch = () => {
    const filtered = Leads?.user?.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    setSearchQuery('');
  };

  const handlePageChange = (newPage) => {
    setFilteredData([]);
    setSearchQuery('');
    setPage(newPage);
  };

  /*  
      JSX
    */
  return (
    <div className="w-full flex flex-col absolute  z-5  bg-gray-100 pb-10 overflow-hidden">
      {openAddTargetModal && (
        <Modal width={40} name="Add Target" onClose={handleToggleAddUser}>
          <AddSalesTarget onSubmit={''} onClose={handleToggleAddUser} />
        </Modal>
      )}
      {Leads && Leads?.length > 0 && (
        <div className="flex z-40 items-center justify-between px-6 py-4 bg-white fixed top-[9rem] border-t-[2px] border-stone-100 left-[18vw] w-[82vw] shadow-sm">
          {/* Pass searchQuery and handleSearchChange as props to Search component */}
          <Search onChange={handleSearchChange} query={searchQuery} onClick={handleOnClickSearch} />
          <div className="flex gap-5 cursor-pointer px-3">
            <div>{/* <img className="" src={filter} alt="" /> */}</div>
            {/* <Button children={'Create'} /> */}
            <Button displayType="full-w-secondary" onClick={() => setOpenAddTargetModal(true)}>
              Set Targets
            </Button>
          </div>
        </div>
      )}
      {Leads && Leads?.length > 0 ? (
        <div className="mt-[15rem] left-0 w-full h-full overflow-clip bg-gray-100 ">
          <GridWrapper width={100}>
            <div className=" h-[28rem] overflow-scroll hide-scrollbar relative">
              <Table
                headers={[
                  'Name',
                  'Phone',
                  'Email',
                  'Customer Type',
                  // 'Date of Birth',
                  // 'Age',
                  'Address',
                  'Reference',
                  'Action'
                ]}
                data={leadsData}
                onView={handleViewSales}
                isDeleteEnable={false}
                isViewEnable={true}
                isEditEnable={false}
                identifier={'id'}
              />
              <PaginationComponent
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                isFetching={isFetching}
              />
            </div>
          </GridWrapper>
        </div>
      ) : (
        <div className="pt-[3rem]">
          <NoDataFound disabledBtn screenHeight={true} />
        </div>
      )}
    </div>
  );
};
