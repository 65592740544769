import React, { useEffect, useState } from 'react';
import Button from '../../shared/components/ui/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createPaymentStages,
  deletePaymentStages,
  getAllBlocks,
  getByIdPaymentStages,
  getPaymentStages,
  updatePaymentStages
} from '../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import Table from '../../shared/components/layout/Table';
import { useForm } from 'react-hook-form';
import { transformData } from '../../super-admin/utils/transformData';
import Modal from '../../shared/components/ui/Modal';
import AddPaymentStage from '../components/forms/AddPaymentStage';
import toast from 'react-hot-toast';
import EditPaymentStages from './EditPaymentStages';

const PaymentStagesDetails = () => {
  const { id: projectId } = useParams();
  const { register } = useForm();
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const [isOpenPaymentStageModal, setIsOpenPaymentStagesModal] = useState(false);
  const [isOpenEditPaymentStageModal, setIsOpenEditPaymentStagesModal] = useState(false);
  const [paymentStagesId, setpaymentStagesId] = useState();
  const [Id, setId] = useState();
  const [isCompletedPayment, setIsCompletedPayment] = useState(false);
  const [paymentConfirmId, setpaymentConfirmId] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: blocksDyna,
    refetch: refetchBlocks,
    error: blocksError
  } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId,
    onError: (error) => {
      console.error('Error fetching blocks:', error);
      toast.error('Failed to fetch blocks');
    }
  });

  const {
    data: AllPaymentStages,
    refetch: refetchpaymentstages,
    error: paymentStagesError
  } = useQuery({
    queryKey: ['payment_stages', selectedBlockId],
    queryFn: () => getPaymentStages(selectedBlockId),
    enabled: !!selectedBlockId,
    onError: (error) => {
      console.error('Error fetching payment stages:', error);
      toast.error('Failed to fetch payment stages');
    }
  });

  useEffect(() => {
    console.log('Selected Block ID:', selectedBlockId);
    console.log('All Payment Stages:', AllPaymentStages);
  }, [selectedBlockId, AllPaymentStages]);

  const PaymentFormat = ['id', 'name', 'percentage', 'actions'];

  const handlePaymentStagesFormat = (item) => {
    console.log(item);

    return {
      id: item.id,
      name: item.name,
      percentage: item.percentage,
      actions: item.is_completed == true ? 'Completed' : 'Actions'
    };
  };

  const paymentdata =
    AllPaymentStages?.map((item) =>
      transformData(handlePaymentStagesFormat(item), PaymentFormat)
    ) || [];

  console.log({ paymentdata });

  const handleSelectBlockChange = (event) => {
    const newSelectedBlockId = event.target.value;
    console.log('New Selected Block ID:', newSelectedBlockId);
    setSelectedBlockId(newSelectedBlockId);
  };

  const handleCreateNewPaymentStages = () => {
    setIsOpenPaymentStagesModal(!isOpenPaymentStageModal);
  };

  const { mutate: handleOnPaymentStage } = useMutation({
    mutationFn: (payload) => createPaymentStages(selectedBlockId, payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Payment Stages created Successfully');
      setIsOpenPaymentStagesModal(false);
      refetchpaymentstages();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error in payment stages:', error);
      toast.error('Error in Creating Payment Stages');
    }
  });

  const { mutate: handleOnDeletePaymentStage } = useMutation({
    mutationFn: (id) => deletePaymentStages(id),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Payment Stage Deleted Successfully');
      refetchpaymentstages();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error in payment stage delete:', error);
      toast.error('Error in Deleting Payment Stage');
    }
  });

  const { mutate: handleOnEditPaymentStage } = useMutation({
    mutationFn: (payload) => updatePaymentStages(payload, Id),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Payment Stage Updated Successfully');
      setIsOpenEditPaymentStagesModal(false);
      refetchpaymentstages();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error in updating payment stage:', error);
      toast.error('Error in Updating Payment Stage');
    }
  });

  const { mutate: handleMarkupdate } = useMutation({
    mutationFn: (payload) =>
      updatePaymentStages({ is_completed: true }, parseInt(paymentConfirmId)),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Payment is Completed');
      refetchpaymentstages();
      setIsCompletedPayment(false);
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error in updating payment stage:', error);
      toast.error('Error in completed payment');
    }
  });

  const handleConfimation = (id) => {
    setpaymentConfirmId(id);
    setIsCompletedPayment(!isCompletedPayment);
  };

  const handleEditPayment = (id) => {
    setIsOpenEditPaymentStagesModal(true);
    setId(id);
    getByIdPaymentStages(parseInt(id))
      .then((res) => setpaymentStagesId(res))
      .catch((err) => {
        console.error('Error fetching payment stage details:', err);
        toast.error('Failed to fetch payment stage details');
      });
  };

  useEffect(() => {
    if (blocksDyna) {
      const blocksWithLevels = blocksDyna.filter((block) => block?.levels?.length > 0);
      const sorted = blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        setSelectedBlockId(sorted[0].id);
        console.log('Initial block selected:', sorted[0].id);
      }
      refetchpaymentstages();
    }
  }, [blocksDyna]);

  if (blocksError) {
    return <div>Error loading blocks: {blocksError.message}</div>;
  }

  return (
    <>
      {isOpenPaymentStageModal && (
        <Modal
          name={'Payment Stages'}
          width={55}
          onClose={() => setIsOpenPaymentStagesModal(false)}>
          <AddPaymentStage
            isSubmitting={isSubmitting}
            handleOnPaymentStage={handleOnPaymentStage}
            onClose={() => setIsOpenPaymentStagesModal(false)}
          />
        </Modal>
      )}

      {isOpenEditPaymentStageModal && (
        <Modal
          name={'Edit Payment Stages'}
          width={55}
          onClose={() => setIsOpenEditPaymentStagesModal(false)}>
          <EditPaymentStages
            handleOnEditPaymentStage={handleOnEditPaymentStage}
            paymentStagesId={paymentStagesId}
            isSubmitting={isSubmitting}
            allPaymentStages={AllPaymentStages}
            onClose={() => setIsOpenEditPaymentStagesModal(false)}
          />
        </Modal>
      )}

      {isCompletedPayment && (
        <Modal name={''} width={55} onClose={() => setIsCompletedPayment(false)}>
          <div>
            <div className="flex justify-center mb-8 text-xl font-semibold">
              <p>Do you want to mark this Completed ?</p>
            </div>
            <div className="flex gap-8 justify-center">
              <Button displayType={'secondary'} onClick={() => setIsCompletedPayment(false)}>
                Cancel
              </Button>
              <Button onClick={handleMarkupdate}>Confirm</Button>
            </div>
          </div>
        </Modal>
      )}

      <div className="w-full flex flex-col absolute top-[16rem] z-10 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden">
        <div className="flex justify-between items-center">
          <div className="mt-5 flex flex-col gap-2">
            <label htmlFor="">Select Block</label>
            {blocksDynaWithLevels && blocksDynaWithLevels.length > 0 && (
              <select
                className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
                {...register(`table_type`)}
                value={selectedBlockId || ''}
                onChange={handleSelectBlockChange}>
                {blocksDynaWithLevels?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="mt-14">
            <Button onClick={handleCreateNewPaymentStages}>Create New</Button>
          </div>
        </div>
        <div className="h-[20rem] mt-10">
          {paymentStagesError ? (
            <div className="flex justify-center">No Data Found</div>
          ) : (
            <Table
              headers={['Payment Stages', 'Payment Percentage', 'Action']}
              data={paymentdata}
              isMarkEnable={true}
              identifier={'id'}
              onMark={(id) => handleConfimation(id)}
              onEdit={(id) => handleEditPayment(id)}
              onDelete={(id) => handleOnDeletePaymentStage(id)}
              isEditEnable={true}
              isDeleteEnable={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentStagesDetails;
