import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Multiselect from 'multiselect-react-dropdown';
import Button from '../../../shared/components/ui/Button';
import { getAllBlocks } from '../../services/api/implementation/impl';

export const CreatePLC = ({ handleListPLC, onClose, isSubmitting }) => {
  const { id: projectId } = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      block_ids: []
    }
  });

  const { data: allBlocks, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  useEffect(() => {
    refetchBlocks();
  }, [projectId, refetchBlocks]);

  const blockOptions =
    allBlocks
      ?.filter((item) => item?.levels?.length > 0)
      ?.map((item) => ({ name: item.name, id: item.id })) || [];

  const onSubmit = (data) => {
    // Transform the selected blocks back to an array of IDs
    const transformedData = {
      ...data,
      block_ids: data.block_ids?.map((block) => block.id)
    };
    handleListPLC(transformedData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Accommodation Details
        </span>
      </div>
      <div className="grid grid-cols-2 gap-3 mt-6">
        {/* PLC Type Name */}
        <div>
          <label className="block mb-2">PLC Type Name *</label>
          <input
            {...register('name', {
              required: 'PLC Type Name is required',
              pattern: {
                value: /^\S.*$/,
                message: 'PLC Type Name cannot be empty or contain only spaces'
              }
            })}
            className="w-full p-2 border rounded"
          />
          {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
        </div>

        {/* PLC Description */}
        <div>
          <label className="block mb-2">PLC Description *</label>
          <input
            {...register('description', { required: 'PLC Description is required' })}
            className="w-full p-2 border rounded"
          />
          {errors.description && (
            <span className="text-red-500 text-sm">{errors.description.message}</span>
          )}
        </div>

        {/* Associated Block */}
        <div>
          <label className="block mb-2">Associated Block *</label>
          <Controller
            name="block_ids"
            control={control}
            defaultValue={[]}
            rules={{
              validate: (value) =>
                value && value.length > 0 ? true : 'At least one block must be selected'
            }}
            render={({ field }) => (
              <>
                <Multiselect
                  options={blockOptions}
                  displayValue="name"
                  onSelect={field.onChange}
                  onRemove={field.onChange}
                  selectedValues={field.value}
                  style={{
                    optionContainer: {
                      border: '1px solid',
                      backgroundColor: 'white'
                    },
                    option: {
                      color: 'black',
                      backgroundColor: 'white'
                    },
                    chips: {
                      background: '#2A565E',
                      color: 'white'
                    },
                    groupHeading: {
                      fontWeight: 'bold'
                    }
                  }}
                />
                {/* Show error for block_ids */}
                {errors.block_ids && (
                  <span className="text-red-500 text-sm">{errors.block_ids.message}</span>
                )}
              </>
            )}
          />
        </div>
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Proceed
        </Button>
      </div>
    </form>
  );
};
