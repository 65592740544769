import React, { useState } from 'react';
import TableHead from '../ui/TableHead';
import TableRow from '../ui/TableRow';
import EditTableRow from '../ui/EditTableRow';
import { useForm } from 'react-hook-form';

function EditTable({
  isFor,
  name,
  headers,
  data,
  handleRowClick,
  isDownloadable,
  sortOptions,
  isFilterable,
  identifier,
  isDeleteEnable,
  isViewEnable,
  isEditEnable,
  onEdit,
  onDelete,
  onView,
  bigHeaders,
  dataFormat,
  onRegister,
  onType,
  editTableType,
  blockType,
}) {
  /* 
    Local state
  */
  const [query, setQuery] = useState('');

  /* 
    Computation
  */
  const width = 100 / headers.length >= 25 ? 100 / headers.length - 1 : 100 / headers.length;

  function handleQueryChange(e) {
    const targetValue = e.target.value;
    setQuery(targetValue);
  }

  // console.log('data editTable', data);

  return (
    <div className="w-full h-full bg-white mb-4 rounded-xl relative">
      <div role="table" className="table-container h-full" style={{ position: 'relative' }}>
        <div
          className="w-full justify-between bg-gray-200 p-3 flex !text-gray-600 rounded-t-xl"
          style={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}>
          {headers?.map((item) => (
            <TableHead key={item} name={item} width={width} />
          ))}
        </div>

        <form
          className={`md:block absolute top-12 bg-white w-full h-full table-body rounded-b-lg ${bigHeaders && 'mt-[2rem]'}`}
          style={{ overflowY: 'auto', maxHeight: '100%', paddingBottom: '3.2rem' }}>
          {data?.map((item, index) => (
            <EditTableRow
              key={index}
              data={item}
              width={width}
              identifier={identifier}
              handleRowClick={
                handleRowClick
                  ? handleRowClick
                  : () => console.log('No row click handler assigned!')
              }
              isDeleteEnable={isDeleteEnable}
              isViewEnable={isViewEnable}
              isEditEnable={isEditEnable}
              onView={onView}
              onEdit={onEdit}
              onDelete={onDelete}
              dataFormat={dataFormat}
              onRegister={onRegister}
              headers={headers}
              rowIndex={index}
              onType={onType}
              editTableType={editTableType}
              blockType={blockType}
            />
          ))}
        </form>
      </div>
    </div>
  );
}

export default EditTable;
