import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GridWrapper from '../../shared/components/layout/GridWrapper';

import Button from '../../shared/components/ui/Button';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';

import user from '../../shared/assets/svg/organisation.svg';

import Modal from '../../shared/components/ui/Modal';
import EditCompany from '../components/forms/EditCompany';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAllUsers, getCompanyByID, updateCompany } from '../services/api/implementation/impl';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { transformData } from '../../super-admin/utils/transformData';
import { BsFiletypePdf } from 'react-icons/bs';
import { AiOutlineFileImage } from 'react-icons/ai';
import { FaRegFile } from 'react-icons/fa';

/*
  Helper Functions for Documents
*/
const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return <BsFiletypePdf size={40} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <AiOutlineFileImage size={40} />;
    default:
      return <FaRegFile size={40} />; // Default icon for other file types
  }
};

const truncateFileName = (fileName, maxLength = 15) => {
  if (!fileName) return 'Unnamed File';
  if (fileName.length <= maxLength) return fileName;
  const extension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
  return `${nameWithoutExtension.slice(0, maxLength - 3)}...${extension}`;
};

/*
  Document Renderer Component
*/
const DocumentRenderer = ({ title, documents }) => {
  if (!documents || (Array.isArray(documents) && documents.length === 0)) return null;

  // Normalize documents to an array of { name, url }
  let normalizedDocs = [];

  if (Array.isArray(documents)) {
    normalizedDocs = documents.flatMap((doc) => {
      if (typeof doc === 'string') {
        // If doc is a URL string without a name
        return [{ name: doc.split('/').pop(), url: doc }];
      } else if (doc.name && doc.url) {
        return [{ name: doc.name, url: doc.url }];
      } else {
        return [];
      }
    });
  } else if (typeof documents === 'object' && documents !== null) {
    // Single document object
    if (documents.name && documents.url) {
      normalizedDocs.push({ name: documents.name, url: documents.url });
    } else if (documents.url) {
      normalizedDocs.push({ name: documents.url.split('/').pop(), url: documents.url });
    }
  }

  if (normalizedDocs.length === 0) return null;

  return (
    <div className="flex flex-col gap-4">
      <h3 className="border w-[20rem] text-center font-semibold p-2 rounded-lg bg-slate-600 text-lg text-white">
        {title}
      </h3>
      <div className="flex flex-wrap gap-3">
        {normalizedDocs.map((file, i) => (
          <div
            key={i}
            className="flex flex-col border w-[12rem] p-5 rounded-xl justify-center items-center bg-white">
            <a href={file.url} target="_blank" rel="noreferrer" download>
              {getFileIcon(file.name)}
            </a>
            <p className="text-center mt-2">{truncateFileName(file.name)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const CompanyDetails = () => {
  const navigate = useNavigate();
  const { id: companyId } = useParams();

  /* 
    Local state
  */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openEditCompanyModal, setOpenEditCompanyModal] = useState(false);
  const [companyDataFetched, setCompanyDataFetched] = useState(false);
  const [upLiftNum, setUpliftNum] = useState(1);

  /* 
    Hook form
  */
  const { reset } = useForm();

  /* 
    Data fetching
  */
  const {
    isLoading,
    isError,
    data: company,
    refetch: refetchCompany
  } = useQuery({
    queryKey: ['company', companyId],
    queryFn: () => getCompanyByID(companyId)
  });

  const { data: users, refetch: refetchUsers } = useQuery({
    queryKey: ['users'],
    queryFn: () => getAllUsers()
  });

  console.log({ users });

  /* 
    Filtering Users for Partners
  */
  let filterByDesignationId = users?.data?.filter((user) => user.desgignation_id === 6);

  let filteredUsers = filterByDesignationId?.filter((user) =>
    user?.company_ids?.includes(parseInt(companyId))
  );

  let partnerNames = filteredUsers?.map((user) => ({
    id: user.id,
    name: user.name,
    email: user.email,
    phone_number: user.phone_number
  }));

  const partnerFormat = ['id', 'name', 'email', 'phone_number'];

  const PartnerFormatted = partnerNames?.map((item) => transformData(item, partnerFormat));

  /* 
    Mutation for Updating Company
  */
  const { mutate: mutateCompany, isLoading: isLoadingNewUserMutation } = useMutation({
    mutationFn: (newCompany) => updateCompany(newCompany, companyId),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      toast.success('Successfully updated company');
      setIsSubmitting(false);
      console.log('Company updated successfully');
      reset();
      setOpenEditCompanyModal(!openEditCompanyModal);
      await refetchCompany(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      toast.error('Error updating company');
      console.error('Error updating company:', err);
    }
  });

  /* 
    Event handlers
  */
  const handleToggleAddCompanyModal = () => {
    setOpenEditCompanyModal(!openEditCompanyModal);
  };

  const onSubmit = (data) => {
    console.log({ data });
    // Validations here
    mutateCompany(data);
  };

  /* 
    Helper method calls
  */
  const landsFormat = ['id', 'land_code', 'khasra_number', 'total_area', 'company_name'];
  const landsFormatted = []; // Lands table removed as per user request

  /* 
    Header data
  */
  const headerData = [
    {
      _id: 1,
      name: 'CIN',
      value: company?.cin ? company?.cin : '________'
    },
    {
      _id: 2,
      name: 'TIN',
      value: company?.tin ? company?.tin : '________'
    },
    {
      _id: 3,
      name: 'TAN',
      value: company?.tan ? company?.tan : '________'
    },
    {
      _id: 4,
      name: 'PAN',
      value: company?.pan ? company?.pan : '________'
    },
    {
      _id: 5,
      name: 'GST',
      value: company?.gst ? company?.gst : '________'
    },
    {
      _id: 6,
      name: 'Address',
      value: company?.address ? company?.address : '________'
    }
  ];

  console.log('Uplift num: ', upLiftNum);

  /* 
    Effects
  */
  useEffect(() => {
    if (openEditCompanyModal) {
      console.log('Preventing scroll');
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [openEditCompanyModal]);

  useEffect(() => {
    refetchCompany();
  }, [refetchCompany]);

  useEffect(() => {
    if (company && !companyDataFetched) {
      // toast.success(`Successfully fetched company`);
      setCompanyDataFetched(true);
    }
  }, [company, companyDataFetched]);

  // If it's a partnership, filter out CIN and TIN
  const filteredHeaderData =
    company?.company_type?.toLowerCase() === 'partnership'
      ? headerData.filter((item) => item.name !== 'CIN' && item.name !== 'TIN')
      : headerData;

  const [selectedFilter, setSelectedFilter] = useState('Partners');

  const handleButtonClick = (filter) => {
    setSelectedFilter(filter);
  };

  /*
    Extracting Documents
  */
  const companyDocuments = [
    { title: 'Company Logos', documents: company?.company_logo },
    { title: 'Coloniser License Documents', documents: company?.coloniser_license_doc }
    // Add more document fields as needed
  ];

  /*
    JSX
  */
  return (
    <>
      {/* Edit Company Modal */}
      {openEditCompanyModal && (
        <Modal
          width={84}
          name="Edit Company Details"
          onClose={handleToggleAddCompanyModal}
          upShift={(upLiftNum + 4) * 10}>
          <EditCompany
            onSubmit={onSubmit}
            data={company}
            isSubmitting={isSubmitting}
            onUpliftChange={(numPartners) => {
              console.log('Coming num partners: ', numPartners);
              setUpliftNum(() => numPartners);
            }}
            onClose={handleToggleAddCompanyModal}
          />
        </Modal>
      )}

      <Layout>
        {/* Header */}
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header
            data={{ name: `Company > ${company?.company_name || 'Loading...'}` }}
            backArrow={true}
          />
        </div>

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="w-full flex flex-col justify-between items-center gap-8 mt-[7rem]">
            {/* Details card component */}
            <div className="p-8 rounded-md bg-white w-full shadow-md flex items-stretch justify-between gap-6">
              {company?.company_logo?.[0]?.url ? (
                <img
                  src={company.company_logo[0].url}
                  alt="company_logo"
                  className="w-[16rem] bg-center bg-cover !object-cover !object-center rounded-lg"
                />
              ) : (
                <div className="bg-gray-300 rounded-xl w-[16rem] flex justify-center items-center">
                  <img
                    src={user} // Default image
                    alt="default_user_image"
                    className="w-[8rem] bg-center bg-cover !object-cover !object-center"
                  />
                </div>
              )}

              <div className="flex-1 flex flex-col gap-8">
                <div className="flex justify-between items-start">
                  <div className="flex flex-col gap-4">
                    <span className="font-semibold text-xl">{company?.company_name}</span>
                    <span className="text-gray-400 text-sm">{`${company?.company_type} Company`}</span>
                  </div>

                  <div className="flex space-x-4">
                    <Button displayType="secondary" onClick={handleToggleAddCompanyModal}>
                      Edit
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(`/builder/management/company/${company?.id}/projects`)
                      }>
                      View Projects
                    </Button>
                  </div>
                </div>

                <div className="w-[88%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {filteredHeaderData?.map((item) => (
                    <div className="flex flex-col gap-1" key={item?._id}>
                      <span className="font-medium">{item?.name}</span>
                      <span className="text-sm text-gray-600">{item?.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Partners and Documents Section */}
            <div className="p-6 rounded-md bg-white w-full shadow-md flex flex-col gap-10 pb-10">
              {/* Partners and Documents */}
              <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                  <span className="text-gray-700 font-semibold">{selectedFilter ? selectedFilter : "Partners"}</span>
                  <div className="button-group flex rounded-lg ">
                    <div
                      className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${
                        selectedFilter === 'Partners' ? `bg-customBlue text-white` : 'text-textC'
                      } rounded-tl rounded-bl`}
                      onClick={() => handleButtonClick('Partners')}>
                      Partners
                    </div>
                    <div
                      className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${
                        selectedFilter === 'Documents' ? `bg-customBlue text-white` : 'text-textC'
                      }`}
                      onClick={() => handleButtonClick('Documents')}>
                      Documents
                    </div>
                  </div>
                </div>

                {selectedFilter === 'Partners' ? (
                  <div className="h-[20rem] overflow-auto">
                    <Table
                      headers={['Name', 'Email', 'Phone']}
                      identifier={'id'}
                      data={PartnerFormatted}
                    />
                  </div>
                ) : (
                  <div className="w-full flex flex-col gap-10 p-5 bg-gray-100 rounded-md shadow-inner">
                    {/* Documents Section */}
                    <div className="flex flex-col gap-10">
                      {companyDocuments.map((docSection, index) => (
                        <DocumentRenderer
                          key={index}
                          title={docSection.title}
                          documents={docSection.documents}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default CompanyDetails;
