import React, { useEffect } from 'react';

const GridWrapper = ({ children, width, isModal, childrenCustomStyle }) => {
  return (
    <div className="w-full justify-center items-center">
      <div className={`w-[${width}%] justify-between items-center m-auto`}>
        <div className={childrenCustomStyle ? childrenCustomStyle : 'm-6'} style={{childrenCustomStyle}}>{children}</div>
      </div>
    </div>
  );
};

export default GridWrapper;
