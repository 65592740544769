// components/TDS.jsx
import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Papa from 'papaparse'; // Import papaparse for CSV generation

import Table from '../../shared/components/layout/Table';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import Search from '../../shared/components/ui/Search';
import FileDownload from '../../../modules/shared/assets/svg/download.svg';
import NoDataFound from '../components/layout/NoDataFound';
import PaginationComponent from '../../shared/components/PaginationComponent';
import { transformData } from '../../super-admin/utils/transformData';
import { getAccountTdsReports } from '../services/api/implementation/impl';
import Button from '../../shared/components/ui/Button';

const TDS = () => {
  /*
   * -------------------------------------------------
   * Component State
   * -------------------------------------------------
   */
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Items per page
  const [isDownloading, setIsDownloading] = useState(false); // State for download status
  const [dotCount, setDotCount] = useState(0); // State for dots animation

  /*
   * -------------------------------------------------
   * Fetching TDS Reports from the API (Server-Side Pagination)
   * -------------------------------------------------
   */
  const {
    data: tdsResponse,
    isFetching: isFetchingTDS,
    isError,
    error,
  } = useQuery({
    queryKey: ['tdsReports', page, limit],
    queryFn: () => getAccountTdsReports(page, limit),
    keepPreviousData: true,
  });

  // If your API returns { data: [...], meta: {...} }
  const allTDSReports = tdsResponse?.data || [];
  const totalItems = tdsResponse?.meta?.total || 0;
  const totalPages = Math.ceil(totalItems / limit);

  /*
   * -------------------------------------------------
   * Transform the Data for Table
   * -------------------------------------------------
   */
  const tdsDataFormat = ['id', 'VendorName', 'BillAmount', 'TDS'];

  const processedTDSReports = allTDSReports.map((report) => ({
    id: report.id || 'no-id',
    VendorName: report.name || 'N/A',
    BillAmount: report.total_amount !== undefined ? `₹ ${report.total_amount}` : '₹ 0',
    TDS: report.tds !== undefined ? `₹ ${report.tds}` : '₹ 0',
  }));

  const tdsData = processedTDSReports.map((row) =>
    transformData(row, tdsDataFormat)
  );

  /*
   * -------------------------------------------------
   * Handlers
   * -------------------------------------------------
   */
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  /**
   * Handle Download CSV using papaparse
   */
  const handleDownload = async () => {
    if (isDownloading) return; // Prevent multiple clicks

    setIsDownloading(true);
    try {
      // Fetch all data with page=1 and limit=1000
      const response = await getAccountTdsReports(1, 1000);
      const tdsReports = response?.data || [];

      if (tdsReports.length === 0) {
        alert('No data available to download.');
        return;
      }

      // Define CSV headers
      const headers = ['Vendor Name', 'Bill Amount', 'TDS'];

      // Map data to CSV rows as objects without the currency symbol
      const rows = tdsReports.map((report) => ({
        'Vendor Name': report.name || 'N/A',
        'Bill Amount': report.total_amount !== undefined ? report.total_amount : 0,
        'TDS': report.tds !== undefined ? report.tds : 0,
      }));

      // Convert to CSV using papaparse
      const csv = Papa.unparse({
        fields: headers,
        data: rows,
      });

      // Create a Blob from the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', `tds_reports.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (downloadError) {
      console.error('Download failed:', downloadError);
      alert('Failed to download the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  /*
   * -------------------------------------------------
   * Dots Animation Effect
   * -------------------------------------------------
   */
  useEffect(() => {
    let interval;
    if (isDownloading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
      }, 500);
    } else {
      setDotCount(0);
    }

    return () => clearInterval(interval);
  }, [isDownloading]);

  const renderDownloadingText = () => {
    return `Downloading${'.'.repeat(dotCount)}`;
  };

  /*
   * -------------------------------------------------
   * Render
   * -------------------------------------------------
   */
  return (
    <div className="w-full flex flex-col relative z-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Top Bar: Search, Download */}
      <div className="flex items-center justify-between px-4 py-4 bg-white fixed top-[9.5rem] border-t-2 border-stone-100 left-[18vw] w-[82vw] shadow-sm">
        {/* Uncomment the Search component if needed */}
        {/* <Search placeholder="Search here..." /> */}
        <div></div>
        <div className="flex items-center gap-3 px-4">
          <button
            className={`relative flex items-center justify-center ${
              isDownloading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleDownload}
            disabled={isDownloading}
            aria-label="Download CSV"
          >
            <img
              height={35}
              width={35}
              src={FileDownload}
              alt="File Download Icon"
            />
            {isDownloading && (
              <span className="absolute top-10 left-0 text-sm text-gray-700">
                {renderDownloadingText()}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* TDS Reports Table */}
      <div className="mt-24 w-full h-full">
        {isFetchingTDS ? (
          <div className="pt-12 text-center">
            <p>Loading...</p>
          </div>
        ) : isError ? (
          <div className="pt-12 text-center text-red-500">
            <p>Error: {error.message}</p>
          </div>
        ) : tdsData && tdsData.length > 0 ? (
          <GridWrapper width={100}>
            <div className="mt-[10rem] h-[28rem] relative">
              <Table
                headers={['Vendor Name', 'Bill Amount', 'TDS']}
                data={tdsData}
                identifier="id"
                isViewEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />

              <PaginationComponent
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                isFetching={isFetchingTDS}
              />
            </div>
          </GridWrapper>
        ) : (
          <div className="pt-12">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TDS;
