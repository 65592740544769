import { formatIndianCurrency } from '../../shared/utils/CurrencyFormat';
import DateFormat from './DateFormat';
import { capitaliseData } from './splitData';

export function transformData(data, format) {
  let transformedData = {};
  format.forEach((item) => {
    if (item === 'actions') {
      transformedData[item] = 'Actions';
    } else if (Object.prototype.hasOwnProperty.call(data, item)) {
      transformedData[item] = data?.[item];
    }
    if (item === 'brandList') {
      transformedData[item] = 'BrandList';
    } else if (Object.prototype.hasOwnProperty.call(data, item)) {
      transformedData[item] = data?.[item];
    }
    if (item === 'itemList') {
      transformedData[item] = 'ItemList';
    } else if (Object.prototype.hasOwnProperty.call(data, item)) {
      transformedData[item] = data?.[item];
    }
    if (item === 'notes') {
      transformedData[item] = 'notes';
    } else if (Object.prototype.hasOwnProperty.call(data, item)) {
      transformedData[item] = data?.[item];
    }
    if (
      item.includes('amount') ||
      item.includes('budget') ||
      item.includes('cost') ||
      item.includes('rate') ||
      item.includes('unitPrice') ||
      item.includes('plc_price')
    ) {
      transformedData[item] =
        data?.[item] && data?.[item]?.length !== 0
          ? formatIndianCurrency(` ${data?.[item]}`)
          : '₹ 0';
    }
    if (item.includes('status') && typeof data?.[item] === 'string') {
      transformedData[item] = `${capitaliseData(data?.[item])}`;
    }
    // Remove further
    if (item.includes('user_status') && !data?.[item]) {
      transformedData[item] = `Unpaid`;
    }
    if (item.includes('created_at')) {
      transformedData[item] = DateFormat(data?.['created_at']);
    }
    if (item.includes('accommodation-name')) {
      transformedData[item] = data.accommodation ? data?.accommodation['name'] : '';
    }
  });

  return transformedData;
}
