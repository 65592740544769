import React, { useCallback, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { state_arr } from '../../static/data/statesAndCities';
import { getStateAndCity } from '../../services/api/implementation/impl';
import toast from 'react-hot-toast';

const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const gstRegex = /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/;
const noSpaceRegex = /^\S*$/; // No spaces allowed
const companyNameRegex = /^[A-Za-z\s!@#$%^&*()_+\-=[\]{};:'",.<>?]+$/;
const pincodeRegex = /^[0-9]{6}$/; // Only 6 digits allowed

const validationSchema = yup.object().shape({
  company_name: yup
    .string()
    .required('Company name is required')
    .matches(companyNameRegex, 'Company name should only contain letters')
    .max(50, 'Company name should be at most 50 characters'),
  company_type: yup.string().required('Company type is required'),
  zip_code: yup
    .string()
    .required('Zip/Pin code is required')
    .matches(pincodeRegex, 'Zip/Pin code should be exactly 6 digits'),
  country: yup.string().required('Country is required').oneOf(['India'], 'Country must be India'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  pan: yup
    .string()
    .required('PAN is required')
    .matches(panRegex, 'Invalid PAN format')
    .matches(noSpaceRegex, 'PAN should not contain spaces'),
  gst: yup
    .string()
    .required('GST is required')
    .matches(gstRegex, 'Invalid GST format')
    .matches(noSpaceRegex, 'GST should not contain spaces')
});

const AddCompany = ({ onSubmit, onClose, isSubmitting }) => {
  const [city, setCity] = useState('Select');
  const [state, setState] = useState('-');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);
  const [tehsilDropdownOptions, setTehsilDropdownOptions] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handlePincodeChange = useCallback(
    async (pincode) => {
      const cleanedPincode = pincode.replace(/[^0-9]/g, '');
      setValue('zip_code', cleanedPincode);

      if (cleanedPincode.length === 6) {
        try {
          const pincodeList = await getStateAndCity(cleanedPincode);
          console.log(pincodeList);
          const postOfficeList = pincodeList[0].PostOffice;

          if (postOfficeList.length > 0) {
            const newState = postOfficeList[0].State;
            const uniqueCircles = [
              ...new Set(
                postOfficeList
                  ?.map((office) => {
                    const circle =
                      office.Name && office.Name !== 'NA' && office.Name.trim() !== ''
                        ? office.Name
                        : office.Block;
                    return circle && circle !== 'NA' && circle.trim() !== '' ? circle : null;
                  })
                  .filter(Boolean)
              )
            ];
            const uniqueTehsils = [...new Set(postOfficeList?.map((office) => office.Block))];

            console.log(uniqueCircles);
            const newCityOptions = uniqueCircles?.map((circle) => ({
              value: circle,
              label: circle
            }));
            setCityDropdownOptions(
              uniqueTehsils?.map((tehsil) => ({ value: tehsil, label: tehsil }))
            );
            const newCity = uniqueCircles[0];

            setValue('state', newState);
            // setCityDropdownOptions(newCityOptions);
            setValue('city', uniqueTehsils[0]);

            setState(newState);
            setCity(newCity);
          } else {
            setValue('state', '-');
            setValue('city', 'Select');
            setCityDropdownOptions([]);

            setState('-');
            setCity('Select');
          }
        } catch (error) {
          toast.error('The entered pincode is invalid. Please enter a valid pincode.');
          setValue('state', '-');
          setValue('city', 'Select');
          setCityDropdownOptions([]);

          setState('-');
          setCity('Select');
        }
      }
    },
    [setValue]
  );

  const handlePanChange = useCallback(
    async (pan) => {
      const cleanedPan = pan.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
      setValue('pan', cleanedPan);
    },
    [setValue]
  );

  const handleGSTChange = useCallback(
    async (gst) => {
      const cleanedGST = gst.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
      setValue('gst', cleanedGST);
    },
    [setValue]
  );

  const handleCompanyName = useCallback(
    async (val) => {
      const trimmedValue = val.trimStart(); // Removes leading spaces
      const sanitizedValue = trimmedValue.replace(/[^a-zA-Z\s]/g, ''); // Only allow alphabets and spaces
      setValue('company_name', sanitizedValue);
    },
    [setValue]
  );

  const handleSubmitForm = (data) => {
    const trimmedData = {
      ...data,
      company_name: data.company_name.trim() // Trim leading and trailing spaces on submit
    };
    onSubmit(trimmedData);
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Company Details</span>

        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between space-x-4">
            <FormInput
              width={46}
              register={register}
              isRequired
              register_key={'company_name'}
              label={'Company Name'}
              type={'text'}
              maxLength={50}
              errors={errors.company_name}
              onType={handleCompanyName}
            />

            <FormInput
              width={46}
              register={register}
              isRequired
              register_key={'company_type'}
              label={'Company Type'}
              type={'dropdown'}
              options={[
                { value: '', label: 'Select', disabled: true, selected: true },
                { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
                { value: 'Partnership', label: 'Partnership' },
                { value: 'Limited Liability (LLC)', label: 'Limited Liability (LLC)' },
                { value: 'Public Limited (Ltd)', label: 'Public Limited (Ltd)' },
                { value: 'Private Limited (Pvt Ltd)', label: 'Private Limited (Pvt Ltd)' },
                { value: 'Nonprofit Organization', label: 'Nonprofit Organization' },
                { value: 'Co-operative', label: 'Cooperative' },
                { value: 'Joint Venture', label: 'Joint Venture' }
              ]}
              errors={errors.company_type}
            />
          </div>
        </div>

        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between space-x-4">
            <FormInput
              width={46}
              register={register}
              isRequired
              register_key={'zip_code'}
              label={'Zip/Pin Code'}
              type={'text'}
              maxLength={6}
              errors={errors.zip_code}
              onType={handlePincodeChange}
            />

            <FormInput
              width={46}
              register={register}
              isRequired
              register_key={'country'}
              label={'Select Country'}
              valuePre={'India'}
              errors={errors.country}
              CustomStyle={{ pointerEvents: 'none' }}
            />
          </div>
        </div>

        <div className="w-full flex flex-col gap-2">
          <div className="flex justify-between space-x-4">
            <FormInput
              width={46}
              register={register}
              isRequired
              register_key={'state'}
              label={'State'}
              errors={errors.state}
              valuePre={state}
              CustomStyle={{ pointerEvents: 'none' }}
            />
            <FormInput
              width={46}
              register={register}
              isRequired
              register_key={'city'}
              label={'City'}
              defaultValuePre={city}
              type={'dropdown'}
              options={cityDropdownOptions}
              errors={errors.city}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-center">
        <FormInput
          width={46}
          register={register}
          isRequired
          register_key={'pan'}
          label={'Company PAN'}
          type={'text'}
          maxLength={10}
          // CustomStyle={{ textTransform: 'uppercase' }}
          errors={errors.pan}
          onType={handlePanChange}
          placeholder={'ABCDE1234F'}
        />

        <FormInput
          width={46}
          register={register}
          isRequired
          register_key={'gst'}
          label={'Company GST'}
          type={'text'}
          errors={errors.gst}
          onType={handleGSTChange}
          maxLength={15}
          placeholder={'27ABCDE1234F1Z5'}
        />
      </div>

      <div className="w-full flex justify-end items-center space-x-4">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
          Add
        </Button>
      </div>
    </form>
  );
};

export default AddCompany;
