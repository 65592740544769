import React, { useCallback, useEffect, useState } from 'react';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Modal from '../../shared/components/ui/Modal';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';

import PrimaryTabbedComponent from '../../shared/components/layout/PrimaryTabbedComponent';
import SecondaryTabbedComponent from '../../shared/components/layout/SecondaryTabbedComponent';

import company_image from '../../shared/assets/png/company_image.png';
import ProjectOverview from '../components/layout/ProjectOverview';
import AccommodationDetails from './AccommodationDetails';
import { AddAccommodation } from '../components/forms/AddAccommodation';
import PreviewBlockDetails from '../components/layout/PreviewBlockDetails';
import PreviewUnitDetails from '../components/layout/PreviewUnitDetails';
import { RateList } from '../../builder/pages/RateList';
import { ImportAccommodation } from '../components/forms/ImportAccommodation';
import accommodationData from '../static/data/accommodation.json';
import {
  createAccommodation,
  editAccommodation,
  getAllAccommodations,
  getProjectByID,
  getAllAccommodationsByProject
} from '../services/api/implementation/impl';
import { EditAccommodation } from '../components/forms/EditAccommodation';
import { PLCsDetails } from './PLCsDetails';
import { BlockDetails } from './BlockDetails';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { transformPayload } from '../utils/transformPayload';
import PaymentStagesList from './PaymentStagesDetails';
import PaymentStagesDetails from './PaymentStagesDetails';
import { useSelector } from 'react-redux';
import UnitPricingList from './UnitPricingList';
import { Document, ProjectDocument } from './ProjectDocument';
import { LateFee } from './LateFee';
import Cookies from 'js-cookie';

const ProjectDetails = () => {
  const { id: projectId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileUrls = useSelector((state) => state.file.fileUrls);

  console.log({ fileUrls });

  /* 
    Data fetching
  */
  const { data: project, refetch: refetchProject } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => getProjectByID(projectId)
  });

  //store project.measurement_type in cookies whever it
  useEffect(() => {
    Cookies.set('measurement_type', project?.measurement_type);
  }, [project]);

  // const { data: accommodations, refetch: refetchAccommodations } = useQuery({
  //   queryKey: ['accommodations'],
  //   queryFn: getAllAccommodations
  // });

  const { data: accommodations, refetch: refetchAccommodations } = useQuery({
    queryKey: ['accommodations', projectId],
    queryFn: () => getAllAccommodationsByProject(projectId)
  });

  const formatIndependent = [
    { type: 'integer', label: 'width' },
    { type: 'integer', label: 'length' },
    { type: 'integer', label: 'plotArea' },
    { type: 'integer', label: 'buildUpArea' },
    { type: 'integer', label: 'buildUpArea' },
    { type: 'integer', label: 'carpetArea' },
    { type: 'integer', label: 'projectId' }
  ];

  const formatGroup = [
    { type: 'integer', label: 'width' },
    { type: 'integer', label: 'length' },
    { type: 'integer', label: 'plotArea' },
    { type: 'integer', label: 'buildUpArea' },
    { type: 'integer', label: 'buildUpArea' },
    { type: 'integer', label: 'carpetArea' },
    { type: 'integer', label: 'projectId' }
  ];

  const formatParking = [
    { type: 'integer', label: 'buildUpArea' },
    { type: 'boolean', label: 'electrified' },
    { type: 'integer', label: 'projectId' }
  ];

  /* 
    Data mutation
  */
  const { mutate: handleOnSubmitIndependent, isLoading: isAddingIndependent } = useMutation({
    mutationFn: (payload) =>
      createAccommodation(
        transformPayload(
          {
            ...payload,
            buildUpArea: payload.buildUpArea || 0,
            carpetArea: payload.carpetArea || 0,
            floorPlanLink: fileUrls?.floorPlanLink,
            accomodationType: 'INDEPENDENT',
            materialListLink: fileUrls?.materialListLink,
            projectId
          },
          formatIndependent
        )
      ),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Accommodation created successfully');
      setOpenAccommodation(false);
      await refetchAccommodations(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new accommodation');
      toast.error('Error creating accommodation');
    }
  });

  const { mutate: handleOnSubmitGroup, isLoading: isAddingGroup } = useMutation({
    mutationFn: (payload) =>
      createAccommodation(
        transformPayload(
          {
            ...payload,
            superBuildUpArea: (
              parseInt(payload.buildUpArea) +
              parseInt(payload.buildUpArea) * parseInt(payload.loadingFactor)
            ).toString(),
            floorPlanLink: fileUrls?.floorPlanLink,
            accomodationType: 'GROUP',
            materialListLink: fileUrls?.materialListLink,
            projectId
          },
          formatGroup
        )
      ),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Accommodation created successfully');
      setOpenAccommodation(false);
      await refetchAccommodations(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new accommodation');
      toast.error('Error creating accommodation');
    }
  });

  const { mutate: handleOnSubmitParking, isLoading: isAddingParking } = useMutation({
    mutationFn: (payload) =>
      createAccommodation(
        transformPayload(
          {
            ...payload,
            accomodationType: 'PARKING',
            projectId
          },
          formatParking
        )
      ),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Accommodation created successfully');
      setOpenAccommodation(false);
      await refetchAccommodations(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new accommodation');
      toast.error('Error creating accommodation');
    }
  });

  // Edit independent

  const { mutate: handleOnEditSubmitIndependent, isLoading: isEditIndependent } = useMutation({
    mutationFn: (payload) =>
      editAccommodation(
        StaticId,
        transformPayload(
          {
            ...payload,
            buildUpArea: payload.buildUpArea || 0,
            carpetArea: payload.carpetArea || 0,
            floorPlanLink: fileUrls?.floorPlanLink,
            accomodationType: 'INDEPENDENT',
            materialListLink: fileUrls?.materialListLink,
            projectId
          },
          formatIndependent
        )
      ),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Accommodation Updated successfully');
      setIsOpenEditAccommodation(false);
      await refetchAccommodations(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new accommodation');
      toast.error('Error creating accommodation');
    }
  });

  // Edit Group

  const { mutate: handleOnEditSubmitGroup, isLoading: isAddingEditGroup } = useMutation({
    mutationFn: (payload) =>
      editAccommodation(
        StaticId,
        transformPayload(
          {
            ...payload,
            superBuildUpArea: (
              parseInt(payload.buildUpArea) +
              parseInt(payload.buildUpArea) * parseInt(payload.loadingFactor)
            ).toString(),
            floorPlanLink: fileUrls?.floorPlanLink,
            accomodationType: 'GROUP',
            materialListLink: fileUrls?.materialListLink,
            projectId
          },
          formatGroup
        )
      ),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Accommodation Update Group successfully');
      setIsOpenEditAccommodation(false);
      await refetchAccommodations(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new accommodation');
      toast.error('Error creating accommodation');
    }
  });

  // Edit Parking
  const { mutate: handleOnEditSubmitParking, isLoading: isAddingEditParking } = useMutation({
    mutationFn: (payload) =>
      editAccommodation(
        StaticId,
        transformPayload(
          {
            ...payload,
            accomodationType: 'PARKING',
            projectId
          },
          formatParking
        )
      ),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Accommodation created successfully');
      setIsOpenEditAccommodation(false);
      await refetchAccommodations(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new accommodation');
      toast.error('Error creating accommodation');
    }
  });

  /*
    Hooks 
  */
  const independentData = accommodationData.filter(
    (item) => item.accommodation_type === 'Independent'
  );
  const groupData = accommodationData.filter((item) => item.accommodation_type === 'Group');
  const parkingData = accommodationData.filter((item) => item.accommodation_type === 'Parking');
  /* 
    Local state 
  */
  const [primaryTab, setPrimaryTab] = useState('Details');
  const [secondaryTab, setSecondaryTab] = useState('Overview');
  const [OpenAccommodation, setOpenAccommodation] = useState(false);
  const [isOpenAddDetailsModal, setIsOpenAddDetailsModal] = useState(false);
  const [isOpenBlockDetailingModal, setIsOpenBlockDetailingModal] = useState(false);
  const [isOpenUnitDetailingModal, setIsOpenUnitDetailingModal] = useState(false);
  const [isOpenImportAccommodation, setisOpenImportAccommodation] = useState(false);
  const [isOpenEditAccommodation, setIsOpenEditAccommodation] = useState(false);
  const [isShow, setIsShow] = useState('INDEPENDENT');
  const [AddIndependentStaticState, setAddIndependentStaticState] = useState(independentData);
  const [AddGroupStaticState, setAddGroupStaticState] = useState(groupData);
  const [AddParkingStaticState, setAddParkingStaticState] = useState(parkingData);
  const [StaticId, setStaticId] = useState();

  /* 
    Meta data
  */
  const tabbedComponentMetaData = {
    tabs: ['Details', 'Documents']
  };

  const secondaryTabbedComponentMetaData = {
    tabs: [
      'Overview',
      'Accommodation',
      'Block',
      `PLC's`,
      'Pricing',
      'Payment Stages',
      'Unit Pricing',
      'Late Fee Configuration'
    ]
  };

  useEffect(() => {
    const savedPrimaryTab = localStorage.getItem('primaryTab');
    const savedSecondaryTab = localStorage.getItem('secondaryTab');

    if (savedPrimaryTab) setPrimaryTab(savedPrimaryTab);
    if (savedSecondaryTab) setSecondaryTab(savedSecondaryTab);
  }, []);

  useEffect(() => {
    const clearStorage = () => {
      localStorage.removeItem('primaryTab');
      localStorage.removeItem('secondaryTab');
    };

    // Clear on unmount
    return () => {
      clearStorage();
    };
  }, []);
  /* 
    Event handlers
  */
  const handleChangePrimaryTab = useCallback((tabName) => {
    setPrimaryTab(tabName);
    localStorage.setItem('primaryTab', tabName); // Save to localStorage
  }, []);

  const handleChangeSecondaryTab = useCallback((tabName) => {
    setSecondaryTab(tabName);
    localStorage.setItem('secondaryTab', tabName); // Save to localStorage
  }, []);

  const handleToggleOpenBlockDetailingModal = () => {
    setIsOpenBlockDetailingModal(!isOpenBlockDetailingModal);
  };

  const handleToggleOpenUnitDetailingModal = () => {
    setIsOpenUnitDetailingModal(!isOpenUnitDetailingModal);
  };

  const handleToggleOnClose = () => {
    setOpenAccommodation(!OpenAccommodation);
  };

  const handleToggleCreateNewModal = () => {
    setOpenAccommodation(!OpenAccommodation);
  };

  const handleToggleImportAccommodationModal = () => {
    setisOpenImportAccommodation(!isOpenImportAccommodation);
  };

  const handleToggleImportAccommodationModalOnClose = () => {
    setisOpenImportAccommodation(!isOpenImportAccommodation);
  };

  const handleToggleAddDetailsModal = useCallback(() => {
    setIsOpenAddDetailsModal(!isOpenAddDetailsModal);
  }, [isOpenAddDetailsModal]);

  const ShowValue = (data) => {
    // console.log('hey data', data);
    setIsShow(data);
  };

  const handleOnEdit = (id) => {
    // console.log('This is the id', id);
    setStaticId(id);
    setIsOpenEditAccommodation(!isOpenEditAccommodation);
  };

  /* 
    Effects
  */
  useEffect(() => {
    if (isOpenAddDetailsModal) {
      // console.log('Preventing scroll');
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpenAddDetailsModal]);

  useEffect(() => {
    refetchProject();
  }, [refetchProject, projectId]);

  /* 
    JSX
  */
  return (
    <>
      {isOpenImportAccommodation && (
        <Modal
          name={'Import Company Accomodation Details'}
          onClose={handleToggleImportAccommodationModalOnClose}
          width={60}>
          <div className="h-[17rem]">
            <ImportAccommodation />
          </div>
        </Modal>
      )}
      {OpenAccommodation && (
        <Modal
          name={`Add Accommodation - ${project?.name}`}
          onClose={handleToggleOnClose}
          width={72}>
          <AddAccommodation
            onSubmitParking={handleOnSubmitParking}
            onSubmitGroup={handleOnSubmitGroup}
            isSubmitting={isSubmitting}
            onSubmitIndependent={handleOnSubmitIndependent}
            independentData={AddIndependentStaticState}
            groupData={AddGroupStaticState}
            parkingData={AddParkingStaticState}
            ShowValue={ShowValue}
            onClose={handleToggleOnClose}
          />
        </Modal>
      )}
      {isOpenEditAccommodation && (
        <Modal
          name={`Edit Accommodation - ${project?.name}`}
          onClose={() => setIsOpenEditAccommodation(false)}
          width={72}>
          <EditAccommodation
            onEditIndependent={handleOnEditSubmitIndependent}
            onEditParking={handleOnEditSubmitParking}
            isSubmitting={isSubmitting}
            onEditGroup={handleOnEditSubmitGroup}
            id={StaticId}
            accommodationData={accommodations}
            onClose={() => setIsOpenEditAccommodation(false)}
          />
        </Modal>
      )}
      {/* Modals */}
      {isOpenAddDetailsModal && (
        <Modal width={72} name="Add details" onClose={handleToggleAddDetailsModal}>
          <div className="flex justify-between items-center p-[10rem] space-x-12">
            <div
              className="w-[50%] p-4 py-8 bg-white shadow-lg flex flex-col justify-center items-center gap-4 rounded-xl cursor-pointer"
              onClick={() => setIsOpenBlockDetailingModal(true)}>
              <span className="font-semibold text-xl">Block Details</span>
              <span>Based on Blocks</span>
            </div>

            <div
              className="w-[50%] p-4 py-8 bg-white shadow-lg flex flex-col justify-center items-center gap-4 rounded-xl cursor-pointer"
              onClick={() => setIsOpenUnitDetailingModal(true)}>
              <span className="font-semibold text-xl">Unit Details</span>
              <span>Based on Units</span>
            </div>
          </div>
        </Modal>
      )}

      {isOpenBlockDetailingModal && (
        <Modal
          width={72}
          name="Block Detailing"
          onClose={handleToggleOpenBlockDetailingModal}
          upShift={16}>
          <PreviewBlockDetails />
        </Modal>
      )}

      {isOpenUnitDetailingModal && (
        <Modal
          width={72}
          name="Unit Detailing"
          onClose={handleToggleOpenUnitDetailingModal}
          upShift={16}>
          <PreviewUnitDetails />
        </Modal>
      )}

      {/* Main content */}
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18%] z-50 w-[82%]">
          <Header data={{ name: `Company > Projects > ${project?.name}` }} backArrow={true} />
        </div>

        {/* Primary Tabbed content */}
        <div className="w-full fixed top-[4rem] z-10 bg-orange-600">
          <PrimaryTabbedComponent
            width={10}
            data={tabbedComponentMetaData}
            activePrimary={primaryTab}
            onChangePrimaryTab={(tab) => handleChangePrimaryTab(tab)}
          />
        </div>

        {/* Secondary Tabbed content */}
        {primaryTab !== 'Documents' && (
          <div className="w-[82%] fixed top-[9.6rem] z-10 bg-orange-500">
            <SecondaryTabbedComponent
              data={secondaryTabbedComponentMetaData}
              activePrimary={secondaryTab}
              onChangePrimaryTab={(tab) => handleChangeSecondaryTab(tab)}>
              {/* Buttons section for overview */}

              {primaryTab === 'Details' && secondaryTab === 'Overview' && (
                <div className="flex justify-between items-center space-x-4">
                  {/* <Button displayType="secondary">Edit</Button> */}
                  {/* <Button onClick={handleToggleAddDetailsModal}>Add details</Button> */}
                </div>
              )}

              {/* Buttons section for Accommodation */}
              {primaryTab === 'Details' && secondaryTab === 'Accommodation' && (
                <div className="flex justify-between items-center space-x-4">
                  <Button onClick={handleToggleCreateNewModal}>Create New</Button>
                </div>
              )}
            </SecondaryTabbedComponent>
          </div>
        )}

        {/* Overview content */}
        {primaryTab === 'Details' && secondaryTab === 'Overview' && (
          <div className="bg-orange-600 w-full h-full ">
            <ProjectOverview />
          </div>
        )}

        {/* Accomodation content */}
        {primaryTab === 'Details' && secondaryTab === 'Accommodation' && (
          <div className=" w-full h-full ">
            <AccommodationDetails accommodations={accommodations} onEdit={handleOnEdit} />
          </div>
        )}

        {/* PLC content */}
        {primaryTab === 'Details' && secondaryTab === `PLC's` && (
          <div className=" w-full h-full ">
            <PLCsDetails />
          </div>
        )}

        {primaryTab === 'Details' && secondaryTab === 'Block' && (
          <div className=" w-full h-full ">
            <BlockDetails projectId={projectId} />
          </div>
        )}

        {primaryTab === 'Details' && secondaryTab === 'Pricing' && (
          <div className=" w-full min-h-full bg-gray-100">
            <RateList projectName={project?.name} />
          </div>
        )}
        {primaryTab === 'Details' && secondaryTab === 'Payment Stages' && (
          <div className=" w-full min-h-full bg-gray-100">
            <PaymentStagesDetails />
          </div>
        )}

        {primaryTab === 'Details' && secondaryTab === 'Unit Pricing' && (
          <div className=" w-full min-h-full bg-gray-100">
            <UnitPricingList />
          </div>
        )}
        {primaryTab === 'Details' && secondaryTab === 'Late Fee Configuration' && (
          <div className=" w-full min-h-full bg-gray-100">
            <LateFee />
          </div>
        )}

        {primaryTab !== 'Details' && (
          <div className="w-full min-h-full bg-gray-100">
            <p className="mt-10">Documents</p>
          </div>
        )}

        {primaryTab == 'Documents' && (
          <div>
            <ProjectDocument />
          </div>
        )}
      </Layout>
    </>
  );
};

export default ProjectDetails;
