import React from 'react';
import Button from './ui/Button';

function PaginationComponent({ page, totalPages, onPageChange, isFetching }) {
  return (
    <div className="flex justify-center mb-[-3.5rem] absolute bottom-0 right-0 left-0 bg-white rounded-t rounded-b-xl py-2 gap-3">
      {/* Previous Button */}

      {totalPages !== 1 && (
        <button
          className="border  border-customBlue text-customBlue py-2 px-4 rounded-lg focus:shadow-outline"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
          style={{
            opacity: page === 1 ? 1 : undefined,
            cursor: page === 1 ? 'pointer' : undefined
          }}>
          Previous
        </button>
      )}

      {/* Page Numbers */}
      {[...Array(totalPages).keys()]?.map((pageIndex) => (
        <button
          key={pageIndex}
          onClick={() => onPageChange(pageIndex + 1)}
          disabled={page === pageIndex + 1}
          style={{
            opacity: page === pageIndex + 1 ? 1 : undefined,
            cursor: page === pageIndex + 1 ? 'pointer' : undefined
          }}
          className={
            page === pageIndex + 1
              ? `border border-customBlue bg-customBlue py-2 px-4 rounded-lg focus:shadow-outline text-white`
              : `border  border-customBlue text-customBlue py-2 px-4 rounded-lg focus:shadow-outline`
          } // Set primary for current page
        >
          {pageIndex + 1}
        </button>
      ))}

      {totalPages !== 1 && (
        <button
          className="border  border-customBlue text-customBlue py-2 px-4 rounded-lg focus:shadow-outline"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages || isFetching}
          style={{
            opacity: page === totalPages || isFetching ? 1 : undefined,
            cursor: page === totalPages || isFetching ? 'pointer' : undefined
          }}>
          Next
        </button>
      )}
      {/* Next Button */}
    </div>
  );
}

export default PaginationComponent;
