import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getOverview } from '../../../services/api/implementation/impl';
import { getAllProjects } from '../../../../builder/services/api/implementation/impl';
import Table from '../../../../shared/components/layout/Table';
import TabComponents from '../../../../shared/components/TabComponent';
import { transformData } from '../../../utils/transformData';

const Overview = () => {
  // Fetching overview data
  const { data: overview } = useQuery({
    queryKey: ['overview'],
    queryFn: () => getOverview()
  });

  // Fetching project data
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: () => getAllProjects()
  });

  console.log({ overview, projects });

  /* 
    Meta data
  */
  const overviewDataFormat = ['BuilderName', 'CompanyName', 'ProjectName', 'TotalRevenue'];

  const overviewBudgetDataFormat = ['company_name', 'project_name', 'estimated_budget'];

  /* 
    Helper method calls
  */
  const transformedData =
    overview &&
    Object.values(overview)?.map((item) =>
      transformData(
        {
          BuilderName: item?.builder?.name,
          CompanyName: item?.company?.company_name,
          ProjectName: item?.project?.name,
          TotalRevenue: item.amount
        },
        overviewDataFormat
      )
    );

  // Extracting and transforming project data for budget view
  const budgetTempFormedData =
    projects &&
    projects?.map((item) => ({
      company_name: item.company?.company_name,
      project_name: item.name,
      estimated_budget: `${item.buildings?.[0]?.estimated_budget || 'N/A'}`
    }));

  console.warn(projects, 'projects');
  const budgetTransformedData = budgetTempFormedData?.map((item) =>
    transformData(item, overviewBudgetDataFormat)
  );

  console.log({ transformedData, budgetTransformedData });

  // State for filtering tabs
  const [filter, setFilter] = useState('revenue');

  return (
    <div className="overflow-y-clip">
      <div className="flex items-center justify-between px-3 py-2 bg-white border-t-[2px] border-stone-100 shadow-sm z-10">
        <TabComponents
          leftButtonText="Revenue Generated"
          rightButtonText="Budget Allocated"
          onLeftButtonClick={() => setFilter('revenue')}
          onRightButtonClick={() => setFilter('budget')}
        />
      </div>
      <div className="overflow-y-auto pt-3 h-full">
      <div className="h-[24rem] p-6">
        {filter === 'budget' ? (
          <Table
            headers={['Company name', 'Project name', 'Budget (In Lakh)']}
            data={budgetTransformedData}
            key={"budget-table"}
          />
        ) : (
          <Table
            headers={['Builder Name', 'Company name', 'Project name', 'Revenue']}
            data={transformedData}
            key={"revenue-table"}
          />
        )}
      </div>
      </div>

    </div>
  );
};

export default Overview;
