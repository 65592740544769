import React, { useEffect, useState } from 'react';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

const EditPricing = ({
  handleEdit,
  selectedTableType, // e.g. 'sqft' or 'accommodation'
  singlePricingData, // data from the server
  pricingType, // e.g. 'sqft' or 'accommodation'
  selectedAccommodationType, // e.g. 'INDEPENDENT' or 'GROUP'
  onClose,
  isSubmitting
}) => {
 const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = useForm();
  console.log('singlePricingData', singlePricingData);

  // Populate the form fields when singlePricingData changes
  useEffect(() => {
    if (!singlePricingData) return;

    if (selectedAccommodationType === 'INDEPENDENT') {
      // For INDEPENDENT, we want plote_price, construction_price
      reset({
        plote_price: singlePricingData.plote_price || '',
        construction_price: singlePricingData.construction_price || '',
        loading_factor: singlePricingData.loading_factor || ''
      });
    } else {
      // For GROUP, we want a single 'price'
      reset({
        rate: singlePricingData.price || '',
        loading_factor: singlePricingData.loading_factor || ''
      });
    }
  }, [singlePricingData, selectedAccommodationType, reset]);

  const onSubmit = (data) => {
    // Common fields
    const processedData = {
      accommodation_id: singlePricingData.accommodation_id,
      project_id: singlePricingData.project_id,
      mode: selectedTableType === 'sqft' ? 'sqft' : 'accommodation'
    };

    if (selectedAccommodationType === 'INDEPENDENT') {
      // For INDEPENDENT
      processedData.plote_price = parseInt(data.plote_price, 10) || 0;
      processedData.construction_price = parseInt(data.construction_price, 10) || 0;

      // If mode is 'sqft', we might also have loading_factor
      if (processedData.mode === 'sqft') {
        processedData.loading_factor = parseFloat(data.loading_factor, 10) || 0;
      }
    } else {
      // For GROUP
      processedData.price = parseInt(data.rate, 10) || 0;

      // If mode is 'sqft', include loading_factor
      if (processedData.mode === 'sqft') {
        processedData.loading_factor = parseFloat(data.loading_factor, 10) || 0;
      }
    }

    // Send the updated object to your mutation
    handleEdit(processedData);
  };

  const [loadingFactor, setLoadingFactor] = useState('');

  const handleLoadingFactorChange = (e) => {
    let inputVal = e;
  
    // 1) Remove anything that's not a digit:
    inputVal = inputVal.replace(/\D/g, '');
  
    // 2) Convert to a number and cap at 100:
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Loading factor cannot exceed 100');
    }
  
    // 3) Set our local state and also setValue in RHF so the form sees this updated value.
    setLoadingFactor(inputVal);
    setValue('loading_factor', inputVal);
  };
  

  return (
    <div>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Rate Details</span>
      </div>

      {/* Form Fields */}
      <div className="flex flex-col gap-4 pt-6">
        {/* If INDEPENDENT => show Plot Price & Construction Price
            If GROUP => show single "Rate"
            For 'sqft' => also show loading_factor
        */}
        {selectedAccommodationType === 'INDEPENDENT' ? (
          <div className="flex flex-wrap gap-4 w-full">
            <FormInput
              width={48}
              label="Plot Price"
              register={register}
              register_key="plote_price"
              type="text"
            />
            <FormInput
              width={48}
              label="Construction Price"
              register={register}
              register_key="construction_price"
              type="text"
            />
            {selectedTableType === 'sqft' && (
              <FormInput
                width={48}
                label="Loading Factor (%)"
                register={register}
                register_key="loading_factor"
                type="text"
                onType={handleLoadingFactorChange}
                defaultValuePre={loadingFactor}
              />
            )}
          </div>
        ) : (
          // GROUP
          <div className="flex flex-wrap gap-4 w-full">
            <FormInput
              width={48}
              label="Rate"
              register={register}
              register_key="rate"
              type="text"
            />
            {selectedTableType === 'sqft' && (
              <FormInput
                width={48}
                label="Loading Factor (%)"
                register={register}
                register_key="loading_factor"
                type="text"
                onType={handleLoadingFactorChange}
                defaultValuePre={loadingFactor}
              />
            )}
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditPricing;
