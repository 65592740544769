import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import { getAllCategories } from '../../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('Full name is required')
    .min(3, 'Full name should be at least 3 characters')
    .max(50, 'Full name should be at most 50 characters'),
  business_name: yup
    .string()
    .required('Business name is required')
    .max(100, 'Business name should be at most 100 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]{10}$/, 'Invalid phone number'),
  email: yup.string().required('Email is required').email('Invalid email format'),
  category_id: yup.string().required('Vendor category is required'),
  companyIds: yup.array().of(yup.number().integer().positive()).optional()
});

export const AddVendor = ({ onClose, onSubmit, isSubmitting }) => {
  const [categories, setCategories] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { data: allCategories, refetch: refetchCategories } = useQuery({
    queryKey: ['categories'],
    queryFn: getAllCategories
  });

  useEffect(() => {
    if (allCategories?.data && allCategories?.data?.length > 0) {
      setCategories(allCategories?.data);
    }
  }, [allCategories?.data]);

  const onSubmitForm = (data) => {
    const formattedData = {
      name: data.name,
      business_name: data.business_name,
      email: data.email,
      phone_number: data.phone_number,
      category_id: data.category_id,
      type: data.type
    };

    onSubmit(formattedData);
    reset();
  };

  const handleNameChange = useCallback(
    async (val) => {
      const cleanedValue = val.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue('name', cleanedValue);
    },
    [setValue]
  );
  const handleBusinessNameChange = useCallback(
    async (val) => {
      const cleanedValue = val.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue('business_name', cleanedValue);
    },
    [setValue]
  );
  const handlePhoneChange = useCallback(
    async (val) => {
      const cleanedValue = val.replace(/[^0-9.]/g, '').slice(0, 10);
      setValue('phone_number', cleanedValue);
    },
    [setValue]
  );

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Vendor Details</span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Contact Person Name'}
            register_key={'name'}
            minLength={3}
            isRequired={true}
            register={register}
            type={'text'}
            onType={handleNameChange}
            errors={errors.name}
          />
          <FormInput
            width={48}
            label={'Business Name'}
            register_key={'business_name'}
            isRequired={true}
            register={register}
            type={'text'}
            errors={errors.business_name}
            onType={handleBusinessNameChange}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Phone Number'}
            register={register}
            isRequired={true}
            register_key={'phone_number'}
            type={'text'}
            onType={handlePhoneChange}
            errors={errors.phone_number}
          />

          <FormInput
            width={48}
            label={'Email Address'}
            register={register}
            register_key={'email'}
            isRequired={true}
            type={'text'}
            errors={errors.email}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Vendor Category'}
            register={register}
            register_key={'category_id'}
            type={'dropdown'}
            isRequired={true}
            // defaultValuePre={'Select Category'}
            options={[
              { value: '', label: 'Select Category', disabled: true, selected: true },
              ...categories.map((category) => ({
                value: category.id,
                label: category.name.trim()
              }))
            ]}
            errors={errors.category_id}
          />
          <FormInput
            width={48}
            label={'Vendor Type'}
            isRequired={true}
            register={register}
            register_key={'type'}
            type={'dropdown'}
            // defaultValuePre={'Select Type'}
            options={[
              { label: 'Select Type', value: '', disabled: true, selected: true }, // Default option
              ...['individual', 'company'].map((item) => ({ label: item, value: item }))
            ]}
          />
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddVendor;
