const DateFormat = (dateString) => {
  // console.log(dateString);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  let format = date.toLocaleDateString('en-US', options);

  return format;
};

export default DateFormat;

export const TimeFormat = (Time) => {
  let time = new Date(Time);
  console.log(time.toLocaleTimeString());
  return time.toLocaleTimeString();
};

export function changeDateToCustomFormat(originalDate) {
  // Create a new Date object with the desired date and time
  const newDate = new Date(originalDate);

  // Define options for formatting the date
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  // Format the date according to the options
  const formattedDate = newDate.toLocaleString('en-US', options);

  return formattedDate;
}

// export function convertToISO(dateString) {
//   // Split the input date string (assumes format YYYY-MM-DD)
//   const [year, month, day] = dateString.split('-');

//   // Create a new Date object with the input values
//   const dateObj = new Date(year, month - 1, day);

//   // Return the ISO string
//   return dateObj.toISOString();
// }

export const convertToISO = (dateString) => {
  if (!dateString) return '';

  // Create a date object for the input date
  const date = new Date(dateString);

  // Get the current time
  const currentTime = new Date();

  // Set the current time to the date object
  date.setHours(currentTime.getHours());
  date.setMinutes(currentTime.getMinutes());
  date.setSeconds(currentTime.getSeconds());
  date.setMilliseconds(currentTime.getMilliseconds());

  // Return the date in ISO format
  return date.toISOString();
};
