import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import toast from 'react-hot-toast';
import { transformData } from '../../super-admin/utils/transformData';
import Modal from '../../shared/components/ui/Modal';
import RequestQuotation from '../components/forms/RequestQuotation';
import FormInput from '../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import {
  deleteRequirement,
  editrequirementQuantity,
  getByIdRequirements,
  requestQuatation,
  updateStatusRequirement
} from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';

const RequirementDetails = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();
  const { id: requirementId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAvailableStocks, setIsAvailableStocks] = useState(false);
  const [openRequestQuotation, setOpenRequestQuotation] = useState(false);
  const [UpdateQuatity, setUpdateQuatity] = useState(false);
  const [quantityId, setquatityId] = useState();

  const { handleSubmit, register } = useForm();

  // When api got remove the comment below

  const { data: getByIdrequiremnt, refetch: refetchRequiremnt } = useQuery({
    queryKey: ['requirementById', parseInt(requirementId, 10)],
    queryFn: () => getByIdRequirements(parseInt(requirementId, 10)),
    enabled: !!parseInt(requirementId, 10)
  });

  console.log({ getByIdrequiremnt });

  /* 
    Dummy data
  */

  const itemsDataFormat = ['id', 'item_name', 'category', 'quantity', 'unit', 'actions'];

  const handleRequirementItemFormat = (item, category) => {
    return {
      id: item?.id,
      item_name: item?.items?.name,
      category: category?.name || '', // Assign category name here
      quantity: item?.quantity,
      unit: item?.items?.uom,
      actions:
        getByIdrequiremnt?.status == 'Completed' || getByIdrequiremnt?.status == 'completed'
          ? null
          : 'Actions'
    };
  };

  const requirementItems = getByIdrequiremnt?.vendorRequirmentMappingItems?.map((item) =>
    transformData(handleRequirementItemFormat(item, getByIdrequiremnt?.category), itemsDataFormat)
  );
  console.log({ requirementItems });

  const { mutate: handleUpdateRequirementQuatity } = useMutation({
    mutationFn: (data) => editrequirementQuantity(data, quantityId),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: (res) => {
      setIsSubmitting(false);
      toast.success('update requirement  successfully!');

      setUpdateQuatity(false);

      refetchRequiremnt();
    },
    onError: (error) => {
      setIsSubmitting(false);
      console.error('Error requiement:', error);
      toast.error('Failed to requirement quatity form!');
    }
  });

  const { mutate: handleDeleteRequirementQuatity } = useMutation({
    mutationFn: (id) => deleteRequirement(parseInt(id)),
    onSuccess: (res) => {
      toast.success('delete requirement  successfully!');
      refetchRequiremnt();
    },
    onError: (error) => {
      console.error('Error requiement:', error);
      toast.error('Failed to requirement  form!');
    }
  });

  // Update the Available stocks

  const { mutate: handleAvailableStocks } = useMutation({
    mutationFn: (data) =>
      updateStatusRequirement({ status: 'completed' }, parseInt(requirementId, 10)),
    onMutate: () => {
      setIsAvailableStocks(true); // Disable the button when mutation starts
    },
    onSuccess: (res) => {
      setIsAvailableStocks(false);
      toast.success('Status requirement  successfully!');

      navigate(-1);
    },
    onError: (error) => {
      setIsAvailableStocks(false);
      console.error('Error requiement:', error);
      toast.error('Failed to requirement quatity form!');
    }
  });

  // Request Quotations

  const { mutate: handleRequestQuotationFun } = useMutation({
    mutationFn: (data) => requestQuatation(data),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsSubmitting(false);
      toast.success('Quotation Requested Successfully');
      setOpenRequestQuotation(false);
    },
    onError: () => {
      setIsSubmitting(false);
      console.error('request quoatation error');
      toast.error('Request Quatation Error');
    }
  });
  /* 
    Event handlers
  */
  const handleRequestQuotation = () => {
    setOpenRequestQuotation(true);
    // toast.success('Quotation requested successfully');

    // navigate(-1);
  };

  const handleUpdateQuantity = (id) => {
    console.log({ id });

    setquatityId(id);
    setUpdateQuatity(true);
  };

  /* 
    JSX
  */
  return (
    <>
      {openRequestQuotation && (
        <Modal width={40} name="Update Quotation" onClose={() => setOpenRequestQuotation(false)}>
          <RequestQuotation
            isSubmitting={isSubmitting}
            onClose={() => setOpenRequestQuotation(false)}
            onSubmit={handleRequestQuotationFun}
            getByIdrequiremnt={getByIdrequiremnt}
          />
        </Modal>
      )}
      {UpdateQuatity && (
        <Modal width={40} name="Update Quantity" onClose={() => setUpdateQuatity(false)}>
          <div className="flex flex-col gap-4">
            <FormInput
              type={'text'}
              width={100}
              label={'Quantity'}
              register={register}
              register_key={'quantity'}
            />
            <Button
              displayType={'primary'}
              onClick={handleSubmit(handleUpdateRequirementQuatity)}
              disabled={isSubmitting}>
              Update Quantity
            </Button>
          </div>
        </Modal>
      )}

      <Layout>
        {/* Header */}
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: `Requirements` }} backArrow={true} />
          {/* <div className='mt-3 ml-8 text-xl font-semibold'>
            <h3>Quotations</h3>
          </div> */}
        </div>

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="mt-[8rem] h-[20rem]">
            <Table
              headers={['Item Name', 'Category', 'Quantity', 'Unit', 'Actions']}
              data={requirementItems}
              identifier={'id'}
              isViewEnable={false}
              isEditEnable={true}
              onEdit={(id) => handleUpdateQuantity(id)}
              // isCancelEnable={true}
              isDeleteEnable={true}
              onDelete={(id) => handleDeleteRequirementQuatity(id)}
            />
          </div>
          {getByIdrequiremnt?.status == 'Completed' ||
          getByIdrequiremnt?.status == 'completed' || requirementItems?.length === 0 ? null : (
            <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-[60px]">
              <Button
                disabled={
                  getByIdrequiremnt?.status == 'Completed' ||
                  getByIdrequiremnt?.status == 'completed'
                    ? true
                    : false || isAvailableStocks
                }
                displayType={'secondary'}
                onClick={handleSubmit(handleAvailableStocks)}>
                Available Stocks
              </Button>
              <Button
                disabled={
                  getByIdrequiremnt?.status == 'Completed' ||
                  getByIdrequiremnt?.status == 'completed'
                    ? true
                    : false
                }
                displayType={'primary'}
                onClick={handleRequestQuotation}>
                Request Quotation
              </Button>
            </div>
          )}
        </GridWrapper>
      </Layout>
    </>
  );
};

export default RequirementDetails;
