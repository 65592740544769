import React, { useState, useEffect } from 'react';
import FileIcon from '../../../shared/assets/svg/file.svg';
import CustomerIcon from '../../../shared/assets/svg/customer.svg';
import LinkIcon from '../../../shared/assets/svg/link.svg';
import PaymentIcon from '../../../shared/assets/svg/payment.svg';
import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { getAllUsers } from '../../services/api/implementation/impl';
import { getAllCompanies, getAllProjects } from '../../../builder/services/api/implementation/impl';

const schema = yup.object().shape({
  amount: yup.number().typeError('Amount must be a number').required('Amount is required'),
  invoice_number: yup.string().required('Invoice number is required'),
  date_issued: yup.string().required('Date issued is required'),
  due_date: yup.string().required('Due date is required'), // Further add .date validation as well
  project_id: yup.string().required('Project name is required')
});

export const InvoiceForm = ({ onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: () => getAllUsers()
  });

  console.log(users, 'users life');

  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getAllCompanies()
  });

  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: () => getAllProjects()
  });

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedBuilder, setSelectedBuilder] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');

  const handleBuilderChange = (e) => {
    const builderId = e.target.value;
    setSelectedBuilder(builderId);
    console.log(builderId, 'builderId');
    const filtered = companies?.filter(
      (company) => company.builder.primery_user.id === parseInt(builderId)
    );
    console.log(companies, 'companies');
    console.log(filtered, 'filtered');
    setFilteredCompanies(filtered);
    setFilteredProjects([]); // Reset projects when builder changes
    setValue('company_id', '');
    setValue('project_id', '');
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    const filtered = projects?.filter((project) => project.company_id === parseInt(companyId));
    setFilteredProjects(filtered);
    setValue('project_id', '');
  };

  console.log(users, 'users');

  const transformedData = users?.data?.map((item) => ({
    value: item.primery_user?.id,
    label: item.primery_user?.name?.trim()
  }));

  const transformedDataCompany = filteredCompanies?.map((item) => ({
    value: item.id,
    label: item.company_name?.trim()
  }));

  const transformedDataProject = filteredProjects?.map((item) => ({
    value: item.id,
    label: item.name?.trim()
  }));

  return (
    <div className="w-[100%]">
      <div className="font-helvetica">
        {/* General info */}
        <div className="flex items-center">
          <img src={FileIcon} alt={FileIcon} />
          <h3 className="ml-2 font-bold text-lg">General Information</h3>
        </div>

        <div className="flex justify-between w-full gap-4 pt-4">
          <div className="flex flex-col gap-2 w-[48%]">
            <FormInput width={100} label={'Amount *'} register_key={'amount'} register={register} />
            {errors.amount && <span className="text-red-600">{errors.amount.message}</span>}
          </div>

          <div className="flex flex-col gap-2 w-[48%]">
            <FormInput
              width={100}
              label={'Invoice number *'}
              register_key={'invoice_number'}
              register={register}
            />
            {errors.invoice_number && (
              <span className="text-red-600">{errors.invoice_number.message}</span>
            )}
          </div>
        </div>

        <div className="flex justify-between w-full gap-4 pt-4">
          <div className="flex flex-shrink-0 w-[48%] flex-col gap-2">
            <FormInput
              width={100}
              label={'Date issued *'}
              register_key={'date_issued'}
              register={register}
              type={'date'}
            />
            {errors.date_issued && (
              <span className="text-red-600">{errors.date_issued.message}</span>
            )}
            {/* <div className="flex gap-1 items-center mt-2">
              <input {...register("send_sms")} className="w-[1rem] h-[1rem]" type="checkbox" />
              <label htmlFor="">Send SMS</label>
            </div> */}
          </div>

          <div className="flex flex-shrink-0 w-[48%] flex-col gap-2">
            <FormInput
              width={100}
              label={'Due date'}
              register_key={'due_date'}
              register={register}
              type={'date'}
              isRequired={true}
            />
            {errors.due_date && <span className="text-red-600">{errors.due_date.message}</span>}
            {/* <div className="flex gap-1 items-center mt-2">
              <input {...register("send_email")} className="w-[1rem] h-[1rem]" type="checkbox" />
              <label htmlFor="">Send Email </label>
            </div> */}
          </div>
        </div>

        <div className="mt-6 w-full">
          <textarea
            className="border w-full  p-4 rounded-md"
            type="text"
            name=""
            {...register('notes')}
            placeholder="Add notes"
            id=""
          />
        </div>

        <div className="flex w-full justify-between mt-10">
          <div className="flex items-center">
            <img src={CustomerIcon} alt="" />
            <h3 className="ml-2 font-bold text-lg">Customer Information</h3>
          </div>
        </div>

        {/* Customer info */}
        <div className="flex justify-between w-full gap-4 pt-4">
          <div className="flex flex-col gap-2 w-[30%]">
            <label className="font-medium">Builders</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('builder_id')}
              value={selectedBuilder}
              onChange={handleBuilderChange}
            >
              <option value="">Select builder</option>
              {transformedData?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.builder_id && <span className="text-red-600">{errors.builder_id.message}</span>}
          </div>

          <div className="flex flex-col gap-2 w-[30%]">
            <label className="font-medium">Company</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('company_id')}
              value={selectedCompany}
              onChange={handleCompanyChange}
              disabled={!selectedBuilder}
            >
              <option value="">Select company</option>
              {transformedDataCompany?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.company_id && <span className="text-red-600">{errors.company_id.message}</span>}
          </div>

          <div className="flex flex-col gap-2 w-[30%]">
            <label className="font-medium">Project name</label>
            <select
              className="w-full p-2 border-[1px] border-stone-300 rounded"
              {...register('project_id')}
              disabled={!selectedCompany || !selectedBuilder}
            >
              <option value="">Select Project</option>
              {transformedDataProject?.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {errors.project_id && <span className="text-red-600">{errors.project_id.message}</span>}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end items-center space-x-4 mt-8">
        <Button type="reset" onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)} type="submit">
          Add
        </Button>
      </div>
    </div>
  );
};
