import React from 'react';
import Button from '../../shared/components/ui/Button';
import FormInput from '../../shared/components/ui/FormInput';
import clocksvg from '../../shared/assets/svg/clocks.svg';
import { useNavigate } from 'react-router-dom';
import {
  downloadAllotment,
  uploadAgreementLetter,
  uploadAllotmentLetter,
  downloadInvoice,
  downloadInvoiceForPaymentStages,
  approveActivity
} from '../services/api/implementation/impl';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const DealCard = ({ special, data, dealId, onSuccess }) => {
  console.warn({ data });
  const navigate = useNavigate();
  let statusUpdate = '';
  let bgColor = '';
  switch (data?.status) {
    case 'Pending':
      statusUpdate = 'Application Form Pending';
      bgColor = '#e47c40';
      break;
    case 'Completed':
      statusUpdate = 'Application Form Submitted';
      bgColor = '#8aa9ac';
      break;
    case 'due':
      statusUpdate = 'Due';
      bgColor = '#e47c40';
      break;
    case 'sign_pending':
      statusUpdate = 'Sign Pending';
      bgColor = '#e47c40';
      break;
  }

  function changeDateToCustomFormat(originalDate) {
    // Create a new Date object with the desired date and time
    const newDate = new Date(originalDate);

    // Define options for formatting the date
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    // Format the date according to the options
    const formattedDate = newDate.toLocaleString('en-US', options);

    return formattedDate;
  }

  // Handle download signed allotment letter
  const { mutate: handleDownloadSignedAllotment } = useMutation({
    mutationFn: async (id) => {
      return await downloadAllotment(id);
    },
    onSuccess: (data, variables) => {
      const id = variables;

      console.log({ data }, 'data');
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `signedCopy_${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      onSuccess();

      toast.success('Allotment Letter Downloaded');
    },
    onError: () => {
      toast.error('Error in downloading allotment letter');
    }
  });

  const handleUploadAllotmentLetter = async (id, payload) => {
    try {
      console.log({ id, payload });
      await uploadAllotmentLetter(id, payload);
      onSuccess();
    } catch (error) {
      console.error('Error uploading allotment letter:', error);
      toast.error('Error uploading allotment letter');
    }
  };
  const handleUploadAgreementForm = async (id, payload) => {
    try {
      console.log({ id, payload });
      await uploadAgreementLetter(id, payload);
      onSuccess();
      // window.location.reload();
    } catch (error) {
      console.error('Error uploading agreement letter:', error);
      toast.error('Error uploading agreement letter');
    }
  };

  const handleClickOnAllotmentLetter = (id) => {
    navigate(`/builder/Allotment/${id}`);
  };

  const handleClikcOnAgreementLetter = (id) => {
    navigate(`/builder/Agreement/${id}`);
  };

  // const handleInvoiceStagesDownload = () => {

  // }

  //write mutation for handleInvoiceStagesDownload
  const { mutate: handleInvoiceStagesDownload } = useMutation({
    mutationFn: async (id) => {
      return await downloadInvoice(id);
    },
    onSuccess: (data, variables) => {
      const id = variables;

      // Check if the response contains valid data
      if (!data || data.size === 0) {
        toast.error('Error: File data is empty or invalid.');
        return;
      }

      console.log({ data }, 'data');
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `invoice_${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      onSuccess();
      toast.success('Invoice Downloaded');
    },

    onError: () => {
      toast.error('Error in downloading invoice');
    }
  });

  const { mutate: handleInvoicePaymentStagesDownload } = useMutation({
    mutationFn: async ({ id, sourceName }) => {
      return await downloadInvoiceForPaymentStages(id, sourceName);
    },
    onSuccess: (data, variables) => {
      const { id, sourceName } = variables;

      // Check if the response contains valid data
      if (!data || data.size === 0) {
        toast.error('Error: File data is empty or invalid.');
        return;
      }

      console.log({ data }, 'data');
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `Payment_Stage_${id}_${sourceName}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      onSuccess();
      toast.success('Invoice Downloaded');
    },

    onError: () => {
      toast.error('Error in downloading invoice');
    }
  });

  const { mutate: handleApprove } = useMutation({
    mutationFn: (payload) => approveActivity(data?.id, payload),

    onSuccess: (data) => {
      toast.success('Approved');
      onSuccess();
    }
  });

  const handleApproveClick = (leadId) => {
    const payload = {
      name: 'Management Approval',
      lead_id: leadId,
      status: 'Completed'
    };

    handleApprove(payload);
  };

  return (
    <div className="flex flex-col w-full flex-shrink-0 border-l border-dashed border-l-gray-400 left-0 gap-5 relative">
      <div className="flex flex-col items-start gap-4  pl-6">
        <div className="flex flex-col gap-0">
          <div className="flex justify-between items-start space-x-4">
            <p className="text-lg font-semibold text-[#2A565E]">
              {data?.name} {data?.payment_stage_name ? '|' + ' ' + data?.payment_stage_name : ''}
            </p>
            {/* 
                        <span className="p-2 px-3 bg-[#265753] text-gray-100 rounded-full">₹ {data?.price || 4000}</span> */}
          </div>
          <span className="text-gray-600 mt-[10px]">{''}</span>
        </div>
      </div>

      <div className="w-[96%] flex flex-col gap-2 p-4 rounded-md bg-[#E9F5F6] self-center">
        <div className="flex justify-between items-center">
          <div className="flex  items-center gap-2">
            <img src={clocksvg} alt="" />
            <p className="text-gray-700 ">{changeDateToCustomFormat(data?.updated_at)}</p>
          </div>
          <p
            className={`${data.status === 'Pending' || data.status === 'sign_pending' ? 'bg-[#f15d47]' : data.status === 'Completed' ? 'bg-[#c4c3d4]' : ''} text-white border p-1 rounded-xl`}
          >
            {data?.status}
          </p>
        </div>
        <span className="text-gray-500">{data?.description}</span>
        {data?.name === 'Allotment Letter' && data?.status === 'Completed' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2">
              <Button onClick={() => handleDownloadSignedAllotment(dealId)} displayType={''}>
                Download Signed Letter
              </Button>
            </div>
          </div>
        ) : data?.name === 'Allotment Letter' && data?.status === 'Pending' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2 flex items-center gap-5">
              <div className="mt-4">
                <Button
                  onClick={() => handleClickOnAllotmentLetter(dealId)}
                  displayType={'secondary'}
                >
                  View
                </Button>
              </div>
              <FormInput
                type={'file-btn'}
                label={'Upload Signed Document'}
                register_key={'allotment_signed_doc'}
                accept={'.pdf'}
                uploadFunctionId={dealId}
                uploadFunction={handleUploadAllotmentLetter}
              />
            </div>
          </div>
        ) : data?.name === 'Agreement Letter' && data?.status === 'Completed' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2">
              <Button onClick={() => handleClikcOnAgreementLetter(dealId)} displayType={''}>
                Download
              </Button>
            </div>
          </div>
        ) : (data?.name === 'Aggrement Letter' || data?.name === 'Agreement Letter') &&
          data?.status === 'Pending' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2 flex items-center gap-5">
              <div className="mt-2">
                <Button
                  onClick={() => handleClikcOnAgreementLetter(dealId)}
                  displayType={'secondary'}
                >
                  View
                </Button>
              </div>
              <FormInput
                type={'file-btn'}
                label={'Upload Signed Document'}
                register_key={'agreement_signed_doc'}
                accept={'.pdf'}
                uploadFunctionId={dealId}
                uploadFunction={handleUploadAgreementForm}
              />
            </div>
          </div>
        ) : data?.name === 'Demand Note (Invoice)' && data?.status === 'Completed' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2">
              <Button onClick={() => handleInvoiceStagesDownload(dealId)} displayType={''}>
                Download
              </Button>
            </div>
          </div>
        ) : data?.name === 'Invoice Advance' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2">
              <Button onClick={() => handleInvoiceStagesDownload(dealId)} displayType={''}>
                Download
              </Button>
            </div>
          </div>
        ) : data?.name === 'Payment Stage (Invoice)' ? (
          <div className="flex items-center gap-20">
            {/* <FormInput type={'file-btn'} /> */}
            <div className="mt-2">
              {console.log(data?.payment_stage_name)}
              <Button
                onClick={() =>
                  handleInvoicePaymentStagesDownload({
                    id: dealId,
                    sourceName: data?.payment_stage_name
                  })
                }
                displayType={''}
              >
                Download
              </Button>
            </div>
          </div>
        ) : data?.name === 'Management Approval' && data?.status !== 'Completed' ? (
          <div className="flex items-center gap-20">
            <div className="mt-2">
              <Button onClick={() => handleApproveClick(data?.lead_id)} displayType={''}>
                Approve
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      <img
        src={`/icons/${special ? 'document' : 'organisation'}.svg`}
        alt="document_icon"
        className="absolute left-[-10px] top-0 bg-white"
      />
    </div>
  );
};

export default DealCard;
