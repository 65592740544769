import React, { useEffect } from 'react';
import cross from '../../assets/svg/dowload.svg';

const Modal = ({ name, width, onClose, upShift, children, hideClose }) => {
  /* 
    Effects
  */
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      } else {
        return;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  /* 
    JSX
  */
  return (
    <div
      className="h-screen w-screen bg-[#0000002a] z-[90] flex justify-center items-center fixed top-0 left-0"
      style={
        {
          // paddingTop: `${upShift}vh`,
          // overflowY: 'auto',
        }
      }
    >
      <div
        className={`bg-white rounded-lg ${upShift ? 'h-[94%]' : 'h-fit' }`}
        style={{
          width: `${width}%`
        }}
      >
        {/* Head */}
        <div className="flex justify-between items-center px-6 py-4 bg-[#E9F5F6] rounded-t-lg">
          <span className="text-gray-700 font-semibold text-lg">{name}</span>
          {!hideClose && (
            <img
              src={cross}
              alt="Cross_image"
              className="cursor-pointer hover:scale-110 transition-all duration-300"
              onClick={onClose}
            />
          )}
        </div>

        {/* Body */}
        <div className="py-6 px-8 max-h-[550px] overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
