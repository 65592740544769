import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { createPassword, verifyEmail } from '../services/api/implementation/impl';
import HousinGramLogo from '../../shared/assets/svg/group.svg';


import ForgotPasswordForm from '../components/forms/ForgotPasswordForm';

const ForgotPassword = () => {
  /*
    Step states: 
    1 -> Ask for email 
    2 -> Ask for code + new password
  */
  const [step, setStep] = useState(1);
  const [savedEmail, setSavedEmail] = useState('');

  const navigate = useNavigate();

  // 1) Send/Resend code
  const { mutate: handleSendCode, isLoading: isSendingCode } = useMutation({
    mutationFn: async (email) => {
      // Adjust if verifyEmail expects { email } vs just a string
      return await verifyEmail({ email });
    },
    onSuccess: (data) => {
      toast.success('Verification code sent to your email.');
      // Move to step 2 only on success
      setStep(2);
    },
    onError: (err) => {
      console.error('Error sending verification code', err);
      toast.error('Error sending verification code.');
    }
  });

  // 2) Reset password
  const { mutate: handleResetPassword, isLoading: isResettingPassword } = useMutation({
    mutationFn: async (payload) => await createPassword(payload),
    onSuccess: () => {
      toast.success('Password reset successfully.');
      navigate('/auth/login');
    },
    onError: (err) => {
      console.error('Error creating password', err);
      toast.error('Error creating password.');
    }
  });

  /**
   * Called from child (Step 1).
   * We save the email so we can include it in the final reset request.
   */
  const onSendCode = (email) => {
    setSavedEmail(email);
    handleSendCode(email);
  };

  /**
   * Called from child (Step 2) if user wants to re-send the code.
   */
  const onResendCode = () => {
    // re-use the same mutation with the same email
    if (!savedEmail) return;
    handleSendCode(savedEmail);
  };

  /**
   * Called from child (Step 2).
   * payload = { code, newPassword }
   * We must also append email from our parent state
   */
  const onResetPassword = ({ code, newPassword }) => {
    handleResetPassword({ email: savedEmail, code, newPassword });
  };

  return (
    <div className="text-stone-700 flex justify-between items-stretch h-screen w-screen overflow-hidden">
      {/* LEFT SIDE: image */}
      <div className="h-screen w-[64%] bg-black">
        <img
          src="https://cdn.pixabay.com/photo/2022/10/03/23/41/house-7497001_1280.png"
          alt="login_vector_image"
          className="w-full h-full !bg-center !bg-cover !object-cover !object-center"
        />
      </div>

      {/* RIGHT SIDE: form */}
      <div className="h-screen w-[36%] p-9 bg-white flex flex-col gap-[100px] justify-center relative">
        <div className="inline-block w-fit p-4 bg-primary rounded-lg absolute top-[36px] left-[36px]">
          <img src={HousinGramLogo} alt="Logo" className="h-30 w-50 mt-2" />
        </div>
        <div className="w-[92%] rounded-lg ">
          <ForgotPasswordForm
            step={step}
            onSendCode={onSendCode}
            onResendCode={onResendCode}
            onResetPassword={onResetPassword}
            isSendingCode={isSendingCode}
            isResettingPassword={isResettingPassword}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
