import React from 'react';
import Button from '../ui/Button';
import { useForm } from 'react-hook-form';
import FormInput from '../ui/FormInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required')
});

const CreatePasswordForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <form className="flex flex-col gap-6 w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col justify-between items-start gap-4">
        <div className="w-full">
          <FormInput
            width={100}
            register={register}
            register_key={'password'}
            label={'Password'}
            type={'password'}
            isRequired={true}
            errors={errors}
          />
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
          )}
        </div>
        <div className="w-full">
          <FormInput
            width={100}
            register={register}
            register_key={'confirmPassword'}
            label={'Confirm Password'}
            type={'password'}
            isRequired={true}
            errors={errors}
          />
          {errors.confirmPassword && (
            <p className="text-red-500 text-sm mt-1">{errors.confirmPassword.message}</p>
          )}
        </div>
      </div>

      <div className="w-full flex flex-col justify-center items-center gap-4">
        <Button type="submit" displayType="full-w-primary" width={100}>
          Create Password
        </Button>
      </div>
    </form>
  );
};

export default CreatePasswordForm;
