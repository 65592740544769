import React from 'react';
import NoDataFoundImg from '../../../shared/assets/png/noDataFound.png';
import Button from '../../../shared/components/ui/Button';

function NoDataFound({ buttonText = 'Add Data', onButtonClick, disabledBtn = false, screenHeight = true }) {
  return (
    <div className={`flex items-center justify-center bg-gray-100 ${screenHeight ? 'h-screen' : 'h-full'}`}>
      <div className="text-center border flex flex-col items-center justify-center gap-2 border-gray-300 rounded-lg p-6 w-[500px] h-[500px] shadow-lg">
        <img
          src={NoDataFoundImg} // Replace this with your actual image URL
          alt="No Data"
          className="w-[65%] mx-auto mb-4 object-contain"
        />
        <h2 className="text-xl font-bold text-gray-800">Dear User</h2>
        <p className="text-gray-600 mb-6">
          The page you are trying to access has no data. <br></br>Proceed with adding data.
        </p>
        {!disabledBtn && (
          <Button
            onClick={onButtonClick}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none">
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default NoDataFound;
