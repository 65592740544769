import React, { useCallback, useEffect, useState } from 'react';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Modal from '../../shared/components/ui/Modal';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';

import PrimaryTabbedComponent from '../../shared/components/layout/PrimaryTabbedComponent';
import SecondaryTabbedComponent from '../../shared/components/layout/SecondaryTabbedComponent';

import company_image from '../../shared/assets/png/company_image.png';
import ProjectOverview from '../components/layout/ProjectOverview';
import AccommodationDetails from './AccommodationDetails';
import { AddAccommodation } from '../components/forms/AddAccommodation';
import PreviewBlockDetails from '../components/layout/PreviewBlockDetails';
import PreviewUnitDetails from '../components/layout/PreviewUnitDetails';
import { RateList } from '../../builder/pages/RateList';
import { ImportAccommodation } from '../components/forms/ImportAccommodation';
import accommodationData from '../static/data/accommodation.json';
import {
  createAccommodation,
  editAccommodation,
  getAllAccommodations,
  getProjectByID
} from '../services/api/implementation/impl';
import { EditAccommodation } from '../components/forms/EditAccommodation';
import { PLCsDetails } from './PLCsDetails';
import { BlockDetails } from './BlockDetails';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { transformPayload } from '../utils/transformPayload';
import PaymentStagesList from './PaymentStagesDetails';
import PaymentStagesDetails from './PaymentStagesDetails';
import { SalesList } from './SalesList';
import CustomerList from './CustomerList';
import DealsDetails from './DealsDetails';
import DealsList from './DealsList';
import InvoicesList from './InvoicesList';
import ConsolidatedList from './ConsolidatedList';
import SalesPayment from './SalesPayment';
// import Modal from '../../shared/components/ui/Modal';

const SalesMain = () => {
  const { id: projectId } = useParams();

  /* 
        Data fetching
      */

  /* 
        Data mutation
      */

  /*
        Hooks 
      */

  /* 
        Local state 
      */
  const [primaryTab, setPrimaryTab] = useState('Details');
  const [secondaryTab, setSecondaryTab] = useState('Leads');
  const [OpenAccommodation, setOpenAccommodation] = useState(false);
  const [isOpenAddDetailsModal, setIsOpenAddDetailsModal] = useState(false);
  const [isOpenBlockDetailingModal, setIsOpenBlockDetailingModal] = useState(false);
  const [isOpenUnitDetailingModal, setIsOpenUnitDetailingModal] = useState(false);
  const [isOpenImportAccommodation, setisOpenImportAccommodation] = useState(false);
  const [isOpenEditAccommodation, setIsOpenEditAccommodation] = useState(false);

  /* 
        Meta data
      */
  const tabbedComponentMetaData = {
    tabs: ['Details', 'Documents', 'Schedule']
  };

  const secondaryTabbedComponentMetaData = {
    tabs: ['Leads', 'Closed Customers', 'Invoices', 'Payment', 'Consolidated View of Sales']
  };

  /* 
        Event handlers
      */
  const handleChangePrimaryTab = useCallback((tabName) => {
    setPrimaryTab(tabName);
  }, []);

  const handleChangeSecondaryTab = useCallback((tabName) => {
    setSecondaryTab(tabName);
  }, []);

  /* 
        Effects
      */
  useEffect(() => {
    if (isOpenAddDetailsModal) {
      // console.log('Preventing scroll');
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpenAddDetailsModal]);

  /* 
        JSX
      */
  return (
    <>
      {/* Main content */}
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18%] z-50 w-[82%]">
          <Header data={{ name: `Sales` }} />
        </div>

        {/* Primary Tabbed content */}
        {/* <div className="w-full fixed top-[4rem] z-50 bg-orange-600">
                    <PrimaryTabbedComponent
                        width={10}
                        data={tabbedComponentMetaData}
                        activePrimary={primaryTab}
                        onChangePrimaryTab={(tab) => handleChangePrimaryTab(tab)}
                    />
                </div> */}

        {/* Secondary Tabbed content */}
        <div className="w-[82%] fixed top-[3.5rem] z-20 bg-orange-500">
          <SecondaryTabbedComponent
            data={secondaryTabbedComponentMetaData}
            activePrimary={secondaryTab}
            onChangePrimaryTab={(tab) => handleChangeSecondaryTab(tab)}>
            {/* Buttons section for overview */}

            {/* Buttons section for Accommodation */}
          </SecondaryTabbedComponent>
        </div>

        {/* Overview content */}
        {secondaryTab === 'Leads' && (
          <div className="bg-orange-600 w-full h-full ">
            <SalesList />
          </div>
        )}

        {/* PLC content */}
        {primaryTab === 'Details' && secondaryTab === `Closed Customers` && (
          <div className=" w-full h-full ">
            <CustomerList />
          </div>
        )}
        {primaryTab === 'Details' && secondaryTab === `Invoices` && (
          <div className=" w-full h-full ">
            <InvoicesList />
          </div>
        )}
        {primaryTab === 'Details' && secondaryTab === `Consolidated View of Sales` && (
          <div className=" w-full h-full ">
            <ConsolidatedList />
          </div>
        )}
        {primaryTab === 'Details' && secondaryTab === `Payment` && (
          <div className=" w-full h-full ">
            <SalesPayment />
          </div>
        )}
      </Layout>
    </>
  );
};

export default SalesMain;
