import React, { useEffect, useState } from 'react';
import ActivityCard from '../components/layout/ActivityCard';
import { getCloseDeals } from '../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import DealCard from './DealCard';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';

const DealsList = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [dealId, setDealId] = useState(null);

  const { id } = useParams();

  const {
    data: deals,
    refetch: refetchDeals,
    isLoading
  } = useQuery({
    queryKey: ['deals'],
    queryFn: () => getCloseDeals()
  });

  useEffect(() => {
    if (deals?.data) {
      const dataFiltered = deals?.data.filter((deal) => deal.id === parseInt(id));
      setFilteredData(dataFiltered);
      setDealId(dataFiltered?.[0]?.id);
    }
  }, [deals?.data, id, refetchDeals]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div className="fixed z-[20] top-0 left-[18vw] z-5 w-[82vw]">
        <Header data={{ name: 'Deals'}} backArrow={true}/>
      </div>
      <div>
        <div className="flex flex-col bg-white shadow-lg rounded-lg mt-[7rem] w-[96%] mx-auto p-6">
          <div className="w-full flex flex-col justify-between items-center gap-8 mt-10">
            {filteredData?.[0]?.lead?.activities?.map((item, index) => (
              <DealCard
                key={index}
                special={index === 0}
                data={item}
                dealId={dealId}
                onSuccess={() => refetchDeals()}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DealsList;
