import React, { useCallback, useEffect, useState } from 'react';

import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import PrimaryTabbedComponent from '../../shared/components/layout/PrimaryTabbedComponent';
import SalesTargets from './SalesTargets';
import SalesReceivables from './SalesReceivables';
import SalesRevenue from './SalesRevenue';
import ProjectExpenditures from './ProjectExpenditures';
import GST from './GST';
import TDS from './TDS';
import NoDataFound from '../components/layout/NoDataFound';
import PendingBills from './PendingBills'; 
import AccountsPaidBills from './AccountsPaidBills';

const AccountsReport = () => {
  /* 
    Local state 
  */
  const [primaryTab, setPrimaryTab] = useState('Project Expenditures');

  /* 
    Meta data
  */
  const tabbedComponentMetaData = {
    tabs: [
      'Project Expenditures',
      'GST',
      'TDS',
      //   'RERA',
      //   'Advance Tax',
      'Paid Bills',
      'Pending Bills'
      //   'Advances',
      //   'Individual Ledgers'
    ]
  };

  useEffect(() => {
    const savedPrimaryTab = localStorage.getItem('primaryTab');
    if (savedPrimaryTab) setPrimaryTab(savedPrimaryTab);
  }, []);

  useEffect(() => {
    const clearStorage = () => {
      localStorage.removeItem('primaryTab');
    };

    // Clear on unmount
    return () => {
      clearStorage();
    };
  }, []);

  /* 
    Event handlers
  */
  const handleChangePrimaryTab = useCallback((tabName) => {
    setPrimaryTab(tabName);
    localStorage.setItem('primaryTab', tabName); // Save to localStorage
  }, []);

  /* 
    JSX 
  */
  return (
    <>
      <Layout>
        {/* Header */}
        <div className="fixed top-0 left-[18%] z-50 w-[82%]">
          <Header data={{ name: 'Accounts Reports' }} backArrow={true} />
        </div>

        {/* Primary Tabbed content */}
        <div className="w-full fixed top-[4rem] z-10 bg-white">
          <PrimaryTabbedComponent
            // width={10}
            data={tabbedComponentMetaData}
            activePrimary={primaryTab}
            onChangePrimaryTab={(tab) => handleChangePrimaryTab(tab)}
          />
        </div>

        {/* Project Expenditures Content */}
        {primaryTab === 'Project Expenditures' && (
          <div className="w-full h-full">
            <ProjectExpenditures key={"ProjectExpenditures"} />
          </div>
        )}

        {/* GST Content */}
        {primaryTab === 'GST' && (
          <div className="w-full h-full">
            <GST key={"GST"} />
          </div>
        )}

        {/*TDS Content */}
        {primaryTab === 'TDS' && (
          <div className="w-full h-full">
            <TDS key={"TDS"} />
          </div>
        )}

        {/*RERA Content */}
        {primaryTab === 'RERA' && (
          <div className="w-full h-full">
            <NoDataFound />
          </div>
        )}

        {/*Advance Tax Content */}
        {primaryTab === 'Advance Tax' && (
          <div className="w-full h-full">
            <NoDataFound />
          </div>
        )}

        {/*Paid Bills Content */}
        {primaryTab === 'Paid Bills' && (
          <div className="w-full h-full">
            <AccountsPaidBills key={"AccountsPaidBills"} />
          </div>
        )}

        {/*Pending Bills Content */}
        {primaryTab === 'Pending Bills' && (
          <div className="w-full h-full">
            <PendingBills key={"PendingBills"} />
          </div>
        )}

        {/*Advances Content */}
        {primaryTab === 'Advances' && (
          <div className="w-full h-full">
            <NoDataFound />
          </div>
        )}

        {/*Individual Ledgers Content */}
        {primaryTab === 'Individual Ledgers' && (
          <div className="w-full h-full">
            <NoDataFound />
          </div>
        )}
      </Layout>
    </>
  );
};

export default AccountsReport;
