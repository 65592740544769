import React from 'react';
import ScheduleCard from '../components/layout/ScheduleCard';
import EventsCard from '../components/layout/EventsCard';
import { getEvent } from '../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const ScheduleDetails = () => {
  const { id } = useParams();

  const {
    data: eventbyUserId,
    isLoading,
    error,
    refetch: refetchEvent
  } = useQuery({
    queryKey: ['eventbyuserId'],
    queryFn: async () => {
      const res = await getEvent(id);
      return res;
    },
    enabled: !!id // Only run the query if id is truthy
  });

  console.log({ eventbyUserId });

  // Function to check if the event is in the past
  const isPastEvent = (endTime) => {
    const currentTime = new Date();
    const eventEndTime = new Date(endTime);
    return currentTime > eventEndTime;
  };

  // Filter events
  const pastEvents = eventbyUserId?.filter((event) => isPastEvent(event.end_time)).reverse();
  const upcomingEvents = eventbyUserId?.filter((event) => !isPastEvent(event.end_time));

  return (
    <div className="flex justify-between items-start min-h-[80vh] bg-gray-100 mt-[12rem] px-6 space-x-6">
      {/* Left */}
      <div className="flex flex-col justify-between gap-6 text-gray-900 bg-white shadow-lg rounded-lg p-8 w-[66%]">
        <span className="text-lg font-semibold">Upcoming Events</span>

        <div className="flex flex-wrap justify-between items-center gap-4">
          {upcomingEvents?.map((item) => (
            <ScheduleCard key={item?.id} data={item} />
          ))}
        </div>
      </div>

      {/* Right */}
      <div className="flex flex-col justify-start gap-6 text-gray-900 bg-white shadow-lg rounded-lg p-8 w-[36%] min-h-[80vh]">
        <span className="text-lg font-semibold">Past Events</span>

        <div className="flex flex-wrap justify-between items-center gap-4">
          {pastEvents?.map((item) => (
            <EventsCard key={item?.id} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleDetails;
