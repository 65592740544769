import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import CreatePasswordForm from '../components/forms/CreatePasswordForm';
import { createPassword } from '../services/api/implementation/impl';
import HousinGramLogo from '../../shared/assets/svg/group.svg';

const CreatePasswordPage = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();
  const location = useLocation();

  // Extract email and code from URL
  const queryParams = new URLSearchParams(location.search);
  const email = decodeURIComponent(queryParams.get('email'));
  const code = queryParams.get('code');

  /* 
      Event handlers
    */
  const { mutate: CreatePassword, isLoading: isLoadingCreatePasswordMutation } = useMutation({
    mutationFn: async (payload) => await createPassword(payload),
    onSuccess: async () => {
      toast.success('Password created successfully');
      navigate('/auth/login');
    },
    onError: (err) => {
      console.error('Error creating password');
      toast.error('Error creating password');
    }
  });

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

  const handleCreatePassword = (data) => {
    const payload = {
      newPassword: data.password,
      email,
      code
    };

    let message = 'Password must contain: ';
    if (!/(?=.*[a-z])/.test(data.password)) message += '\n- At least one lowercase letter';
    if (!/(?=.*[A-Z])/.test(data.password)) message += '\n- At least one uppercase letter';
    if (!/(?=.*\d)/.test(data.password)) message += '\n- At least one digit';
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(data.password))
      message += '\n- At least one special character';
    if (data.password.length < 8) message += '\n- Minimum length of 8 characters';

    if (message !== 'Password must contain: ') {
      toast.error(message);
      return;
    }

    console.log(payload);
    // Uncomment this line to enable the API call
    CreatePassword(payload);
  };

  /* 
      JSX
    */
  return (
    <div className="text-stone-700 flex justify-between items-stretch h-screen w-screen overflow-hidden">
      <div className="h-screen w-[64%] bg-black">
        <img
          src="https://cdn.pixabay.com/photo/2022/10/03/23/41/house-7497001_1280.png"
          alt="login_vector_image"
          className="w-full h-full !bg-center !bg-cover !object-cover !object-center"
        />
      </div>

      <div className="h-screen w-[36%] bg-white flex flex-col items-center justify-center relative">
      <div className="inline-block w-fit p-4 bg-primary rounded-lg absolute top-[36px] left-[36px]">
        <img src={HousinGramLogo} alt="Logo" className="h-30 w-50 mt-2" />
      </div>
        <div className="w-[92%] rounded-lg p-8 py-14">
          <CreatePasswordForm onSubmit={handleCreatePassword} />
        </div>
      </div>
    </div>
  );
};

export default CreatePasswordPage;
