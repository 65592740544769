import { useQuery } from '@tanstack/react-query';

import React, { useEffect, useState } from 'react';
import {
  getAllBillings,
  getAllPaymentHistory,
  getInvoice
} from '../../../services/api/implementation/impl';
import Table from '../../../../shared/components/layout/Table';
// import { IconList } from "../../utils/IconList";
import searchIcon from '../../../../shared/assets/svg/search.svg';
import filterIcon from '../../../../shared/assets/svg/filter.svg';
import reverseIcon from '../../../../shared/assets/svg/reverse.svg';

import { transformData } from '../../../utils/transformData';
import GridWrapper from '../../../../shared/components/layout/GridWrapper';

// import payments from '../../../static/payments.json';
import toast from 'react-hot-toast';

const PaymentHistory = ({ onChangePaymentId }) => {
  // const { data: payments, refetch: refetchInvoice } = useQuery({
  //   queryKey: ["payments"],
  //   queryFn: () => getInvoice(),
  // });

  /* 
    Meta data
  */
  const paymentFormat = [
    'id',
    'invoice_number',
    'project_name',
    'amount',
    'amount_paid',
    'created_at',
    'actions'
  ];

  /* 
    Data fetching
  */
  const { data: payments } = useQuery({
    queryKey: ['payment'],
    queryFn: getAllPaymentHistory()
  });

  console.log('Payments: ', payments);

  /* 
    Effets
  */
  useEffect(() => {
    // toast.success(`Successfully fetched ${payments?.length} payments`);
  }, [payments?.length]);

  /* 
    Helper method calls
  */

  //const transformedData = payment?.length && payment?.map((el) => transformData(el, InvoiceDataFormat));
  const transformedData =
    payments &&
    payments?.length > 0 &&
    payments?.map((el) =>
      transformData(
        {
          id: el?.id,
          invoice_number: el?.invoice?.invoice_number,
          project_name: el?.invoice?.project.name,
          amount_paid: el?.amount,
          created_at: el?.created_at,
          amount: el?.invoice?.amount
        },
        paymentFormat
      )
    );
  /////////////////////////////////////---------------------------------------------

  const [searchQuery, setSearchQuery] = useState('');
  const [reverseOrder, setReverseOrder] = useState(false);

  const filteredData =
    transformedData &&
    transformedData?.length > 0 &&
    transformedData?.filter((row) =>
      // console.log("rowwww", row)
      row.customer_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );

  if (reverseOrder) {
    filteredData.reverse();
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReverseOrderChange = () => {
    setReverseOrder(!reverseOrder);
  };

  const handleInvoiceFilter = (e) => {
    // OpenModal(e);
    // setmodalWidth(26);
    // setmodalHeight(22);
  };
  return (
    <div className="overflow-y-auto pt-3">
      <div className="h-[31rem] p-6">
        <Table
          headers={[
            'Invoice no.',
            'Project Name',
            'Invoice value',
            'Amount paid',
            'Payment date',
            'Actions'
          ]}
          data={transformedData}
          identifier={'id'}
          onEdit={(id) => onChangePaymentId(id)}
        />
      </div>
    </div>
  );
};

export default PaymentHistory;
