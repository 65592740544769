import React, { useEffect, useState } from 'react';
import Button from '../ui/Button';
import FormInput from '../ui/FormInput';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';
import { loginUser } from '../../services/api/implementation/impl';
import Cookies from 'js-cookie';
import socket from '../../../../socket';
import HousinGramLogo from '../../../shared/assets/svg/group.svg';

const RegisterForm = () => {
  /* 
    Local state
  */
  const [email, setEmail] = useState('');

  /* 
    Hooks
  */
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  /* 
    Event handlers
  */
  const { mutate: handleLogin } = useMutation({
    mutationFn: async (payload) => await loginUser(payload),
    onSuccess: async (res) => {
      console.log({ res });
      const token = res.data.data.token;
      let userRole = res?.data?.data?.user?.role?.name;
      let userRoleId = res?.data?.data?.user?.role?.id;
      let userId = res?.data?.data?.user?.id;
      let userDesignation = res?.data?.data?.user?.designation?.name;

      Cookies.set('token', token);
      Cookies.set('userRoleId', userRoleId);
      Cookies.set('userId', userId);
      Cookies.set('userDesignation', userDesignation);
      Cookies.set('userRole', userRole);

      toast.success('User successfully logged in');

      // Navigate by role
      if (userRole === 'builder') {
        navigate('/builder/management/company');
      } else if (userRole === 'admin') {
        navigate('/super-admin/home');
      }

      // Setup socket
      socket.auth.token = Cookies.get('token');
      socket.connect();
      socket.on('connect', () => {
        console.log('socket connected', socket.connected);
      });
      socket.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
      });
    },
    onError: (err) => {
      console.error('Error logging in user:', err);
      toast.error('Incorrect email or password');
    }
  });

  /* 
    Handle autofill synchronization
    (setTimeout ensures the browser has time to fill them)
  */
  useEffect(() => {
    setTimeout(() => {
      const emailInput = document.querySelector('input[name="email"]');
      const passwordInput = document.querySelector('input[name="password"]');
      if (emailInput && passwordInput) {
        setValue('email', emailInput.value);
        setValue('password', passwordInput.value);
      }
    }, 200);
  }, [setValue]);

  /* 
    Submit handler
  */
  const handleFormSubmit = (data) => {
    const trimmedData = {
      email: data.email.trim(),
      password: data.password.trim()
    };
    handleLogin(trimmedData);
  };

  return (
    <form
      className="flex flex-col gap-[70px] w-full h-full justify-center relative"
      onSubmit={handleSubmit(handleFormSubmit)}
      autoComplete="on">
        {/* <div className="inline-block w-fit p-4 bg-primary rounded-lg absolute top-[36px] left-[36px]">
        <img src={HousinGramLogo} alt="Logo" className="h-30 w-50 mt-2" />
      </div> */}
      <div className='flex flex-col gap-10'>
        <span className="text-stone-800 text-3xl font-semibold">Welcome back!</span>
        <div className="flex flex-col justify-between items-start gap-4">
          <FormInput
            width={100}
            register={register}
            register_key="email"
            label="Email"
            type="email"
            isRequired
            autoComplete="username"
            onType={setEmail}
            // errors={errors.email} // optional if you want to pass error
          />

          <FormInput
            width={100}
            register={register}
            register_key="password"
            label="Password"
            type="password"
            isRequired
            autoComplete="current-password"
            // errors={errors.password} // optional if you want to pass error
          />

          <div className="flex justify-between w-full items-center text-stone-700">
            <span>Trouble logging in ?</span>
            <span
              className="text-primary cursor-pointer"
              onClick={() => navigate('/auth/forgot-password')}>
              Forgot password
            </span>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col justify-center items-center gap-4">
        <Button type="submit" displayType="full-w-primary" width={100}>
          Login
        </Button>
      </div>
    </form>
  );
};

export default RegisterForm;
