// components/AddSalesTarget.jsx
import React, { useState, useEffect } from 'react';
import Button from '../../../shared/components/ui/Button';
import { useForm } from 'react-hook-form';
import NoDataFound from '../layout/NoDataFound';
import PaginationComponent from '../../../shared/components/PaginationComponent';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

// The following imports are placeholders. Replace them with
// your actual API call implementations.
import { getTargetReports, bulkUpdateTarget } from '../../services/api/implementation/impl';

const AddSalesTarget = ({ onClose }) => {
  /*
   * -------------------------------------------------
   * React Hook Form Setup
   * -------------------------------------------------
   */
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-based
  const currentYear = currentDate.getFullYear();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      selected_month: currentMonth.toString(),
      selected_year: currentYear.toString()
    }
  });

  // We'll watch these so our query can re-run if the month/year changes
  const watchedMonth = watch('selected_month');
  const watchedYear = watch('selected_year');

  // Pagination states (adjust as needed; or remove if you have no pagination)
  const [page, setPage] = useState(1);
  const [limit] = useState(500); // items per page

  const queryClient = useQueryClient();

  /*
   * -------------------------------------------------
   * Fetching Targets
   * -------------------------------------------------
   * This fetches all the sales persons (with their targets) 
   * for the selected month/year using getTargetReports.
   */
  const {
    data: targetResponse,
    isFetching: isFetchingTargets,
    isError: isErrorTargets,
    error: errorTargets
  } = useQuery({
    queryKey: ['targets', page, limit, watchedMonth, watchedYear],
    queryFn: () =>
      getTargetReports(page, limit, Number(watchedMonth), Number(watchedYear)),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // Only fetch if month/year are valid (not empty)
    enabled: !!watchedMonth && !!watchedYear
  });

  /*
   * -------------------------------------------------
   * Pre-Fill the Target Fields
   * -------------------------------------------------
   * - If user.target is > 0, prefill with that number.
   * - If user.target is 0 or null, setValue as empty string.
   */
  useEffect(() => {
    if (targetResponse?.data) {
      targetResponse.data.forEach((user) => {
        setValue(
          `target_${user.id}`,
          user.target && user.target > 0 ? user.target : ''
        );
      });
    }
  }, [targetResponse, setValue]);

  // The list of sales users (with or without targets)
  const salesUsers = targetResponse?.data || [];
  const totalItems = salesUsers.length;
  // If using pagination, compute total pages
  const totalPages = Math.ceil(totalItems / limit);

  /*
   * -------------------------------------------------
   * Mutation to Bulk Update Targets
   * -------------------------------------------------
   */
  const { mutate: handleOnSubmitAddTarget, isLoading: isLoadingAddTarget } = useMutation({
    mutationFn: ({ newTargets, selectedMonth, selectedYear }) =>
      bulkUpdateTarget(newTargets, selectedMonth, selectedYear),
    onSuccess: () => {
      toast.success('Targets updated successfully!');
      onClose();
      // Reset form to default
      reset({
        selected_month: currentMonth.toString(),
        selected_year: currentYear.toString()
      });
      // Invalidate or refetch the query so updated data shows next time
      queryClient.invalidateQueries(['targets']);
    },
    onError: (error) => {
      console.error('Error updating targets:', error);
      const errorMessage =
        error?.response?.data?.message || 'Failed to submit form!';
      toast.error(errorMessage);
    }
  });

  /*
   * -------------------------------------------------
   * Handle Form Submission
   * -------------------------------------------------
   */
  const onSubmit = (data) => {
    const selectedMonthNumber = Number(data.selected_month);
    const selectedYearNumber = Number(data.selected_year);

    // Build the payload by including only valid, non-empty, non-zero values
    const payload = [];
    salesUsers.forEach((user) => {
      const val = data[`target_${user.id}`];
      /*
       * Because we used valueAsNumber: true in react-hook-form,
       * an empty input can be NaN. We skip:
       *   - '' (empty string)
       *   - undefined
       *   - null
       *   - 0
       *   - NaN
       */
      if (
        val !== '' &&
        val !== undefined &&
        val !== null &&
        val !== 0 &&
        !Number.isNaN(val)
      ) {
        payload.push({
          user_id: user.id,
          target: Number(val) // or just val, since val is a number already
        });
      }
    });

    // Fire the mutation with the filtered payload
    handleOnSubmitAddTarget({
      newTargets: payload,
      selectedMonth: selectedMonthNumber,
      selectedYear: selectedYearNumber
    });
  };

  /*
   * -------------------------------------------------
   * Pagination Logic (if needed)
   * -------------------------------------------------
   */
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  /*
   * -------------------------------------------------
   * Render
   * -------------------------------------------------
   */
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="p-6 bg-white rounded-lg shadow-md"
    >
      {/* Month / Year Inputs */}
      <div className="flex justify-between items-center">
        {/* Month Selection */}
        <div className="flex flex-col mb-6 w-[48%]">
          <label htmlFor="month" className="mb-2 text-[#67676C]">
            Select Month <span className="text-red-500">*</span>
          </label>
          <select
            id="month"
            className={`w-full p-2 border ${
              errors.selected_month ? 'border-red-500' : 'border-stone-300'
            } rounded`}
            {...register('selected_month', {
              required: 'Month is required',
              valueAsNumber: true,
              validate: (value) => {
                if (Number(value) >= 1 && Number(value) <= 12) {
                  return true;
                }
                return 'Please select a valid month';
              }
            })}
          >
            <option value="">Select a Month</option>
            {[
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ].map((month, index) => (
              <option key={index} value={index + 1}>
                {month}
              </option>
            ))}
          </select>
          {errors.selected_month && (
            <span className="text-red-500 text-sm mt-1">
              {errors.selected_month.message}
            </span>
          )}
        </div>

        {/* Year Selection */}
        <div className="flex flex-col mb-6 w-[48%]">
          <label htmlFor="year" className="mb-2 text-[#67676C]">
            Select Year <span className="text-red-500">*</span>
          </label>
          <select
            id="year"
            className={`w-full p-2 border ${
              errors.selected_year ? 'border-red-500' : 'border-stone-300'
            } rounded`}
            {...register('selected_year', {
              required: 'Year is required',
              valueAsNumber: true,
              validate: (value) => {
                const year = Number(value);
                const cyear = new Date().getFullYear();
                if (year >= cyear - 10 && year <= cyear + 10) {
                  return true;
                }
                return `Please select a year between ${cyear - 10} and ${cyear + 10}`;
              }
            })}
          >
            {Array.from({ length: 21 }, (_, i) => currentYear - 10 + i).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            )}
          </select>
          {errors.selected_year && (
            <span className="text-red-500 text-sm mt-1">
              {errors.selected_year.message}
            </span>
          )}
        </div>
      </div>

      {/* Targets Table or Loading/Error states */}
      {isFetchingTargets ? (
        <div className="text-center py-10">
          <p>Loading sales users and targets...</p>
        </div>
      ) : isErrorTargets ? (
        <div className="text-center py-10 text-red-500">
          <p>
            Error:{' '}
            {errorTargets?.message || 'An error occurred while fetching data.'}
          </p>
        </div>
      ) : salesUsers.length > 0 ? (
        <div>
          {/* The table of users and target fields */}
          <div className="overflow-x-auto">
            <table className="min-w-full border-collapse">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Name</th>
                  {/* <th className="px-4 py-2 border">Role</th> */}
                  <th className="px-4 py-2 border">Set Target</th>
                </tr>
              </thead>
              <tbody>
                {salesUsers.map((user) => (
                  <tr key={user.id} className="hover:bg-gray-100">
                    <td className="px-4 py-2 border w-1/2">{user.name}</td>
                    {/* <td className="px-4 py-2 border">
                      {user.user_type || 'Sales Rep'}
                    </td> */}
                    <td className="px-4 py-2 border w-1/2">
                      <input
                        type="number"
                        min="0"
                        className={`w-full p-2 border rounded ${
                          errors[`target_${user.id}`]
                            ? 'border-red-500'
                            : 'border-stone-300'
                        }`}
                        placeholder="Enter Target"
                        {...register(`target_${user.id}`, {
                          // Not required, can leave empty if no update
                          min: {
                            value: 0,
                            message: 'Target cannot be negative'
                          },
                          valueAsNumber: true
                        })}
                      />
                      {errors[`target_${user.id}`] && (
                        <span className="text-red-500 text-sm mt-1">
                          {errors[`target_${user.id}`].message}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination (if you need it) */}
          <PaginationComponent
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isFetching={isFetchingTargets}
          />

          {/* Form Actions */}
          <div className="flex justify-end mt-6 gap-4">
            <Button
              type="button"
              onClick={onClose}
              displayType="secondary"
              className="mr-4"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isLoadingAddTarget}>
              {isLoadingAddTarget ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
      ) : (
        <div className="text-center py-10">
          <NoDataFound disabledBtn screenHeight />
        </div>
      )}
    </form>
  );
};

export default AddSalesTarget;
