import React from 'react';

const Button = ({ children, onClick, displayType, type, disabled, customStyle }) => {
  if (displayType === 'downloadButton') {
    return (
      <button
        type={type}
        disabled={disabled}
        className={`border ${displayType === 'secondary' ? 'border-customBlue' : 'border-customBlue bg-customBlue'} text-white  py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline`}
        style={customStyle}
        onClick={onClick}>
        {children}
      </button>
    );
  }
  if (displayType === 'primary-red') {
    return (
      <button
        type={type}
        className={`bg-[#C2281B] py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline`}
        disabled={disabled}
        onClick={onClick}>
        <p className={`text-stone-100 hover:customBlue`}>{children}</p>
      </button>
    );
  }

  if (displayType === 'full-w-primary') {
    return (
      <button
        type={type}
        className={`border ${displayType === 'secondary' ? 'border-customBlue' : 'border-customBlue bg-customBlue'}  py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline w-full`}
        disabled={disabled}
        onClick={onClick}>
        <p className={`text-stone-100 hover:customBlue`}>{children}</p>
      </button>
    );
  }

  if (displayType === 'full-w-secondary') {
    return (
      <button
        type={type}
        className={`border  border-customBlue text-customBlue py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline w-full`}
        disabled={disabled}
        onClick={onClick}>
        <p className={`hover:customBlue`}>{children}</p>
      </button>
    );
  }

  if (displayType === 'secondary-red') {
    return (
      <button
        type={type}
        className={`border border-[#C2281B]  py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline`}
        disabled={disabled}
        onClick={onClick}>
        <p className={`text-[#C2281B] hover:customBlue`}>{children}</p>
      </button>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={`border ${displayType === 'secondary' ? 'border-customBlue' : 'border-customBlue bg-customBlue'}  py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline`}
      style={customStyle}
      onClick={onClick}>
      <p
        className={`${displayType === 'secondary' && '!text-customBlue'} text-white hover:customBlue`}>
        {children}
      </p>
    </button>
  );
};

export default Button;
