import { configureStore } from '@reduxjs/toolkit';
import fileSlice from './modules/builder/features/files';

const store = configureStore({
  reducer: {
    file: fileSlice
  }
});

export default store;
