// components/SalesReceivables.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse'; // Import papaparse for CSV generation
import { toast } from 'react-hot-toast'; // Import react-hot-toast for notifications

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Table from '../../shared/components/layout/Table';
import Search from '../../shared/components/ui/Search';
import Button from '../../shared/components/ui/Button';
import PaginationComponent from '../../shared/components/PaginationComponent';
import NoDataFound from '../components/layout/NoDataFound';

import filter from '../../../modules/shared/assets/svg/filter.svg';
import FileDownload from '../../../modules/shared/assets/svg/download.svg';

// <-- The APIs
import {
  getAllProjects,
  getReceivablesReports,
} from '../services/api/implementation/impl';

// <-- The same utility used in UsersList
import { transformData } from '../../super-admin/utils/transformData';

const SalesReceivables = () => {
  /*
   * ----------------------------------------
   * Data Format Array for the Receivables Table
   * ----------------------------------------
   */
  const receivablesDataFormat = [
    'id',
    'block',
    'unit',
    'customer',
    'totalPayable',
    // 'receivableAmount',
    'paid',
    'pending',
  ];

  /*
   * ----------------------------------------
   * Component State
   * ----------------------------------------
   */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Company');
  const applyFilterRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState([]);
  
  // Server-based pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  // Downloading State
  const [isDownloading, setIsDownloading] = useState(false);
  const [dotCount, setDotCount] = useState(0);

  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const { register } = useForm();

  /*
   * ----------------------------------------
   * Fetch Projects
   * ----------------------------------------
   */
  const {
    data: projects,
    isFetching: isFetchingProjects
  } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
    keepPreviousData: true,
  });

  /*
   * ----------------------------------------
   * Fetch Receivables with Server Pagination
   * ----------------------------------------
   */
  const {
    data: receivables,             // This object has { data: [...], meta: {...} }
    isFetching: isFetchingReceivables
  } = useQuery({
    // Include page & limit in the query key so that React Query re-fetches 
    // whenever page or limit changes
    queryKey: ['receivables', page, limit],
    // Pass page & limit to your function. This is the crucial part.
    queryFn: () => getReceivablesReports(page, limit),
    refetchOnMount: "always",
    keepPreviousData: true
  });

  // The list of projects 
  const projectList = projects || [];

  // The actual Receivables array from your API. 
  // e.g.: { status: 200, message: "Order List...", data: [...], meta: {...} }
  const allReceivables = receivables?.data || [];

  // Compute total pages
  const totalItems = receivables?.meta?.total || 0;
  const totalPages = Math.ceil(totalItems / limit);

  /*
   * ----------------------------------------
   * Transform Receivables Data for the Table
   * ----------------------------------------
   */
  const processedReceivables = allReceivables.map((item) => {
    // Calculate paid amount as (totalPayable - due_amount)
    const paidAmount = (item?.totalPayable || 0) - parseFloat(item?.due_amount || 0);

    return {
      // Unique identifier
      id: item?.unit?.id ?? item?.id ?? 'no-id',

      block: item?.blockName || 'N/A',
      unit: item?.unitName || 'N/A',
      customer: item?.leadName || 'N/A',

      totalPayable: `₹${(item?.totalPayable || 0).toLocaleString()}`,
      // receivableAmount: `₹${(item?.receivableAmount || 0).toLocaleString()}`, // Commented out as per original format
      paid: `₹${paidAmount.toLocaleString()}`,
      pending: `₹${(item?.due_amount || 0).toLocaleString()}`,
    };
  });

  // Transform each row so Table sees the same structure 
  const transformedReceivablesData = processedReceivables.map((row) =>
    transformData(row, receivablesDataFormat)
  );

  /*
   * ----------------------------------------
   * Event Handlers
   * ----------------------------------------
   */
  const handlePageChange = (newPage) => {
    // This will trigger the query to re-fetch with the new page
    setPage(newPage);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    handleClearAll();
  };

  const handleProjectNameChange = (e) => {
    setSelectedProjectId(e.target.value);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const handleCheckboxChange = (val) => {
    setCheckedItems((prev) =>
      prev.includes(val) ? prev.filter((item) => item !== val) : [...prev, val]
    );
  };

  const handleClearAll = () => {
    setCheckedItems([]);
    setSelectedFilter('Company');
  };

  // Close filter popup when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (applyFilterRef.current && !applyFilterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        handleClearAll();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /*
   * ----------------------------------------
   * Handle Download CSV using papaparse
   * ----------------------------------------
   */
  const handleDownload = async () => {
    if (isDownloading) return; // Prevent multiple clicks

    setIsDownloading(true);
    try {
      // Fetch all data with page=1 and limit=1000
      const response = await getReceivablesReports(1, 1000);
      const targets = response?.data || [];

      if (targets.length === 0) {
        toast.error('No data available to download.');
        return;
      }

      // Define CSV headers
      const headers = ['Block', 'Unit', 'Customer', 'Total Payable', 'Paid', 'Pending'];

      // Map data to CSV rows
      const rows = targets.map((item) => {
        const paidAmount = (item?.totalPayable || 0) - parseFloat(item?.due_amount || 0);
        return {
          'Block': item?.blockName || 'N/A',
          'Unit': item?.unitName || 'N/A',
          'Customer': item?.leadName || 'N/A',
          'Total Payable': item?.totalPayable !== undefined ? item.totalPayable : 0,
          'Paid': paidAmount !== undefined ? paidAmount : 0,
          'Pending': item?.due_amount !== undefined ? parseFloat(item.due_amount) : 0,
        };
      });

      // Convert to CSV using papaparse
      const csv = Papa.unparse({
        fields: headers,
        data: rows,
      });

      // Create a Blob from the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', `sales_receivables_reports.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success('Download initiated successfully!');
    } catch (downloadError) {
      console.error('Download failed:', downloadError);
      toast.error('Failed to download the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  /*
   * ----------------------------------------
   * Dots Animation Effect
   * ----------------------------------------
   */
  useEffect(() => {
    let interval;
    if (isDownloading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
      }, 500);
    } else {
      setDotCount(0);
    }

    return () => clearInterval(interval);
  }, [isDownloading]);

  const renderDownloadingText = () => {
    return `Downloading${'.'.repeat(dotCount)}`;
  };

  /*
   * ----------------------------------------
   * Rendering
   * ----------------------------------------
   */
  return (
    <div className="w-full flex flex-col z-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Top bar: Search & Filter/Download Icons */}
      <div className="flex items-center justify-between px-6 py-4 bg-white  border-t-[2px] border-stone-100 shadow-sm">
        {/* <Search placeholder="Search here..." /> */}
        <div></div>
        <div className="flex items-center gap-3">
          {/* Filter Icon (Optional) */}
          {/* <img
            onClick={toggleFilter}
            className=" cursor-pointer"
            height={35}
            width={35}
            src={filter}
            alt="Filter Icon"
          /> */}
          {/* Download Button */}
          <button
            className={`relative flex items-center justify-center ${
              isDownloading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleDownload}
            disabled={isDownloading}
            aria-label="Download CSV"
          >
            <img
              height={35}
              width={35}
              src={FileDownload}
              alt="File Download Icon"
            />
            {isDownloading && (
              <span className="absolute top-10 left-0 text-sm text-gray-700">
                {renderDownloadingText()}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Filter Widget */}
      {isFilterOpen && (
        <div
          ref={applyFilterRef}
          className="absolute right-[2rem] mt-[14.5rem] w-[33rem] bg-white border rounded-lg shadow-lg z-[1000]"
        >
          <div className="py-3 px-4 border-b flex justify-between items-center">
            <h4 className="font-semibold">Apply Filters</h4>
            <p
              onClick={handleClearAll}
              className="text-blue-500 underline hover:text-blue-700 transition duration-200 cursor-pointer"
            >
              Clear all
            </p>
          </div>
          <div className="flex justify-between items-start">
            {/* Left Pane (Tabs) */}
            <div className="flex flex-col w-[30%]">
              <p
                className={`py-3 px-4 cursor-pointer border-b  ${
                  selectedFilter === 'Company' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Company')}
              >
                Company
              </p>
              <p
                className={`py-3 px-4 cursor-pointer ${
                  selectedFilter === 'Month' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Month')}
              >
                Month
              </p>
              <p
                className={`py-3 px-4 cursor-pointer border-t ${
                  selectedFilter === 'Block' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Block')}
              >
                Block
              </p>
            </div>
            {/* Right Pane (Checkboxes) */}
            <div className="flex flex-col w-[70%] border-l">
              {selectedFilter === 'Company' && (
                <>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(1)}
                      onChange={() => handleCheckboxChange(1)}
                    />
                    Ved Builders
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(2)}
                      onChange={() => handleCheckboxChange(2)}
                    />
                    Nupur Constructions
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(9)}
                      onChange={() => handleCheckboxChange(9)}
                    />
                    LR Constructions
                  </div>
                </>
              )}
              {selectedFilter === 'Month' && (
                <div className="grid grid-cols-3 gap-1">
                  {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                  ].map((month, index) => (
                    <div key={index} className="flex items-center gap-2 py-2 px-2 text-[#67676C]">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={checkedItems.includes(month)}
                        onChange={() => handleCheckboxChange(month)}
                      />
                      {month}
                    </div>
                  ))}
                </div>
              )}
              {selectedFilter === 'Block' && (
                <>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(3)}
                      onChange={() => handleCheckboxChange(3)}
                    />
                    Block A
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(4)}
                      onChange={() => handleCheckboxChange(4)}
                    />
                    Block B
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(5)}
                      onChange={() => handleCheckboxChange(5)}
                    />
                    Block C
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Actions (Cancel/Apply) */}
          <div className="flex justify-end gap-4 p-6 border-t">
            <Button displayType="secondary" onClick={toggleFilter}>
              Cancel
            </Button>
            <Button onClick={() => { /* Implement Apply Filter Logic */ }}>Apply</Button>
          </div>
        </div>
      )}

      {/* Receivables Table */}
      <div className="w-full h-full">
        {isFetchingReceivables ? (
          <div className="pt-12 text-center">
            <p>Loading...</p>
          </div>
        ) : receivables?.data && receivables.data.length > 0 ? (
          <GridWrapper width={100}>
            <div className="h-[22rem] relative">
              {/* Project Selection (Optional) */}
              <div className="flex justify-between items-center mb-5">
                {/* Uncomment and implement project selection if needed */}
                {/* <div className="flex flex-col gap-2">
                  <label htmlFor="project">Select Project</label>
                  {projectList.length > 0 ? (
                    <select
                      className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
                      {...register('selected_project')}
                      value={selectedProjectId || ''}
                      onChange={handleProjectNameChange}
                    >
                      {projectList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>Loading projects...</p>
                  )}
                </div> */}
              </div>

              {/* Table */}
              <Table
                // The columns match the order in receivablesDataFormat
                headers={[
                  'Block',
                  'Unit',
                  'Customer',
                  'Total Payable',
                  // 'Receivable Amount', // Commented out as per original format
                  'Paid',
                  'Pending/Receivable Amount',
                ]}
                // The entire array from the server for the current page
                data={transformedReceivablesData}
                identifier="id"
                isViewEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />

              {/* Pagination */}
              <PaginationComponent
                page={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                isFetching={isFetchingReceivables}
              />
            </div>
          </GridWrapper>
        ) : (
          <div className="pt-[3rem]">
            <NoDataFound disabledBtn screenHeight={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesReceivables;
