import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import TableData from '../../shared/components/ui/TableData';
import Modal from '../../shared/components/ui/Modal';
import { EditUser } from '../components/forms/EditUser';

import company_image from '../../shared/assets/png/company_image.png';
import {
  deleteUserById,
  editPermission,
  getUserById,
  updateUserById
} from '../services/api/implementation/impl';

const UserDetails = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const { id: userId } = useParams();
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [changedPermissions, setChangedPermissions] = useState({});

  const { data: user, refetch: refetchUser } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUserById(userId),
    enabled: !!userId
  });

  useEffect(() => {
    const filteredPermissions = user?.userResourceAccess?.filter(
      (item) => item.is_visible === true
    );
    setPermissions(filteredPermissions);
    setChangedPermissions({});
    refetchUser();
  }, [user]);

  const deleteMutation = useMutation({
    mutationFn: deleteUserById,
    onMutate: () => {
      setIsDeleting(true); // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsDeleting(false);
      toast.success('User deleted successfully');
      navigate(-1);
    },
    onError: () => {
      toast.error('Error deleting user');
      setIsDeleting(false);
    }
  });

  const updateMutation = useMutation({
    mutationFn: (data) => updateUserById(userId, data),
    onMutate: () => {
      setIsSubmitting(true);
      setIsDeactivating(true);
      // Disable the button when mutation starts
    },
    onSuccess: () => {
      setIsDeactivating(false);
      setIsSubmitting(false);
      toast.success('User updated successfully');
      setIsOpenEditUser(false);
      refetchUser();
    },
    onError: () => {
      toast.error('Error updating user');
      setIsDeactivating(false);
      setIsSubmitting(false);
    }
  });

  const { mutate: handlePermission, isLoading: isLoadingPermission } = useMutation({
    mutationFn: (payload) => editPermission(payload),
    onSuccess: async () => {
      toast.success('User Permission is given successfully');
      setIsEditing(!isEditing);
      setChangedPermissions({});
      await refetchUser(); // UI data update
    },
    onError: (err) => {
      console.error('Error editing new project');
      toast.error('Error editing project');
    }
  });

  const handlePermissionChange = (index, action, value) => {
    const updatedPermission = {
      ...permissions[index],
      [action]: value,
      user_id: userId
    };

    setPermissions((prev) => {
      const newPermissions = [...prev];
      newPermissions[index][action] = value;
      return newPermissions;
    });

    setChangedPermissions((prev) => ({
      ...prev,
      [permissions[index].permission_name]: updatedPermission
    }));
  };

  const handleSubmit = () => {
    const formattedPermissions = Object.values(changedPermissions)?.map((perm) => ({
      resource_name: perm.resource_name,
      permission_name: perm.permission_name,
      user_id: userId,
      can_view: perm.can_view,
      can_create: perm.can_create,
      can_update: perm.can_update,
      can_delete: perm.can_delete
    }));

    console.log('Submitting permissions:', formattedPermissions);

    handlePermission(formattedPermissions);
  };

  const handleDeactivate = () => {
    updateMutation.mutate({ is_active: false });
  };

  const headerData = [
    { name: 'Phone Number', value: user?.phone_number },
    { name: 'Email Address', value: user?.email },
    { name: 'User Type', value: user?.designation?.name }
  ];

  return (
    <>
      {isOpenEditUser && (
        <Modal width={56} name="Edit user" onClose={() => setIsOpenEditUser(false)}>
          <EditUser
            isSubmitting={isSubmitting}
            onSubmit={updateMutation.mutate}
            userId={userId}
            onClose={() => setIsOpenEditUser(false)}
          />
        </Modal>
      )}
      <Layout>
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: `Users > ${user?.name}` }} backArrow={true} />
        </div>

        <GridWrapper width={100}>
          <div className="w-full flex flex-col justify-between items-center gap-8 mt-[6rem]">
            <div className="p-8 rounded-lg bg-white w-full shadow-md flex items-center justify-between gap-6 space-x-10">
              {user?.name ? (
                <div className="bg-gray-300 w-[12rem] h-[12rem] rounded-full flex justify-center items-center">
                  <span className="text-[8rem] text-white bg-center bg-cover !object-cover !object-center">
                    {user.name[0]}
                  </span>
                </div>
              ) : (
                <img
                  src={company_image}
                  alt="company_image"
                  className="w-[12rem] bg-center bg-cover !object-cover !object-center"
                />
              )}

              <div className="flex-1 flex flex-col gap-8">
                <div className="flex justify-between items-start">
                  <div className="flex flex-col gap-4">
                    <span className="font-semibold text-xl">{user?.name}</span>
                    <span className="text-gray-400 text-sm">{user?.user_type}</span>
                  </div>
                </div>

                <div className="w-[88%] flex justify-between items-center gap-4 flex-wrap">
                  {headerData?.map((item, index) => (
                    <div className="flex flex-col gap-4 flex-shrink-0 min-w-[30%]" key={index}>
                      <span>{item.name}</span>
                      <span className="text-sm">{item.value}</span>
                    </div>
                  ))}
                </div>
                <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-4">
                  <Button displayType="secondary" onClick={() => setIsOpenEditUser(true)}>
                    Edit
                  </Button>
                  <Button
                    displayType="secondary-red"
                    onClick={handleDeactivate}
                    disabled={isDeactivating}>
                    Deactivate
                  </Button>
                  {/* <Button
                    displayType="primary-red"
                    onClick={() => deleteMutation.mutate(userId)}
                    disabled={isDeleting}>
                    Delete
                  </Button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="p-8 flex flex-col gap-8 justify-between items-start space-x-4 bg-white shadow-lg mt-10 rounded-lg">
            <div className="w-full flex flex-col gap-4">
              <span className="font-semibold">Permissions</span>

              <div className="w-full bg-gray-100 flex flex-col p-4 ">
                <div className="w-full flex justify-between items-center">
                  <TableData data="Permission" width={30} />
                  <TableData data="View Access" width={30} />
                  <TableData data="Create Access" width={30} />
                  <TableData data="Delete Access" width={30} />
                  <TableData data="Update Access" width={30} />
                </div>
              </div>

              <div className="mt-[-1rem] overflow-scroll h-[35rem] hide-scrollbar">
                {permissions?.map((perm, index) => (
                  <div
                    key={index}
                    className="w-full bg-white border-b-[1px] border-gray-300 flex flex-col p-4">
                    <div className="w-full flex justify-between items-center">
                      <TableData
                        data={perm.permission_name} // Assuming 'resource_name' is the name of the permission section
                        width={30}
                      />
                      {['can_view', 'can_create', 'can_delete', 'can_update']?.map((action) => (
                        <label
                          key={action}
                          className="checkbox-container flex items-center w-[28%] space-x-3">
                          <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-blue-500"
                            disabled={!isEditing}
                            checked={perm[action]} // Ensure this is a boolean value
                            onChange={(e) =>
                              handlePermissionChange(index, action, e.target.checked)
                            }
                          />
                          <span className="ml-2">Enable</span>
                        </label>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-4">
              {isEditing ? (
                <>
                  <Button displayType="secondary" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                  <Button
                    displayType="primary"
                    disabled={Object.keys(changedPermissions).length === 0}
                    onClick={handleSubmit}>
                    Save
                  </Button>
                </>
              ) : (
                <Button displayType="primary" onClick={() => setIsEditing(true)}>
                  Edit Permission
                </Button>
              )}
            </div>
          </div>
        </GridWrapper>
      </Layout>
    </>
  );
};

export default UserDetails;
