import React, { useState, useEffect } from 'react';
import Button from '../../../shared/components/ui/Button';

const AddBuilder = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    primary_username: '',
    primary_phone_number: '',
    primary_email: '',
    secondary_username: '',
    secondary_phone_number: '',
    secondary_email: ''
  });

  const [touched, setTouched] = useState({
    primary_username: false,
    primary_phone_number: false,
    primary_email: false,
    secondary_username: false,
    secondary_phone_number: false,
    secondary_email: false
  });

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);

  // Validate phone number format
  const isValidPhone = (phone) => /^[0-9]{10}$/.test(phone);

  // Validate email format
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validate form data
  const validateForm = (data) => {
    const newErrors = {};

    // Primary field validation
    if (!data.primary_username.trim()) {
      newErrors.primary_username = 'Full Name is required';
    }

    if (!data.primary_phone_number) {
      newErrors.primary_phone_number = 'Phone number is required';
    } else if (!isValidPhone(data.primary_phone_number)) {
      newErrors.primary_phone_number = 'Phone number must be exactly 10 digits';
    }

    if (!data.primary_email) {
      newErrors.primary_email = 'Email is required';
    } else if (!isValidEmail(data.primary_email)) {
      newErrors.primary_email = 'Invalid email';
    }

    // Check if any secondary field is filled
    const hasSecondaryData =
      data.secondary_username.trim() ||
      data.secondary_phone_number.trim() ||
      data.secondary_email.trim();

    // If any secondary field is filled, validate all secondary fields
    if (hasSecondaryData) {
      if (!data.secondary_username.trim()) {
        newErrors.secondary_username = 'Secondary Full Name is required';
      }

      if (!data.secondary_phone_number) {
        newErrors.secondary_phone_number = 'Secondary Phone number is required';
      } else if (!isValidPhone(data.secondary_phone_number)) {
        newErrors.secondary_phone_number = 'Phone number must be exactly 10 digits';
      }

      if (!data.secondary_email) {
        newErrors.secondary_email = 'Secondary Email is required';
      } else if (!isValidEmail(data.secondary_email)) {
        newErrors.secondary_email = 'Invalid email';
      }

      // Check for duplicate phone and email
      if (data.secondary_phone_number === data.primary_phone_number) {
        newErrors.secondary_phone_number =
          'Secondary Phone number cannot be the same as Primary Phone number';
      }

      if (data.secondary_email === data.primary_email) {
        newErrors.secondary_email = 'Secondary Email cannot be the same as Primary Email';
      }
    }

    return newErrors;
  };

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    // Sanitize phone numbers
    if (name.includes('phone_number')) {
      sanitizedValue = value.replace(/[^0-9]/g, '');
    }

    setFormData((prev) => ({
      ...prev,
      [name]: sanitizedValue
    }));
  };

  // Handle field blur
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitAttempted(true);
  
    // Mark all fields as touched
    const allTouched = Object.keys(touched).reduce(
      (acc, key) => ({
        ...acc,
        [key]: true
      }),
      {}
    );
    setTouched(allTouched);
  
    if (Object.keys(validateForm(formData)).length === 0) {
      // Create a filtered payload
      const filteredData = { ...formData };
  
      // Check and remove secondary fields if they are empty
      if (
        !formData.secondary_username.trim() &&
        !formData.secondary_phone_number.trim() &&
        !formData.secondary_email.trim()
      ) {
        delete filteredData.secondary_username;
        delete filteredData.secondary_phone_number;
        delete filteredData.secondary_email;
      }
  
      onSubmit(filteredData);
    }
  };
  

  // Update validation on form changes
  useEffect(() => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0);
  }, [formData]);

  // Helper function to determine if error should be shown
  const shouldShowError = (fieldName) => {
    return (touched[fieldName] || isSubmitAttempted) && errors[fieldName];
  };

  // Check if any secondary field is filled
  const anySecondaryFilled =
    formData.secondary_username.trim() ||
    formData.secondary_phone_number.trim() ||
    formData.secondary_email.trim();

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
      {/* Primary User Details */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b border-stone-200 w-full pb-2">Primary User Details</span>

        <div className="w-full flex flex-col gap-2">
          {/* Primary Full Name */}
          <div className="flex flex-col gap-1">
            <label htmlFor="primary_username" className="text-gray-600">
              Full Name *
            </label>
            <input
              id="primary_username"
              name="primary_username"
              type="text"
              className={`w-full p-2 border ${
                shouldShowError('primary_username') ? 'border-red-600' : 'border-stone-300'
              } rounded`}
              value={formData.primary_username}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Full Name"
              aria-invalid={shouldShowError('primary_username') ? 'true' : 'false'}
              aria-describedby={
                shouldShowError('primary_username') ? 'primary_username_error' : undefined
              }
            />
            {shouldShowError('primary_username') && (
              <span id="primary_username_error" className="text-red-600">
                {errors.primary_username}
              </span>
            )}
          </div>

          <div className="w-full flex flex-col md:flex-row md:space-x-6">
            {/* Primary Phone */}
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <label htmlFor="primary_phone_number" className="text-gray-600">
                Phone Number *
              </label>
              <input
                id="primary_phone_number"
                name="primary_phone_number"
                type="text"
                className={`w-full p-2 border ${
                  shouldShowError('primary_phone_number') ? 'border-red-600' : 'border-stone-300'
                } rounded`}
                value={formData.primary_phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Phone Number"
                maxLength={10}
                aria-invalid={shouldShowError('primary_phone_number') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('primary_phone_number') ? 'primary_phone_number_error' : undefined
                }
              />
              {shouldShowError('primary_phone_number') && (
                <span id="primary_phone_number_error" className="text-red-600">
                  {errors.primary_phone_number}
                </span>
              )}
            </div>

            {/* Primary Email */}
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <label htmlFor="primary_email" className="text-gray-600">
                Email Address *
              </label>
              <input
                id="primary_email"
                name="primary_email"
                type="email"
                className={`w-full p-2 border ${
                  shouldShowError('primary_email') ? 'border-red-600' : 'border-stone-300'
                } rounded`}
                value={formData.primary_email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Email Address"
                aria-invalid={shouldShowError('primary_email') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('primary_email') ? 'primary_email_error' : undefined
                }
              />
              {shouldShowError('primary_email') && (
                <span id="primary_email_error" className="text-red-600">
                  {errors.primary_email}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Secondary User Details */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b border-stone-200 w-full pb-2">
          Secondary User Details (Optional)
        </span>

        <div className="w-full flex flex-col gap-2">
          {/* Secondary Full Name */}
          <div className="flex flex-col gap-1">
            <label htmlFor="secondary_username" className="text-gray-600">
              Full Name {anySecondaryFilled && '*'}
            </label>
            <input
              id="secondary_username"
              name="secondary_username"
              type="text"
              className={`w-full p-2 border ${
                shouldShowError('secondary_username') ? 'border-red-600' : 'border-stone-300'
              } rounded`}
              value={formData.secondary_username}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Full Name"
              aria-invalid={shouldShowError('secondary_username') ? 'true' : 'false'}
              aria-describedby={
                shouldShowError('secondary_username') ? 'secondary_username_error' : undefined
              }
            />
            {shouldShowError('secondary_username') && (
              <span id="secondary_username_error" className="text-red-600">
                {errors.secondary_username}
              </span>
            )}
          </div>

          <div className="w-full flex flex-col md:flex-row md:space-x-6">
            {/* Secondary Phone */}
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <label htmlFor="secondary_phone_number" className="text-gray-600">
                Phone Number {anySecondaryFilled && '*'}
              </label>
              <input
                id="secondary_phone_number"
                name="secondary_phone_number"
                type="text"
                className={`w-full p-2 border ${
                  shouldShowError('secondary_phone_number') ? 'border-red-600' : 'border-stone-300'
                } rounded`}
                value={formData.secondary_phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Phone Number"
                maxLength={10}
                aria-invalid={shouldShowError('secondary_phone_number') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('secondary_phone_number')
                    ? 'secondary_phone_number_error'
                    : undefined
                }
              />
              {shouldShowError('secondary_phone_number') && (
                <span id="secondary_phone_number_error" className="text-red-600">
                  {errors.secondary_phone_number}
                </span>
              )}
            </div>

            {/* Secondary Email */}
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <label htmlFor="secondary_email" className="text-gray-600">
                Email Address {anySecondaryFilled && '*'}
              </label>
              <input
                id="secondary_email"
                name="secondary_email"
                type="email"
                className={`w-full p-2 border ${
                  shouldShowError('secondary_email') ? 'border-red-600' : 'border-stone-300'
                } rounded`}
                value={formData.secondary_email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Email Address"
                aria-invalid={shouldShowError('secondary_email') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('secondary_email') ? 'secondary_email_error' : undefined
                }
              />
              {shouldShowError('secondary_email') && (
                <span id="secondary_email_error" className="text-red-600">
                  {errors.secondary_email}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Form Actions */}
      <div className="w-full flex justify-end items-center space-x-4">
        <Button displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit">Add</Button>
      </div>
    </form>
  );
};

export default AddBuilder;
