import React, { useEffect, useState } from 'react';
import Button from '../../shared/components/ui/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getAllBlocks,
  getUnitPricingByBlockId,
  updateUnitStatus,
  getUnitStatus
} from '../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import Table from '../../shared/components/layout/Table';
import { useForm } from 'react-hook-form';
import { transformData } from '../../super-admin/utils/transformData';
import toast from 'react-hot-toast';
import { UpdateStatus } from '../components/forms/UpdateStatus';
import Modal from '../../shared/components/ui/Modal';
import PaginationComponent from '../../shared/components/PaginationComponent';

const UnitPricingList = () => {
  const { id: projectId } = useParams();
  const { register } = useForm();
  const [newBlockId, setNewBlockId] = useState(''); // Set the initial state to an empty string
  const [blocksDynaWithLevels, setBlocksDynaWithLevels] = useState([]);
  const [isOpenPaymentStageModal, setIsOpenPaymentStagesModal] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [updateStatusOptions, setUpdateStatusOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: blocksDyna, refetch: refetchBlocks } = useQuery({
    queryKey: ['blocks', projectId],
    queryFn: () => getAllBlocks(projectId),
    enabled: !!projectId
  });

  // pagination
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // items per page

  const {
    data: unitPricingData,
    refetch: refetchUnitPricingData,
    isFetching
  } = useQuery({
    queryKey: ['unit', page],
    queryFn: () => getUnitPricingByBlockId(newBlockId, page, limit),
    keepPreviousData: true
  });

  const { mutate: handleUnitStatusUpdate } = useMutation({
    mutationFn: (payload) => updateUnitStatus(selectedUnitId, payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async (data) => {
      setIsSubmitting(false);
      toast.success('Unit status updated successfully');
      await refetchUnitPricingData();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error updating unit status', err);
      toast.error('Error updating unit status', err);
    }
  });

  const totalPages = unitPricingData?.meta ? Math.ceil(unitPricingData?.meta.total / limit) : 1;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  console.log({ unitPricingData });

  const UpdateStatusOptions = [
    // { id: 1, name: 'Booked' },
    { id: 2, name: 'Vacant' },
    { id: 3, name: 'Blocked' },
    { id: 4, name: 'Temporary_Booked' },
    { id: 5, name: 'Sales_Deed_Completed' },
    { id: 6, name: 'Sales_Deed_with_Pending_Payments' },
    { id: 7, name: 'No_Dues' },
    { id: 8, name: 'Blocked_for_Broker' }
  ];

  //the above status i want to fetch it from a api call named unitStatus and then use it here
  const { data: unitStatus, refetch: refetchUnitStatus } = useQuery({
    queryKey: ['unitStatus', page],
    queryFn: () => getUnitStatus(),
    enabled: true
  });

  useEffect(() => {
    if (unitStatus) {
      console.log('unitStatus', unitStatus);
      refetchUnitStatus();
      const modifiedArray = unitStatus?.filter((item) => item !== "Booked");
      setUpdateStatusOptions(modifiedArray);
    }
  }, [unitStatus]);

  const UnitPricingFormat = [
    'id',
    'unitName',
    'cost',
    'plc_price',
    'gst',
    'total_price',
    'accommodationName',
    'status',
    'actions'
  ];

  const UnitPricingData =
    unitPricingData?.data && unitPricingData?.data?.length > 0
      ? unitPricingData?.data
          .map((item) =>
            transformData(
              {
                ...item,
                cost: parseInt(item?.cost) || 0,
                plc_price: parseInt(item?.plc_price) || 0,
                total_price: `₹${(
                  parseInt(item?.cost) + parseInt(item?.plc_price) + parseInt(item?.gst) || 0
                ).toLocaleString()}.00`,
                accommodationName: item?.accommodation?.name,
                gst: `₹${parseInt(item?.gst)}`,
                status: item?.status
              },
              UnitPricingFormat
            )
          )
          .sort((a, b) => {
            const nameA = a.unitName.toLowerCase();
            const nameB = b.unitName.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          })
      : [];

  console.log({ UnitPricingData });

  const handleSelectBlockChange = (event) => {
    const selectedBlockId = event.target.value;
    setNewBlockId(selectedBlockId); // Set the new block ID
  };

  const handleStatusUpdate = (unitId) => {
    setOpenUpdateStatus(!openUpdateStatus);
    console.log('id', unitId);
    setSelectedUnitId(unitId);
  };

  useEffect(() => {
    if (Array.isArray(blocksDyna) && blocksDyna.length > 0) {
      // Check if blocksDyna is an array and has items
      const blocksWithLevels = blocksDyna.filter((block) => block?.levels?.length > 0);
      const sorted = blocksWithLevels.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      console.log(sorted, 'sorted');

      if (sorted.length > 0) {
        setBlocksDynaWithLevels(sorted);
        setNewBlockId(sorted[0].id); // Set the first block ID automatically
      }
    }
  }, [blocksDyna]);

  useEffect(() => {
    // Only refetch the unit pricing data when a valid block ID is set
    if (newBlockId) {
      refetchUnitPricingData();
    }
  }, [newBlockId, refetchUnitPricingData]);

  return (
    <>
      {openUpdateStatus && (
        <Modal width={40} name="Update Status" onClose={() => setOpenUpdateStatus(false)}>
          <UpdateStatus
            onClose={() => setOpenUpdateStatus(false)}
            statuses={updateStatusOptions}
            isSubmitting={isSubmitting} 
            onSubmit={handleUnitStatusUpdate}
            type={'unitPricing'}
          />
        </Modal>
      )}
      <div className="w-full flex flex-col absolute top-[16rem] z-10 pl-5 pr-5 bg-gray-100 pb-10 overflow-hidden">
        <div className="flex justify-between items-center">
          <div className="mt-5 flex flex-col gap-2">
            <label htmlFor="">Select Block</label>
            {blocksDynaWithLevels && blocksDynaWithLevels.length > 0 && (
              <select
                className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
                {...register(`table_type`)}
                value={newBlockId}
                onChange={handleSelectBlockChange}>
                {blocksDynaWithLevels?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="h-[25rem] mt-8 hide-scrollbar overflow-y-scroll relative">
          <Table
            headers={[
              'Unit Name',
              'Unit Price',
              'Total Plc Price',
              'GST',
              'Total Price',
              'Acc Name',
              'Status',
              'Action'
            ]}
            data={UnitPricingData}
            identifier={'id'}
            isEditEnable={false}
            isStatusUpdateEnable={true}
            onStatusUpdate={(id) => handleStatusUpdate(id)}
            // onStatusUpdate={handleStatusUpdate}
          />

          <PaginationComponent
            page={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isFetching={isFetching}
          />
        </div>
      </div>
    </>
  );
};

export default UnitPricingList;
