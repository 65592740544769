import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import Table from '../../../shared/components/layout/Table';
import GridWrapper from '../../../shared/components/layout/GridWrapper';
import { transformData } from '../../../super-admin/utils/transformData';
import { addBrand } from '../../services/api/implementation/impl';
import toast from 'react-hot-toast';

// Validation schema
const validationSchema = yup.object().shape({
  brands: yup.array().of(
    yup.object().shape({
      brand_name: yup
        .string()
        .required('Brand name is required')
        .max(50, 'Brand name should be at most 50 characters')
    })
  )
});

export const AddBrands = ({ onClose, brandList, categoryId }) => {
  const [showForm, setShowForm] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      brands: brandList?.map((brand) => ({ brand_name: brand.name }))
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'brands'
  });

  const onAddBrandClick = () => {
    setShowForm(true);
    append({ brand_name: '' });
  };

  const onSubmitForm = async (data) => {
    console.log({ data });

    try {
      await Promise.all(data.brands?.map((brand) => addBrand({ ...brand, categoryId })));
      toast.success('Brands added successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to add brands');
    }
    reset();
  };

  const brandsDataFormat = ['id', 'name'];
  const transformedData = brandList?.map((item) => transformData(item, brandsDataFormat));

  return (
    <>
      {/* <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Brand Details
        </span>
      </div> */}
      {/* <div className="flex justify-end mt-5">
        <p className='text-primary cursor-pointer' onClick={onAddBrandClick}>
          Add Your Brand
        </p>
      </div> */}
      <GridWrapper width={100}>
        <div className="mt-[2rem] h-[10rem]">
          <Table
            headers={['Brands']}
            data={transformedData}
            identifier={'id'}
            isEditEnable={false}
          />
          {showForm && (
            <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6 pb-6">
              {fields?.map((field, index) => (
                <div key={field.id} className="flex justify-between items-center w-full">
                  <FormInput
                    width={100}
                    register_key={`brands.${index}.brand_name`}
                    register={register}
                    type={'text'}
                    errors={errors?.brands?.[index]?.brand_name}
                    placeholder={'Brand Name'}
                  />
                  <Button type="button" displayType="secondary" onClick={() => remove(index)}>
                    Remove
                  </Button>
                </div>
              ))}

              <div className="w-full flex justify-end items-center space-x-4 mt-8">
                <Button type="button" onClick={onClose} displayType="secondary">
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </div>
            </form>
          )}
        </div>
      </GridWrapper>
    </>
  );
};
