import React from 'react';

import GridWrapper from '../../../shared/components/layout/GridWrapper';
import Button from '../../../shared/components/ui/Button';

import company_image from '../../../shared/assets/png/company_image.png';

import { useQuery } from '@tanstack/react-query';

import { getProjectByID } from '../../services/api/implementation/impl';
import { useParams } from 'react-router-dom';
import ProjectBuilding from '../../../shared/assets/png/Project_Building.webp'

const ProjectOverview = () => {
  const { id: projectId } = useParams();

  /* 
    Data fetching
  */
  const { data: project } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => getProjectByID(projectId)
  });
  console.log({ project });

  const formatAreaType = (areaType) => {
    return areaType?.replace('sq/', '');
  };

  /* 
    Header data
  */
  const headerData = [
    {
      _id: 1,
      name: 'Project Name',
      value: project?.name || '_____'
    },
    {
      _id: 2,
      name: 'Land Name',
      value: project?.land?.name || '_____'
    },
    {
      _id: 3,
      name: 'Total Area',
      value: `${project?.land?.total_mainArea + " " + formatAreaType(project?.land?.total_mainAreaType)}, ${project?.land?.total_subArea + " " + formatAreaType(project?.land?.total_subAreaType)}` || '_____'
    },
    // {
    //   _id: 5,
    //   name: 'Phone',
    //   value: project?.owner_phone_numbers ? `+91 ${project?.owner_phone_numbers.join(", ")}` : '_____'
    // },
    {
      _id: 6,
      name: 'Khasra number',
      value: project?.land?.khasra
        ? project?.land?.khasra?.map((item) => item.number)?.join(', ')
        : '_____'
    }
  ];

  function convertDateToDDMMYYYY(dateString) {
    // Parse the input into a Date object
    const date = new Date(dateString);

    // Check for invalid date
    if (isNaN(date)) {
      return 'Invalid Date';
    }

    // Extract day, month, and year
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getUTCFullYear();

    // Return in dd/mm/yyyy format
    return `${day}/${month}/${year}`;
  }

  const secondaryHeaderData = [
    {
      _id: 1,
      name: 'Project Type',
      value: project?.project_type || '_____'
    },
    {
      _id: 2,
      name: 'Project start date',
      value: convertDateToDDMMYYYY(project?.start_date || '') || '_____'
    },
    {
      _id: 3,
      name: 'Building permission number',
      value: project?.buildings?.map((item) => item.permissionNo).join(',') || '_____'
    },
    {
      _id: 4,
      name: 'RERA registration number',
      value: project?.buildings?.map((item) => item.rera_registration_no).join(',') || '_____'
    }
  ];

  return (
    <div className="w-full absolute top-[16rem] z-5 bg-gray-100 pb-10 overflow-x-hidden">
      <GridWrapper width={100}>
        <div className="flex justify-between gap-10 w-full items-center bg-white p-6 shadow-lg rounded-md">
          {project?.projectImages && project?.projectImages[0]?.url ? (
            <img
              src={project?.projectImages[0]?.url}
              alt="projectImage"
              className="w-[10rem] h-[11rem] bg-center bg-cover !object-cover !object-center rounded-lg"
            />
          ) : (
            <img
              src={ProjectBuilding}
              alt="projectImages"
              className="h-[20rem] w-[20rem] bg-cover bg-center rounded-lg !object-cover !object-center"
            />
          )}

          <div className="grid grid-cols-2 gap-8 w-full h-full ">
            {/* Left Column */}
            <div className="flex flex-col gap-6">
              {headerData?.map((item) => (
                <div className="flex gap-4 items-center" key={item?._id}>
                  <span className="font-semibold w-[600px] ">{item?.name}</span>
                  <span className="text-sm w-full border-b border-gray-200">{item?.value}</span>
                </div>
              ))}
            </div>

            {/* Right Column */}
            <div className="flex flex-col gap-6">
              {secondaryHeaderData?.map((item) => (
                <div className="flex gap-10 items-center" key={item?._id}>
                  <span className="font-semibold w-[600px] ">{item?.name}</span>
                  <span className="text-sm w-full border-b border-gray-200">{item?.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </GridWrapper>

      {/* Projects */}
      {/* <div className="w-full px-6">
        <div className="bg-white px-4 py-4 rounded-md w-full flex justify-between items-center">
          <span className="text-stone-700 font-semibold text-lg">People</span>
          <Button>Assign to Project</Button>
        </div>
      </div> */}

      {/* Details */}
      {/* <div className="w-full px-6 mt-6">
        <div className="bg-white px-16 py-8 rounded-md w-full flex justify-between items-center shadow-lg">
          {secondaryHeaderData?.map((item) => (
            <div className="flex flex-col items-start justify-start gap-4  " key={item?._id}>
              <span className="font-semibold">{item?.name}</span>
              <span className=" text-sm ">{item?.value}</span>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ProjectOverview;
