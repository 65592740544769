import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Modal from '../../shared/components/ui/Modal';
import Button from '../../shared/components/ui/Button';
import ZohoIntegration from '../components/forms/ZohoIntegration';
import ZohoLogo from '../../shared/assets/svg/zohoLogo.svg';
import Play from '../../shared/assets/svg/play.svg';
import { addZohoIntegration, getZohoIntegration } from '../services/api/implementation/impl';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

const Integration = () => {
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
  const [isConfigureModalOpen, setIsConfigureModalOpen] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [latestZohoData, setLatestZohoData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDemoModalToggle = () => {
    setIsDemoModalOpen(!isDemoModalOpen);
  };

  const handleConfigureModalToggle = () => {
    setIsConfigureModalOpen(!isConfigureModalOpen);
  };

  const handleConnect = (data) => {
    console.log('Connecting with Zoho using:', data);
    handleAddZohoIntegration(data);
  };

  const { mutate: handleAddZohoIntegration } = useMutation({
    mutationFn: (payload) => addZohoIntegration(payload),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Zoho Integration added successfully');
      setIsConfigureModalOpen(false);
      await refetchZohoIntegration();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error adding Zoho Integration', err);
      toast.error('Error adding Zoho Integration');
    }
  });

  const { data: zohoIntegrationData, refetch: refetchZohoIntegration } = useQuery({
    queryKey: ['zohoIntegration'],
    queryFn: getZohoIntegration
  });

  console.log('Zoho Integration Data:', zohoIntegrationData);

  useEffect(() => {
    if (zohoIntegrationData) {
      setIsConfigured(true);
      // Set the latest data (last item in the array)
      setLatestZohoData(zohoIntegrationData);
    }
  }, [zohoIntegrationData]);

  return (
    <Layout>
      {/* Header */}
      <div className="fixed top-0 left-[18vw] z-40 w-[82vw]">
        <Header data={{ name: 'Integrations' }} />
      </div>

      {/* Main Content */}
      <div className="mt-[4.5rem] w-full h-full bg-gray-100 p-6">
        <div className="flex items-start gap-8">
          {/* Zoho Integration Card */}
          <div className="w-[25%] bg-white shadow-md p-6 rounded-lg flex flex-col items-start">
            <img src={ZohoLogo} alt="Zoho Logo" className="h-full w-full mb-4" />
            <h3 className="font-bold text-lg mb-2 flex gap-2">
              Zoho
              {isConfigured && (
                <div className="text-[#418b7a] flex gap-[2px] justify-center items-center text-sm font-medium">
                  <span className="border bg-[#418b7a] rounded-full p-[0.20rem] text-[#418b7a] mt-[2px]"></span>
                  <p>Live</p>
                </div>
              )}
            </h3>
            <p className="text-sm text-gray-600 mb-4 text-justify">
              Easily integrate with Zoho to sync your data and streamline your processes. Whether
              its Zoho CRM, Zoho Projects, or other Zoho apps, our integration ensures smooth
              connectivity and helps you work more efficiently without manual data transfers.
            </p>
            {isConfigured ? (
              <Button onClick={handleConfigureModalToggle} displayType={'full-w-primary'}>
                Update
              </Button>
            ) : (
              <div className="w-full flex gap-4 justify-between">
                <Button displayType="secondary" onClick={handleDemoModalToggle}>
                  <span className="flex gap-1 justify-start items-center">
                    <img src={Play} alt="" className="pt-1" />
                    Demo
                  </span>
                </Button>
                <Button onClick={handleConfigureModalToggle}>Configure</Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Demo Modal */}
      {isDemoModalOpen && (
        <Modal name="Zoho Demo" onClose={handleDemoModalToggle} width={50}>
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/zv0jXPmKBrk"
            title="Zoho Integration Demo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </Modal>
      )}

      {/* Configure Modal */}
      {isConfigureModalOpen && (
        <ZohoIntegration
          isSubmitting={isSubmitting}
          onClose={handleConfigureModalToggle}
          onConnect={handleConnect}
          defaultValues={latestZohoData}
        />
      )}
    </Layout>
  );
};

export default Integration;
