import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GridWrapper from '../../shared/components/layout/GridWrapper';
import Button from '../../shared/components/ui/Button';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';
import toast from 'react-hot-toast';
import { transformData } from '../../super-admin/utils/transformData';
import { useQuery } from '@tanstack/react-query';
import {
  getByIdRequirements,
  getQuatationByRequirement,
  updateQuatationRequestItems
} from '../services/api/implementation/impl';
import Modal from '../../shared/components/ui/Modal';

const OrdersRequirement = () => {
  /* 
      Hooks
    */
  const navigate = useNavigate();
  const { id: requirementId } = useParams();
  const [isCompletedPayment, setIsCompletedPayment] = useState(false);
  const [AcceptId, setAcceptId] = useState(null);
  const [isNotes, setisNotes] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkIfAccepted, setCheckIfAccepted] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState(null);

  const { data: GetQuatationByRequirement, refetch: refetchGetQuatationByRequirement } = useQuery({
    queryKey: ['quotation', parseInt(requirementId, 10)],
    queryFn: () => getQuatationByRequirement(parseInt(requirementId, 10)),
    enabled: !!parseInt(requirementId, 10)
  });

  console.log({ GetQuatationByRequirement });

  useEffect(() => {
    refetchGetQuatationByRequirement();
  }, [requirementId]);

  /* 
      Dummy data
    */
  function changeDateToCustomFormat(originalDate) {
    // Create a new Date object with the desired date and time
    const newDate = new Date(originalDate);

    // Define options for formatting the date
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    // Format the date according to the options
    const formattedDate = newDate.toLocaleString('en-US', options);

    return formattedDate;
  }

  //write a function to handle date month and year 
  function customDateFormat(originalDate) {
    // Create a new Date object with the desired date and time
    const newDate = new Date(originalDate);

    // Define options for formatting the date
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };

    // Format the date according to the options
    const formattedDate = newDate.toLocaleString('en-US', options);

    return formattedDate;
  }

  const handleQuotation = (item) => {
    return {
      id: item.id,
      vendor: item?.vendor?.name,
      category: item?.vendorRequirement?.category?.name,
      amount: item?.total_amount,
      validity: customDateFormat(item?.validity_date),
      notes: item?.notes == null ? '' : 'Notes'
    };
  };

  const itemsDataFormat = ['id', 'vendor', 'category', 'amount', 'validity', 'notes', 'actions'];
  const quotation = GetQuatationByRequirement?.map((item) =>
    transformData(handleQuotation(item), itemsDataFormat)
  );

  /* 
      Event handlers
    */
  const handleAccept = () => {
    setIsSubmitting(true);
    updateQuatationRequestItems(parseInt(AcceptId), {
      status: 'Accept'
    })
      .then((res) => {
        setIsSubmitting(false);
        toast.success('Quotation accepted successfully');
        navigate(-1);
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error('Error in update Quatation');
        console.log('Error in update Quatation');
      });
  };

  const handleReject = (id) => {
    updateQuatationRequestItems(parseInt(id), {
      status: 'Rejected'
    })
      .then((res) => {
        toast.error('Quotation rejected');
        navigate(-1);
      })
      .catch((err) => {
        console.log('Error in update Quatation');
      });
  };

  const handleAcceptModal = (id) => {
    setAcceptId(id);
    setIsCompletedPayment(true);
  };

  const handleViewDetails = (id) => {
    navigate(`/builder/operations/orders/requirement/${requirementId}/list/${id}`);
  };

  const handleNotes = (id) => {
    console.log('handleNotes id', id);
    const quotation = GetQuatationByRequirement.find((item) => parseInt(item.id) === parseInt(id));
    console.log('quotation GetQuatationByRequirement', quotation);
    setSelectedNotes(quotation?.notes);
    setisNotes(true);
  };

  //write a useeffect to check if a single item in the quotation has a status "Accept" if yes then set the state to true
  useEffect(() => {
    if (GetQuatationByRequirement) {
      const check = GetQuatationByRequirement.some((item) => item.status === 'Accept');
      setCheckIfAccepted(check);
    }
  }, [GetQuatationByRequirement]);
  

  /* 
      JSX
    */
  return (
    <>
      {isCompletedPayment && (
        <Modal name={''} width={55} onClose={() => setIsCompletedPayment(false)}>
          <div>
            <div className="flex justify-center mb-8 text-xl font-semibold">
              <p>Do you want to mark this Completed ?</p>
            </div>
            <div className="flex gap-8 justify-center">
              <Button displayType={'secondary'} onClick={() => setIsCompletedPayment(false)}>
                Cancel
              </Button>
              <Button onClick={handleAccept} disabled={isSubmitting}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isNotes && (
        <Modal name={''} width={55} onClose={() => setisNotes(false)}>
          <div>
            <div className="flex justify-center mb-8 text-xl font-semibold">
              <p className='text-lg'>{selectedNotes}</p>
            </div>
          </div>
        </Modal>
      )}
      <Layout>
        {/* Header */}
        <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
          <Header data={{ name: `Requirement ` }} backArrow={true} />
        </div>

        {/* Grid */}
        <GridWrapper width={100}>
          <div className="mt-[6rem] h-[20rem]">
            <Table
              headers={['Vendor', 'Category', 'Quotations Amount', 'Validity', 'Notes', 'Action']}
              data={quotation}
              identifier={'id'}
              onView={handleViewDetails}
              onAccept={(id) => handleAcceptModal(id)}
              onCancel={(id) => handleReject(id)}
              isViewEnable={true}
              isAcceptEnable={!checkIfAccepted}
              isCancelEnable={true}
              isEditEnable={false}
              onViewNote={(id) => handleNotes(id)}
            />
          </div>

          {/* <div className="w-full flex justify-end items-center space-x-4 pr-4 mt-[60px]">
                        <Button displayType={'primary-red'} onClick={handleReject}>
                            Reject
                        </Button>
                        <Button displayType={'primary'} onClick={handleAccept}>
                            Accept
                        </Button>
                    </div> */}
        </GridWrapper>
      </Layout>
    </>
  );
};

export default OrdersRequirement;
