import React from 'react';
import DateFormat from '../../../super-admin/utils/DateFormat';

const ActivityCard = ({ special, data }) => {
  return (
    <div className="flex flex-col w-full flex-shrink-0 border-l border-dashed border-l-gray-400 left-0 gap-5 relative">
      <div className="flex flex-col items-start gap-4  pl-6">
        <div className="flex flex-col gap-0">
          <div className="flex justify-between items-start space-x-4">
            <span className="text-lg text-[#2A565E]">{data?.name}</span>

            {/* <span className="p-2 px-3 bg-[#265753] text-gray-100 rounded-full">₹ {data?.price || 4000}</span> */}
          </div>
          <span className="text-gray-600 mt-[10px]">
            {DateFormat(data?.created_at)} - {DateFormat(data?.updated_at)}
          </span>
        </div>
      </div>

      <div className="w-[96%] flex flex-col gap-2 p-4 rounded-md bg-[#E9F5F6] self-center">
        <span className="text-gray-700 font-semibold">{data?.primaryExtra || 'Verification'}</span>
        <span className="text-gray-500">{data?.secondaryExtra || 'Checked'}</span>
      </div>

      <img
        src={`/icons/${special ? 'document' : 'organisation'}.svg`}
        alt="document_icon"
        className="absolute left-[-10px] top-0 bg-white"
      />
    </div>
  );
};

export default ActivityCard;
