import React from 'react';
import ActivityCard from '../components/layout/ActivityCard';
import { getActivityByID, getLeadByID } from '../services/api/implementation/impl';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

const ActivityList = () => {
  const { id } = useParams();

  const {
    data: activityId,
    isLoading,
    error,
    refetch: refetchLeadbyId
  } = useQuery({
    queryKey: ['activityById', parseInt(id)],
    queryFn: async () => {
      const res = await getActivityByID(parseInt(id));
      return res;
    },
    enabled: !!id // Only run the query if id is truthy
  });

  console.log({ activityId });
  /*
    Meta data
  */
  const activityMetadata = [
    {
      id: 1,
      name: 'Signed contract',
      price: '2000',
      startTime: '12am',
      endTime: '04pm',
      primaryExtra: 'Contract signed',
      secondaryExtra: 'Done'
    },
    {
      id: 2,
      name: 'Meeting scheduled',
      price: '3000',
      startTime: '09am',
      endTime: '06pm',
      primaryExtra: 'Status',
      secondaryExtra: 'Done'
    },
    {
      id: 3,
      name: 'Negotiation started',
      price: '40000',
      startTime: '10am',
      endTime: '11am',
      primaryExtra: 'Verification',
      secondaryExtra: 'Checked'
    },
    {
      id: 4,
      name: 'Possession given',
      price: '300',
      startTime: '09am',
      endTime: '10am',
      primaryExtra: 'Deal agreed',
      secondaryExtra: 'Checked'
    }
  ];

  /* 
    JSX
  */
  return (
    <div className="flex flex-col bg-white shadow-lg rounded-lg mt-[12rem] w-[96%] mx-auto p-6">
      <span className="text-lg font-semibold">Activities</span>

      <div className="w-full flex flex-col justify-between items-center gap-8 mt-10">
        {activityId?.map((item, index) => (
          <ActivityCard key={index} special={index === 0} data={item} />
        ))}
      </div>
    </div>
  );
};

export default ActivityList;
