import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import FormInput from '../../../shared/components/ui/FormInput';
import Button from '../../../shared/components/ui/Button';
import user from '../../../shared/assets/svg/organisation.svg';
import { getStateAndCity } from '../../services/api/implementation/impl';
import { uploadFile } from '../../features/files';

const EditCompany = ({ data, onUpliftChange, onSubmit, onClose, isSubmitting }) => {
  const dispatch = useDispatch();
  const fileUrls = useSelector((state) => state.file.fileUrls);

  const [city, setCity] = useState('Select');
  const [stateVal, setStateVal] = useState('-');
  const [cityDropdownOptions, setCityDropdownOptions] = useState([]);

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      company_name: data?.company_name || '',
      company_type: data?.company_type || '',
      company_email: data?.company_email || '',
      company_number: data?.company_number || '',
      cin: data?.cin || '',
      tin: data?.tin || '',
      pan: data?.pan || '',
      tan: data?.tan || '',
      gst: data?.gst || '',
      zip_code: data?.zip_code || '',
      state: data?.state || '',
      city: data?.city || '',
      country: data?.country || '',
      coloniser_license_no: data?.coloniser_license_no || '',
    },
  });

  // ---- Handle Pincode (Zip) ----
  const handlePincodeChange = useCallback(
    async (pincode) => {
      const cleanedPincode = pincode.toString().replace(/[^0-9]/g, '');
      setValue('zip_code', cleanedPincode);

      if (cleanedPincode.length === 6) {
        try {
          const pincodeList = await getStateAndCity(parseInt(cleanedPincode, 10));
          const postOfficeList = pincodeList[0]?.PostOffice;

          if (postOfficeList && postOfficeList.length > 0) {
            const uniqueCities = [...new Set(postOfficeList.map((office) => office.Name))];
            setCityDropdownOptions(uniqueCities.map((item) => ({ value: item, label: item })));

            // Handle State
            const stateFromPincode = postOfficeList[0].State;
            if (data?.state && data.state === stateFromPincode) {
              setValue('state', data.state);
              setStateVal(data.state);
            } else {
              setValue('state', stateFromPincode);
              setStateVal(stateFromPincode);
            }

            // Handle City
            if (data?.city && uniqueCities.includes(data.city)) {
              setValue('city', data.city);
              setCity(data.city);
            } else {
              const defaultCity = uniqueCities[0];
              setValue('city', defaultCity);
              setCity(defaultCity);
            }
          } else {
            throw new Error('No post office found for this pincode');
          }
        } catch (error) {
          toast.error('The entered pincode is invalid. Please enter a valid pincode.');
          setValue('state', '-');
          setValue('city', 'Select');
          setStateVal('-');
          setCity('Select');
          setCityDropdownOptions([]);
        }
      }
    },
    [data, setValue]
  );

  // Initialize state/city if data is already available
  useEffect(() => {
    if (data?.zip_code && data?.state && data?.city) {
      setValue('state', data.state);
      setValue('city', data.city);
      setStateVal(data.state);
      setCity(data.city);
      handlePincodeChange(parseInt(data.zip_code, 10));
    }
  }, [data, setValue, handlePincodeChange]);

  // ---- Prefill coloniser license doc if needed ----
  useEffect(() => {
    if (fileUrls?.coloniser_license_doc) {
      setValue('coloniser_license_doc', fileUrls.coloniser_license_doc);
    } else if (data?.coloniser_license_doc?.length > 0) {
      setValue(
        'coloniser_license_doc',
        data.coloniser_license_doc.map((doc) => ({ name: doc.name, url: doc.url }))
      );
      dispatch(
        uploadFile({
          fileType: 'coloniser_license_doc',
          url: data.coloniser_license_doc.map((doc) => ({ name: doc.name, url: doc.url })),
        })
      );
    }
  }, [data, fileUrls, setValue, dispatch]);

  // ---- Prefill company logo if needed ----
  useEffect(() => {
    if (fileUrls?.company_logo) {
      // If the user has just uploaded or updated the file in the UI
      setValue('company_logo', fileUrls.company_logo);
    } else if (data?.company_logo?.length > 0) {
      // If the data from the API already has a stored logo
      setValue(
        'company_logo',
        data.company_logo.map((doc) => ({ name: doc.name, url: doc.url }))
      );
      dispatch(
        uploadFile({
          fileType: 'company_logo',
          url: data.company_logo.map((doc) => ({ name: doc.name, url: doc.url })),
        })
      );
    }
  }, [data, fileUrls, setValue, dispatch]);

  // ---- Handlers for various input transformations ----
  const handleGSTChange = useCallback(
    (gst) => {
      const cleanedGST = gst.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
      setValue('gst', cleanedGST);
    },
    [setValue]
  );

  const handlePanChange = useCallback(
    (pan) => {
      const cleanedPan = pan.toUpperCase().replace(/[^a-zA-Z0-9]/g, '');
      setValue('pan', cleanedPan);
    },
    [setValue]
  );

  const handleCINChange = useCallback(
    (cin) => {
      const cleanedCIN = cin.toUpperCase().replace(/[^a-zA-Z0-9]/g, '').slice(0, 21);
      setValue('cin', cleanedCIN);
    },
    [setValue]
  );

  const handleTINChange = useCallback(
    (tin) => {
      const cleanedTIN = tin.replace(/[^0-9]/g, '').slice(0, 11); // 11-digit TIN
      setValue('tin', cleanedTIN);
    },
    [setValue]
  );

  const handleTANChange = useCallback(
    (tan) => {
      const cleanedTAN = tan.toUpperCase().replace(/[^a-zA-Z0-9]/g, '').slice(0, 10); // 10-char TAN
      setValue('tan', cleanedTAN);
    },
    [setValue]
  );

  const handleColoniserChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9]/g, ''); // Only allow numbers
      setValue('coloniser_license_no', cleanedValue);
    },
    [setValue]
  );

  const handleNumberInput = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^0-9]/g, ''); // Only allow numbers
      setValue('company_number', cleanedValue);
    },
    [setValue]
  );

  const companyType = watch('company_type');

  // ---- Final form submission ----
  const onSubmitForm = useCallback(
    (formData) => {
      // Safely extract and filter any null/undefined items from fileUrls
      const finalCompanyLogo = Array.isArray(fileUrls?.company_logo)
        ? fileUrls.company_logo.filter((item) => item && item.url) // Keep only valid objects
        : [];
  
      const finalColoniserDoc = Array.isArray(fileUrls?.coloniser_license_doc)
        ? fileUrls.coloniser_license_doc.filter((item) => item && item.url)
        : [];
  
      // If you only ever want the first file in each array, do this:
      // const finalCompanyLogo = (Array.isArray(fileUrls?.company_logo) && fileUrls.company_logo[0]?.url)
      //   ? [fileUrls.company_logo[0]]
      //   : [];
      //
      // const finalColoniserDoc = (Array.isArray(fileUrls?.coloniser_license_doc) && fileUrls.coloniser_license_doc[0]?.url)
      //   ? [fileUrls.coloniser_license_doc[0]]
      //   : [];
  
      const payload = {
        ...formData,
        company_logo: finalCompanyLogo,
        coloniser_license_doc: finalColoniserDoc,
      };
  
      console.log('Final Payload:', payload);
      onSubmit(payload);
    },
    [fileUrls, onSubmit]
  );
  

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-6">
      {/* --- Company Details --- */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Company Details
        </span>
        <div className="flex flex-col w-full flex-1 gap-4">
          <div className="flex space-x-8 justify-between items-start w-full">
            {fileUrls?.company_logo && fileUrls.company_logo[0]?.url ? (
              <img
                src={fileUrls.company_logo[0].url}
                alt="company_image"
                className="w-[10rem] h-[11rem] bg-center bg-cover object-cover object-center rounded-lg"
              />
            ) : data?.company_logo?.length > 0 ? (
              <img
                src={user}
                alt="company_image"
                className="w-[10rem] h-[11rem] bg-center bg-cover object-cover object-center rounded-lg"
              />
            ) : (
              <div className="bg-gray-300 rounded-xl w-[10rem] h-[10.8rem] flex justify-center items-center">
                <img
                  src={user}
                  alt="user_image"
                  className="w-[6rem] bg-center bg-cover object-cover object-center"
                />
              </div>
            )}

            <div className="flex flex-col w-full flex-1 h-full items-stretch gap-6">
              <div className="w-full flex flex-col gap-4 flex-1">
                <div className="flex justify-between space-x-4">
                  <FormInput
                    width={24}
                    register={register}
                    isRequired
                    register_key="company_name"
                    label="Company Name"
                    type="text"
                    maxLength={50}
                  />

                  <FormInput
                    width={24}
                    register={register}
                    isRequired
                    register_key="company_type"
                    label="Company Type"
                    type="dropdown"
                    options={[
                      { value: '', label: 'Select' },
                      { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
                      { value: 'Partnership', label: 'Partnership' },
                      { value: 'Limited Liability (LLC)', label: 'Limited Liability (LLC)' },
                      { value: 'Public Limited (Ltd)', label: 'Public Limited (Ltd)' },
                      { value: 'Private Limited (Pvt Ltd)', label: 'Private Limited (Pvt Ltd)' },
                      { value: 'Nonprofit Organization', label: 'Nonprofit Organization' },
                      { value: 'Co-operative', label: 'Cooperative' },
                      { value: 'Joint Venture', label: 'Joint Venture' },
                    ]}
                  />

                  <FormInput
                    width={24}
                    register={register}
                    isRequired
                    register_key="company_email"
                    label="Email"
                    placeholder="Enter Company's Email"
                  />

                  <FormInput
                    width={24}
                    register={register}
                    isRequired
                    register_key="company_number"
                    label="Contact Number"
                    onType={handleNumberInput}
                    placeholder="Enter Company Number"
                  />
                </div>
              </div>
              <div className="w-full">
                <FormInput
                  width={24}
                  register={register}
                  type="file-btn"
                  register_key="company_logo"
                  label="Company Logo"
                  prefilledFiles={fileUrls['company_logo'] || []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --- Company Legal Details --- */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2"></span>
        <div className="w-full flex flex-col gap-4">
          <div className="flex justify-between space-x-4">
            {data?.company_type !== 'Partnership' && companyType !== 'Partnership' && (
              <>
                <FormInput
                  width={30}
                  register={register}
                  register_key="cin"
                  placeholder="Enter CIN"
                  label="CIN"
                  onType={handleCINChange}
                  type="text"
                />
                <FormInput
                  width={30}
                  register={register}
                  register_key="tin"
                  label="TIN"
                  placeholder="Enter TIN"
                  onType={handleTINChange}
                />
              </>
            )}

            <FormInput
              width={30}
              register={register}
              register_key="pan"
              isRequired
              label="PAN"
              onType={handlePanChange}
              placeholder="Enter PAN"
            />
          </div>

          <div className="flex justify-between space-x-4">
            <FormInput
              width={30}
              register={register}
              register_key="tan"
              placeholder="Enter TAN"
              label="TAN"
              type="text"
              onType={handleTANChange}
            />
            <FormInput
              width={30}
              register={register}
              register_key="gst"
              placeholder="Enter GST"
              label="GST"
              onType={handleGSTChange}
              isRequired
            />
            <FormInput
              width={30}
              register={register}
              register_key="coloniser_license_no"
              isRequired
              label="Coloniser License Number"
              onType={handleColoniserChange}
              placeholder="Enter Coloniser License Number"
            />
          </div>

          <div className="w-full">
            <FormInput
              width={24}
              register={register}
              type="file-btn"
              register_key="coloniser_license_doc"
              label="Coloniser License Doc"
              accept=".pdf"
              prefilledFiles={fileUrls['coloniser_license_doc'] || []}
            />
          </div>
        </div>
      </div>

      {/* --- Address Details --- */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">Address</span>
        <div className="w-full flex flex-col gap-4">
          <div className="flex justify-between space-x-4">
            <FormInput
              width={24}
              register={register}
              register_key="zip_code"
              label="Zip/Pin Code"
              type="text"
              onType={handlePincodeChange}
              placeholder="Enter Zip/Pin Code"
            />
            <FormInput
              width={24}
              register={register}
              register_key="state"
              label="State"
              CustomStyle={{ pointerEvents: 'none' }}
            />
            <FormInput
              width={24}
              register={register}
              register_key="city"
              label="City"
              type="dropdown"
              options={cityDropdownOptions}
            />
            <FormInput
              width={24}
              register={register}
              register_key="country"
              label="Country"
              CustomStyle={{ pointerEvents: 'none' }}
            />
          </div>
        </div>
      </div>

      {/* --- Action Buttons --- */}
      <div className="w-full flex justify-end items-center space-x-4">
        <Button onClick={onClose} displayType="secondary">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default EditCompany;
