import React, { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import Multiselect from 'multiselect-react-dropdown';
import {
  getAllProjects,
  getAllRoles,
  getAllCompanies,
  getAllDesignations
} from '../../services/api/implementation/impl';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

// Validation schema
const validationSchema = yup.object().shape({
  designation_id: yup.number().required('Designations is required'),
  name: yup
    .string()
    .required('Full name is required')
    .min(3, 'Full name should be at least 3 characters')
    .max(50, 'Full name should be at most 50 characters'),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      'Phone number is not valid'
    )
    .test(
      'len',
      'Must be a valid phone number',
      (val) => !val || (val && val.replace(/[^\d]/g, '').length === 10)
    ),
  email: yup.string().required('Email is required').email('Invalid email format'),
  // Ensures that at least one company is selected
  company_ids: yup.array().min(1, 'At least one company must be selected')
});

export const AddUser = ({ onClose, onSubmit, users, isSubmitting }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    // Default all fields, especially company_ids, so Yup can properly detect an empty array
    defaultValues: {
      name: '',
      phone_number: '',
      email: '',
      designation_id: '',
      company_ids: []
    }
  });

  const [salesId, setSalesId] = useState('');
  const [maxDiscount, setMaxDiscount] = useState('');

  // Fetch data
  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getAllRoles
  });
  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects
  });
  const { data: companies } = useQuery({
    queryKey: ['companies'],
    queryFn: getAllCompanies
  });
  const { data: designations } = useQuery({
    queryKey: ['designations'],
    queryFn: getAllDesignations
  });

  let userRoleId = Cookies.get('userRoleId');
  console.log({ userRoleId });

  const onSubmitForm = (data) => {
    console.log({ data });
    const modifiedData = {
      ...data,
      user_type: 'Employee',
      // Since data.company_ids is an array of objects from the multiselect,
      // we map to just their IDs
      company_ids: data.company_ids.map((item) => item.id),
      role_id: 2,
      authorized_person: true
    };
    if (parseInt(salesId) === 3) {
      modifiedData.max_discount_limit = parseInt(data.max_discount_limit, 10);
    }
    console.log({ modifiedData });
    onSubmit(modifiedData);
  };

  const transformedDataCompany = companies?.map((item) => ({
    name: item.company_name?.trim(),
    id: item.id
  }));

  // Helper to only keep digits and set phone number
  const handlePhoneNumber = useCallback(
    (val) => {
      const newval = val.replace(/[^0-9]/g, '');
      setValue('phone_number', newval);
    },
    [setValue]
  );

  // Filter roles based on selected role names
  const selectedRoles = ['Engineer', 'Sales', 'Operation', 'Manager', 'Partner'];
  const filteredRoles = roles?.filter((role) =>
    selectedRoles.map((r) => r.toLowerCase()).includes(role.name.toLowerCase())
  );

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleSelected = (value) => {
    // If it's 'Sales', typically that might be an ID of 3, etc.
    setSalesId(value);
  };

  // Helper for name: only letters and spaces, up to 50 chars
  const handleNameChange = useCallback(
    (val) => {
      const cleanedValue = val.replace(/[^a-zA-Z\s]/g, '').slice(0, 50);
      setValue('name', cleanedValue);
    },
    [setValue]
  );

  const handleMaxDiscountChange = (e) => {
    let inputVal = e.replace(/\D/g, ''); // remove non-digits
    const numericVal = parseInt(inputVal, 10);
    if (!isNaN(numericVal) && numericVal > 100) {
      inputVal = '100';
      toast.error('Max discount cannot exceed 100');
    }
    setMaxDiscount(inputVal);
    setValue('max_discount_limit', inputVal);
  };

  return (
    <>
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">User details</span>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} className="flex flex-col gap-4 pt-6">
        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Designation'}
            register={register}
            isRequired
            register_key={'designation_id'}
            type={'dropdown'}
            options={[
              { value: '', label: 'Select Designation', selected: true, disabled: true }, // Default placeholder
              ...(designations?.map((item) => ({
                value: parseInt(item.id),
                label: capitalize(item.name?.trim())
              })) || [])
            ]}
            onDropdownChange={handleSelected}
            errors={errors.designation_id}
          />
          <FormInput
            width={48}
            label={'Full Name'}
            isRequired
            register_key={'name'}
            register={register}
            type={'text'}
            onType={handleNameChange}
            errors={errors.name}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <FormInput
            width={48}
            label={'Phone Number'}
            isRequired
            register={register}
            register_key={'phone_number'}
            type={'text'}
            onType={handlePhoneNumber}
            errors={errors.phone_number}
            maxLength={10}
          />
          <FormInput
            width={48}
            label={'Email address'}
            register={register}
            isRequired
            register_key={'email'}
            type={'text'}
            errors={errors.email}
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="w-[48%]">
            <label className="text-gray-600 mb-1 block">Assign Company *</label>
            <Controller
              name="company_ids"
              control={control}
              // We rely on the Yup validation, but you can also add `rules={{ required: true }}`
              render={({ field }) => (
                <Multiselect
                  options={transformedDataCompany || []}
                  selectedValues={field.value}
                  onSelect={(selectedList) => field.onChange(selectedList)}
                  onRemove={(selectedList) => field.onChange(selectedList)}
                  displayValue="name"
                  placeholder=""
                />
              )}
            />
            {errors.company_ids && (
              <span className="text-red-500">{errors.company_ids.message}</span>
            )}
          </div>
          {parseInt(salesId) === 3 ? (
            <FormInput
              width={48}
              label={'Max Discount (%)'}
              register={register}
              register_key={'max_discount_limit'}
              type={'text'}
              onType={handleMaxDiscountChange}
              defaultValuePre={maxDiscount}
              errors={errors.max_discount_limit}
            />
          ) : null}
        </div>

        <div className="w-full flex justify-end items-center space-x-4 mt-8">
          <Button type="button" onClick={onClose} displayType="secondary">
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Add
          </Button>
        </div>
      </form>
    </>
  );
};
