import React, { useEffect } from 'react';
import FormInput from '../../shared/components/ui/FormInput';
import { useForm } from 'react-hook-form';
import Button from '../../shared/components/ui/Button';
import toast from 'react-hot-toast';

const EditPaymentStages = ({
  handleOnEditPaymentStage,
  paymentStagesId,
  allPaymentStages,
  onClose,
  isSubmitting
}) => {
  const { register, handleSubmit, setValue } = useForm();
  console.log(paymentStagesId);

  useEffect(() => {
    setValue('name', paymentStagesId?.name);
    setValue('percentage', paymentStagesId?.percentage);
  }, [paymentStagesId]);

  const onSubmit = (data) => {
    const updatedPercentage = parseInt(data.percentage);
    const totalPercentage = allPaymentStages.reduce(
      (acc, stage) =>
        stage.id === paymentStagesId.id ? acc + updatedPercentage : acc + stage.percentage,
      0
    );

    if (totalPercentage > 100) {
      toast.error('Total percentage cannot exceed 100%');
      return;
    }

    const newPayload = { ...data, percentage: updatedPercentage };
    handleOnEditPaymentStage(newPayload, paymentStagesId.id);
  };

  return (
    <div className="flex flex-col gap-3">
      <FormInput
        register={register}
        register_key={`name`}
        type="text"
        placeholder={`Payment`}
        defaultValuePre={paymentStagesId?.name}
      />
      <FormInput
        register={register}
        register_key={`percentage`}
        type="number"
        placeholder="Percentage"
        defaultValuePre={paymentStagesId?.percentage}
        min="0"
        max="100"
        step="0.01"
      />
      <Button onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
        Save
      </Button>
    </div>
  );
};

export default EditPaymentStages;
