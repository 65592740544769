import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import Table from '../../shared/components/layout/Table';

import { useQuery } from '@tanstack/react-query';
import { getLandById } from '../services/api/implementation/impl';
import { transformData } from '../../super-admin/utils/transformData';
import { useParams } from 'react-router-dom';
import { BsFiletypePdf } from 'react-icons/bs';
import { AiOutlineFileImage } from 'react-icons/ai';
import { FaRegFile } from 'react-icons/fa';

/*
  Conversion helpers
*/
const convertHectaresToSqMt = (hectares) => (hectares ? (hectares * 10000).toFixed(2) : null);
const convertHectaresToSqFt = (hectares) => (hectares ? (hectares * 107639.104).toFixed(2) : null);

/*
  Helper Functions for Documents
*/
const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'pdf':
      return <BsFiletypePdf size={80} />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <AiOutlineFileImage size={90} />;
    default:
      return <FaRegFile size={80} />; // Default icon for other file types
  }
};

const truncateFileName = (fileName, maxLength = 15) => {
  if (!fileName) return 'Unnamed File';
  if (fileName.length <= maxLength) return fileName;
  const extension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
  return `${nameWithoutExtension.slice(0, maxLength - 3)}...${extension}`;
};

/*
  Document Renderer Component
*/
const DocumentRenderer = ({ title, documents }) => {
  if (!documents || (Array.isArray(documents) && documents.length === 0)) return null;

  // Normalize documents to an array of { name, url }
  let normalizedDocs = [];

  if (Array.isArray(documents)) {
    normalizedDocs = documents.flatMap((doc) => {
      if (typeof doc === 'string') {
        // If doc is a URL string without a name
        return [{ name: doc.split('/').pop(), url: doc }];
      } else if (doc.name && doc.url) {
        return [{ name: doc.name, url: doc.url }];
      } else {
        return [];
      }
    });
  } else if (typeof documents === 'object' && documents !== null) {
    // Single document object
    if (documents.name && documents.url) {
      normalizedDocs.push({ name: documents.name, url: documents.url });
    } else if (documents.url) {
      normalizedDocs.push({ name: documents.url.split('/').pop(), url: documents.url });
    }
  }

  if (normalizedDocs.length === 0) return null;

  return (
    <div className="flex flex-col gap-10 border rounded-md border-gray-200 p-3">
      <h3 className="border w-full text-center font-semibold p-2 rounded-lg bg-slate-600 text-lg text-white">
        {title}
      </h3>
      <div className="flex flex-wrap gap-3">
        {normalizedDocs.map((file, i) => (
          <div
            key={i}
            className="flex flex-col border w-[12rem] p-5 rounded-xl justify-center items-center">
            <a href={file.url} target="_blank" rel="noreferrer" download>
              {getFileIcon(file.name)}
            </a>
            <p className="text-center">{truncateFileName(file.name)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const LandDetails = () => {
  /*
    Local state
  */
  const { id } = useParams();

  /*
    Data fetching
  */
  const { data, refetch: refetchLand } = useQuery({
    queryKey: ['land', id],
    queryFn: () => getLandById(id)
  });

  console.log('Land data:', data);

  /*
    Meta data
  */
  const khasraTableMetaData = ['number', 'area', 'guntha', 'area_sqmt', 'area_sqft'];
  const ownerTableMetaData = ['name', 'email', 'phone_number', 'aadhar'];

  /*
    Helper method calls
  */
  const khasraTransformedData = data?.ownerLandMappings?.[0]?.owner?.khasra?.map((item) => {
    const areaInHectare = item.mainArea || 0; // Assuming 'area' is in hectares as per your original code
    return transformData(
      {
        ...item,
        area: item.mainArea || 0,
        guntha: item.subArea || 0,
        // area_hectare: areaInHectare,
        area_sqmt: item.area_sqmt || convertHectaresToSqMt(areaInHectare),
        area_sqft: item.area_sqft || convertHectaresToSqFt(areaInHectare)
      },
      khasraTableMetaData
    );
  });

  const ownerTransformedData = data?.ownerLandMappings?.map(({ owner }) =>
    transformData(
      {
        name: owner.name,
        email: owner.email,
        phone_number: owner.phone_number,
        aadhar: owner.aadhar
      },
      ownerTableMetaData
    )
  );

  /*
    Effects
  */
  useEffect(() => {
    refetchLand();
  }, [refetchLand]);

  const [selectedFilter, setSelectedFilter] = useState('Land Details');

  const handleButtonClick = (filter) => {
    setSelectedFilter(filter);
  };
  const formatAreaType = (areaType) => {
    return areaType?.replace('sq/', '');
  };
  /*
    Extracting Documents
  */
  const landDocuments = [
    { title: 'Registry Documents', documents: data?.registryDocs || data?.registry_doc_link },
    { title: 'Advocate Searches', documents: data?.advocateSearches || data?.advocate_search_link },
    { title: 'Diversion Orders', documents: data?.diversionOrders || data?.diversion_order_link || data?.diversionOrderFiles },
    { title: 'Additional Documents', documents: data?.additionalDocuments || data?.additional_documents_link },
    { title: 'Khasra Map Links', documents: data?.ownerLandMappings?.[0]?.owner?.khasra?.flatMap(khasra => khasra.khasra_map_link) },
    { title: 'Khasra P1B2 Links', documents: data?.ownerLandMappings?.[0]?.owner?.khasra?.flatMap(khasra => khasra.khasra_p1b2_link) },
    { title: 'Land Use Maps', documents: data?.ownerLandMappings?.[0]?.owner?.khasra?.flatMap(khasra => khasra.land_use_map) },
    { title: 'Google Location Images', documents: data?.google_map_image_link },
    { title: 'Google Maps', documents: data?.google_map_link ? [{ name: 'Google Map', url: data.google_map_link }] : null },
    // Add more document fields as needed
  ];

  /*
    JSX
  */
  return (
    <Layout>
      {/* Header */}
      <div className="fixed top-0 left-[18%] w-[82%] z-10">
        <Header data={{ name: `Lands > ${data?.name || 'Loading...'}` }} backArrow />
      </div>

      {/* Filter Buttons */}
      <div className="flex z-5 items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 left-[18vw] w-[82%] shadow-sm">
        <div className="flex w-full justify-between items-center gap-5 cursor-pointer">
          <span className="font-semibold">{selectedFilter ? selectedFilter : 'Land Details'}</span>
          <div className="button-group flex rounded-lg ">
            <div
              className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${
                selectedFilter === 'Land Details' ? `bg-customBlue text-white` : 'text-textC'
              } rounded-tl rounded-bl`}
              onClick={() => handleButtonClick('Land Details')}>
              Land Details
            </div>
            <div
              className={`border border-x-1 px-2 py-1 font-helvetica cursor-pointer button ${
                selectedFilter === 'Documents' ? `bg-customBlue text-white` : 'text-textC'
              }`}
              onClick={() => handleButtonClick('Documents')}>
              Documents
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      {selectedFilter === 'Land Details' ? (
        <div className="w-full flex flex-col absolute top-[10rem] z-5 pl-5 pr-5 bg-gray-100 pb-10 overflow-auto">
          {/* Khasra Details */}
          <div>
            <h3 className="m-3 text-lg font-semibold">Khasra Details</h3>
            <div className="h-[20rem] overflow-auto">
              <Table
                headers={[
                  `${data?.state === 'Karnataka' ? 'Survey' : 'Khasra'} Number `,
                  `${data?.state === 'Karnataka' ? 'Survey' : 'Khasra'} Area (${formatAreaType(data?.total_mainAreaType)})`,
                  'Guntha',
                  // `Area in ${formatAreaType(data?.total_mainAreaType)}`,
                  'Area in Sq Mt',
                  'Area in Sq Ft'
                ]}
                identifier={'id'}
                data={khasraTransformedData}
              />
            </div>
          </div>

          {/* Owner Details */}
          <div className="mt-10">
            <h3 className="m-3 text-lg font-semibold">Owners</h3>
            <div className="h-[22rem] overflow-auto">
              <Table
                headers={['Name', 'Email', 'Number', 'Aadhar Number']}
                identifier={'id'}
                data={ownerTransformedData}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-col absolute top-[10rem] z-5 pl-5 pr-5 bg-gray-100 pb-10 overflow-auto">
          {/* Documents Section */}
          <div className="flex flex-wrap gap-10">
            {landDocuments.map((docSection, index) => (
              <DocumentRenderer key={index} title={docSection.title} documents={docSection.documents} />
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default LandDetails;
