import React, { useState } from 'react';

import search from '../../../shared/assets/svg/search.svg';

const Search = ({ query, onChange, onClick }) => {
  /* 
    Local state
  */
  const [searchQuery, setSearchQuery] = useState(query);

  /*
    Event handlers 
  */
  const handleSearchChange = (e) => {
    // console.log(e.target.value)
    setSearchQuery(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="flex items-center border border-gray-500 px-2 rounded-md">
      <input
        type="text"
        name=""
        placeholder="Search here...."
        className=" w-[24rem] p-1 outline-none "
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <img onClick={onClick} src={search} alt="serach_icon" />
    </div>
  );
};

export default Search;
