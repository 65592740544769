import React, { useEffect, useState } from 'react';
import Table from '../../../../shared/components/layout/Table';
import searchIcon from '../../../../shared/assets/svg/search.svg';
import filterIcon from '../../../../shared/assets/svg/filter.svg';
import reverseIcon from '../../../../shared/assets/svg/reverse.svg';
import { transformData } from '../../../utils/transformData';
import { useQuery } from '@tanstack/react-query';
import { getAllInvoices } from '../../../services/api/implementation/impl';
import toast from 'react-hot-toast';

const InvoiceManagement = ({
  newAddedInvoice,
  newEditedInvoice,
  onEdit,
  onChangeActiveInvoiceId
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [reverseOrder, setReverseOrder] = useState(false);

  const { data: invoices, refetch: refetchInvoices } = useQuery({
    queryKey: ['invoices'],
    queryFn: getAllInvoices
  });

  useEffect(() => {
    refetchInvoices();
    // toast.success(`Successfully fetched ${invoices?.length} invoices`);
  }, [invoices?.length, newAddedInvoice, refetchInvoices, newEditedInvoice]);

  const transformedData = invoices?.map((el) =>
    transformData(
      {
        id: el.id,
        builder_name: el.builder?.name,
        project_name: el.project?.name,
        invoice_number: el.invoice_number,
        amount: el.amount,
        amount_paid: el.amount - el.due_amount,
        due_amount: el.due_amount,
        status: el.status
      },
      [
        'id',
        'builder_name',
        'project_name',
        'invoice_number',
        'amount',
        'amount_paid',
        'due_amount',
        'status',
        'actions'
      ]
    )
  );

  const filteredData = transformedData?.filter((row) =>
    row.builder_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (reverseOrder) {
    filteredData.reverse();
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReverseOrderChange = () => {
    setReverseOrder(!reverseOrder);
  };

  const handleEditInvoice = (id) => {
    onEdit();
  };

  const handleInvoiceFilter = () => {
    console.log('Opening invoice filter modal');
  };

  const handleChangeActiveInvoice = (id) => {
    onChangeActiveInvoiceId(id);
  };

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-4 bg-white  w-full shadow">
        <div className="flex gap-2">
          <div className="flex items-center border border-gray-500 px-2 rounded-md">
            <input
              type="text"
              placeholder="Search here..."
              className="w-[386px] p-1 outline-none"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <img src={searchIcon} alt="" />
          </div>
          {/* <img
            src={filterIcon}
            className="cursor-pointer"
            onClick={handleInvoiceFilter}
            alt=""
          />
          <img
            src={reverseIcon}
            alt="Reverse Order"
            onClick={handleReverseOrderChange}
            className="w-10 cursor-pointer"
          /> */}
        </div>
      </div>
      <div className="overflow-y-auto pt-3">
        <div className="p-6 h-[28rem]">
          <Table
            headers={[
              'Builder',
              'Project',
              'Invoice no.',
              'Invoice value',
              'Amount paid',
              'Amount due',
              'Status',
              'Actions'
            ]}
            data={filteredData}
            onEdit={(id) => handleChangeActiveInvoice(id)}
            identifier={'id'}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceManagement;
