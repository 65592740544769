// components/SalesRevenue.jsx
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Papa from 'papaparse'; // Import papaparse for CSV generation
import { toast } from 'react-hot-toast'; // Import react-hot-toast for notifications

import Table from '../../shared/components/layout/Table';
import GridWrapper from '../../shared/components/layout/GridWrapper';
import PaginationComponent from '../../shared/components/PaginationComponent';
import SecondaryTabbedComponent from '../../shared/components/layout/SecondaryTabbedComponent';
import Button from '../../shared/components/ui/Button';
import NoDataFound from '../components/layout/NoDataFound';

import filter from '../../../modules/shared/assets/svg/filter.svg';
import FileDownload from '../../../modules/shared/assets/svg/download.svg';

// <-- The APIs
import {
  getAllProjects,
  getBlockWiseRevenueReports,
  getUnitWiseRevenueReports,
} from '../services/api/implementation/impl';

// <-- The same utility used in UsersList
import { transformData } from '../../super-admin/utils/transformData';

const SalesRevenue = () => {
  /*
   * ----------------------------------------------
   * State & Refs
   * ----------------------------------------------
   */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Company');
  const applyFilterRef = useRef(null);
  const [checkedItems, setCheckedItems] = useState([]);

  // Tabs and Pagination
  const [secondaryTab, setSecondaryTab] = useState('Blocks wise');
  const [blockPage, setBlockPage] = useState(1);
  const [unitPage, setUnitPage] = useState(1);
  const [limit] = useState(10); // items per page

  const [projectList, setProjectList] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const { register } = useForm();

  // Downloading State
  const [isDownloading, setIsDownloading] = useState(false);
  const [dotCount, setDotCount] = useState(0);

  /*
   * ----------------------------------------------
   * Fetch Projects
   * ----------------------------------------------
   */
  const {
    data: projects,
    isFetching: isFetchingProjects,
  } = useQuery({
    queryKey: ['projects'],
    queryFn: getAllProjects,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (projects && projects.length > 0) {
      setProjectList(projects);
    }
  }, [projects]);

  const handleProjectNameChange = (e) => {
    setSelectedProjectId(e.target.value);
  };

  /*
   * ----------------------------------------------
   * Fetch Revenue Data
   * ----------------------------------------------
   * Each tab calls a separate API with its own pagination state.
   */
  const {
    data: blockRevenueResponse,
    isFetching: isFetchingBlockRevenue,
  } = useQuery({
    queryKey: ['blockRevenueReports', blockPage, limit, selectedProjectId],
    queryFn: () =>
      getBlockWiseRevenueReports(blockPage, limit, selectedProjectId),
    keepPreviousData: true,
  });

  const {
    data: unitRevenueResponse,
    isFetching: isFetchingUnitRevenue,
  } = useQuery({
    queryKey: ['unitRevenueReports', unitPage, limit, selectedProjectId],
    queryFn: () =>
      getUnitWiseRevenueReports(unitPage, limit, selectedProjectId),
    keepPreviousData: true,
  });

  // Get data and meta information for each tab
  const blockRevenueData = blockRevenueResponse?.data || [];
  const blockTotalItems = blockRevenueResponse?.meta?.total || 0;
  const blockTotalPages = Math.ceil(blockTotalItems / limit);

  const unitRevenueData = unitRevenueResponse?.data || [];
  const unitTotalItems = unitRevenueResponse?.meta?.total || 0;
  const unitTotalPages = Math.ceil(unitTotalItems / limit);

  /*
   * ----------------------------------------------
   * Transform Data for the Table
   * ----------------------------------------------
   */
  let dataFormat = [];
  let tableHeaders = [];
  let processedRevenue = [];

  if (secondaryTab === 'Blocks wise') {
    dataFormat = ['block', 'unitsSold', 'revenue', 'contribution', 'growthRate', 'id'];
    tableHeaders = ['Block', 'Units Sold', 'Revenue', '% Revenue Contribution', 'Growth Rate'];

    processedRevenue = blockRevenueData.map((item) => ({
      id: item?.id || 'no-id',
      block: item?.name || 'N/A',
      unitsSold: item?.unitSold || 0,
      revenue: `₹${(item?.revenue || 0).toLocaleString()}`,
      contribution: `${item?.revenueContribution || 0}%`,
      growthRate: `₹${(item?.unitGrowthRate || 0).toLocaleString()}`,
    }));
  } else {
    dataFormat = ['unitName', 'accommodationName', 'revenue', 'contribution', 'growthRate', 'id'];
    tableHeaders = ['Unit Name', 'Accommodation Name', 'Revenue', '% Revenue Contribution', 'Growth Rate'];

    processedRevenue = unitRevenueData.map((item) => ({
      id: item?.id || 'no-id',
      unitName: item?.unitName || 'N/A',
      accommodationName: item?.accommodation_name || 'N/A',
      revenue: `₹${(item?.revenue || 0).toLocaleString()}`,
      contribution: `${item?.revenueContribution || 0}%`,
      growthRate: `₹${(item?.growthRate || 0).toLocaleString()}`,
    }));
  }

  const transformedRevenueData = processedRevenue.map((row) =>
    transformData(row, dataFormat)
  );

  /*
   * ----------------------------------------------
   * Event Handlers
   * ----------------------------------------------
   */
  const handleChangeSecondaryTab = (tabName) => {
    setSecondaryTab(tabName);
  };

  const handlePageChange = (newPage) => {
    if (secondaryTab === 'Blocks wise') {
      setBlockPage(newPage);
    } else {
      setUnitPage(newPage);
    }
  };

  // Filter Widget
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
    handleClearAll();
  };
  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };
  const handleCheckboxChange = (val) => {
    setCheckedItems((prev) =>
      prev.includes(val) ? prev.filter((item) => item !== val) : [...prev, val]
    );
  };
  const handleClearAll = () => {
    setCheckedItems([]);
    setSelectedFilter('Company');
  };

  // Close the filter widget if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (applyFilterRef.current && !applyFilterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        handleClearAll();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /*
   * ----------------------------------------------
   * Handle Download CSV using papaparse
   * ----------------------------------------------
   */
  const handleDownload = async () => {
    if (isDownloading) return; // Prevent multiple clicks

    setIsDownloading(true);
    try {
      let responseData = [];
      let headers = [];
      let rows = [];

      if (secondaryTab === 'Blocks wise') {
        // Fetch all block-wise data with page=1 and limit=1000
        const response = await getBlockWiseRevenueReports(1, 1000, selectedProjectId);
        responseData = response?.data || [];

        if (responseData.length === 0) {
          toast.error('No data available to download.');
          return;
        }

        // Define CSV headers
        headers = ['Block', 'Units Sold', 'Revenue', '% Revenue Contribution', 'Growth Rate'];

        // Map data to CSV rows
        rows = responseData.map((item) => ({
          'Block': item?.name || 'N/A',
          'Units Sold': item?.unitSold !== undefined ? item.unitSold : 0,
          'Revenue': item?.revenue !== undefined ? item.revenue : 0,
          '% Revenue Contribution': item?.revenueContribution !== undefined ? item.revenueContribution : 0,
          'Growth Rate': item?.unitGrowthRate !== undefined ? item.unitGrowthRate : 0,
        }));
      } else {
        // Fetch all unit-wise data with page=1 and limit=1000
        const response = await getUnitWiseRevenueReports(1, 1000, selectedProjectId);
        responseData = response?.data || [];

        if (responseData.length === 0) {
          toast.error('No data available to download.');
          return;
        }

        // Define CSV headers
        headers = ['Unit Name', 'Accommodation Name', 'Revenue', '% Revenue Contribution', 'Growth Rate'];

        // Map data to CSV rows
        rows = responseData.map((item) => ({
          'Unit Name': item?.unitName || 'N/A',
          'Accommodation Name': item?.accommodation_name || 'N/A',
          'Revenue': item?.revenue !== undefined ? item.revenue : 0,
          '% Revenue Contribution': item?.revenueContribution !== undefined ? item.revenueContribution : 0,
          'Growth Rate': item?.growthRate !== undefined ? item.growthRate : 0,
        }));
      }

      // Convert to CSV using papaparse
      const csv = Papa.unparse({
        fields: headers,
        data: rows,
      });

      // Create a Blob from the CSV content
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      const fileName =
        secondaryTab === 'Blocks wise'
          ? `sales_revenue_blocks_wise.csv`
          : `sales_revenue_unit_wise.csv`;
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      toast.success('Download initiated successfully!');
    } catch (downloadError) {
      console.error('Download failed:', downloadError);
      toast.error('Failed to download the file. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  /*
   * ----------------------------------------------
   * Dots Animation Effect
   * ----------------------------------------------
   */
  useEffect(() => {
    let interval;
    if (isDownloading) {
      interval = setInterval(() => {
        setDotCount((prev) => (prev < 3 ? prev + 1 : 0));
      }, 500);
    } else {
      setDotCount(0);
    }

    return () => clearInterval(interval);
  }, [isDownloading]);

  const renderDownloadingText = () => {
    return `Downloading${'.'.repeat(dotCount)}`;
  };

  /*
   * ----------------------------------------------
   * Rendering
   * ----------------------------------------------
   */
  return (
    <div className="w-full flex flex-col z-5 bg-gray-100 pb-10 overflow-hidden">
      {/* Secondary Tabs */}
      <div className="flex items-center justify-between px-6 py-4 bg-white border-stone-100 shadow-sm">
        <SecondaryTabbedComponent
          data={{ tabs: ['Blocks wise', 'Unit wise'] }}
          activePrimary={secondaryTab}
          onChangePrimaryTab={handleChangeSecondaryTab}
        />
        <div className="flex items-center gap-3 px-4">
          {/* Filter Icon (Optional) */}
          {/* <img
            onClick={toggleFilter}
            className="cursor-pointer"
            height={35}
            width={35}
            src={filter}
            alt="Filter Icon"
          /> */}
          {/* Download Button */}
          <button
            className={`relative flex items-center justify-center ${
              isDownloading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`}
            onClick={handleDownload}
            disabled={isDownloading}
            aria-label="Download CSV"
          >
            <img
              height={43}
              width={43}
              src={FileDownload}
              alt="File Download Icon"
            />
            {isDownloading && (
              <span className="absolute top-12 left-0 text-sm text-gray-700">
                {renderDownloadingText()}
              </span>
            )}
          </button>
        </div>
      </div>

      {/* Filter Widget */}
      {isFilterOpen && (
        <div
          ref={applyFilterRef}
          className="absolute right-[2rem] mt-[7rem] w-[33rem] bg-white border rounded-lg shadow-lg z-[1000]"
        >
          <div className="py-3 px-4 border-b flex justify-between items-center">
            <h4 className="font-semibold">Apply Filters</h4>
            <p
              onClick={handleClearAll}
              className="text-blue-500 underline hover:text-blue-700 transition duration-200 cursor-pointer"
            >
              Clear all
            </p>
          </div>
          <div className="flex justify-between items-start">
            {/* Left Pane (Tabs) */}
            <div className="flex flex-col w-[30%]">
              <p
                className={`py-3 px-4 cursor-pointer border-b  ${
                  selectedFilter === 'Company' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Company')}
              >
                Company
              </p>
              <p
                className={`py-3 px-4 cursor-pointer ${
                  selectedFilter === 'Month' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Month')}
              >
                Month
              </p>
              <p
                className={`py-3 px-4 cursor-pointer border-t ${
                  selectedFilter === 'Block' ? 'bg-[#d0edf1]' : ''
                }`}
                onClick={() => handleFilterClick('Block')}
              >
                Block
              </p>
            </div>
            {/* Right Pane (Checkboxes) */}
            <div className="flex flex-col w-[70%] border-l">
              {selectedFilter === 'Company' && (
                <>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(1)}
                      onChange={() => handleCheckboxChange(1)}
                    />
                    Ved Builders
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(2)}
                      onChange={() => handleCheckboxChange(2)}
                    />
                    Nupur Constructions
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(9)}
                      onChange={() => handleCheckboxChange(9)}
                    />
                    LR Constructions
                  </div>
                </>
              )}
              {selectedFilter === 'Month' && (
                <div className="grid grid-cols-3 gap-1">
                  {[
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                  ].map((month, index) => (
                    <div key={index} className="flex items-center gap-2 py-2 px-2 text-[#67676C]">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={checkedItems.includes(month)}
                        onChange={() => handleCheckboxChange(month)}
                      />
                      {month}
                    </div>
                  ))}
                </div>
              )}
              {selectedFilter === 'Block' && (
                <>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(3)}
                      onChange={() => handleCheckboxChange(3)}
                    />
                    Block A
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(4)}
                      onChange={() => handleCheckboxChange(4)}
                    />
                    Block B
                  </div>
                  <div className="flex items-center gap-2 py-3 px-4 text-[#67676C]">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={checkedItems.includes(5)}
                      onChange={() => handleCheckboxChange(5)}
                    />
                    Block C
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Actions (Cancel/Apply) */}
          <div className="flex justify-end gap-4 p-6 border-t">
            <Button displayType="secondary" onClick={toggleFilter}>
              Cancel
            </Button>
            <Button onClick={() => { /* Implement Apply Filter Logic */ }}>Apply</Button>
          </div>
        </div>
      )}

      {/* Main Content */}
      <GridWrapper width={100}>
      <div className="h-[22rem] relative">
          {/* Project Dropdown */}
          <div className="flex justify-between items-center mb-5">
            {/* Uncomment and implement project selection if needed */}
            {/* <div className="flex flex-col gap-2">
              <label htmlFor="project">Select Project</label>
              {projectList.length > 0 ? (
                <select
                  className="w-[10rem] p-2 border-[1px] border-stone-300 rounded"
                  {...register('selected_project')}
                  value={selectedProjectId || ''}
                  onChange={handleProjectNameChange}
                >
                  <option value="">Select a Project</option>
                  {projectList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              ) : (
                <p>Loading projects...</p>
              )}
            </div> */}
          </div>

          {/* Revenue Table */}
          {transformedRevenueData.length > 0 ? (
            <>
              <Table
                headers={tableHeaders}
                data={transformedRevenueData}
                identifier="id"
                isViewEnable={false}
                isDeleteEnable={false}
                isEditEnable={false}
              />
              <PaginationComponent
                page={secondaryTab === 'Blocks wise' ? blockPage : unitPage}
                totalPages={secondaryTab === 'Blocks wise' ? blockTotalPages : unitTotalPages}
                onPageChange={handlePageChange}
                isFetching={secondaryTab === 'Blocks wise' ? isFetchingBlockRevenue : isFetchingUnitRevenue}
              />
            </>
          ) : (
            <NoDataFound disabledBtn screenHeight={true} />
          )}
        </div>
      </GridWrapper>
    </div>
  );
};

export default SalesRevenue;
