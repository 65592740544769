import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getDashboardHeaderData } from '../../services/api/implementation/impl';
import Layout from './AppLayout';
import Header from '../../../shared/components/layout/Header';
import HeaderCard from '../../../shared/components/ui/HeaderCard';
import toast from 'react-hot-toast';

const Dashboard = () => {
  const [newData, setNewData] = useState([]);

  // Data fetching
  const { data, refetch: refetchDashboardDetails } = useQuery({
    queryKey: ['dashboard'],
    queryFn: () => getDashboardHeaderData(),
    onSuccess: () => {
      toast.success('Successfully fetched dashboard data');
    }
  });

  useEffect(() => {
    if (data) {
      const totalBuilders = data.values[data.keys.indexOf('totalBuilders')];
      const activeBuilders = data.values[data.keys.indexOf('activeBuilder')];
      const inactiveBuilders = totalBuilders - activeBuilders;

      const updatedData = [
        { key: 'Total Builders', value: totalBuilders, type: 'information' },
        { key: 'Active Builders', value: activeBuilders, type: 'completed' },
        { key: 'Inactive Builders', value: inactiveBuilders, type: 'ongoing' }
      ];

      setNewData(updatedData);
    }
  }, [data]);

  // JSX
  return (
    <Layout>
      {/* Header */}
      <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
        <Header data={{ name: 'Dashboard' }} />
      </div>

      <div className="flex flex-wrap justify-between items-center gap-4 px-8 mt-[6rem]">
        {newData?.map((item, index) => (
          <div className="w-[46%]" key={index}>
            <HeaderCard data={item} />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Dashboard;
