import React from 'react';
import { splitData } from '../../../super-admin/utils/splitData';

const HeaderCard = ({ data }) => {
  let bgColor = 'gray';

  switch (data?.type) {
    case 'information':
      bgColor = '#D1B44D';
      break;
    case 'ongoing':
      bgColor = '#E97B37';
      break;
    case 'completed':
      bgColor = '#3F8D78';
      break;
    case 'delayed':
      bgColor = '#C2281B';
      break;
    default:
      bgColor = 'gray';
  }

  return (
    <div className="p-6 px-8 rounded-lg flex flex-col gap-2 items-start justify-center bg-white w-[100%] relative">
      <span className="text-stone-600 text-lg w-full">{splitData(data?.key, '_', ' ')}</span>
      <span className="text-3xl font-semibold text-stone-700">{data?.value}</span>
      <div
        className={`w-[6px] h-[56%] rounded-t-2xl rounded-r-[24rem] absolute left-0 top-[50%] translate-y-[-50%]`}
        style={{ backgroundColor: bgColor }}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default HeaderCard;
