import axiosInstance from '../../../../builder/services/api/implementation/axiosInstance';
import { APPLICATION_API_BASE_URL } from '../configuration/config';
import { DOCGENERATION_API_BASE_URL } from '../configuration/config';
import { USERMANAGEMENT_API_BASE_URL } from '../configuration/config';
import { API_BASE_URL } from '../configuration/config';
import { sanitizeData } from '../../../utils/sanitizeData';
import { getHeaders } from '../../../utils/getToken';

// Static data
import invoices from '../../../static/invoices.json';

/* 
  GET requests
*/
export async function getDashboardHeaderData() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/dashboard`, headers);
    const res = data.data.data;
    return { keys: Object.keys(res), values: Object.values(res) };
  } catch (err) {
    console.error(`Error loading header data ${err}`);
    throw new Error('Header data not loaded');
  }
}

export async function getAllUsers(page = 1, limit = 200) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/builder?page=${page}&limit=${limit}`,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Users data not loaded');
  }
}

export async function getUser(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/builder/${id}`, headers);
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error fetching data: ${err}`);
    throw new Error('User data not fetched');
  }
}

/* 
  POST Requests
*/
export async function createUser(newUser) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(`${APPLICATION_API_BASE_URL}/builder`, newUser, headers);
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error creating user: ${err}`);
    throw new Error('Users data not created');
  }
}

/* 
  PUT Requests
*/
export async function updateUser(updatedUser, userId) {
  const sanitizedUpdatedUser = sanitizeData(updatedUser);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/users/${userId}`,
      sanitizedUpdatedUser,
      headers
    );
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error updating user: ${err}`);
    throw new Error('Users data not updated');
  }
}

// Delete User
export async function deleteUser(id) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.delete(`${APPLICATION_API_BASE_URL}/builder/${id}`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error deleting user: ${err}`);
    throw new Error('User not deleted');
  }
}

// Billing & Payment
export async function getAllBillings() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/billings?limit=100`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Billings data not loaded');
  }
}

export async function postBillings(postdata) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/billings`,
      postdata,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error posting data: ${err}`);
    throw new Error('Billings data not posted');
  }
}

export async function getAllInvoices() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/superadmininvoices?limit=200`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Invoices data not loaded');
  }
}

export async function addInvoice(postData) {
  postData.company_id = parseInt(postData.company_id);

  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/superadmininvoices`,
      postData,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error adding invoice: ${err}`);
    throw new Error('Invoice not added');
  }
}

// Get Overview
export async function getOverview() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/superadminrevenue`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Overview data not loaded');
  }
}

///////////////////////////////////////////
/// Billings-Payments > Invoices
export async function addInvoiceStatic(invoices, setInvoices, newInvoice) {
  newInvoice = {
    builder_name: newInvoice?.builder_name,
    company_name: newInvoice?.company_name,
    project_name: newInvoice?.project_name,
    invoice_no: newInvoice?.invoice_number,
    amount_paid: newInvoice?.amount,
    amount_due: newInvoice?.amount,
    status: 'Unpaid',
    action: 'Actions'
  };

  try {
    setInvoices([...invoices, newInvoice]);
    return newInvoice;
  } catch (err) {
    console.error(`Error adding invoice: ${err}`);
    throw new Error('Error adding new invoice');
  }
}

export async function addPaymentStatic(payments, setPayments, newPayment) {
  try {
    setPayments([...payments, newPayment]);
    return newPayment;
  } catch (err) {
    console.error(`Error adding payment: ${err}`);
    throw new Error('Error adding new payment');
  }
}

export async function getInvoiceById(invoiceId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/superadmininvoices/${invoiceId}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error fetching data: ${err}`);
    throw new Error('Invoice data not fetched');
  }
}

export function getInvoiceByIdStatic(invoiceId, invoicesStatic) {
  try {
    const selectedInvoice = invoicesStatic.filter((item) => item.id === parseInt(invoiceId));
    return selectedInvoice[0];
  } catch (err) {
    console.error(`Error fetching data: ${err}`);
    throw new Error('Invoice data not fetched');
  }
}

export async function updateInvoiceByIdStatic(invoicesStatic, invoiceId, newInvoice) {
  try {
    const updatedInvoices = invoicesStatic?.map((invoice) =>
      invoice.id === invoiceId ? { ...invoice, ...newInvoice } : invoice
    );
    return updatedInvoices;
  } catch (err) {
    console.error(`Error updating invoice: ${err}`);
    throw new Error('Invoice update failed');
  }
}

export async function editInvoice(id, putData) {
  const sanitizedUpdatedUser = sanitizeData(putData);
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/superadmininvoices/${id}`,
      sanitizedUpdatedUser,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating data: ${err}`);
    throw new Error('Invoices data not updated');
  }
}

export function getPaymentsByIdStatic(paymentId, paymentStatic) {
  try {
    const selectedPayment = paymentStatic.filter((item) => item.id === parseInt(paymentId));
    return selectedPayment[0];
  } catch (err) {
    console.error(`Error fetching data: ${err}`);
    throw new Error('Payment data not fetched');
  }
}

// Get All Payment History
export async function getAllPaymentHistory() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/superadminpayments?limit=200`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Payment history not loaded');
  }
}

export async function getPaymentByID(paymentId) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(
      `${APPLICATION_API_BASE_URL}/superadminpayments/id=${paymentId}`,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Payment data not loaded');
  }
}

// Add Payment History
export async function addPaymentHistoryAPI(postdata) {
  postdata.company_id = parseInt(postdata.company_id);
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/superadminpayments`,
      postdata,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error adding payment history: ${err}`);
    throw new Error('Payment history not added');
  }
}

export async function editPaymentAPI(id, putData) {
  const sanitizedUpdatedUser = sanitizeData(putData);
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.put(
      `${APPLICATION_API_BASE_URL}/superadminpayments/${id}`,
      sanitizedUpdatedUser,
      headers
    );
    return data.data.data;
  } catch (err) {
    console.error(`Error updating payment data: ${err}`);
    throw new Error('Payment data not updated');
  }
}

export async function getAllProjects() {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.get(`${APPLICATION_API_BASE_URL}/project?limit=100`, headers);
    return data.data.data;
  } catch (err) {
    console.error(`Error loading data: ${err}`);
    throw new Error('Projects data not loaded');
  }
}

//wriet api for resend email invites
export async function resendEmailInvites(payload) {
  try {
    const headers = await getHeaders();
    const data = await axiosInstance.post(
      `${APPLICATION_API_BASE_URL}/auth/invite`,
      payload,
      headers
    );
    return data.data;
  } catch (err) {
    console.error(`Error connecting: ${err}`);
    throw new Error('Email not connected');
  }
}
