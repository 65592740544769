import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/ui/Button';
import PageNotFoundImg from '../assets/svg/pageNotFound.svg';
import Error404 from '../assets/png/error404.png';

const PageNotFound = ({ homePath }) => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate(homePath);
  };

  return (
    <div className="relative flex flex-col justify-center items-center h-screen bg-gray-100">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${PageNotFoundImg})` }}
      ></div>
      <div className="absolute inset-0 backdrop-blur-sm bg-white/30"></div>
      <div className="relative flex flex-col gap-5 justify-center items-center top-[100px]">
        <img src={Error404} alt="404 Error" className="w-[400px] h-[100%]" />{' '}
        {/* Adjust width and height as needed */}
        <p className="text-xl text-gray-600">
          Uh-oh! Lost in the construction zone? This page isn’t ready yet.
        </p>
        <Button onClick={goToHome}>Head Back Home</Button>
      </div>
    </div>
  );
};

export default PageNotFound;
