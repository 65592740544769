export default function generateLevelPayload(originalPayload) {
  const levels = [];
  const keys = Object.keys(originalPayload);

  // Iterate over the keys and group them by their suffix number
  for (let i = 0; i < keys.length / 3; i++) {
    const nameKey = `Level Name-${i}`;
    const typeKey = `Floor Type-${i}`;
    const unitsKey = `No of Units-${i}`;

    if (
      Object.prototype.hasOwnProperty.call(originalPayload, nameKey) &&
      Object.prototype.hasOwnProperty.call(originalPayload, typeKey) &&
      Object.prototype.hasOwnProperty.call(originalPayload, unitsKey)
    ) {
      const level = {
        name: originalPayload[nameKey],
        floor_type: originalPayload[typeKey],
        no_of_units: parseInt(originalPayload[unitsKey], 10)
      };

      // Only add the level if it has a valid name
      if (level.name) {
        levels.push(level);
      }
    }
  }

  // Assuming block_id and project_id are constants or fetched from somewhere
  const block_id = originalPayload?.block_id; // Replace with actual value
  const project_id = parseInt(originalPayload?.project_id); // Replace with actual value

  return {
    block_id,
    project_id,
    levels
  };
}
