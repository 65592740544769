import axios from 'axios';

import { API_BASE_URL } from '../../../../builder/services/api/configuration/config';
import { USERMANAGEMENT_API_BASE_URL } from '../../../../builder/services/api/configuration/config';
import { DOCGENERATION_API_BASE_URL } from '../../../../builder/services/api/configuration/config';
import { APPLICATION_API_BASE_URL } from '../../../../builder/services/api/configuration/config';
export async function registerUser(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/register`, payload);
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error registering user: ${err}`);
    throw new Error('New user not registered');
  }
}

export async function verifyEmail(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/verify-register`, payload);
    const res = data.data.data;
    return res;
  } catch (err) {
    console.error(`Error verifing email: ${err}`);
    throw new Error('Incorrect code entered');
  }
}
//write api for forget password 
export async function forgetPassword(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/forgot-password`, payload);
    return data;
  } catch (err) {
    console.error(`Error forget password: ${err}`);
    throw new Error('Incorrect email entered');
  }
}

export async function loginUser(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/login`, payload);
    localStorage.setItem('accessToken', data.data.accessToken);
    return data;
  } catch (err) {
    console.error(`Error login user: ${err}`);
    throw new Error('Incorrect email or password');
  }
}

export async function uploadImage(payload) {
  console.log('Comiung to upload image function');

  try {
    const data = await axios.post(`${APPLICATION_API_BASE_URL}/s3/upload`, payload);
    return data.data;
  } catch (err) {
    console.error(`Error uploading file: ${err}`);
    throw new Error('Error uploading file');
  }
}

export async function uploadMultiple(payload) {
  console.log('Comiung to upload image function');

  try {
    const data = await axios.post(`${APPLICATION_API_BASE_URL}/s3/uploads`, payload);
    return data.data;
  } catch (err) {
    console.error(`Error uploading file: ${err}`);
    throw new Error('Error uploading file');
  }
}

export async function sendOtp(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/login-phone`, payload);
    return data;
  } catch (err) {
    console.error(`Error sending OTP: ${err}`);
    throw new Error('Error sending OTP');
  }
}

export async function verifyOtp(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/verify-phone`, payload);
    return data;
  } catch (err) {
    console.error(`Error verifying OTP: ${err}`);
    throw new Error('Incorrect OTP');
  }
}

// Reset Password

export async function createPassword(payload) {
  try {
    const data = await axios.post(`${USERMANAGEMENT_API_BASE_URL}/auth/reset`, payload);
    return data;
  } catch (err) {
    console.error(`Error create Password: ${err}`);
    throw new Error('Incorrect create Password');
  }
}
