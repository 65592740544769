import React from 'react';

const Loader = () => {
  return (
    <div className="w-full h-full bg-white flex justify-center items-center">
      <div className="w-10 h-10 bg-[#0000002a]">&nbsp;</div>
    </div>
  );
};

export default Loader;
