import React, { useEffect, useState } from 'react';
import { getUser, updateUser } from '../../services/api/implementation/impl';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Button from '../../../shared/components/ui/Button';

const EditBuilder = ({ onClose, id, onSuccess }) => {
  const [formData, setFormData] = useState({
    primary_username: '',
    primary_phone_number: '',
    primary_email: '',
    secondary_username: '',
    secondary_phone_number: '',
    secondary_email: ''
  });

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);

  const [primaryUserId, setPrimaryUserId] = useState(null);
  const [secondaryUserId, setSecondaryUserId] = useState(null);

  // Whether or not the user already has secondary user details
  const [hasExistingSecondaryUser, setHasExistingSecondaryUser] = useState(false);

  // Validation functions
  const isValidPhone = (phone) => /^[0-9]{10}$/.test(phone);
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Mutation for updating user
  const { mutateAsync: updateUserMutation, isLoading: isUpdating } = useMutation({
    mutationFn: async ({ userData, userId }) => await updateUser(userData, userId),
    onSuccess: () => {
      toast.success('User updated successfully');
    },
    onError: (err) => {
      toast.error('Error updating user');
      console.error('Error updating user', err);
    }
  });

  // Fetch user data
  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const user = await getUser(id);
        if (isMounted) {
          setPrimaryUserId(user.primary_user_id);
          setSecondaryUserId(user.secondry_user_id);

          // Primary user data
          if (user.primery_user) {
            setFormData((prev) => ({
              ...prev,
              primary_username: user.primery_user.name || '',
              primary_phone_number: user.primery_user.phone_number || '',
              primary_email: user.primery_user.email || ''
            }));
          }

          // Secondary user data
          if (user.secondry_user) {
            setHasExistingSecondaryUser(true);
            setFormData((prev) => ({
              ...prev,
              secondary_username: user.secondry_user.name || '',
              secondary_phone_number: user.secondry_user.phone_number || '',
              secondary_email: user.secondry_user.email || ''
            }));
          }
        }
      } catch (error) {
        console.error('Error fetching user:', error);
        toast.error('Error loading user data');
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [id]);

  // Check if any secondary field is filled or we already have a secondary user
  const hasAnySecondaryData = () => {
    return (
      (formData.secondary_username && formData.secondary_username.trim()) ||
      (formData.secondary_phone_number && formData.secondary_phone_number.trim()) ||
      (formData.secondary_email && formData.secondary_email.trim()) ||
      hasExistingSecondaryUser
    );
  };

  // Validate form data
  const validateForm = (data) => {
    const newErrors = {};

    // Primary field validation
    if (!data.primary_username.trim()) {
      newErrors.primary_username = 'Full Name is required';
    }
    if (!data.primary_phone_number) {
      newErrors.primary_phone_number = 'Phone number is required';
    } else if (!isValidPhone(data.primary_phone_number)) {
      newErrors.primary_phone_number = 'Must be exactly 10 digits';
    }
    if (!data.primary_email) {
      newErrors.primary_email = 'Email is required';
    } else if (!isValidEmail(data.primary_email)) {
      newErrors.primary_email = 'Invalid email address';
    }

    // If any secondary field is filled or there's an existing secondary user, validate all secondary fields
    if (hasAnySecondaryData()) {
      if (!data.secondary_username.trim()) {
        newErrors.secondary_username = 'Secondary Full Name is required';
      }
      if (!data.secondary_phone_number) {
        newErrors.secondary_phone_number = 'Secondary Phone number is required';
      } else if (!isValidPhone(data.secondary_phone_number)) {
        newErrors.secondary_phone_number = 'Phone number must be exactly 10 digits';
      }
      if (!data.secondary_email) {
        newErrors.secondary_email = 'Secondary Email is required';
      } else if (!isValidEmail(data.secondary_email)) {
        newErrors.secondary_email = 'Invalid email address';
      }

      // Check for duplicates
      if (data.secondary_phone_number === data.primary_phone_number) {
        newErrors.secondary_phone_number =
          'Secondary Phone number cannot be same as Primary Phone number';
      }
      if (data.secondary_email === data.primary_email) {
        newErrors.secondary_email =
          'Secondary Email cannot be same as Primary Email';
      }
    }

    return newErrors;
  };

  // Handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;

    // Restrict phone number to digits only
    if (name.includes('phone_number')) {
      sanitizedValue = value.replace(/[^0-9]/g, '').slice(0, 10);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: sanitizedValue
    }));

    setTouched((prev) => ({
      ...prev,
      [name]: true
    }));
  };

  // Handle blur
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true
    }));
  };

  // Helper for showing errors
  const shouldShowError = (fieldName) =>
    (touched[fieldName] || isSubmitAttempted) && errors[fieldName];

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitAttempted(true);

    // Mark all as touched
    const allTouched = Object.keys(formData).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );
    setTouched(allTouched);

    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Primary user payload
        const primaryUser = {
          name: formData.primary_username,
          phone_number: formData.primary_phone_number,
          email: formData.primary_email
        };

        // Update primary user
        await updateUserMutation({ userId: primaryUserId, userData: primaryUser });

        // If secondary data is present (existing or new), and passes validation
        if (
          hasAnySecondaryData() &&
          formData.secondary_username &&
          formData.secondary_phone_number &&
          formData.secondary_email
        ) {
          const secondaryUser = {
            name: formData.secondary_username,
            phone_number: formData.secondary_phone_number,
            email: formData.secondary_email
          };
          if (secondaryUserId) {
            // Update existing secondary user
            await updateUserMutation({
              userId: secondaryUserId,
              userData: secondaryUser
            });
          } else {
            // Create a new secondary user if needed
            await updateUserMutation({
              userId: null,
              userData: secondaryUser
            });
          }
        }

        onSuccess();
        onClose();
      } catch (error) {
        console.error('Error updating users:', error);
        toast.error('Error updating users');
      }
    }
  };

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
      {/* ---------------------- Primary User Details ---------------------- */}
      <div className="flex flex-col justify-between items-start gap-4">
        <span className="text-lg border-b border-stone-200 w-full pb-2">
          Primary User Details
        </span>

        <div className="w-full flex flex-col gap-2">
          {/* Primary Full Name */}
          <div className="flex flex-col gap-1">
            <label htmlFor="primary_username" className="text-gray-600">
              Full Name *
            </label>
            <input
              id="primary_username"
              name="primary_username"
              type="text"
              className={`w-full p-2 border ${
                shouldShowError('primary_username') ? 'border-red-600' : 'border-stone-300'
              } rounded`}
              value={formData.primary_username}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Full Name"
              aria-invalid={shouldShowError('primary_username') ? 'true' : 'false'}
              aria-describedby={
                shouldShowError('primary_username') ? 'primary_username_error' : undefined
              }
            />
            {shouldShowError('primary_username') && (
              <span id="primary_username_error" className="text-red-600">
                {errors.primary_username}
              </span>
            )}
          </div>

          <div className="w-full flex flex-col md:flex-row md:space-x-6">
            {/* Primary Phone */}
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <label htmlFor="primary_phone_number" className="text-gray-600">
                Phone Number *
              </label>
              <input
                id="primary_phone_number"
                name="primary_phone_number"
                type="text"
                className={`w-full p-2 border ${
                  shouldShowError('primary_phone_number') ? 'border-red-600' : 'border-stone-300'
                } rounded bg-gray-100`}
                value={formData.primary_phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Phone Number"
                disabled
                maxLength={10}
                aria-invalid={shouldShowError('primary_phone_number') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('primary_phone_number') ? 'primary_phone_error' : undefined
                }
              />
              {shouldShowError('primary_phone_number') && (
                <span id="primary_phone_error" className="text-red-600">
                  {errors.primary_phone_number}
                </span>
              )}
            </div>

            {/* Primary Email */}
            <div className="flex flex-col gap-1 w-full md:w-1/2">
              <label htmlFor="primary_email" className="text-gray-600">
                Email Address *
              </label>
              <input
                id="primary_email"
                name="primary_email"
                type="email"
                className={`w-full p-2 border ${
                  shouldShowError('primary_email') ? 'border-red-600' : 'border-stone-300'
                } rounded bg-gray-100`}
                value={formData.primary_email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Email Address"
                disabled
                aria-invalid={shouldShowError('primary_email') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('primary_email') ? 'primary_email_error' : undefined
                }
              />
              {shouldShowError('primary_email') && (
                <span id="primary_email_error" className="text-red-600">
                  {errors.primary_email}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ------------------- Conditionally Render Secondary ------------------- */}
      {hasExistingSecondaryUser && (
        <div className="flex flex-col justify-between items-start gap-4">
          <span className="text-lg border-b border-stone-200 w-full pb-2">
            Secondary User Details
          </span>

          <div className="w-full flex flex-col gap-2">
            {/* Secondary Full Name */}
            <div className="flex flex-col gap-1">
              <label htmlFor="secondary_username" className="text-gray-600">
                Full Name {hasAnySecondaryData() && '*'}
              </label>
              <input
                id="secondary_username"
                name="secondary_username"
                type="text"
                className={`w-full p-2 border ${
                  shouldShowError('secondary_username') ? 'border-red-600' : 'border-stone-300'
                } rounded`}
                value={formData.secondary_username}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Full Name"
                aria-invalid={shouldShowError('secondary_username') ? 'true' : 'false'}
                aria-describedby={
                  shouldShowError('secondary_username') ? 'secondary_username_error' : undefined
                }
              />
              {shouldShowError('secondary_username') && (
                <span id="secondary_username_error" className="text-red-600">
                  {errors.secondary_username}
                </span>
              )}
            </div>

            <div className="w-full flex flex-col md:flex-row md:space-x-6">
              {/* Secondary Phone */}
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <label htmlFor="secondary_phone_number" className="text-gray-600">
                  Phone Number {hasAnySecondaryData() && '*'}
                </label>
                <input
                  id="secondary_phone_number"
                  name="secondary_phone_number"
                  type="text"
                  className={`w-full p-2 border ${
                    shouldShowError('secondary_phone_number') ? 'border-red-600' : 'border-stone-300'
                  } rounded bg-gray-100`} 
                  // ^ We disable if user already existed
                  value={formData.secondary_phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Phone Number"
                  disabled
                  maxLength={10}
                  aria-invalid={shouldShowError('secondary_phone_number') ? 'true' : 'false'}
                  aria-describedby={
                    shouldShowError('secondary_phone_number') ? 'secondary_phone_error' : undefined
                  }
                />
                {shouldShowError('secondary_phone_number') && (
                  <span id="secondary_phone_error" className="text-red-600">
                    {errors.secondary_phone_number}
                  </span>
                )}
              </div>

              {/* Secondary Email */}
              <div className="flex flex-col gap-1 w-full md:w-1/2">
                <label htmlFor="secondary_email" className="text-gray-600">
                  Email Address {hasAnySecondaryData() && '*'}
                </label>
                <input
                  id="secondary_email"
                  name="secondary_email"
                  type="email"
                  className={`w-full p-2 border ${
                    shouldShowError('secondary_email') ? 'border-red-600' : 'border-stone-300'
                  } rounded bg-gray-100`}
                  // ^ Similarly disabled if user exists
                  value={formData.secondary_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Email Address"
                  disabled
                  aria-invalid={shouldShowError('secondary_email') ? 'true' : 'false'}
                  aria-describedby={
                    shouldShowError('secondary_email') ? 'secondary_email_error' : undefined
                  }
                />
                {shouldShowError('secondary_email') && (
                  <span id="secondary_email_error" className="text-red-600">
                    {errors.secondary_email}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* ------------------------ Form Actions ------------------------ */}
      <div className="w-full flex justify-end items-center space-x-4">
        <Button displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" disabled={isUpdating}>
          {isUpdating ? 'Saving...' : 'Save'}
        </Button>
      </div>
    </form>
  );
};

export default EditBuilder;
