import React from 'react';

import LoginForm from '../components/forms/LoginForm';
import HousinGramLogo from '../../shared/assets/svg/group.svg';

const Login = () => {
  /* 
    JSX
  */
  return (
    <div className="text-stone-700 flex justify-center items-stretch h-screen w-screen overflow-hidden">
      <div className="h-screen w-[64%] bg-black">
        <img
          src="https://cdn.pixabay.com/photo/2022/10/03/23/41/house-7497001_1280.png"
          alt="login_vector_image"
          className="w-full h-full !bg-center !bg-cover !object-cover !object-center"
        />
      </div>

      <div className="h-screen w-[36%] bg-white flex flex-col items-center justify-start relative">
      <div className="inline-block w-fit p-4 bg-primary rounded-lg absolute top-[36px] left-[36px]">
        <img src={HousinGramLogo} alt="Logo" className="h-30 w-50 mt-2" />
      </div>
        <div className="w-[92%] rounded-lg p-8 py-14 h-full">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
