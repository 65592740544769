import React from 'react';
import EditTableData from './EditTableData';

function EditTableRow({
  data,
  width,
  identifier,
  onEdit,
  onDelete,
  onView,
  isDeleteEnable,
  isViewEnable,
  isEditEnable,
  handleRowClick,
  dataFormat,
  onRegister,
  headers,
  rowIndex,
  onType,
  editTableType,
  blockType
}) {
  return (
    <div
      className="flex flex-wrap max-w-full justify-between border-b-[1px] p-4 cursor-pointer"
      onClick={() => handleRowClick(data)}>
      {Object.keys(data)?.map((item, index) =>
        data?.[item] === 'Actions' ? (
          <EditTableData
            key={index}
            data={`${data[item]}---${data?.[identifier]}`}
            identifier={identifier}
            width={width}
            alignLeft={index === 0}
            alignRight={index === Object.keys(data).length - 1}
            onEdit={onEdit}
            onDelete={onDelete}
            onView={onView}
            isDeleteEnable={isDeleteEnable}
            isViewEnable={isViewEnable}
            isEditEnable={isEditEnable}
            register_key={`${headers[index]}-${rowIndex}`}
            onType={onType}
            editTableType={editTableType}
            blockType={blockType}
          />
        ) : (
          item !== identifier && (
            <EditTableData
              key={index}
              data={data[item]}
              identifier={identifier}
              width={width}
              alignLeft={index === 0}
              alignRight={index === Object.keys(data).length - 1}
              onEdit={onEdit}
              onDelete={onDelete}
              onView={onView}
              isDeleteEnable={isDeleteEnable}
              isViewEnable={isViewEnable}
              isEditEnable={isEditEnable}
              onRegister={onRegister}
              register_key={`${headers[index]}-${rowIndex}`}
              onType={onType}
              editTableType={editTableType}
              blockType={blockType}
            />
          )
        )
      )}
    </div>
  );
}

export default EditTableRow;
