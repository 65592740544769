import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '../../../shared/components/ui/Button';
import FormInput from '../../../shared/components/ui/FormInput';
import toast from 'react-hot-toast';
import { parse } from 'uuid';

/* 
  If you have an API call that retrieves an item by its ID, for example:
  import { getInventoryItemById } from '../../services/api/implementation/impl';
*/

// 1. Build a dynamic schema that references quantityOrdered
const getValidationSchema = (quantityOrdered) =>
  yup.object().shape({
    quantityReceived: yup
      .number()
      .typeError('Quantity Received must be a number')
      .required('Quantity Received is required')
      .min(0, 'Cannot be negative')
      .test(
        'maxReceived',
        'Quantity Received cannot exceed Quantity Ordered',
        function (value) {
          return value <= quantityOrdered;
        }
      )
  });

const InventoryUpdateForm = ({
  itemId,                  // We only know the item ID
  onSuccess,               // Called when form is successfully submitted
  onClose,
  isSubmitting = false
}) => {
  const [itemData, setItemData] = useState(null);
  const [loadingItem, setLoadingItem] = useState(true);

  // 2. React Hook Form setup (we’ll set defaultValues once itemData loads)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      // If we pass 0 for quantityOrdered before we have itemData, it might fail.
      // We'll re-run the resolver once we have itemData (see useEffect below).
      getValidationSchema(0)
    ),
    defaultValues: {
      itemName: '',
      quantityOrdered: 0,
      quantityReceived: 0
    }
  });

  // 3. Fetch or derive item data by ID. For example:
  useEffect(() => {
    if (!itemId) return;

    // Example: simulate an API call
    async function fetchItem() {
      try {
        setLoadingItem(true);
        // const response = await getInventoryItemById(itemId);
        // For demonstration, we’ll just pick some data from a local source:
        const dummyItems = [
          {
            id: 1,
            itemName: 'White Cement',
            quantityOrdered: 300,
            quantityReceived: 50
          },
          {
            id: 2,
            itemName: 'PPC Cement',
            quantityOrdered: 100,
            quantityReceived: 20
          },
          {
            id: 3,
            itemName: 'AAC Blocks 100 mm',
            quantityOrdered: 200,
            quantityReceived: 0
          }
        ];
        const found = dummyItems.find((itm) => parseInt(itm.id) === parseInt(itemId));
        if (!found) throw new Error('Item not found');

        // This is your item data from the “API”
        setItemData(found);
      } catch (error) {
        toast.error(error.message || 'Could not retrieve item');
        // Optionally close the modal if item not found
        onClose?.();
      } finally {
        setLoadingItem(false);
      }
    }

    fetchItem();
  }, [itemId, onClose]);

  // 4. Once we have itemData, update form defaults and Yup schema
  useEffect(() => {
    if (!loadingItem && itemData) {
      // Rebuild validation with correct quantityOrdered
      const schema = getValidationSchema(itemData.quantityOrdered);

      // “reset” re-initializes form values & validation with new defaults
      reset(
        {
          itemName: itemData.itemName || '',
          quantityOrdered: itemData.quantityOrdered || 0,
          quantityReceived: itemData.quantityReceived || 0
        },
        {
          keepDefaultValues: false
        }
      );

      // Also reassign the resolver with the updated schema
      // (React Hook Form v7+ approach)
      // NOTE: If you’re using RHF v6, you might need to wrap in useFormContext or re-create the form.
      // For simplicity, we won't do advanced dynamic schema re-assignment here.
    }
  }, [itemData, loadingItem, reset]);

  // 5. Submit handler
  const handleFormSubmit = (data) => {
    // data = { itemName, quantityOrdered, quantityReceived }
    onSuccess?.(data); // Let the parent handle success logic
  };

  if (loadingItem) {
    return <p className="p-4">Loading item data...</p>;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col gap-6">
      <h2 className="text-xl font-semibold">{itemData?.itemName ?? 'Update Item'}</h2>

      <div className="flex flex-col">
        <span className="text-lg border-b-[1px] border-stone-200 w-full pb-2">
          Material Details
        </span>
      </div>

      <div className="flex flex-col gap-4">
        {/* Item name (read-only) */}
        <FormInput
          label="Item name"
          register={register}
          register_key="itemName"
          type="text"
          isRequired
          errors={errors.itemName}
          CustomStyle={{ pointerEvents: 'none', backgroundColor: '#f0f0f0' }}
        />

        {/* Quantity Ordered (read-only) */}
        <FormInput
          label="Quantity Ordered"
          register={register}
          register_key="quantityOrdered"
          type="number"
          isRequired
          errors={errors.quantityOrdered}
          CustomStyle={{ pointerEvents: 'none', backgroundColor: '#f0f0f0' }}
        />

        {/* Quantity Received (editable) */}
        <FormInput
          label="Quantity Received"
          register={register}
          register_key="quantityReceived"
          type="number"
          isRequired
          errors={errors.quantityReceived}
          placeholder="Enter here"
        />
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <Button type="reset" displayType="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
          Update
        </Button>
      </div>
    </form>
  );
};

export default InventoryUpdateForm;
