import { useQuery } from '@tanstack/react-query';

import React, { useEffect } from 'react';
import { getAllBillings, postBillings } from '../../../services/api/implementation/impl';
import Table from '../../../../shared/components/layout/Table';
import { useMutation } from '@tanstack/react-query';
import { transformData } from '../../../utils/transformData';
import GridWrapper from '../../../../shared/components/layout/GridWrapper';
const BillingSummary = () => {
  // getAllBillings

  // console.log("billings", billings);
  /*  
    Meta data
  */
  const billingsDataFormat = ['company_id', 'total_billed_amount', 'total_due', 'total_collected'];

  /* 
      Helper method calls
   */
  const { data: billings, refetch: refetchbillings } = useQuery({
    queryKey: ['billings'],
    queryFn: () => getAllBillings()
  });

  const transformedData =
    billings && Object.values(billings)?.map((item) => transformData(item, billingsDataFormat));

  console.log('transformedData', transformedData);

  return (
    <div>
      <div className="mt-[11rem] h-[24rem]">
        <Table
          headers={['Company Name', 'Billed Amount', ' Total Due', 'Total Collected']}
          data={transformedData}
        />
      </div>
    </div>
  );
};

export default BillingSummary;
