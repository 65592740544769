import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../builder/components/layout/AppLayout';
import Table from '../../shared/components/layout/Table';
import Button from '../../shared/components/ui/Button';
import Search from '../../shared/components/ui/Search';
import Header from '../../shared/components/layout/Header';
import Modal from '../../shared/components/ui/Modal';
import { transformData } from '../../super-admin/utils/transformData';
import AddLand from '../components/forms/AddLand';
import EditLand from '../components/forms/EditLand';
import { createLand, editLandById, getAllLands } from '../services/api/implementation/impl';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { filterData } from '../../shared/utils/filterData';
import NoDataFound from '../components/layout/NoDataFound';

const LandsList = () => {
  /* 
    Hooks
  */
  const navigate = useNavigate();

  /* 
    Local state
  */
  const [isOpenAddLand, setIsOpenAddLand] = useState(false);
  const [isOpenEditLand, setIsOpenEditLand] = useState(false);
  const [LandsStaticId, setLandsStaticId] = useState(null);
  const [filterLand, setFilterLand] = useState([]);
  const [landQuery, setLandQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  /* 
    Meta data
  */
  const landsFormat = ['id', 'name', 'number', 'area', 'tehsil', 'actions'];

  /* 
    Data fetching
  */
  const {
    data: lands,
    refetch: refetchLands,
    isError
  } = useQuery({
    queryKey: ['lands'],
    queryFn: getAllLands
  });

  useEffect(() => {
    setFilterLand(lands);
  }, [lands]);

  useEffect(() => {
    refetchLands();
  }, []);

  /* 
    Data mutation
  */
  const { mutate: mutateLand, isLoading: isLoadingNewLand } = useMutation({
    mutationFn: (land) => createLand(land),
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Land created successfully');
      setIsOpenAddLand(false);
      await refetchLands(); // UI data update
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error creating new land', err);
      toast.error('Error creating land');
    }
  });

  const { mutate: handleEditLand, isLoading: isEditingLand } = useMutation({
    mutationFn: (updatedLand) => {
      console.log('Updated Land Payload:', updatedLand); // Log the payload for debugging
      return editLandById(parseInt(LandsStaticId), updatedLand);
    },
    onMutate: () => {
      setIsSubmitting(true); // Disable the button when mutation starts
    },
    onSuccess: async () => {
      setIsSubmitting(false);
      toast.success('Land edited successfully');
      setIsOpenEditLand(false);
      window.location.reload();
      await refetchLands();
    },
    onError: (err) => {
      setIsSubmitting(false);
      console.error('Error editing land:', err); // Log the error for debugging
      toast.error('Error editing land');
    }
  });

  /* 
    Helper method calls
  */
  const handleSearchChange = useCallback(
    (incomingQuery) => {
      if (incomingQuery) {
        const filteredLand = filterData('name', lands, incomingQuery);
        setFilterLand(filteredLand);
      } else {
        setFilterLand(lands);
      }
    },
    [lands]
  );
  const formatNumber = (input) => {
    const number = parseFloat(input);
    if (isNaN(number)) {
      return 'N/A';
    }

    const fixedNumber = number.toFixed(2);

    // Check if the decimal part is "00"
    if (fixedNumber.split('.')[1] === '00') {
      return parseInt(fixedNumber, 10); // Return as an integer
    }

    return fixedNumber; // Return as a string with 2 decimals
  };

  const formatAreaType = (areaType) => {
    return areaType?.replace('sq/', '');
  };

  const landsFormatted = filterLand?.map((item) => {
    // Collect all khasra numbers from each owner
    let allKhasraNumbers = [];

    // Safely iterate over all ownerLandMappings
    if (Array.isArray(item?.ownerLandMappings)) {
      item.ownerLandMappings.forEach((mapping) => {
        if (Array.isArray(mapping?.owner?.khasra)) {
          // Gather all numbers from the owner’s khasra array
          mapping.owner.khasra.forEach((k) => {
            allKhasraNumbers.push(k.number);
          });
        }
      });
    }

    // If we found any khasra numbers, join them; otherwise default to 'N/A'
    const numbers =
      allKhasraNumbers.length > 0
        ? `${allKhasraNumbers.join(', ')} ${item?.state === 'Karnataka' ? '(survey)' : '(khasra)'}`
        : 'N/A';

    // Format total area
    const totalArea =
      `${formatNumber(item?.total_mainArea) || 0} ${
        formatAreaType(item?.total_mainAreaType) || 'sq/hectare'
      }, ${formatNumber(item?.total_subArea) || 0} ${
        formatAreaType(item?.total_subAreaType) || 'gunta'
      }` || 'N/A';

    // Return transformed data
    return transformData(
      {
        ...item,
        number: numbers,
        area: totalArea,
        tehsil: item?.tehsil + ' ' + (item?.state === 'Karnataka' ? '(taluk)' : '(tehsil)') || 'N/A'
      },
      landsFormat
    );
  });

  /* 
    Event handlers
  */
  const handleOnSubmitAddLand = (data) => {
    mutateLand(data);
  };

  const handleToggleAddLand = useCallback(() => {
    setIsOpenAddLand(!isOpenAddLand);
  }, [isOpenAddLand]);

  const handleToggleEditLand = useCallback(
    (id) => {
      setLandsStaticId(id);
      setIsOpenEditLand(!isOpenEditLand);
    },
    [isOpenEditLand]
  );

  if (isError) {
    toast.error(`Error fetching lands`);
    navigate('/');
    return null;
  }

  /* 
    JSX
  */
  return (
    <>
      {isOpenAddLand && (
        <Modal name={'Add Land'} onClose={handleToggleAddLand} width={88} upShift>
          <AddLand
            isSubmitting={isSubmitting}
            onSubmit={handleOnSubmitAddLand}
            onClose={handleToggleAddLand}
          />
        </Modal>
      )}

      {isOpenEditLand && (
        <Modal name={'Edit Land'} onClose={handleToggleEditLand} width={88} upShift>
          <EditLand
            isSubmitting={isSubmitting}
            onSubmit={handleEditLand}
            id={LandsStaticId}
            onClose={handleToggleEditLand}
          />
        </Modal>
      )}

      <div className="bg-gray-100">
        <Layout>
          {/* Header */}
          <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
            <Header data={{ name: 'Lands' }} />
          </div>

          {landsFormatted && landsFormatted?.length > 0 ? (
            <>
              {/* Main content */}
              <div className="flex items-center justify-between px-6 py-4 bg-white fixed top-[4rem] border-t-[2px] border-stone-100 w-[82vw] shadow-sm">
                <Search onChange={handleSearchChange} query={landQuery} />

                <div className="flex gap-5 cursor-pointer">
                  <Button onClick={handleToggleAddLand}>Add Land</Button>
                </div>
              </div>

              {/* Table content */}
              <div className="w-[82%] fixed top-[10rem] h-[34rem] overflow-hidden flex justify-center items-start px-6">
                <Table
                  headers={[
                    'Land Name',
                    'Allotment Number',
                    'Total Area',
                    'Adminstrative Division',
                    'Action'
                  ]}
                  identifier={'id'}
                  isViewEnable={true}
                  onView={(id) => navigate(`/builder/management/land/${id}`)}
                  onEdit={(id) => handleToggleEditLand(id)}
                  data={landsFormatted}
                />
              </div>
            </>
          ) : (
            <NoDataFound buttonText="Add Land" onButtonClick={handleToggleAddLand} />
          )}
        </Layout>
      </div>
    </>
  );
};

export default LandsList;
