import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/layout/AppLayout';
import Header from '../../shared/components/layout/Header';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '../../shared/components/ui/Button';
import AgreementForSale from '../../shared/assets/png/Agreement-for-Sale.png';
import Modal from '../../shared/components/ui/Modal';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getallotmentLetter } from '../services/api/implementation/impl';
import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';
import { AllotmentMissingForm } from '../components/forms/AllotmentMissingForm';
import { set } from 'react-hook-form';

import { getApplicationByDealId } from '../services/api/implementation/impl';

const AgreementFormNew = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const commentsRef = useRef(null);
  const [newComment, setNewComment] = useState('');
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  const [isMissingFieldModalOpen, setIsMissingFieldModalOpen] = useState(false);

  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [documentNumber, setDocumentNumber] = useState(null);
  const [extraCharges, setExtraCharges] = useState(null);
  const [Colonizer, setColonizer] = useState(null);
  const [commencement, setcommencement] = useState(null);

  const { id } = useParams();
  let location = useLocation();
  let pathname = location.pathname;
  let parts = pathname.split('/');
  // console.log(parts[2]);

  console.log(id);

  //api call for getApplicationByDealId
  const { data: applicationData, refetch: refetchApplicationData } = useQuery({
    queryKey: ['getApplicationByDealId', id],
    queryFn: () => getApplicationByDealId(id),
    enabled: !!id
  });

  console.log('applicationdata', applicationData);

  // console.log("applicationdata", applicationData[0]?.id);

  useEffect(() => {
    if (applicationData) {
      console.log(applicationData);
      setSelectedApplicationId(applicationData[0]?.id);
      setExtraCharges(applicationData[0]?.extra_charges);
      setColonizer(applicationData[0]?.colonizer_statement)
      setcommencement(applicationData[0]?.commencement_certificate_statement)
    }
  }, [applicationData, id]);

  console.log({ applicationData });

  const addComment = () => {
    const newDiv = document.createElement('div');
    newDiv.className = 'border p-4 mb-2';
    newDiv.innerHTML = `
      <div class="comment-content">${newComment}</div>
      <button class="mt-2 px-4 py-2 bg-red-500 text-white rounded" onclick="this.parentElement.remove()">Delete Comment</button>
    `;
    commentsRef.current.appendChild(newDiv);
    setNewComment('');
  };

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch('/HTMLpdfFiles/AgreementFOrm.html'); // Correct path relative to public folder
        const text = await response.text();

        // Extract content inside the body tag
        const bodyMatch = text.match(/<body[^>]*>((.|[\n\r])*)<\/body>/im);
        const bodyContent = bodyMatch ? bodyMatch[1] : '';

        // Extract content inside the head tag
        const headMatch = text.match(/<head[^>]*>((.|[\n\r])*)<\/head>/im);
        const headContent = headMatch ? headMatch[1] : '';

        // Append head styles to the document head
        const headElement = document.createElement('div');
        headElement.innerHTML = headContent;
        const styles = headElement.querySelectorAll('style');
        styles.forEach((style) => document.head.appendChild(style));

        setHtmlContent(bodyContent);
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtmlContent();
  }, []);

  // const { data: getAllotmentLetter, refetch: allotmentLetter } = useQuery({
  //   queryKey: ['getAllotmentLetter', id],
  //   queryFn: () => getallotmentLetter(parseInt(id))
  // })

  // console.log({ getAllotmentLetter });

  const { mutate: handleGetAllotmentLetter } = useMutation({
    mutationFn: async (id) => {
      return await getallotmentLetter(id); // Fetch the PDF or binary content
    },
    onSuccess: (data) => {
      const blob = new Blob([data], { type: 'application/pdf' }); // Adjust the type if it's not a PDF
      const url = window.URL.createObjectURL(blob);

      if (!isPreview) {
        // Download as file
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Allotment_Letter${id}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success('Allotment Letter Downloaded');
      } else {
        // Show in modal for preview
        setHtmlContent(url + '#toolbar=0'); // Use this URL in the iframe
        setIsPreviewEnabled(true);
      }
    },
    onError: () => {
      toast.error('Error in downloading allotment letter');
    }
  });

  const handlePreview = () => {
    setIsPreview(true);
    if (!extraCharges || extraCharges.length === 0) {
      toast.error('Please fill all the missing fields');
      openMissingFieldModal();
      return;
    }
    handleGetAllotmentLetter(id); // Only pass id, handle preview via state
  };

  const handleDownload = () => {
    setIsPreview(false);
    if (!extraCharges || extraCharges.length === 0) {
      toast.error('Please fill all the missing fields');
      openMissingFieldModal();
      return;
    }
    if (!commencement) {
      toast.error('Please fill all the missing fields');
      openMissingFieldModal();
      return;
    }
    if (!Colonizer) {
      toast.error('Please fill all the missing fields');
      openMissingFieldModal();
      return;
    }
    handleGetAllotmentLetter(id); // Call without setting isPreview to trigger download
  };

  const openMissingFieldModal = () => {
    console.log('Open Missing Field Modal');
    setIsMissingFieldModalOpen(true);
  };

  const missinModalClose = () => {
    setIsMissingFieldModalOpen(false);
    setIsPreview(false);
  };

  return (
    <Layout>
      {isPreviewEnabled && (
        <Modal
          width={40}
          name={'Agreement Form Preview'}
          onClose={() => setIsPreviewEnabled(false)}>
          <div className="h-[500px]">
            <iframe src={htmlContent} width="100%" height="100%" style={{ border: 'none' }} />{' '}
          </div>
        </Modal>
      )}

      {isMissingFieldModalOpen && (
        <Modal width={68} name={'Allotment Letter'} onClose={missinModalClose}>
          <AllotmentMissingForm
            onSubmit={() => console.log('submitted')}
            onClose={missinModalClose}
            selectedApplicationId={selectedApplicationId}
            onSuccess={() => refetchApplicationData()}
            data={applicationData}
          />
        </Modal>
      )}
      <div className="fixed z-[20] top-0 left-[18vw] w-[82vw]">
        <Header data={{ name: `Company > ${parts[2]} Letter` }} backArrow={true} />
      </div>

      <div className="w-full flex flex-col justify-between items-center gap-4 mt-[4rem] px-5">
        <div className="flex justify-end items-end w-full mt-4 px-4 py-2 gap-5">
          {((!extraCharges || extraCharges.length == 0) || !Colonizer || !commencement) && (
            <Button
              className="border px-4 py-2 rounded-lg bg-[#2a575d] text-white"
              onClick={openMissingFieldModal}>
              Add Missing Fields
            </Button>
          )}

          <Button
            className="border px-4 py-2 rounded-lg bg-[#2a575d] text-white"
            onClick={handleDownload}>
            Download
          </Button>
        </div>

        <div className="h-[400px] relative w-full border-2 flex items-center justify-center bg-cover bg-center">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${AgreementForSale})` }}></div>
          <div className="absolute inset-0 backdrop-blur-sm bg-white/30"></div>
          <div className="relative">
            <Button
              className="border px-4 py-2 rounded-lg bg-[#2a575d] text-white"
              onClick={handlePreview}>
              Preview
            </Button>{' '}
          </div>
        </div>

        <div className="w-full p-8 bg-white">
          <h2 className="text-xl font-bold mb-4">Comments</h2>
          <div className="flex flex-col gap-4">
            <ReactQuill value={newComment} onChange={setNewComment} />
            <div>
              <Button onClick={addComment}>Add Comment</Button>
            </div>
          </div>
        </div>
      </div >
    </Layout >
  );
};

export default AgreementFormNew;
